import React from "react";
import { PublicClientApplication } from "@azure/msal-browser";
import ReactDOM from 'react-dom/client';
import { MsalProvider } from "@azure/msal-react";

/*React component*/
import App from "./App.js";

import { msalConfig } from "./msalAuthConfig";

const msalInstance = new PublicClientApplication(msalConfig);

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <MsalProvider instance={msalInstance}>
  <React.StrictMode>
    <App />
  </React.StrictMode>
</MsalProvider>
);

