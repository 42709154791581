import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

/*Import components */
import GlobalUserTabProjects from "../components/projects/GlobalUserTabProjects";
import FacultetUserTabProjects from "../components/projects/FacultetUserTabProjects";
import FacultetUserTabProjectsOthers from "../components/projects/FacultetUserTabProjectOthers";

/*Api */
import { fetchDELETE, fetchGET } from "../apiHelper";
import {
  brisanjeProjekta,
  seznamProjektovGlobalni,
  seznamProjektovGlobalniVsi,
  seznamProjektovFakultetni,
  seznamProjektovFakultetniVsi,
  seznamOstalihProjektovFakultetni,
  seznamOstalihProjektovFakultetniVsi,
} from "../listOfApi";

/*React-Bootstrap components */
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Card from "react-bootstrap/Card";
import Modal from "react-bootstrap/Modal";

/*Import Material-icons */
import { AiFillInfoCircle } from "react-icons/ai";
import { Tooltip as ReactTooltip } from "react-tooltip";

/*Spinner*/
import SpinnerLoading from "./SpinnerLoading";

const Projects = ({ role }) => {
  const [globalniProjekti, setGlobalniProjekti] = useState([]);
  const [globalniProjektiVsi, setGlobalniProjektiVsi] = useState([]);

  const [fakultetniProjekti, setFakultetniProjekti] = useState([]);
  const [fakultetniProjektiVsi, setFakultetniProjektiVsi] = useState([]);

  const [fakultetniOstaliProjekti, setFakultetniOstaliProjekti] = useState([]);
  const [fakultetniOstaliProjektiVsi, setfakultetniOstaliProjektiVsi] =
    useState([]);

  const [showModal, setShowModal] = useState(false);
  const [projectId, setProjectId] = useState("");
  const [projectName, setProjectName] = useState("");
  const [showRejectedProjects, setShowRejectedProjects] = useState(false);
  const [projectsLoaderUrejanje, setProjectsLoaderUrejanje] = useState(true);
  const [projectsLoaderOstali, setProjectsLoaderOstali] = useState(true);
  const [tooltipSifranti, setTooltipSifranti] = useState({});

  //Pridobi projekte glede na vlogo
  useEffect(() => {
    //Globalni skrbnik
    if (role.skrbnik === false) {
      setProjectsLoaderUrejanje(true);

      if (showRejectedProjects === false) {
        seznamProjektovGlobalniHelper();
      } else {
        seznamProjektovGlobalniVsiHelper();
      }
    }
    //Fakultetni skrbnik
    else if (role.skrbnik === true) {
      setProjectsLoaderUrejanje(true);
      setProjectsLoaderOstali(true);

      if (showRejectedProjects === false) {
        seznamProjektovFakultetniHelper();
        seznamOstalihProjektovFakultetniHelper();
      } else {
        seznamProjektovFakultetniVsiHelper();
        seznamOstalihProjektovFakultetniVsiHelper();
      }
    }
  }, [showRejectedProjects, role.skrbnik]);

  /*TOOLTIPS JSON TOOLTIP SIFRANT */
  const getData = () => {
    fetch("../../tooltip.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        setTooltipSifranti(myJson);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  // Globalni admin - vsi projekti razen zavrnjenih
  const seznamProjektovGlobalniHelper = () => {
    fetchGET(seznamProjektovGlobalni).then((projects) => {
      if (projects !== 0) {
        const setData = projects.map((project) => {
          return {
            ...project,
            datumZacetek: new Date(project.zacetek).toLocaleDateString(),
            datumKonec: new Date(project.konec).toLocaleDateString(),
          };
        });

        setGlobalniProjekti(setData);
        setProjectsLoaderUrejanje(false);
      }
    });
  };

  // Globalni admin - vsi projekti vključno z zavrnjenimi
  const seznamProjektovGlobalniVsiHelper = () => {
    fetchGET(seznamProjektovGlobalniVsi).then((projects) => {
      if (projects !== 0) {
        const setData = projects.map((project) => {
          return {
            ...project,
            datumZacetek: new Date(project.zacetek).toLocaleDateString(),
            datumKonec: new Date(project.konec).toLocaleDateString(),
          };
        });

        setGlobalniProjektiVsi(setData);
        setProjectsLoaderUrejanje(false);
      }
    });
  };

  // Fakultetni skrbnik - vsi projekti članice fakultetnega skrbnika razen zavrnjenih
  const seznamProjektovFakultetniHelper = () => {
    fetchGET(seznamProjektovFakultetni).then((projects) => {
      if (projects !== 0) {
        const setData = projects.map((project) => {
          return {
            ...project,
            datumZacetek: new Date(project.zacetek).toLocaleDateString(),
            datumKonec: new Date(project.konec).toLocaleDateString(),
          };
        });

        setFakultetniProjekti(setData);
        setProjectsLoaderUrejanje(false);
      }
    });
  };

  // Fakultetni skrbnik - vsi projekti članice fakultetnega skrbnika vključno z zavrnjenimi
  const seznamProjektovFakultetniVsiHelper = () => {
    fetchGET(seznamProjektovFakultetniVsi).then((projects) => {
      if (projects !== 0) {
        const setData = projects.map((project) => {
          return {
            ...project,
            datumZacetek: new Date(project.zacetek).toLocaleDateString(),
            datumKonec: new Date(project.konec).toLocaleDateString(),
          };
        });

        setFakultetniProjektiVsi(setData);
        setProjectsLoaderUrejanje(false);
      }
    });
  };

  // Fakultetni skrbnik - vsi ostali projekti (razen zavrnjenih), kjer ne sodeluje članica fakultetnega skrbnika
  const seznamOstalihProjektovFakultetniHelper = () => {
    fetchGET(seznamOstalihProjektovFakultetni).then((projects) => {
      if (projects !== 0) {
        const setData = projects.map((project) => {
          return {
            ...project,
            datumZacetek: new Date(project.zacetek).toLocaleDateString(),
            datumKonec: new Date(project.konec).toLocaleDateString(),
          };
        });

        setFakultetniOstaliProjekti(setData);
        setProjectsLoaderOstali(false);
      } else {
      }
    });
  };

  // Fakultetni skrbnik - vsi ostali projekti, kjer ne sodeluje članica fakultetnega skrbnika
  const seznamOstalihProjektovFakultetniVsiHelper = () => {
    fetchGET(seznamOstalihProjektovFakultetniVsi).then((projects) => {
      if (projects !== 0) {
        const setData = projects.map((project) => {
          return {
            ...project,
            datumZacetek: new Date(project.zacetek).toLocaleDateString(),
            datumKonec: new Date(project.konec).toLocaleDateString(),
          };
        });

        setfakultetniOstaliProjektiVsi(setData);
        setProjectsLoaderOstali(false);
      } else {
      }
    });
  };

  //upravljanje modalnega okna
  const setDataForDeleteModal = (id, naziv) => {
    setShowModal(true);
    setProjectId(id);
    setProjectName(naziv);
  };

  //Brisanje projekta
  const handleDelete = () => {
    fetchDELETE(`${brisanjeProjekta}/${projectId}`).then((deleted) => {
      if (deleted === 1) {
        setShowModal(false);
        window.location.reload();
      } else {
        //TODO: prišlo je do napake pri brisanju podatkov.
      }
    });
  };

  return (
    <Container fluid>
      {(role.projekti === 1 || role.projekti === 2) && (
        <>
          <ReactTooltip id="tab-tooltip" style={{ zIndex: 1000 }} />
          <Modal
            show={showModal}
            onHide={() => {
              setShowModal(false);
            }}
          >
            <Modal.Body>
              Želite izbrisati projekt: <strong>{projectName}</strong> ?
            </Modal.Body>
            <Modal.Footer>
              <Button
                className="btn btn-danger"
                variant="secondary"
                onClick={handleDelete}
              >
                Izbriši
              </Button>
              <Button
                variant="primary"
                onClick={() => {
                  setShowModal(false);
                }}
              >
                Ne
              </Button>
            </Modal.Footer>
          </Modal>
          <Row>
            <Col className="main-header font-weight-bold">Projekti</Col>
          </Row>
          <Row>
            <Col className="header-divider">
              <hr />
            </Col>
          </Row>

          <Tabs id="controlled-tab-example" defaultActiveKey="urejanje">
            <Tab
              eventKey="urejanje"
              title={
                <span>
                  {role.skrbnik ? "Projekti članice" : "Urejanje projektov"}
                  <AiFillInfoCircle
                    className="ms-1"
                    data-tooltip-id="tab-tooltip"
                    data-tooltip-variant="dark"
                    data-tooltip-content={
                      role.skrbnik === true
                        ? "Projekti, v katerih sodeluje vaša članica."
                        : "Vsi projekti"
                    }
                  />
                </span>
              }
            >
              <Row className="mt-3">
                <Col>
                  <div className="float-end">
                    {role.projekti === 2 && (
                      <Link
                        className="programs-button-add-text "
                        to={`/Projekti/DodajProjekt/`}
                      >
                        <Button variant="outline-primary border-0">
                          Dodaj projekt
                        </Button>
                      </Link>
                    )}
                  </div>
                  <div className="float-end me-4 programs-checkbox">
                    <span className="me-2">Prikaži zavrnjene projekte</span>
                    <input
                      type="checkbox"
                      checked={showRejectedProjects}
                      onChange={() =>
                        setShowRejectedProjects(!showRejectedProjects)
                      }
                    />
                  </div>
                </Col>
              </Row>
              {/* Prikaz projektov za globalnega uporabnika */}
              {!projectsLoaderUrejanje && role.skrbnik === false && (
                <GlobalUserTabProjects
                  role={role}
                  showRejectedProjects={showRejectedProjects}
                  globalniProjekti={globalniProjekti}
                  globalniProjektiVsi={globalniProjektiVsi}
                  setDataForDeleteModal={setDataForDeleteModal}
                />
              )}
              {/*Prikaz projektobv za fakultetnega skrbnika - pregled kjer smo skrbnik in partner */}
              {!projectsLoaderUrejanje && role.skrbnik === true && (
                <FacultetUserTabProjects
                  role={role}
                  showRejectedProjects={showRejectedProjects}
                  fakultetniProjekti={fakultetniProjekti}
                  fakultetniProjektiVsi={fakultetniProjektiVsi}
                  setDataForDeleteModal={setDataForDeleteModal}
                />
              )}
              {projectsLoaderUrejanje && <SpinnerLoading />}
            </Tab>

            {role.skrbnik === true && (
              <Tab
                eventKey="ostali"
                title={
                  <span>
                    Ostali projekti UM{" "}
                    <AiFillInfoCircle
                      className="mr-1"
                      data-tooltip-id="tab-tooltip"
                      data-tooltip-variant="dark"
                      data-tooltip-content={
                        "Ostali projekti UM (omejen dostop za vpogled v podatke)."
                      }
                    />
                  </span>
                }
              >
                <Row className="mt-3">
                  <Col>
                    <div className="float-end me-4 programs-checkbox">
                      <span className="me-2">Prikaži zavrnjene projekte</span>
                      <input
                        type="checkbox"
                        checked={showRejectedProjects}
                        onChange={() =>
                          setShowRejectedProjects(!showRejectedProjects)
                        }
                      />
                    </div>
                  </Col>
                </Row>
                {/* Prikaz projektov za ostale članice */}
                {!projectsLoaderOstali && (
                  <FacultetUserTabProjectsOthers
                    role={role}
                    showRejectedProjects={showRejectedProjects}
                    fakultetniOstaliProjekti={fakultetniOstaliProjekti}
                    fakultetniOstaliProjektiVsi={fakultetniOstaliProjektiVsi}
                  />
                )}
                {projectsLoaderOstali && <SpinnerLoading />}
              </Tab>
            )}
          </Tabs>
        </>
      )}
    </Container>
  );
};

export default Projects;
