import React, { useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import { useIdleTimer } from 'react-idle-timer'
import { NavLink} from "react-router-dom";


/* React-bootstrap komponente */
import Navbar from "react-bootstrap/Navbar";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal'

import { FiLogOut } from "react-icons/fi";

/* Logo import*/
import logotipum from "./logotipum.png"

//Nastavitve za session in prompt time
const timeout = 3600000
const promptBeforeIdle = 300000


const Header = ({ isAuthenticated }) => {
  const { instance, accounts } = useMsal();

  const [remaining, setRemaining] = useState(timeout)
  const [showModal, setShowModal] = useState(false)
  const [state, setState] = useState("active")

  //samodejni izpis iz aplikacije
  const onIdle = () => {
    setState("idle")
    setShowModal(false);
    handleLogout();
  }

  //odpiranje modalnega okna
  const onPrompt = () => {
    if (isAuthenticated) {
      setState("Prompted")
      setShowModal(true)
    }
  }

  // zaznavanaje dejavnosti v aplikaciji
  const onActive = () => {
    setState("Active")
    setShowModal(false)
  }

  const { getRemainingTime, activate } = useIdleTimer({
    onPrompt,
    onActive,
    onIdle,
    timeout,
    promptBeforeIdle,
    throttle: 500,
    events: ['click']
  })

  useEffect(() => {
    if (isAuthenticated) {
      const interval = setInterval(() => {
        setRemaining(Math.ceil(getRemainingTime() / 1000))
      }, 500)

      return () => {
        clearInterval(interval)
      }
    }
  })

  //Resetiranje časa seje z klikom na gumb v modalnem oknu
  const handleRenewButtonClick = () => {
    activate();
    setShowModal(false)
  }

  //const timeTillPrompt = Math.max(remaining - promptBeforeIdle / 1000, 0)

  //Funkcija za izpis iz aplikacije (samodejno vzame obstoječi account brez azure prompt)
  const handleLogout = () => {
    let id = accounts[0].homeAccountId
    const currentAccount = instance.getAccountByHomeId(id);
    const logoutHint = currentAccount.idTokenClaims.login_hint;

    instance.logoutRedirect({
      onRedirectNavigate: () => {
        return false;
      },
      account: currentAccount,
      logoutHint: logoutHint
    });

  }


  const formatTime = (sessionTime) => {
    const minutes = Math.floor(sessionTime / 60);
    const seconds = sessionTime % 60;

    return `${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
  };


  return (
    <>
      <Modal
        show={showModal}
        centered
        dialogClassName="sessionModal"
        backdrop="static"
        onHide={() => setShowModal(false)}
      >
        <Modal.Body>
          Seja se bo iztekla čez: <span className={remaining < 10 ? "text-danger" : "text-success"}> {formatTime(remaining)}</span>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={() => {
              handleRenewButtonClick()
            }}
          >
            Podaljšaj sejo
          </Button>
          <Button
            variant="outline-primary"
            onClick={handleLogout}
          >
            Izpis iz aplikacije
          </Button>
        </Modal.Footer>
      </Modal>
      <Col className="bg-white">
        {process.env.REACT_APP_ENVIRONMENT === "local" && (
          <div className="environment local">
            UNIVERZA V MARIBORU - BAZA PROJEKTOV - LOKALNI SISTEM
          </div>
        )}
        {process.env.REACT_APP_ENVIRONMENT === "dev" && (
          <div className="environment dev">
            UNIVERZA V MARIBORU - BAZA PROJEKTOV - RAZVOJNI SISTEM
          </div>
        )}
        {process.env.REACT_APP_ENVIRONMENT === "test" && (
          <div className="environment test">
            UNIVERZA V MARIBORU - BAZA PROJEKTOV - TESTNI SISTEM
          </div>
        )}

        <Navbar fixed="top" className="header left-header">
          <Navbar.Brand className="header-brand">
            <NavLink to="/">
              <img
                src={logotipum}
                height="80"
                className="d-inline-block align-top"
                alt="logo univerza"
              />
            </NavLink>
          </Navbar.Brand>
        </Navbar>
        <Navbar fixed="top" className="header right-header">
          <Navbar.Text className="mt-1 ms-2 header-tittle">
            BAZA PROJEKTOV
          </Navbar.Text>

          <Navbar.Toggle />
          <Navbar.Collapse className="justify-content-end">
            {isAuthenticated && (
              <Navbar.Text  className="d-flex me-2">
                {accounts[0] && accounts[0].username}
                <Nav.Link
                  title={"Odjava"}
                  onClick={handleLogout}
                  onStitle="Odjava"
                  className="p-0 navbar-text ms-3"
                >
                  <FiLogOut className="sidebar-icon" />
                </Nav.Link>
              </Navbar.Text>
            )}

          </Navbar.Collapse>
        </Navbar>
      </Col>
    </>
  );
};

export default Header;
