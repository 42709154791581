import React from "react";
import { Spinner } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

function SpinnerLoading() {
  return (
    <Row className="mt-5 mb-3">
      <Col className="text-center">
        <Spinner animation="border" role="status" />
      </Col>
    </Row>
  );
}

export default SpinnerLoading;
