import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

/* Import API */
import {
  podatkiZaProgram,
  urejanjePrograma,
  potDoPrograma,
} from '../listOfApi';
import { fetchGET, fetchPUT } from '../apiHelper';

/* React-Bootstrap komponente */
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

/* React-Icons */
import { AiOutlineEdit } from 'react-icons/ai';

const EditProgram = ({ role }) => {
  /*Pridobljen parameter iz ID */
  const { id } = useParams();
  const history = useNavigate();

  const [programData, setProgramData] = useState({
    objectID: '',
    parentID: '',
    naziv: '',
    naziv_eng: '',
    nivo: '',
    obtezitev: '',
    aktiven: false,
  });
  const [programPoti, setProgramPoti] = useState([]);
  const [invalidProgramId, setInvalidProgramId] = useState(false);
  const [editError, setEditError] = useState(false);

  useEffect(() => {
    fetchGET(`${podatkiZaProgram}/${id}`).then((program) => {
      if (program !== 0) {
        setProgramData({
          objectID: program.objectID,
          parentID: program.parentID,
          naziv: program.naziv,
          naziv_eng: program.naziv_eng,
          nivo: program.nivo,
          obtezitev: program.obtezitev,
          aktiven: program.aktiven,
        });

        fetchGET(`${potDoPrograma}/${id}`).then((poti) => {
          setProgramPoti(poti);
        });
      } else {
        setInvalidProgramId(true);
      }
    });
  }, []);

  const handleSubmitForm = (e) => {
    e.preventDefault();
    fetchPUT(urejanjePrograma, programData).then((data) => {
      if (data !== 0) {
        history(-1);
      } else {
        setEditError(true);
      }
    });
  };

  const handleFormData = (e) => {
    let value = checkFormDataType(e);
    setProgramData({ ...programData, [e.target.name]: value });
  };

  const checkFormDataType = (e) => {
    let value;
    if (e.target.name === 'aktiven') {
      value = !programData.aktiven;
    } else if (e.target.name === 'obtezitev') {
      value = parseInt(e.target.value);
    } else {
      value = e.target.value;
    }
    return value;
  };

  return (
    <Container fluid>
      {role.programi === 2 && (
        <>
          {invalidProgramId && <p>Program ne obstaja!</p>}
          {!invalidProgramId && (
            <>
              <Row>
                <Col className='main-header font-weight-bold'>
                  <AiOutlineEdit className='me-2' />
                  {programData.naziv}
                </Col>
              </Row>
              <Row>
                <Col className='header-divider'>
                  <hr />
                </Col>
              </Row>
              {editError && (
                <Row className='mb-3'>
                  <h3>3Prišlo je do napake pri shranjevanju sprememb</h3>
                </Row>
              )}
              <Row className='mb-3'>
                <Col>
                  <div className='mb-3'>
                    <span> Program (stuktura) :</span>
                  </div>
                  {programPoti.map((programi, index) => {
                    return (
                      <p className={`mb-2 ps-1 ms-${2 + index}`} key={index}>
                        - {programi}
                      </p>
                    );
                  })}
                </Col>
              </Row>
              <Row>
                <Col className='mt-3'>
                  <Form onSubmit={handleSubmitForm}>
                    <Form.Group controlId='formNaziv'>
                      <Row>
                        <Col>
                          <Form.Label>Naziv</Form.Label>
                          <Form.Control
                            name='naziv'
                            type='text'
                            required
                            minLength='3'
                            maxLength='255'
                            value={programData.naziv}
                            onChange={handleFormData}
                            placeholder='Naziv programa'
                          />
                        </Col>
                        <Col>
                          <Form.Label>Angleški naziv</Form.Label>
                          <Form.Control
                            name='naziv_eng'
                            type='text'
                            maxLength='255'
                            value={programData.naziv_eng}
                            onChange={handleFormData}
                            placeholder='Naziv programa v Angleščini'
                          />
                        </Col>
                      </Row>
                    </Form.Group>
                    <Form.Group controlId='formObtezitev'>
                      <Form.Label className='mt-3'>Utež</Form.Label>
                      <Form.Control
                        as='select'
                        value={programData.obtezitev}
                        name='obtezitev'
                        onChange={handleFormData}
                      >
                        <option value='1'>1</option>
                        <option value='2'>2</option>
                        <option value='3'>3</option>
                        <option value='4'>4</option>
                        <option value='5'>5</option>
                        <option value='6'>6</option>
                        <option value='7'>7</option>
                        <option value='8'>8</option>
                        <option value='9'>9</option>
                        <option value='10'>10</option>
                      </Form.Control>
                    </Form.Group>

                    <Form.Group className='mt-3' controlId='formBasicCheckbox'>
                      <Form.Check
                        name='aktiven'
                        type='checkbox'
                        checked={programData.aktiven}
                        onChange={handleFormData}
                        label='Aktiven'
                      />
                    </Form.Group>
                    <div className='mt-3'>
                      <Button variant='primary' type='submit'>
                        Shrani
                      </Button>
                      <Button
                        variant='outline-primary ms-3'
                        onClick={() => {
                          history(-1);
                        }}
                      >
                        Prekliči
                      </Button>
                    </div>
                  </Form>
                </Col>
              </Row>
            </>
          )}
        </>
      )}
    </Container>
  );
};

export default EditProgram;
