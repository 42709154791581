import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

/*Import API*/
import {
  vnosNovegaPrograma,
  potDoPrograma,
  programStrukturaAPI,
} from '../listOfApi';
import { fetchPOST, fetchGET } from '../apiHelper';

/*React-Bootstrap components */
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

/*React-icons*/
import { BiLayerPlus } from 'react-icons/bi';

const AddProgram = ({ role }) => {
  /*Pridobljen parameter iz ID */
  const { id } = useParams();
  const history = useNavigate();

  const [programData, setProgramData] = useState({
    naziv: '',
    naziv_eng: '',
    obtezitev: 0,
    nivo: 1,
    aktiven: false,
    parentID: 0,
  });
  const [headerTitle, setHeaderTitle] = useState('program');
  const [programPoti, setProgramPoti] = useState([]);
  const [addProgramError, setAddProgramError] = useState(false);
  const [invalidId, setInvalidId] = useState(true);
  const [programNivo, setProgramNivo] = useState('');

  useEffect(() => {
    /* Se izvede v primeru da preko url dobimo ID za K1,K2,K3,K4 */
    if (id !== undefined) {
      fetchGET(`${programStrukturaAPI}/${id}`).then((programi) => {
        if (programi !== 0) {
          setInvalidId(false);
          setProgramNivo(programi.nivo);

          fetchGET(`${potDoPrograma}/${id}`).then((poti) => {
            setProgramPoti(poti);
          });
        }
      });
      /* Se izvede ko gre za program K1 ki še nima ID */
    } else if (id === undefined) {
      setInvalidId(false);
    }
  }, []);

  /* Se izvede ob vsaki spremembi vrednosti v nivoju */
  useEffect(() => {
    setPropertysForProgram();
  }, [programNivo]);

  const setPropertysForProgram = () => {
    if (id !== undefined) {
      setHeaderTitle('podprogram');
      setProgramData({
        ...programData,
        parentID: parseInt(id),
        nivo: parseInt(programNivo) + 1,
      });
    } else if (id === undefined) {
      setProgramData({
        ...programData,
        nivo: 1,
      });
    }
  };

  const handleSubmitForm = (e) => {
    e.preventDefault();
    fetchPOST(vnosNovegaPrograma, programData).then((program) => {
      if (program === 1) {
        history(-1);
      } else {
        setAddProgramError(true);
      }
    });
  };

  const handleFormData = (e) => {
    let value = checkFormDataType(e);
    setProgramData({ ...programData, [e.target.name]: value });
  };

  const checkFormDataType = (e) => {
    let value;
    if (e.target.name === 'aktiven') {
      value = !programData.aktiven;
    } else if (e.target.name === 'obtezitev' || e.target.name === 'nivo') {
      value = parseInt(e.target.value);
    } else {
      value = e.target.value;
    }
    return value;
  };

  return (
    <Container fluid>
      {role.programi === 2 && (
        <>
          {invalidId && <p>Program ne obstaja...!</p>}
          {!invalidId && (
            <>
              <Row>
                <Col className='main-header font-weight-bold'>
                  <BiLayerPlus className='me-2' />
                  Dodaj <span>{headerTitle}</span>
                </Col>
              </Row>
              <Row>
                <Col className='header-divider'>
                  <hr />
                </Col>
              </Row>
              {addProgramError && (
                <Row className='mb-3'>
                  <h3>3Prišlo je do napake pri vnosu programa/podprograma</h3>
                </Row>
              )}
              <Row className='mb-3'>
                {!invalidId && (
                  <Col>
                    {id && (
                      <div className='mb-3'>
                        <span> Program (struktura):</span>
                      </div>
                    )}
                    {programPoti.map((programi, index) => {
                      return (
                        <p className={`mb-2 ps-1 ms-${2 + index}`} key={index}>
                          - {programi}
                        </p>
                      );
                    })}
                  </Col>
                )}
              </Row>
              <Row>
                <Col className='mt-1'>
                  <Form onSubmit={handleSubmitForm}>
                    <Form.Group controlId='formNaziv'>
                      <Row>
                        <Col>
                          <Form.Label>Naziv</Form.Label>
                          <Form.Control
                            name='naziv'
                            type='text'
                            required
                            minLength='3'
                            maxLength='255'
                            value={programData.naziv}
                            onChange={handleFormData}
                            placeholder='Naziv programa'
                          />
                        </Col>
                        <Col>
                          <Form.Label>Angleški naziv</Form.Label>
                          <Form.Control
                            name='naziv_eng'
                            type='text'
                            maxLength='255'
                            value={programData.naziv_eng}
                            onChange={handleFormData}
                            placeholder='Naziv programa v Angleščini'
                          />
                        </Col>
                      </Row>
                    </Form.Group>

                    <Form.Group controlId='formObtezitev' className='mt-3'>
                      <Form.Label>Utež</Form.Label>
                      <Form.Control
                        as='select'
                        name='obtezitev'
                        onChange={handleFormData}
                      >
                        <option value='1'>1</option>
                        <option value='2'>2</option>
                        <option value='3'>3</option>
                        <option value='4'>4</option>
                        <option value='5'>5</option>
                        <option value='6'>6</option>
                        <option value='7'>7</option>
                        <option value='8'>8</option>
                        <option value='9'>9</option>
                        <option value='10'>10</option>
                      </Form.Control>
                    </Form.Group>

                    <Form.Group controlId='formBasicCheckbox' className='mt-3 mb-3'>
                      <Form.Check
                        name='aktiven'
                        type='checkbox'
                        onChange={handleFormData}
                        label='Aktiven'
                      />
                    </Form.Group>

                      <Button variant='primary' type='submit'>
                      Dodaj
                    </Button>
                    <Button
                      variant='outline-primary'
                      className='ms-3'
                      onClick={() => {
                        history(-1);
                      }}
                    >
                      Prekliči
                    </Button>
                  </Form>
                </Col>

              </Row>
            </>
          )}
        </>
      )}
    </Container>
  );
};

export default AddProgram;
