import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import SelectSearch from "react-select-search";

/*Import API helpers */
import { fetchPOST, fetchGET } from "../apiHelper";
import {
  vnosNovegaRazpisa,
  seznamPodprogramov,
  prviNivoProgramiAktivni,
  financiranjeKategorije,
} from "../listOfApi";

/*React-Bootstrap components */
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

/*React-icons*/
import { MdAddToPhotos } from "react-icons/md";

const AddTenders = ({ role }) => {
  const history = useNavigate();

  const utezi = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"];

  const [tenderData, setTenderData] = useState({
    razpis_naziv: "",
    obtezitev: 5,
    opis: "",
    povezava: "",
    pomemben: false,
    odprt: false,
    odprt_tekst: "",
    prijava_datum: "",
    prijava_ura: "",
    sifrantARRS: false,
    sifrantCERIF: false,
    sifrantKLASIUS: false,
    programID: "",
    id_financiranje_kategorija: "",
  });
  const [vnosError, setVnosError] = useState(false);
  const [validacijaError, setValidacijaError] = useState(false);
  const [prviNivoOptions, setPrviNivoOptions] = useState([]);
  const [ostaliNivojiOptions, setOstaliNivojiOptions] = useState([]);
  const [financiranjeKatego, setFinanciranjeKatego] = useState([]);
  const [podprogramValue, setPodprogramValue] = useState("");

  /* Se sproži ob vsaki spremembi glede na check box "razpis odprte narave" */
  useEffect(() => {
    if (!tenderData.odprt) {
      setTenderData({
        ...tenderData,
        odprt_tekst: " ",
        prijava_datum: "",
      });
    } else if (tenderData.odprt) {
      setTenderData({
        ...tenderData,
        prijava_datum: "2100-01-01",
        prijava_ura: "",
        odprt_tekst: "Razpis je odprt do porabe sredstev",
      });
    }
  }, [tenderData.odprt]);

  /* Pridobivanje kategorij financiranja */
  useEffect(() => {
    fetchGET(financiranjeKategorije).then((finanKategorije) => {
      const financiranjeKategorijeOptions = finanKategorije.map(
        (kategorija, index) => {
          return { name: kategorija.naziv, value: kategorija.id };
        }
      );
      setFinanciranjeKatego(financiranjeKategorijeOptions);
    });
  }, []);

  /* Se sproži za pridobivanje programov K1 - prvega nivoja */
  useEffect(() => {
    fetchGET(prviNivoProgramiAktivni).then((programi) => {
      /*Preverimo če programi obstajajo */
      let programiOptions;

      if (programi !== 0) {
        programiOptions = programi.map((program) => {
          return { name: program.naziv, value: program.objectID };
        });
        setPrviNivoOptions(programiOptions);
      }
    });
  }, []);

  /*preveri parametre ob vnosu in jih zabeleži v objekt tenderData */
  const handleFormData = (e) => {
    let value = checkFormDataType(e);
    setTenderData({
      ...tenderData,
      [e.target.name]: value,
    });
  };

  /* Funkcija: preveri tip vnesenega parametra */
  const checkFormDataType = (e) => {
    let value;

    if (e.target.name === "pomemben") {
      value = !tenderData.pomemben;
    } else if (e.target.name === "odprt") {
      value = !tenderData.odprt;
    } else if (
      e.target.name === "sifrantARRS" ||
      e.target.name === "sifrantCERIF" ||
      e.target.name === "sifrantKLASIUS"
    ) {
      value = !tenderData[e.target.name];
    } else if (e.target.name === "obtezitev") {
      value = parseInt(e.target.value);
    } else {
      value = e.target.value;
    }
    return value;
  };

  /* Funkcija za shranjevanje podatkov na backend */
  const handleSubmitForm = (e) => {
    e.preventDefault();

    if (
      tenderData.programID !== "" &&
      tenderData.id_financiranje_kategorija !== ""
    ) {
      fetchPOST(vnosNovegaRazpisa, tenderData).then((razpis) => {
        if (razpis === 1) {
          history(-1);
        } else {
          setVnosError(true);
        }
      });
    } else {
      setValidacijaError(true);
      setTimeout(() => setValidacijaError(false), 3000);
    }
  };

  /* Funkcija za upravljanje z SELECT elementom za finančne kategorije */
  const handleChangeFinanceKategorije = (selectedOption) => {
    setTenderData({
      ...tenderData,
      id_financiranje_kategorija: selectedOption,
    });
  };

  /* Funkcija za upravljanje z SELECT elementom (programi) */
  const handleChangeSelectProgrami = (selectedOption) => {
    setPodprogramValue({ selection: 0 });
    setOstaliNivojiOptions([]);
    setTenderData({ ...tenderData, programID: selectedOption });

    fetchGET(`${seznamPodprogramov}/${selectedOption}`).then(
      (seznamPodprogramov) => {
        let podprogramiSeznamOptions;

        if (seznamPodprogramov !== 0) {
          podprogramiSeznamOptions = seznamPodprogramov.map((podprogram) => {
            return { name: podprogram.naziv, value: podprogram.id };
          });

          if (podprogramiSeznamOptions.length !== 0) {
            setOstaliNivojiOptions(podprogramiSeznamOptions);
          }
        }
      }
    );
  };

  /* Funkcija za upravljanje z SELECT elementom (podprogrami) */
  const handleChangeSelectPodprogrami = (selectedOption) => {
    setTenderData({ ...tenderData, programID: selectedOption });
  };

  return (
    <Container fluid>
      {role.razpisi === 2 && (
        <>
          <Row>
            <Col className="main-header font-weight-bold">
              <MdAddToPhotos className="me-2" />
              Dodaj <span>razpis</span>
            </Col>
          </Row>
          <Row>
            <Col className="header-divider">
              <hr />
            </Col>
          </Row>
          <Row>
            {vnosError && (
              <Col>
                <h2 className="text-danger">Vnos razpisa ni uspel!</h2>
              </Col>
            )}
            {validacijaError && (
              <Col>
                <h2 className="text-danger">
                  Polji program in Vir financiranja sta obvezni!
                </h2>
              </Col>
            )}
          </Row>
          <Row>
            <Col className="mt-3">
              <Form onSubmit={handleSubmitForm}>
                {/* Pripadajoč program/podprogram  */}
                <Row>
                  <Col>
                    <Form.Label>Program</Form.Label>
                    <SelectSearch
                      options={prviNivoOptions}
                      onChange={handleChangeSelectProgrami}
                      search
                      name="selectProgram"
                      placeholder="Izberi program"
                    />
                  </Col>
                </Row>
                {/* Pripadajoč podprogram*/}
                <Row>
                  <Col className="mt-3">
                    <Form.Label>Podprogram</Form.Label>
                    <SelectSearch
                      options={ostaliNivojiOptions}
                      onChange={handleChangeSelectPodprogrami}
                      search
                      name="selectPodprogram"
                      placeholder="Izberi podprogram"
                    />
                  </Col>
                </Row>
                {/*Naziv*/}
                <Form.Group controlId="formNaziv">
                  <Row>
                    <Col className="mt-4">
                      <Form.Label>Uradni naslov razpisa</Form.Label>
                      <Form.Control
                        name="razpis_naziv"
                        type="text"
                        required
                        minLength="3"
                        value={tenderData.razpis_naziv}
                        onChange={handleFormData}
                      />
                    </Col>
                  </Row>
                </Form.Group>
                {/*url*/}
                <Form.Group className="mt-3" controlId="povezava">
                  <Form.Label>URL</Form.Label>
                  <Form.Control
                    name="povezava"
                    type="text"
                    minLength="3"
                    value={tenderData.povezava}
                    onChange={handleFormData}
                  />
                </Form.Group>
                {/*odprtost, datum, ura*/}
                <Row className="mt-3">
                  <Col>
                    <Form.Group controlId="formBasicCheckbox">
                      <Form.Label>Razpis odprte narave</Form.Label>
                      <Form.Check
                        name="odprt"
                        type="checkbox"
                        onChange={handleFormData}
                        label="Da"
                        readOnly
                      />
                    </Form.Group>
                  </Col>
                  {tenderData.odprt && (
                    <Col>
                      <Form.Group controlId="pomembenRazpisTextbox">
                        <Form.Label></Form.Label>
                        <Form.Control
                          name="odprt_tekst"
                          type="text"
                          value={tenderData.odprt_tekst}
                          onChange={handleFormData}
                        />
                      </Form.Group>
                    </Col>
                  )}
                  {!tenderData.odprt && (
                    <>
                      <Col>
                        <Form.Group controlId="datum">
                          <Form.Label>Rok prijave</Form.Label>
                          <Form.Control
                            name="prijava_datum"
                            type="date"
                            value={tenderData.prijava_datum}
                            onChange={handleFormData}
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group controlId="uraprijave">
                          <Form.Label>Ura prijave</Form.Label>
                          <Form.Control
                            name="prijava_ura"
                            type="time"
                            value={tenderData.prijava_ura}
                            onChange={handleFormData}
                          />
                        </Form.Group>
                      </Col>
                    </>
                  )}
                </Row>
                {/* opis */}
                <Form.Group controlId="opis">
                  <Form.Label>Kratek opis</Form.Label>
                  <Form.Control
                    name="opis"
                    as="textarea"
                    rows={3}
                    values={tenderData.opis}
                    onChange={handleFormData}
                  />
                </Form.Group>
                {/* uteži */}
                <Row className="mt-3">
                  <Col>
                    <Form.Group controlId="formObtezitev">
                      <Form.Label>Utež</Form.Label>
                      <Form.Control
                        as="select"
                        name="obtezitev"
                        onChange={handleFormData}
                      >
                        {utezi.map((utez) => {
                          return (
                            <option key={utez} value={utez}>
                              {utez}
                            </option>
                          );
                        })}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col>
                    {/* izpostavljen  */}
                    <Form.Group controlId="formBasicCheckboxRazpis">
                      <Form.Label>Izpostavljen razpis</Form.Label>
                      <Form.Check
                        name="pomemben"
                        type="checkbox"
                        onChange={handleFormData}
                        label="Da"
                      />
                    </Form.Group>
                  </Col>
                </Row>

                {/* sifranti  */}
                <Form.Group
                  className="mt-3"
                  controlId="formBasicCheckboxSifranti"
                >
                  <Form.Label>Obvezen vnos šifrantov na projektih</Form.Label>
                  <Row>
                    <Col>
                      <Form.Check
                        name="sifrantARRS"
                        type="checkbox"
                        onChange={handleFormData}
                        label="ARIS"
                      />
                    </Col>
                    <Col>
                      <Form.Check
                        name="sifrantCERIF"
                        type="checkbox"
                        onChange={handleFormData}
                        label="CERIF"
                      />
                    </Col>
                    <Col>
                      <Form.Check
                        name="sifrantKLASIUS"
                        type="checkbox"
                        onChange={handleFormData}
                        label="Klasius – P16"
                      />
                    </Col>
                  </Row>
                </Form.Group>

                <Form.Group className="mt-3" controlId="financiranje">
                  <Form.Label>Vir financiranja</Form.Label>
                  <Row>
                    <Col>
                      <SelectSearch
                        options={financiranjeKatego}
                        onChange={handleChangeFinanceKategorije}
                        name="selectFinanceKategorije"
                        placeholder="Izberi kategorijo financiranja"
                      />
                    </Col>
                  </Row>
                </Form.Group>

                <Button variant="primary" type="submit" className="mt-4">
                  Dodaj
                </Button>
                <Button
                  className="mt-4"
                  variant="outline-primary ms-3"
                  onClick={() => {
                    history(-1);
                  }}
                >
                  Prekliči
                </Button>
              </Form>
            </Col>
          </Row>
        </>
      )}
    </Container>
  );
};

export default AddTenders;
