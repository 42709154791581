import React, { useEffect, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import SpinnerLoading from "../SpinnerLoading";
import Button from "react-bootstrap/Button";
import { BiTrash } from "react-icons/bi";
import { fetchDELETE, fetchGET, fetchPOST, fetchPUT } from "../../apiHelper";
import {
  brisanjeAktualnihRazpisov,
  vnosAktualnihRazpisovPodkategorije,
  brisanjeAktualnihRazpisovPodkategorije,
  vnosAktualnihRazpisovTretjaKategorija,
  brisanjeAktualnihRazpisovPrviTretjiNivo,
  seznamVsehProgramov,
  urejanjeAktualnegaRazpisaKategorija,
  urejanjeAktualnegaRazpisaPodkategorija,
  urejanjeAktualnegaRazpisaRazpisa,
  vrstniRedKategorije,
  vrstniRedPodkategorije,
} from "../../listOfApi";
import Modal from "react-bootstrap/Modal";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Select from "react-select";
import {
  AiOutlineEdit,
  AiOutlineStar,
  AiOutlineArrowUp,
  AiOutlineArrowDown,
} from "react-icons/ai";

import { format } from "date-fns";

const showModalData = {
  errorAddPrviNivo: false,
  opozoriloPredBrisanjem: false,
};

const brisanjeData = {
  idAktualniRazpisBrisanje: "",
  razpisNaziv: "",
  nivo: "",
};

const podkategorijaData = {
  naziv: "",
};

const razpisData = {
  naziv: "",
  url: "",
  opis: "",
  datum: "",
  ura: null,
  izpostavljen: false,
};

const kategorijaDataEdit = {
  naziv: "",
};

const razpisDataEdit = {
  naziv: "",
  url: "",
  opis: "",
  datum: "",
  ura: null,
  izpostavljen: false,
};

function CurrentTendersAutomatic({
  aktualniRazpisi,
  initAktualniRazpisi,
  initIzpostavljeniAktualni,
  aktualniError,
  aktualniLoading,
  role,
  predogled,
}) {
  const [brisanjeNivoji, setBrisanjeNivoji] = useState(brisanjeData);
  const [podkategorija, setPodkategorija] = useState(podkategorijaData);
  const [kategorija, setKategorija] = useState(kategorijaDataEdit);
  const [razpis, setRazpis] = useState(razpisData);
  const [razpisID, setRazpisID] = useState("");
  const [razpisEdit, setRazpisEdit] = useState(razpisDataEdit);
  const [kategorijaID, setKategorijaID] = useState("");
  const [podkategorijaID, setPodkategorijaID] = useState("");
  const [showAddPodkategorija, setShowAddPodkategorija] = useState(false);
  const [showAddRazpis, setShowAddRazpis] = useState(false);
  const [showEditKategorija, setShowEditKategorija] = useState(false);
  const [showEditPodkategorija, setShowEditPodkategorija] = useState(false);
  const [showEditRazpis, setShowEditRazpis] = useState(false);
  const [showAddPodkategorijaAvtomatski, setShowAddPodkategorijaAvtomatski] =
    useState(false);
  const [showModal, setShowModal] = useState(showModalData);
  const [errorPostAktualniPodkategorija, setErrorPostAktualniPodkategorija] =
    useState(false);
  const [seznamProgramovSifrant, setSeznamProgramovSifrant] = useState([]);
  const [programID, setProgramID] = useState("");

  useEffect(() => {
    if (showAddPodkategorijaAvtomatski !== false) {
      initDropDownProgrami();
    }
  }, [showAddPodkategorijaAvtomatski]);

  useEffect(() => {
    if (razpisEdit.ura === "") {
      setRazpisEdit({ ...razpisEdit, ura: null });
    }
  }, [razpisEdit.ura]);

  function handleDeleteAktualniRazpis() {
    let url;
    if (brisanjeNivoji.nivo === 1) {
      url = brisanjeAktualnihRazpisov;
    } else if (brisanjeNivoji.nivo === 2) {
      url = brisanjeAktualnihRazpisovPodkategorije;
    } else if (brisanjeNivoji.nivo === 3) {
      url = brisanjeAktualnihRazpisovPrviTretjiNivo;
    }

    fetchDELETE(`${url}/${brisanjeNivoji.idAktualniRazpisBrisanje}`).then(
      (deleted) => {
        if (deleted === 1) {
          initAktualniRazpisi();
          setShowModal({ ...showModal, opozoriloPredBrisanjem: false });
        } else {
          setShowModal({ ...showModal, errorAddPrviNivo: true });
          setTimeout(() => {
            setShowModal({ ...showModal, errorAddPrviNivo: false });
          }, 3000);
        }
      }
    );
  }

  /* Pridobivanje vseh programo/podprogramov za šifrant pri dodajanju razpisa na seznam aktualnih razpisov */
  function initDropDownProgrami() {
    fetchGET(seznamVsehProgramov).then((seznamProgramov) => {
      if (seznamProgramov !== 0) {
        let seznam = seznamProgramov.map((program) => {
          return { label: program.naziv, value: program.objectID };
        });
        setSeznamProgramovSifrant(seznam);
      }
    });
  }

  function handleFormAddPodkategorija(e) {
    e.preventDefault();

    let value;
    if (programID === "") {
      value = 0;
    } else {
      value = programID;
    }

    if (kategorijaID !== "") {
      fetchPOST(
        `${vnosAktualnihRazpisovPodkategorije}/${kategorijaID}/${value}`,
        podkategorija
      ).then((odgovor) => {
        if (odgovor === 1) {
          setPodkategorija({
            naziv: "",
          });
          setProgramID("");
          setKategorijaID("");
          setShowAddPodkategorija(false);
          setShowAddPodkategorijaAvtomatski(false);
          initAktualniRazpisi();
        } else {
          setErrorPostAktualniPodkategorija(true);
          setTimeout(() => {
            setErrorPostAktualniPodkategorija(false);
          }, 5000);
        }
      });
    }
  }

  function handleFormAddRazpis(e) {
    e.preventDefault();
    fetchPOST(
      `${vnosAktualnihRazpisovTretjaKategorija}/${kategorijaID}/${podkategorijaID}`,
      razpis
    ).then((odgovor) => {
      if (odgovor === 1) {
        setRazpis({
          naziv: "",
          url: "",
          datum: "",
          ura: null,
          opis: "",
          izpostavljen: false,
        });
        setKategorijaID("");
        setPodkategorijaID("");
        setShowAddRazpis(false);
        initAktualniRazpisi();
        initIzpostavljeniAktualni();
      } else {
        setErrorPostAktualniPodkategorija(true);
        setTimeout(() => {
          setErrorPostAktualniPodkategorija(false);
        }, 5000);
      }
    });
  }

  function handleSubmitKategorijaEdit(e) {
    e.preventDefault();
    fetchPUT(
      `${urejanjeAktualnegaRazpisaKategorija}/${kategorijaID}`,
      kategorija
    ).then((data) => {
      if (data !== 0) {
        initAktualniRazpisi();
        initIzpostavljeniAktualni();
        setShowEditKategorija(false);
        setKategorija({ naziv: "" });
        setKategorijaID("");
      } else {
        //V primeru napake.
      }
    });
  }

  function handleSubmitPodkategorijaEdit(e) {
    e.preventDefault();

    fetchPUT(
      `${urejanjeAktualnegaRazpisaPodkategorija}/${podkategorijaID}`,
      podkategorija
    ).then((data) => {
      if (data !== 0) {
        initAktualniRazpisi();
        initIzpostavljeniAktualni();
        setShowEditPodkategorija(false);
        setPodkategorija({ naziv: "" });
        setPodkategorijaID("");
      } else {
        //V primeru napake.
      }
    });
  }

  function handleSubmitRazpisEdit(e) {
    e.preventDefault();

    fetchPUT(
      `${urejanjeAktualnegaRazpisaRazpisa}/${razpisID}`,
      razpisEdit
    ).then((data) => {
      if (data !== 0) {
        initAktualniRazpisi();
        initIzpostavljeniAktualni();
        setShowEditRazpis(false);
        setRazpisEdit({
          naziv: "",
          url: "",
          ura: null,
          datum: "",
          opis: "",
          izpostavljen: false,
        });
        setRazpisID("");
      } else {
        //V primeru napake.
      }
    });
  }

  function buttonKategorijaUpHandler(id, vrstniRed) {
    fetchPUT(`${vrstniRedKategorije}/${id}/${-1}`).then((data) => {
      if (data !== 0) {
        initAktualniRazpisi();
      } else {
        //V primeru napake.
      }
    });
  }

  function buttonKategorijaDownHandler(id, vrstniRed) {
    fetchPUT(`${vrstniRedKategorije}/${id}/${+1}`).then((data) => {
      if (data !== 0) {
        initAktualniRazpisi();
      } else {
        //V primeru napake.
      }
    });
  }

  function buttonPodkategorijaDownHandler(id, vrstniRed) {
    fetchPUT(`${vrstniRedPodkategorije}/${id}/${+1}`).then((data) => {
      if (data !== 0) {
        initAktualniRazpisi();
      } else {
        //V primeru napake.
      }
    });
  }

  function buttonPodkategorijaUpHandler(id, vrstniRed) {
    fetchPUT(`${vrstniRedPodkategorije}/${id}/${-1}`).then((data) => {
      if (data !== 0) {
        initAktualniRazpisi();
      } else {
        //V primeru napake.
      }
    });
  }

  function buttonDelete(id, naziv, nivo) {
    return (
      <Button
        className="avtorizacije-button-trash text-white"
        onClick={() => {
          setBrisanjeNivoji({
            ...brisanjeNivoji,
            idAktualniRazpisBrisanje: id,
            razpisNaziv: naziv,
            nivo: nivo,
          });
          setShowModal({
            ...showModal,
            opozoriloPredBrisanjem: true,
          });
        }}
      >
        <BiTrash className="text-danger" />
      </Button>
    );
  }

  if (aktualniError) {
    return <h5>"Prišlo je do napaka pri pridobivanju podatkov!"</h5>;
  }

  if (aktualniLoading) {
    return <SpinnerLoading />;
  }

  return (
    <>
      <Modal
        show={showModal.errorAddPrviNivo}
        onHide={() => setShowModal({ ...showModal, errorAddPrviNivo: false })}
      >
        <Modal.Body className="text-center">
          Prišlo je do napake pri brisanju kategorije/podkategorije/razpisa!
        </Modal.Body>
      </Modal>

      <Modal
        show={showModal.opozoriloPredBrisanjem}
        onHide={() =>
          setShowModal({ ...showModal, opozoriloPredBrisanjem: false })
        }
      >
        <Modal.Body>
          Želite izbrisati AKTUALNI RAZPIS:
          <strong> {brisanjeNivoji.razpisNaziv}</strong>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() =>
              setShowModal({ ...showModal, opozoriloPredBrisanjem: false })
            }
          >
            Prekliči
          </Button>
          <Button variant="primary" onClick={handleDeleteAktualniRazpis}>
            V redu
          </Button>
        </Modal.Footer>
      </Modal>

      {aktualniRazpisi.map((kategorija, i, { length }) => {
        return (
          <Row key={kategorija.kategorijaID}>
            <Col>
              <Row className="row-header-razpisi-avtomatski d-flex align-items-center">
                <Col lg={10} className="row-header-avtomatski-razpisi-title">
                  {!showEditKategorija[kategorija.kategorijaID] && (
                    <>
                      {role.aktualniRazpisi === 2 && (
                        <>
                          {predogled === false && (
                            <>
                              {length - 1 !== i && (
                                <AiOutlineArrowDown
                                  onClick={() => {
                                    buttonKategorijaDownHandler(
                                      kategorija.kategorijaID,
                                      kategorija.vrstniRed
                                    );
                                  }}
                                  className="me-2"
                                />
                              )}
                              {i !== 0 && (
                                <AiOutlineArrowUp
                                  onClick={() => {
                                    buttonKategorijaUpHandler(
                                      kategorija.kategorijaID,
                                      kategorija.vrstniRed
                                    );
                                  }}
                                  className="me-2"
                                />
                              )}
                            </>
                          )}
                        </>
                      )}

                      {kategorija.kategorija}
                      <>
                        {role.aktualniRazpisi === 2 && (
                          <>
                            {predogled === false && (
                              <>
                                <Button
                                  variant="outline-primary"
                                  className="text-warning"
                                >
                                  <AiOutlineEdit
                                    onClick={() => {
                                      setShowEditKategorija({
                                        [kategorija.kategorijaID]: true,
                                      });
                                      setKategorijaID(kategorija.kategorijaID);
                                      setKategorija({
                                        naziv: kategorija.kategorija,
                                      });
                                    }}
                                  />
                                </Button>
                                {buttonDelete(
                                  kategorija.kategorijaID,
                                  kategorija.kategorija,
                                  1
                                )}
                              </>
                            )}
                          </>
                        )}
                      </>
                    </>
                  )}
                  {showEditKategorija[kategorija.kategorijaID] && (
                    <Form onSubmit={handleSubmitKategorijaEdit}>
                      <Row className="d-flex align-items-center">
                        <Col>
                          <Form.Control
                            name="naziv"
                            type="text"
                            required
                            minLength="3"
                            defaultValue={kategorija.kategorija}
                            onChange={(e) => {
                              setKategorija({ naziv: e.target.value });
                            }}
                          />
                        </Col>
                        <Col>
                          <Button
                            variant="primary"
                            type="submit"
                            className="ms-2"
                          >
                            Uredi
                          </Button>
                          <Button
                            variant="primary"
                            className="text-white "
                            onClick={() => {
                              setShowEditKategorija(false);
                              setKategorijaID("");
                            }}
                          >
                            Prekliči
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  )}
                </Col>
                <Col className="text-end" lg={2}>
                  Rok prijave
                </Col>
              </Row>
              {kategorija.rocniVnos === true && (
                <>
                  {predogled === false && (
                    <>
                      <Row className="pt-2">
                        <Col>
                          {role.aktualniRazpisi === 2 && (
                            <Button
                              className="org-button-details-text"
                              id="1"
                              onClick={() => {
                                setShowAddRazpis({
                                  [kategorija.kategorijaID]: true,
                                });
                                setKategorijaID(kategorija.kategorijaID);
                                setPodkategorijaID(-1);
                                setRazpis({
                                  naziv: "",
                                  url: "",
                                  datum: "",
                                  ura: null,
                                  opis: "",
                                  izpostavljen: false,
                                });
                              }}
                            >
                              Dodaj razpis
                            </Button>
                          )}
                        </Col>
                      </Row>
                      <Row className="pt-2">
                        <Col>
                          {role.aktualniRazpisi === 2 && (
                            <Button
                              className="org-button-details-text"
                              id="1"
                              onClick={() => {
                                setShowAddPodkategorija({
                                  [kategorija.kategorijaID]: true,
                                });
                                setKategorijaID(kategorija.kategorijaID);
                              }}
                            >
                              Dodaj podkategorijo
                            </Button>
                          )}
                        </Col>
                      </Row>
                      <Row className="pt-2">
                        <Col>
                          {role.aktualniRazpisi === 2 && (
                            <Button
                              className="org-button-details-text"
                              id="1"
                              onClick={() => {
                                setShowAddPodkategorijaAvtomatski({
                                  [kategorija.kategorijaID]: true,
                                });
                                setKategorijaID(kategorija.kategorijaID);
                              }}
                            >
                              Dodaj podkategorijo s strukturo iz baze projektov
                            </Button>
                          )}
                        </Col>
                      </Row>
                    </>
                  )}
                  {role.aktualniRazpisi === 2 && (
                    <>
                      {predogled === false && (
                        <Row>
                          <Col>
                            <hr />
                          </Col>
                        </Row>
                      )}
                    </>
                  )}

                  {showAddPodkategorija[kategorija.kategorijaID] && (
                    <>
                      {errorPostAktualniPodkategorija && (
                        <h5 className="text-danger">
                          Prišlo je do napake pri dodajanju podkategorije!
                        </h5>
                      )}
                      <Card className="main-container-card__shadows m-2">
                        <Card.Body>
                          <Form onSubmit={handleFormAddPodkategorija}>
                            <Row className="d-flex align-items-center">
                              <Col>
                                <Form.Label>
                                  Uradni naslov podkategorije
                                </Form.Label>
                                <Form.Control
                                  name="naziv"
                                  type="text"
                                  required
                                  minLength="3"
                                  value={podkategorija.podkategorija}
                                  onChange={(e) =>
                                    setPodkategorija({
                                      ...podkategorija,
                                      naziv: e.target.value,
                                    })
                                  }
                                />
                              </Col>

                              <Col>
                                <Button
                                  variant="primary"
                                  type="submit"
                                  className="mt-4 ms-2"
                                >
                                  Dodaj
                                </Button>
                                <Button
                                  variant="outline-primary ms-3"
                                  onClick={() => {
                                    setShowAddPodkategorija(false);
                                    setPodkategorija({
                                      naziv: "",
                                    });
                                  }}
                                  className="mt-4 "
                                >
                                  Prekliči
                                </Button>
                              </Col>
                            </Row>
                          </Form>
                        </Card.Body>
                      </Card>
                    </>
                  )}
                  {showAddRazpis[kategorija.kategorijaID] && (
                    <>
                      {errorPostAktualniPodkategorija && (
                        <h5 className="text-danger">
                          Prišlo je do napake pri dodajanju razpisa!
                        </h5>
                      )}
                      <Card className="main-container-card__shadows m-2">
                        <Card.Body>
                          <Form onSubmit={handleFormAddRazpis}>
                            <Row className="d-flex align-items-center">
                              <Col>
                                <Form.Label>Uradni naslov razpisa</Form.Label>
                                <Form.Control
                                  name="naziv"
                                  type="text"
                                  required
                                  minLength="3"
                                  onChange={(e) =>
                                    setRazpis({
                                      ...razpis,
                                      naziv: e.target.value,
                                    })
                                  }
                                />
                              </Col>
                              <Col>
                                <Form.Label>URL</Form.Label>
                                <Form.Control
                                  name="povezava"
                                  type="text"
                                  minLength="3"
                                  onChange={(e) => {
                                    setRazpis({
                                      ...razpis,
                                      url: e.target.value,
                                    });
                                  }}
                                />
                              </Col>
                            </Row>
                            <Row className="d-flex align-items-center">
                              <Col>
                                <Form.Label>Rok prijave</Form.Label>
                                <Form.Control
                                  name="prijava_datum"
                                  type="date"
                                  value={razpis.datum}
                                  onChange={(e) => {
                                    setRazpis({
                                      ...razpis,
                                      datum: e.target.value,
                                    });
                                  }}
                                />
                              </Col>
                              <Col>
                                <Form.Label>Ura prijave</Form.Label>
                                <Form.Control
                                  name="prijava_ura"
                                  type="time"
                                  onChange={(e) => {
                                    setRazpis({
                                      ...razpis,
                                      ura: e.target.value,
                                    });
                                  }}
                                />
                              </Col>
                            </Row>
                            <Row className="d-flex align-items-center">
                              <Col>
                                <Form.Label>Kratek opis</Form.Label>
                                <Form.Control
                                  name="opis"
                                  as="textarea"
                                  rows={6}
                                  values={razpis.opis}
                                  onChange={(e) => {
                                    setRazpis({
                                      ...razpis,
                                      opis: e.target.value,
                                    });
                                  }}
                                />
                              </Col>
                            </Row>
                            <Row className="d-flex align-items-center mt-2">
                              <Col>
                                <Form.Label>Izpostavljen</Form.Label>
                                <Form.Check
                                  name="rocniVnos"
                                  type="checkbox"
                                  onChange={(e) => {
                                    setRazpis({
                                      ...razpis,
                                      izpostavljen: !razpis.izpostavljen,
                                    });
                                  }}
                                  label="Da"
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <Button
                                  variant="primary"
                                  type="submit"
                                  className="mt-4 ms-2"
                                >
                                  Dodaj
                                </Button>
                                <Button
                                  variant="outline-primary ms-3"
                                  onClick={() => {
                                    setShowAddRazpis(false);
                                    setRazpis({
                                      naziv: "",
                                      url: "",
                                      ura: null,
                                      datum: "",
                                      opis: "",
                                      izpostavljen: false,
                                    });
                                  }}
                                  className="mt-4 "
                                >
                                  Prekliči
                                </Button>
                              </Col>
                            </Row>
                          </Form>
                        </Card.Body>
                      </Card>
                    </>
                  )}
                  {showAddPodkategorijaAvtomatski[kategorija.kategorijaID] && (
                    <>
                      <Card className="main-container-card__shadows">
                        <Card.Body>
                          <Form onSubmit={handleFormAddPodkategorija}>
                            <Row className="d-flex align-items-center">
                              <Col>
                                <Form.Label>Uradni naslov</Form.Label>
                                <Form.Control
                                  name="naziv"
                                  type="text"
                                  required
                                  minLength="3"
                                  onChange={(e) =>
                                    setPodkategorija({ naziv: e.target.value })
                                  }
                                />
                              </Col>
                              <Col>
                                <Form.Label>Programi</Form.Label>

                                <Select
                                  menuPortalTarget={document.body}
                                  options={seznamProgramovSifrant}
                                  placeholder="Programi"
                                  onChange={(value) => {
                                    setProgramID(value.value);
                                  }}
                                  isSearchable
                                  name="programID"
                                />
                              </Col>
                              <Col>
                                <Button
                                  variant="primary"
                                  type="submit"
                                  className="mt-4 ms-2"
                                >
                                  Dodaj
                                </Button>
                                <Button
                                  variant="outline-primary ms-3"
                                  onClick={() => {
                                    setShowAddPodkategorijaAvtomatski(
                                      !showAddPodkategorijaAvtomatski
                                    );
                                    setPodkategorija({
                                      naziv: "",
                                    });
                                  }}
                                  className="mt-4 "
                                >
                                  Prekliči
                                </Button>
                              </Col>
                            </Row>
                          </Form>
                        </Card.Body>
                      </Card>
                    </>
                  )}
                </>
              )}
              {kategorija.podkategorije.map((podkategorija, j, { length }) => {
                return (
                  <div key={j}>
                    {podkategorija.podkategorijaID !== -1 /*&&
                      podkategorija.razpisi.length > 0 */ && (
                      <Row className="row-header-razpisi-avtomatski-drugi-nivo mb-3">
                        <Col className="row-header-razpisi-avtomatski-drugi-nivo-title">
                          {role.aktualniRazpisi === 2 &&
                            kategorija.rocniVnos === true && (
                              <>
                                {predogled === false && (
                                  <>
                                    {/*Premikanje pozicije podkategorija dol */}
                                    {length - 1 !== j && (
                                      <AiOutlineArrowDown
                                        onClick={() => {
                                          buttonPodkategorijaDownHandler(
                                            podkategorija.podkategorijaID
                                          );
                                        }}
                                        className="me-2"
                                      />
                                    )}
                                    {/*Premikanje pozicije podkategorija gor */}
                                    {j !== 0 &&
                                      kategorija.podkategorije[j - 1]
                                        .podkategorija !== null && (
                                        <AiOutlineArrowUp
                                          onClick={() => {
                                            buttonPodkategorijaUpHandler(
                                              podkategorija.podkategorijaID
                                            );
                                          }}
                                          className="me-2"
                                        />
                                      )}
                                  </>
                                )}
                              </>
                            )}

                          {/*Prikaz naziv */}
                          {kategorija.rocniVnos === false && (
                            <>{podkategorija.podkategorija}</>
                          )}

                          {/*Prikaz naziv */}
                          {kategorija.rocniVnos === true && (
                            <>{podkategorija.podkategorija}</>
                          )}

                          {/*Urejanje podkategorije */}
                          {role.aktualniRazpisi === 2 &&
                            kategorija.rocniVnos === true && (
                              <>
                                {predogled === false && (
                                  <>
                                    {!showEditPodkategorija[
                                      podkategorija.podkategorijaID
                                    ] && (
                                      <>
                                        <Button
                                          className="text-warning"
                                          variant="link"
                                          onClick={() => {
                                            setShowEditPodkategorija({
                                              [podkategorija.podkategorijaID]: true,
                                            });
                                            setPodkategorijaID(
                                              podkategorija.podkategorijaID
                                            );
                                            setPodkategorija({
                                              naziv:
                                                podkategorija.podkategorija,
                                            });
                                          }}
                                        >
                                          <AiOutlineEdit />
                                        </Button>
                                      </>
                                    )}

                                    {!showEditPodkategorija[
                                      podkategorija.podkategorijaID
                                    ] && (
                                      <>
                                        {buttonDelete(
                                          podkategorija.podkategorijaID,
                                          podkategorija.podkategorija,
                                          2
                                        )}
                                      </>
                                    )}
                                  </>
                                )}

                                {showEditPodkategorija[
                                  podkategorija.podkategorijaID
                                ] && (
                                  <Form
                                    onSubmit={handleSubmitPodkategorijaEdit}
                                  >
                                    <Row className="d-flex align-items-center">
                                      <Col>
                                        <Form.Control
                                          name="naziv"
                                          type="text"
                                          required
                                          minLength="3"
                                          defaultValue={
                                            podkategorija.podkategorija
                                          }
                                          onChange={(e) => {
                                            setPodkategorija({
                                              naziv: e.target.value,
                                            });
                                          }}
                                        />
                                      </Col>
                                      <Col>
                                        <Button
                                          variant="primary"
                                          type="submit"
                                          className="ms-2"
                                        >
                                          Uredi
                                        </Button>
                                        <Button
                                          variant="primary"
                                          className="text-white ms-1"
                                          onClick={() => {
                                            setShowEditPodkategorija(false);
                                            setPodkategorijaID("");
                                          }}
                                        >
                                          Prekliči
                                        </Button>
                                      </Col>
                                    </Row>
                                  </Form>
                                )}
                              </>
                            )}
                        </Col>
                      </Row>
                    )}

                    {/*Možnost dodajanje razpisa v primeru da je status podkategorije rocniVnos TRUE */}
                    {role.aktualniRazpisi === 2 &&
                      kategorija.rocniVnos === true &&
                      podkategorija.rocniVnos === true &&
                      podkategorija.podkategorijaID !== -1 && (
                        <>
                          {predogled === false && (
                            <>
                              <Row className="pt-2">
                                <Col>
                                  <Button
                                    className="org-button-details-text"
                                    id="1"
                                    onClick={() => {
                                      setShowAddRazpis({
                                        [podkategorija.podkategorijaID]: true,
                                      });
                                      setKategorijaID(kategorija.kategorijaID);
                                      setPodkategorijaID(
                                        podkategorija.podkategorijaID
                                      );
                                      setRazpis({
                                        naziv: "",
                                        url: "",
                                        datum: "",
                                        ura: null,
                                        opis: "",
                                        izpostavljen: false,
                                      });
                                    }}
                                  >
                                    Dodaj razpis
                                  </Button>
                                </Col>
                              </Row>
                              {predogled === false && (
                                <Row>
                                  <Col>
                                    <hr />
                                  </Col>
                                </Row>
                              )}
                            </>
                          )}

                          {showAddRazpis[podkategorija.podkategorijaID] && (
                            <>
                              {errorPostAktualniPodkategorija && (
                                <h5 className="text-danger">
                                  Prišlo je do napake pri dodajanju razpisa!
                                </h5>
                              )}
                              <Card className="main-container-card__shadows m-2">
                                <Card.Body>
                                  <Form onSubmit={handleFormAddRazpis}>
                                    <Row className="d-flex align-items-center">
                                      <Col>
                                        <Form.Label>
                                          Uradni naslov razpisa
                                        </Form.Label>
                                        <Form.Control
                                          name="naziv"
                                          type="text"
                                          required
                                          minLength="3"
                                          onChange={(e) =>
                                            setRazpis({
                                              ...razpis,
                                              naziv: e.target.value,
                                            })
                                          }
                                        />
                                      </Col>
                                      <Col>
                                        <Form.Label>URL</Form.Label>
                                        <Form.Control
                                          name="povezava"
                                          type="text"
                                          minLength="3"
                                          onChange={(e) => {
                                            setRazpis({
                                              ...razpis,
                                              url: e.target.value,
                                            });
                                          }}
                                        />
                                      </Col>
                                    </Row>
                                    <Row className="d-flex align-items-center">
                                      <Col>
                                        <Form.Label>Rok prijave</Form.Label>
                                        <Form.Control
                                          name="prijava_datum"
                                          type="date"
                                          value={razpis.datum}
                                          onChange={(e) => {
                                            setRazpis({
                                              ...razpis,
                                              datum: e.target.value,
                                            });
                                          }}
                                        />
                                      </Col>
                                      <Col>
                                        <Form.Label>Ura prijave</Form.Label>
                                        <Form.Control
                                          name="prijava_ura"
                                          type="time"
                                          onChange={(e) => {
                                            setRazpis({
                                              ...razpis,
                                              ura: e.target.value,
                                            });
                                          }}
                                        />
                                      </Col>
                                    </Row>
                                    <Row className="d-flex align-items-center">
                                      <Col>
                                        <Form.Label>Kratek opis</Form.Label>
                                        <Form.Control
                                          name="opis"
                                          as="textarea"
                                          rows={6}
                                          values={razpis.opis}
                                          onChange={(e) => {
                                            setRazpis({
                                              ...razpis,
                                              opis: e.target.value,
                                            });
                                          }}
                                        />
                                      </Col>
                                    </Row>

                                    <Row className="d-flex align-items-center mt-2">
                                      <Col>
                                        <Form.Label>Izpostavljen</Form.Label>
                                        <Form.Check
                                          name="rocniVnos"
                                          type="checkbox"
                                          onChange={(e) =>
                                            setRazpis({
                                              ...razpis,
                                              izpostavljen:
                                                !razpis.izpostavljen,
                                            })
                                          }
                                          label="Da"
                                        />
                                      </Col>
                                    </Row>

                                    <Row>
                                      <Col>
                                        <Button
                                          variant="primary"
                                          type="submit"
                                          className="mt-4 ms-2"
                                        >
                                          Dodaj
                                        </Button>
                                        <Button
                                          variant="outline-primary ms-3"
                                          onClick={() => {
                                            setShowAddRazpis(false);
                                            setRazpis({
                                              naziv: "",
                                              url: "",
                                              ura: null,
                                              datum: "",
                                              opis: "",
                                              izpostavljen: false,
                                            });
                                          }}
                                          className="mt-4 "
                                        >
                                          Prekliči
                                        </Button>
                                      </Col>
                                    </Row>
                                  </Form>
                                </Card.Body>
                              </Card>
                            </>
                          )}
                        </>
                      )}

                    {podkategorija.razpisi.length > 0 &&
                      podkategorija.razpisi.map((razpis, index) => {
                        return (
                          <div key={index}>
                            {!showEditRazpis[razpis.razpisID] && (
                              <Row className="row-header-razpisi-avtomatski-tretji-nivo d-flex align-items-center">
                                <Col lg={4}>
                                  {razpis.url !== "" ? (
                                    <a
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      href={razpis.url}
                                    >
                                      {razpis.naziv}
                                    </a>
                                  ) : (
                                    <>{razpis.naziv}</>
                                  )}
                                  {razpis.izpostavljen === true && (
                                    <>
                                      {predogled === false && (
                                        <span className="ms-3 me-2">
                                          <AiOutlineStar />
                                        </span>
                                      )}
                                    </>
                                  )}
                                  {role.aktualniRazpisi === 2 &&
                                    kategorija.rocniVnos === true &&
                                    podkategorija.rocniVnos === true && (
                                      <>
                                        {predogled === false && (
                                          <>
                                            <Button
                                              variant="link"
                                              className="text-warning"
                                              onClick={() => {
                                                setShowEditRazpis({
                                                  [razpis.razpisID]: true,
                                                });
                                                setRazpisID(razpis.razpisID);
                                                setRazpisEdit({
                                                  naziv: razpis.naziv,
                                                  url: razpis.url,
                                                  opis: razpis.opis,
                                                  datum: razpis.datum,
                                                  ura: razpis.ura,
                                                  izpostavljen:
                                                    razpis.izpostavljen,
                                                });
                                              }}
                                            >
                                              <AiOutlineEdit />
                                            </Button>
                                            {buttonDelete(
                                              razpis.razpisID,
                                              razpis.naziv,
                                              3
                                            )}
                                          </>
                                        )}
                                      </>
                                    )}
                                </Col>
                                <Col lg={7}>
                                  {razpis.opis !== ""
                                    ? razpis.opis
                                    : "Ni opisa"}
                                </Col>
                                <Col lg={1} className="text-end">
                                  <Row className="mb-1">
                                    <Col>
                                      {razpis.novo === true && (
                                        <span className="ms-2 p-1 rounded border-success bg-success text-light">
                                          Novo
                                        </span>
                                      )}
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col>
                                      {razpis.datum === "2100-01-01" &&
                                        "Odprt razpis"}
                                      {razpis.datum !== null &&
                                        razpis.datum !== "2100-01-01" &&
                                        format(
                                          new Date(razpis.datum),
                                          "d. M. yyyy"
                                        )}
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col>
                                      {razpis.ura !== null &&
                                        `do: ${razpis.ura.slice(0, -3)}`}
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                            )}
                            {showEditRazpis[razpis.razpisID] && (
                              <Row>
                                <Col>
                                  <Card className="main-container-card__shadows m-2">
                                    <Card.Body>
                                      <Form onSubmit={handleSubmitRazpisEdit}>
                                        <Row className="d-flex align-items-center">
                                          <Col>
                                            <Form.Label>
                                              Uradni naslov razpisa
                                            </Form.Label>
                                            <Form.Control
                                              name="naziv"
                                              type="text"
                                              required
                                              minLength="3"
                                              value={razpisEdit.naziv}
                                              onChange={(e) =>
                                                setRazpisEdit({
                                                  ...razpisEdit,
                                                  naziv: e.target.value,
                                                })
                                              }
                                            />
                                          </Col>
                                          <Col>
                                            <Form.Label>URL</Form.Label>
                                            <Form.Control
                                              name="povezava"
                                              type="text"
                                              minLength="3"
                                              value={razpisEdit.url}
                                              onChange={(e) => {
                                                setRazpisEdit({
                                                  ...razpisEdit,
                                                  url: e.target.value,
                                                });
                                              }}
                                            />
                                          </Col>
                                        </Row>
                                        <Row className="d-flex align-items-center">
                                          <Col>
                                            <Form.Label>Rok prijave</Form.Label>
                                            <Form.Control
                                              name="prijava_datum"
                                              type="date"
                                              value={razpisEdit.datum}
                                              onChange={(e) => {
                                                setRazpisEdit({
                                                  ...razpisEdit,
                                                  datum: e.target.value,
                                                });
                                              }}
                                            />
                                          </Col>
                                          <Col>
                                            <Form.Label>Ura prijave</Form.Label>
                                            <Form.Control
                                              name="prijava_ura"
                                              type="time"
                                              value={razpisEdit.ura}
                                              onChange={(e) => {
                                                setRazpisEdit({
                                                  ...razpisEdit,
                                                  ura: e.target.value,
                                                });
                                              }}
                                            />
                                          </Col>
                                        </Row>
                                        <Row className="d-flex align-items-center">
                                          <Col>
                                            <Form.Label>Kratek opis</Form.Label>
                                            <Form.Control
                                              name="opis"
                                              as="textarea"
                                              rows={6}
                                              value={razpisEdit.opis}
                                              onChange={(e) => {
                                                setRazpisEdit({
                                                  ...razpisEdit,
                                                  opis: e.target.value,
                                                });
                                              }}
                                            />
                                          </Col>
                                        </Row>
                                        <Row className="d-flex align-items-center mt-2">
                                          <Col>
                                            <Form.Label>
                                              Izpostavljen
                                            </Form.Label>
                                            <Form.Check
                                              name="rocniVnos"
                                              type="checkbox"
                                              defaultChecked={
                                                razpisEdit.izpostavljen
                                              }
                                              onChange={(e) => {
                                                setRazpisEdit({
                                                  ...razpisEdit,
                                                  izpostavljen:
                                                    !razpisEdit.izpostavljen,
                                                });
                                              }}
                                              label="Da"
                                            />
                                          </Col>
                                        </Row>
                                        <Row>
                                          <Col>
                                            <Button
                                              variant="primary"
                                              type="submit"
                                              className="mt-4 ms-2"
                                            >
                                              Uredi
                                            </Button>
                                            <Button
                                              variant="outline-primary ms-3"
                                              onClick={() => {
                                                setShowEditRazpis(false);
                                                setRazpisID("");
                                                setRazpisEdit({
                                                  naziv: "",
                                                  url: "",
                                                  ura: null,
                                                  datum: "",
                                                  opis: "",
                                                  izpostavljen: false,
                                                });
                                              }}
                                              className="mt-4 "
                                            >
                                              Prekliči
                                            </Button>
                                          </Col>
                                        </Row>
                                      </Form>
                                    </Card.Body>
                                  </Card>
                                </Col>
                              </Row>
                            )}
                            <Row>
                              <Col>
                                <hr />
                              </Col>
                            </Row>
                          </div>
                        );
                      })}
                  </div>
                );
              })}
            </Col>
          </Row>
        );
      })}
    </>
  );
}

export default CurrentTendersAutomatic;
