import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';

/*import React-component */
import ProgramStructureContainer from './program_structure/ProgramStructureContainer';

/*Import API */
import {programStrukturaAPI, spremeniStatusProgram} from '../listOfApi';
import {fetchGET, fetchPUT} from '../apiHelper';

/*Import Context */
import { UserContext } from './UserContext';

/* React-Bootstrap component */
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

/*React-icons */
import { AiFillProfile } from 'react-icons/ai';

const ProgramStructure = ({ role }) => {
  /*Pridobljen parameter iz ID */
  const { id } = useParams();

  const [program, setProgram] = useState({});
  const [programLoaded, setProgramLoaded] = useState(false);
  const [loading, setLoading] = useState(true);
  const [invalidProgramId, setInvalidProgramId] = useState(false);

  useEffect(() => {
    fetchProgramStruktura();
  }, []);

  const changeProgramStatus = (programID) => {
    fetchPUT(`${spremeniStatusProgram}/${programID}`).then((data) => {
      if (data !== 0) {
        fetchProgramStruktura();
      } else {
      }
    });
  }
  
  const fetchProgramStruktura = () => {
    fetchGET(`${programStrukturaAPI}/${id}`).then((programi) => {
      if (programi !== 0) {
        setProgram(programi);
        setProgramLoaded(true);
      } else {
        setInvalidProgramId(true);
      }
      setLoading(false);
    });
  }


  return (
    <Container className='mb-3 main-container-editprogram' fluid>
      {(role.programi === 1 || role.programi === 2) && (
        <>
          {loading && <div>Nalaganje...</div>}
          {invalidProgramId && <p>Program ne obstaja!</p>}
          {!invalidProgramId && (
            <>
              {!loading && (
                <>
                  <Row>
                    <Col
                      lg={9}
                      xl={10}
                      className='main-header font-weight-bold'
                    >
                      <Link to={`/Programi/`}>Programi/</Link>
                      <span>{program.naziv}/</span>
                    </Col>
                    <Col lg={3} xl={2}>
                      <div className='float-end'>
                        <Link
                          className='programs-button-add-text ms-2'
                          to={`/Programi`}
                        >
                          <Button
                            variant='primary'
                            className='button-back-programi'
                          >
                            <AiFillProfile /> <span>Programi</span>
                          </Button>
                        </Link>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col className='header-divider'>
                      <hr />
                    </Col>
                  </Row>
                  <Row>
                    {loading && <div>Nalaganje...</div>}
                    <UserContext.Provider value={role}>
                      <ProgramStructureContainer
                        program={program}
                        changeProgramStatus={changeProgramStatus}
                        id={id}
                        programLoaded={programLoaded}
                      />
                    </UserContext.Provider>
                  </Row>
                </>
              )}
            </>
          )}
        </>
      )}
    </Container>
  );
};

export default ProgramStructure;
