import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";

/* Import API */
import {
  userAvtorizacije,
  urejanjeAvtorizacije,
  claniceUM,
} from "../listOfApi";
import { fetchGET, fetchPUT } from "../apiHelper";

/* React-Bootstrap component */
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

/* React-Icons */
import { AiOutlineEdit } from "react-icons/ai";
import SelectSearch from "react-select-search";

const EditUserFakultetaRolesAuthorisation = () => {
  /*Pridobljen parameter iz ID */
  const { id } = useParams();
  const history = useNavigate();

  const [fakultetniUser, setFakultetniUser] = useState({
    upn: "",
    kisID: "",
    clanicaID: "",
    programi: 1,
    projekti: 2,
    partnerji: 1,
    razpisi: 1,
    aktualniRazpisi: 1,
    skrbnik: false,
    admin: false,
  });

  const [sifrantClanice, setSifrantClanice] = useState([]);

  useEffect(() => {
    fetchGET(claniceUM).then((clanice) => {
      if (clanice !== 0) {
        let clanicePro = clanice
          .filter((clanica) => clanica.naziv !== "TTO")
          .map((clanica) => {
            return { name: clanica.naziv, value: clanica.id };
          });
        setSifrantClanice(clanicePro);
      }
    });
  }, []);

  useEffect(() => {
    fetchGET(`${userAvtorizacije}/${id}`).then((user) => {
      if (user !== 0) {
        setFakultetniUser(user);
      } else {
      }
    });
  }, [sifrantClanice]);

  const handleForm = (e) => {
    e.preventDefault();

    fetchPUT(urejanjeAvtorizacije, fakultetniUser).then((odgovor) => {
      if (odgovor === 1) {
        history(-1);
      } else {
      }
    });
  };

  return (
    <Container fluid>
      <>
        <Row>
          <Col className="main-header font-weight-bold">
            <AiOutlineEdit className="me-2" />
            {fakultetniUser.upn}
          </Col>
        </Row>
        <Row>
          <Col className="header-divider">
            <hr />
          </Col>
        </Row>
        <Form onSubmit={handleForm}>
          <Row>
            <Col>
              <Form.Label>Digitalna identiteta</Form.Label>
              <Form.Control
                readOnly
                required
                name="enaslov"
                type="email"
                minLength="3"
                maxLength="100"
                value={fakultetniUser.upn}
              />
            </Col>
            <Col>
              <Form.Label>Članica</Form.Label>
              <SelectSearch
                options={sifrantClanice}
                onChange={(value) => {
                  setFakultetniUser({
                    ...fakultetniUser,
                    clanicaID: value,
                  });
                }}
                value={fakultetniUser.clanicaID}
                search
                name="selectClanica"
                placeholder="Članica"
              />
            </Col>
          </Row>
          <Row className="mt-4">
            <Col>
              <Button type="submit" variant="primary">
                Uredi avtorizacije
              </Button>
              <Button
                variant="outline-primary ms-3"
                onClick={() => {
                  history(-1);
                }}
              >
                Prekliči
              </Button>
            </Col>
          </Row>
        </Form>
      </>
    </Container>
  );
};

export default EditUserFakultetaRolesAuthorisation;
