import React, { useContext } from "react";
import CurrencyInput from "react-currency-input-field";

/* Import React Bootstrap */
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

/* Import React Icons */
import { AiFillInfoCircle, AiOutlineEdit } from "react-icons/ai";

/* Import Context */
import { UserContext } from "../UserContext";

const NacrtovanaVrednostProjekta = ({
  projectSkrbnikClanica,
  nacrtovaneVrednostiProjekta,
  showErrorDodajanjeUrejanjeNacrtovanihFinanc,
  showNacrtovaneVrednostiProjekta,
  tooltipSifranti,
  sestevekNacrtovanihVrednostiClanic,
  nacrtovaneVrednostiProjektaPrikaz,
  showUrejanjeDodajanjeFinancniClaniceNacrtovani,
  nacrtovaneVrednostiClanice,
  nacrtovaneVrednostiClaniceUrejanje,
  nacrtovaneVrednostiClanicePrikaz,
  seStateShowNacrtovaneVrednostiProjekta,
  setStateNacrtovanaVrednostProjektaUrejanje,
  setStateShowUrejanjeDodajanjeFinancniClaniceNacrtovani,
  setStateNacrtovaneVrednostiClaniceUrejanje,
  setStateNacrtovaneVrednostiClanicePrikaz,
  numberFormatValuta,
  handleAddNacrtovaneVrednostiProjekta,
  handleAddNacrtovaneVrednostiClanice,
  jeClanicaProjekta
}) => {
  const role = useContext(UserContext);

  return (
    <Card className="mt-2">
      <Card.Body>
        <Row className="align-items-center">
          <Col>
            <div className="float-end">
              {((role.skrbnik === true &&
                role.clanicaID === projectSkrbnikClanica) ||
                (role.skrbnik === false && role.projekti === 2)) && (
                  <Button
                    className="org-button-details-text"
                    id="1"
                    onClick={seStateShowNacrtovaneVrednostiProjekta}
                  >
                    <AiOutlineEdit />
                    <span>Uredi načrtovane finančne podatke</span>
                  </Button>
                )}
            </div>
          </Col>
        </Row>
        <Row className="project-partners-details-card">
          <Col>
            <div className="p-4">
              {showErrorDodajanjeUrejanjeNacrtovanihFinanc && (
                <h4 className="text-danger mb-4">
                  Prišlo je do napake pri urejanju načrtovanih finančnih
                  podatkov!
                </h4>
              )}
              {!showNacrtovaneVrednostiProjekta && (
                <Row>
                  <Col>
                    <span>
                      {" "}
                      Vrednost projekta{" "}
                      <sup>
                        <AiFillInfoCircle
                          className="tooltip-icon"
                          data-tooltip-id="tab-tooltip"
                          data-tooltip-content={tooltipSifranti.P41vrednostProjekta}
                        />
                      </sup>
                      :{" "}
                    </span>{" "}
                    {nacrtovaneVrednostiProjekta.nacrtovanaVrednost !== null
                      ? `${numberFormatValuta(
                        nacrtovaneVrednostiProjekta.nacrtovanaVrednost
                      )}`
                      : "Ni še podatka"}
                  </Col>
                  <Col>
                    <span>
                      {" "}
                      Vrednost sofinanciranja iz projektnih virov{" "}
                      <sup>
                        <AiFillInfoCircle
                          className="tooltip-icon"
                          data-tooltip-id="tab-tooltip"
                          data-tooltip-content={
                            tooltipSifranti.P41vrednostSofinanciranjaIzProjektnihVirov
                          }
                        />
                      </sup>
                      :{" "}
                    </span>{" "}
                    {nacrtovaneVrednostiProjekta.nacrtovaniProjektniViri !==
                      null
                      ? `${numberFormatValuta(
                        nacrtovaneVrednostiProjekta.nacrtovaniProjektniViri
                      )}`
                      : "Ni še podatka"}
                  </Col>
                </Row>
              )}
              {showNacrtovaneVrednostiProjekta && (
                <>
                  <Row className="align-items-center">
                    <Col lg={4}>
                      <Form.Label> Vrednost Projekta (€) </Form.Label>
                      <CurrencyInput
                        className="form-control"
                        placeholder="Vnesi znesek"
                        decimalScale={2}
                        value={
                          nacrtovaneVrednostiProjektaPrikaz.nacrtovanaVrednost
                        }
                        intlConfig={{ locale: "sl-SI", currency: "EUR" }}
                        onValueChange={(value) => {
                          setStateNacrtovanaVrednostProjektaUrejanje(
                            value,
                            null,
                            "nacrtovanaVrednost"
                          );
                        }}
                      />
                    </Col>
                    <Col lg={{ span: 4, offset: 1 }}>
                      <Form.Label>
                        Vrednost sofinanciranja iz projektnih virov (€)
                      </Form.Label>
                      <CurrencyInput
                        className="form-control"
                        placeholder="Vnesi znesek"
                        decimalScale={2}
                        value={
                          nacrtovaneVrednostiProjektaPrikaz.nacrtovaniProjektniViri
                        }
                        intlConfig={{ locale: "sl-SI", currency: "EUR" }}
                        onValueChange={(value) => {
                          setStateNacrtovanaVrednostProjektaUrejanje(
                            null,
                            value
                          );
                        }}
                      />
                    </Col>
                    <Col lg={3}>
                      <Button
                        variant="primary"
                        className="me-3 custom-top-margin projectiDetails-buttonWidth"
                        onClick={handleAddNacrtovaneVrednostiProjekta}
                      >
                        Potrdi
                      </Button>
                      <Button
                        variant="outline-primary"
                        className="ms-3 custom-top-margin projectiDetails-buttonWidth"
                        onClick={() => seStateShowNacrtovaneVrednostiProjekta()}
                      >
                        Prekliči
                      </Button>
                    </Col>
                  </Row>
                </>
              )}
              <Row className="mt-5">
                <Col>
                  <span>
                    {" "}
                    Vrednost projekta za UM{" "}
                    <sup>
                      <AiFillInfoCircle
                        className="tooltip-icon"
                        data-tooltip-id="tab-tooltip"
                        data-tooltip-content={tooltipSifranti.P41vrednostProjektaZaUM}
                      />{" "}
                    </sup>
                    :{" "}
                  </span>{" "}
                  {sestevekNacrtovanihVrednostiClanic.nacrtovanaVrednost !== null
                    ? `${numberFormatValuta(
                      sestevekNacrtovanihVrednostiClanic.nacrtovanaVrednost
                    )}`
                    : "Ni podatka"}
                </Col>
                <Col>
                  <span>
                    {" "}
                    Vrednost sofinanciranja iz projektnih virov za UM{" "}
                    <sup>
                      <AiFillInfoCircle
                        className="tooltip-icon"
                        data-tooltip-id="tab-tooltip"
                        data-tooltip-content={
                          tooltipSifranti.P41vrednostSofinanciranjaIzProjektnihVirovZaUM
                        }
                      />
                    </sup>
                    :{" "}
                  </span>{" "}
                  {sestevekNacrtovanihVrednostiClanic.nacrtovanaVrednostViri !==
                    null
                    ? `${numberFormatValuta(
                      sestevekNacrtovanihVrednostiClanic.nacrtovanaVrednostViri
                    )}`
                    : "Ni podatka"}
                </Col>
              </Row>
              <Row className="mt-3">
                <Col>
                  <span>
                    Vrednost / delež lastnega sofinanciranja UM{" "}
                    <sup>
                      <AiFillInfoCircle
                        className="tooltip-icon"
                        data-tooltip-id="tab-tooltip"
                        data-tooltip-content={
                          tooltipSifranti.P41VrednostDelezLastnegaSofinanciranjaUM
                        }
                      />
                    </sup>
                    :
                  </span>{" "}
                  {sestevekNacrtovanihVrednostiClanic.nacrtovanaLastnaVrednost !==
                    null &&
                    sestevekNacrtovanihVrednostiClanic.nacrtovanaDelez !== null
                    ? `${numberFormatValuta(
                      sestevekNacrtovanihVrednostiClanic.nacrtovanaLastnaVrednost
                    )} / ${(
                      " " + sestevekNacrtovanihVrednostiClanic.nacrtovanaDelez
                    )
                      .toString()
                      .replace(".", ",")} %`
                    : "Ni podatka / Ni podatka"}
                </Col>
                <Col>
                  <span>
                    Obseg zaposlitev (izraženo v FTE){" "}
                    <sup>
                      <AiFillInfoCircle
                        className="tooltip-icon"
                        data-tooltip-id="tab-tooltip"
                        data-tooltip-content={tooltipSifranti.P41obsegZaposlitveFTE}
                      />
                    </sup>
                    :{" "}
                  </span>{" "}
                  {sestevekNacrtovanihVrednostiClanic.nacrtovaniObseg !== null
                    ? (" " + sestevekNacrtovanihVrednostiClanic.nacrtovaniObseg)
                      .toString()
                      .replace(".", ",")
                    : "Ni podatka"}
                </Col>
              </Row>
            </div>
          </Col>
        </Row>

        {((role.skrbnik === true && role.clanicaID === projectSkrbnikClanica) || (role.skrbnik === true && jeClanicaProjekta === true && role.clanicaID !== projectSkrbnikClanica) ||
          (role.skrbnik === false && role.projekti === 2)) && (
            <>
              {nacrtovaneVrednostiClanice.map((clanicaFinance, index) => {
                return (
                  <Row className="project-partners-details-card" key={index}>
                    <Col>
                      <Card className="mt-3">
                        <Card.Body>
                          {!showUrejanjeDodajanjeFinancniClaniceNacrtovani[
                            clanicaFinance.clanicaID
                          ] && (
                              <Row>
                                <Col lg={1}>{clanicaFinance.clanica}</Col>
                                <Col>
                                  <Row>
                                    <Col>
                                      <span>
                                        Vrednost projekta za članico UM{" "}
                                        <sup>
                                          <AiFillInfoCircle
                                            className="tooltip-icon"
                                            data-tooltip-id="tab-tooltip"
                                            data-tooltip-content={
                                              tooltipSifranti.P41vrednostProjektaZaClanicoUM
                                            }
                                          />{" "}
                                        </sup>
                                        :{" "}
                                      </span>{" "}
                                      {clanicaFinance.nacrtovanaVrednost !== null
                                        ? `${numberFormatValuta(
                                          clanicaFinance.nacrtovanaVrednost
                                        )}`
                                        : "Ni podatka"}
                                    </Col>
                                  </Row>
                                  <Row className="mt-2">
                                    <Col>
                                      <span>
                                        Vrednost / delež lastnega sofinanciranja UM{" "}
                                        <sup>
                                          <AiFillInfoCircle
                                            className="tooltip-icon"
                                            data-tooltip-id="tab-tooltip"
                                            data-tooltip-content={
                                              tooltipSifranti.P41vrednostDelezLastnegaSofinanciranjaUM
                                            }
                                          />{" "}
                                        </sup>
                                        :{" "}
                                      </span>{" "}
                                      {clanicaFinance.nacrtovanaLastnaVrednost !==
                                        null
                                        ? numberFormatValuta(
                                          clanicaFinance.nacrtovanaLastnaVrednost
                                        )
                                        : "Ni podatka"}
                                      {"/"}{" "}
                                      {clanicaFinance.nacrtovanaDelez !== null
                                        ? `${(" " + clanicaFinance.nacrtovanaDelez)
                                          .toString()
                                          .replace(".", ",")} %`
                                        : "Ni podatka"}
                                    </Col>
                                  </Row>
                                </Col>
                                <Col>
                                  <Row>
                                    <Col>
                                      <span>
                                        Vrednost sofinanciranja iz projektnih virov
                                        za članico UM{" "}
                                        <sup>
                                          {" "}
                                          <AiFillInfoCircle
                                            className="tooltip-icon"
                                            data-tooltip-id="tab-tooltip"
                                            data-tooltip-content={
                                              tooltipSifranti.P41vrednostSofinanaciranjaIzProjektnihVirovZaClanicoUM
                                            }
                                          />
                                        </sup>
                                        :{" "}
                                      </span>{" "}
                                      {clanicaFinance.nacrtovanaVrednostViri !==
                                        null
                                        ? `${numberFormatValuta(
                                          clanicaFinance.nacrtovanaVrednostViri
                                        )} `
                                        : "Ni podatka"}
                                    </Col>
                                  </Row>
                                  <Row className="mt-2">
                                    <Col>
                                      <span>
                                        Obseg zaposlitev na članici UM{" "}
                                        <sup>
                                          {" "}
                                          <AiFillInfoCircle
                                            className="tooltip-icon"
                                            data-tooltip-id="tab-tooltip"
                                            data-tooltip-content={
                                              tooltipSifranti.P41obsegZaposlitveNaClaniciUM
                                            }
                                          />{" "}
                                        </sup>
                                        :
                                      </span>{" "}
                                      {clanicaFinance.nacrtovaniObseg !== null
                                        ? clanicaFinance.nacrtovaniObseg
                                          .toString()
                                          .replace(".", ",")
                                        : "Ni podatka"}
                                    </Col>
                                  </Row>
                                </Col>
                                <Col lg={1}>
                                  <div className="float-end">
                                    {((role.skrbnik === true && role.clanicaID === projectSkrbnikClanica) ||
                                      (role.skrbnik === false && role.projekti === 2)) && (
                                        <Button
                                          className="org-button-details-text"
                                          id={clanicaFinance.clanicaID}
                                          onClick={(e) => {
                                            setStateShowUrejanjeDodajanjeFinancniClaniceNacrtovani(
                                              e.currentTarget.id
                                            );
                                            setStateNacrtovaneVrednostiClaniceUrejanje(
                                              clanicaFinance.clanicaID,
                                              clanicaFinance.nacrtovanaVrednost,
                                              clanicaFinance.nacrtovanaLastnaVrednost,
                                              clanicaFinance.nacrtovanaVrednostViri,
                                              clanicaFinance.nacrtovaniObseg
                                            );

                                            setStateNacrtovaneVrednostiClanicePrikaz(
                                              clanicaFinance.clanicaID,
                                              clanicaFinance.nacrtovanaVrednost !==
                                                null
                                                ? clanicaFinance.nacrtovanaVrednost
                                                : undefined,
                                              clanicaFinance.nacrtovanaLastnaVrednost !==
                                                null
                                                ? clanicaFinance.nacrtovanaLastnaVrednost
                                                : undefined,
                                              clanicaFinance.nacrtovanaVrednostViri !==
                                                null
                                                ? clanicaFinance.nacrtovanaVrednostViri
                                                : undefined,
                                              clanicaFinance.nacrtovaniObseg
                                            );
                                          }}
                                        >
                                          <AiOutlineEdit />
                                        </Button>
                                      )}
                                  </div>
                                </Col>
                              </Row>
                            )}
                          {showUrejanjeDodajanjeFinancniClaniceNacrtovani[
                            clanicaFinance.clanicaID
                          ] && (
                              <Form onSubmit={handleAddNacrtovaneVrednostiClanice}>
                                <Row>
                                  <Col lg={1}>{clanicaFinance.clanica}</Col>
                                  <Col>
                                    <Row>
                                      <Col>
                                        <Form.Label>
                                          Vrednost projekta za članico UM (€)
                                        </Form.Label>
                                        <CurrencyInput
                                          className="form-control"
                                          placeholder="Vnesi znesek"
                                          decimalScale={2}
                                          value={
                                            nacrtovaneVrednostiClanicePrikaz.nacrtovanaVrednost
                                          }
                                          intlConfig={{
                                            locale: "sl-SI",
                                            currency: "EUR",
                                          }}
                                          onValueChange={(value) => {
                                            setStateNacrtovaneVrednostiClaniceUrejanje(
                                              null,
                                              value !== undefined
                                                ? parseFloat(
                                                  value.replace(",", ".")
                                                )
                                                : null,
                                              null,
                                              null,
                                              null,
                                              "nacrtovanaVrednost"
                                            );
                                            setStateNacrtovaneVrednostiClanicePrikaz(
                                              null,
                                              value,
                                              null,
                                              null,
                                              null,
                                              "nacrtovanaVrednost"
                                            );
                                          }}
                                        />
                                      </Col>
                                    </Row>
                                    <Row className="mt-2">
                                      <Col>
                                        <Form.Label>
                                          Vrednost lastnega sofinanciranja UM (€){" "}
                                        </Form.Label>
                                        <Row>
                                          <Col>
                                            <CurrencyInput
                                              className="form-control"
                                              placeholder="Vnesi znesek"
                                              decimalScale={2}
                                              value={
                                                nacrtovaneVrednostiClanicePrikaz.nacrtovanaLastnaVrednost
                                              }
                                              intlConfig={{
                                                locale: "sl-SI",
                                                currency: "EUR",
                                              }}
                                              onValueChange={(value) => {
                                                setStateNacrtovaneVrednostiClaniceUrejanje(
                                                  null,
                                                  null,
                                                  value !== undefined
                                                    ? parseFloat(
                                                      value.replace(",", ".")
                                                    )
                                                    : null,
                                                  null,
                                                  null,
                                                  "nacrtovanaLastnaVrednost"
                                                );
                                                setStateNacrtovaneVrednostiClanicePrikaz(
                                                  null,
                                                  null,
                                                  value,
                                                  null,
                                                  null,
                                                  "nacrtovanaLastnaVrednost"
                                                );
                                              }}
                                            />
                                          </Col>
                                        </Row>
                                      </Col>
                                    </Row>
                                  </Col>
                                  <Col>
                                    <Row>
                                      <Col>
                                        <Form.Label>
                                          Vrednost sofinanciranja iz projektnih
                                          virov za članico UM (€){" "}
                                        </Form.Label>
                                        <CurrencyInput
                                          className="form-control"
                                          placeholder="Vnesi znesek"
                                          decimalScale={2}
                                          value={
                                            nacrtovaneVrednostiClanicePrikaz.nacrtovanaVrednostViri
                                          }
                                          intlConfig={{
                                            locale: "sl-SI",
                                            currency: "EUR",
                                          }}
                                          onValueChange={(value) => {
                                            setStateNacrtovaneVrednostiClaniceUrejanje(
                                              null,
                                              null,
                                              null,
                                              value !== undefined
                                                ? parseFloat(
                                                  value.replace(",", ".")
                                                )
                                                : null,
                                              null,
                                              "nacrtovanaVrednostViri"
                                            );
                                            setStateNacrtovaneVrednostiClanicePrikaz(
                                              null,
                                              null,
                                              null,
                                              value,
                                              null,
                                              "nacrtovanaVrednostViri"
                                            );
                                          }}
                                        />
                                      </Col>
                                    </Row>
                                    <Row className="mt-2">
                                      <Col>
                                        <Form.Label>
                                          Obseg zaposlitev na članici UM
                                        </Form.Label>
                                        <Form.Control
                                          type="number"
                                          placeholder="Vnesi obseg zaposlitve"
                                          min="0"
                                          step="0.01"
                                          value={
                                            nacrtovaneVrednostiClaniceUrejanje.nacrtovaniObseg
                                          }
                                          onChange={(e) => {
                                            setStateNacrtovaneVrednostiClaniceUrejanje(
                                              null,
                                              null,
                                              null,
                                              null,
                                              isNaN(parseFloat(e.target.value))
                                                ? null
                                                : parseFloat(e.target.value),
                                              "nacrtovaniObseg"
                                            );
                                          }}
                                        />
                                      </Col>
                                    </Row>
                                  </Col>
                                  <Col
                                    lg={2}
                                    className="d-flex align-items-center justify-content-center"
                                  >
                                    <Button
                                      variant="primary"
                                      className="me-2"
                                      type="submit"
                                    >
                                      Potrdi
                                    </Button>
                                    <Button
                                      variant="outline-primary ms-3"
                                      onClick={() => {
                                        setStateShowUrejanjeDodajanjeFinancniClaniceNacrtovani(
                                          null,
                                          "preklici"
                                        );
                                      }}
                                    >
                                      Prekliči
                                    </Button>
                                  </Col>
                                </Row>
                              </Form>
                            )}
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                );
              })}
            </>
          )}
      </Card.Body>
    </Card>
  );
};

export default NacrtovanaVrednostProjekta;
