import React, { useContext, useState } from 'react';

/*Import Context */
import { UserContext } from '../UserContext';

/*Import React-Bootstrap */
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form"

/*Import React-Icons */
import { AiOutlineEdit } from "react-icons/ai";
import { BiTrash } from "react-icons/bi";


const StroskovnaMesta = ({
    stroskovnaMestaClanic,
    showAddStroskovnoMestoError,
    showAddStroskovnoMesto,
    showEditStroskovnoMesto,
    editStroskovnoMesto,
    handleAddStroskovnoMestoAliNosilec,
    handleEditStroskovnoMestoAliNosilec,
    handleDeleteStroskovno,
    setStateShowAddStroskovnoMesto,
    setStateShowEditStroskovnoMesto,
    setStateAddStroskovnoMesto,
    setStateClanicaStroskovnoMestoNosilecId,
    setStateEditStroskovnoMesto,
    projectSkrbnikClanica
}) => {
    const role = useContext(UserContext);

    const [showModalDeleteStroskovno, setShowModalDeleteStroskovno] = useState(false);
    const [clanicaStroskovnoMestoNosilecSkupniNaziv, setClanicaStroskovnoMestoNosilecNaziv] = useState('')

    return (
        <>
            <Modal
                show={showModalDeleteStroskovno}
                onHide={() => {
                    setShowModalDeleteStroskovno(false);
                }}
            >
                <Modal.Body>
                    Želite izbrisati: <strong>{clanicaStroskovnoMestoNosilecSkupniNaziv}</strong>?
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        className='btn btn-danger'
                        variant='secondary'
                        onClick={() => {
                            handleDeleteStroskovno()
                            setShowModalDeleteStroskovno(false)
                        }}
                    >
                        Izbriši
                    </Button>
                    <Button
                        variant='primary'
                        onClick={() => {
                            setShowModalDeleteStroskovno(false);
                        }}
                    >
                        Ne
                    </Button>
                </Modal.Footer>
            </Modal>
            <Card className='mt-2'>
                <Card.Body>
                    {showAddStroskovnoMestoError && (
                        <h4 className='text-danger mt-3 mb-4'>
                            Prišlo je do napake pri dodajanju ali urejanju stroškovnega mesta/nosilca!
                        </h4>
                    )}
                    <Row className='project-partners-details-card'>
                        <Col>
                            {stroskovnaMestaClanic.map((stroskovna, index) => {
                                return (
                                    <Card className="mt-3" key={index}>
                                        <Card.Body>
                                            <Row className="mb-3">
                                                <Col>
                                                    {stroskovna.clanica}
                                                </Col>
                                            </Row>
                                            <Row className="pl-2">
                                                <Col lg={2}>
                                                    <span>Stroškovno mesto</span>
                                                </Col>
                                                <Col lg={2}>
                                                    <span>Stroškovni nosilec</span>
                                                </Col>
                                                <Col>
                                                    <div className='float-end'>
                                                        {((role.skrbnik === true && role.clanicaID === projectSkrbnikClanica) ||
                                                            (role.skrbnik === false && role.projekti === 2)) && (
                                                                <Button
                                                                    className='org-button-details-text'
                                                                    id={stroskovna.clanicaID}
                                                                    onClick={(e) => {
                                                                        setStateShowAddStroskovnoMesto(e.currentTarget.id);
                                                                        setStateAddStroskovnoMesto(parseInt(e.currentTarget.id), null, null, 'clanicaID')
                                                                    }}
                                                                >
                                                                    <span>Dodaj</span>
                                                                    <AiOutlineEdit />
                                                                </Button>
                                                            )}
                                                    </div>
                                                </Col>
                                            </Row>
                                            {showAddStroskovnoMesto[stroskovna.clanicaID] && (
                                                <Form onSubmit={handleAddStroskovnoMestoAliNosilec}>
                                                    <Row className="mb-3">
                                                        <Col lg={2}>
                                                            <Form.Control
                                                                required
                                                                type="text"
                                                                placeholder="Stroškovno mesto"
                                                                onChange={(e) => setStateAddStroskovnoMesto(null, e.target.value, null, 'stroskovnoMesto')}
                                                            />
                                                        </Col>
                                                        <Col lg={2}>
                                                            <Form.Control
                                                                type="text"
                                                                placeholder="Stroškovni nosilec"
                                                                onChange={(e) => setStateAddStroskovnoMesto(null, null, e.target.value, 'stroskovniNosilec')}
                                                            />
                                                        </Col>
                                                    </Row>
                                                    <div className='d-flex'>
                                                        <Button
                                                            variant='primary'
                                                            className='me-2'
                                                            type="submit"
                                                        >
                                                            Dodaj
                                                        </Button>
                                                        <Button
                                                            variant='outline-primary'
                                                            onClick={() => {
                                                                setStateShowAddStroskovnoMesto(null, 'preklici')
                                                                setStateAddStroskovnoMesto()
                                                            }}
                                                        >
                                                            Prekliči
                                                        </Button>
                                                    </div>
                                                </Form>
                                            )}
                                            <Row>
                                                <Col>
                                                    {stroskovna.seznam?.map((strosekPosamezen, indexStrosekPosamezen) => {
                                                        return (
                                                            <div key={indexStrosekPosamezen}>
                                                                {showEditStroskovnoMesto[strosekPosamezen.id] && (
                                                                    <Form onSubmit={handleEditStroskovnoMestoAliNosilec}>
                                                                        <Row className="p-2">
                                                                            <Col lg={2}>
                                                                                <Form.Control
                                                                                    required
                                                                                    type="text"
                                                                                    placeholder="Stroškovno mesto"
                                                                                    value={editStroskovnoMesto.stroskovnoMesto}
                                                                                    onChange={(e) => setStateEditStroskovnoMesto(null, null, e.target.value, null, 'stroskovnoMesto')}
                                                                                />
                                                                            </Col>
                                                                            <Col lg={2}>
                                                                                <Form.Control
                                                                                    type="text"
                                                                                    placeholder="Stroškovni nosilec"
                                                                                    value={editStroskovnoMesto.stroskovniNosilec}
                                                                                    onChange={(e) => setStateEditStroskovnoMesto(null, null, null, e.target.value, 'stroskovniNosilec')}
                                                                                />
                                                                            </Col>
                                                                        </Row>
                                                                        <div className='d-flex mb-3 mt-3'>
                                                                            <Button
                                                                                variant='primary'
                                                                                className='me-2'
                                                                                type="submit"
                                                                            >
                                                                                Uredi
                                                                            </Button>
                                                                            <Button
                                                                                variant='outline-primary'
                                                                                onClick={() => {
                                                                                    setStateShowEditStroskovnoMesto(null, 'preklici')
                                                                                    setStateEditStroskovnoMesto()

                                                                                }}
                                                                            >
                                                                                Prekliči
                                                                            </Button>
                                                                        </div>
                                                                    </Form>
                                                                )}
                                                                {!showEditStroskovnoMesto[strosekPosamezen.id] && (
                                                                    <>
                                                                        <Row
                                                                            className="ps-3 align-items-center">
                                                                            <Col lg={2}>
                                                                                {strosekPosamezen.stroskovnoMesto}
                                                                            </Col>
                                                                            <Col lg={2}>
                                                                                {strosekPosamezen.stroskovniNosilec}
                                                                            </Col>
                                                                            <Col >
                                                                                <Button

                                                                                    className='org-button-details-text ps-1'
                                                                                    id={strosekPosamezen.id}
                                                                                    onClick={(e) => {
                                                                                        setStateShowEditStroskovnoMesto(e.currentTarget.id)
                                                                                        setStateEditStroskovnoMesto(strosekPosamezen.id, stroskovna.clanicaID, strosekPosamezen.stroskovnoMesto, strosekPosamezen.stroskovniNosilec, 'vsaPolja')
                                                                                    }}
                                                                                >
                                                                                    <AiOutlineEdit />
                                                                                </Button>
                                                                                <Button
                                                                                    className='avtorizacije-button-trash '
                                                                                    id={strosekPosamezen.id}
                                                                                    onClick={(e) => {
                                                                                        setStateClanicaStroskovnoMestoNosilecId(strosekPosamezen.id)
                                                                                        setClanicaStroskovnoMestoNosilecNaziv(`${strosekPosamezen.stroskovnoMesto}  ${strosekPosamezen.stroskovniNosilec}`)
                                                                                        setShowModalDeleteStroskovno(true);

                                                                                    }}
                                                                                >
                                                                                    <BiTrash
                                                                                        className='text-danger' />
                                                                                </Button>
                                                                            </Col>
                                                                            <Col>

                                                                            </Col>
                                                                        </Row>
                                                                    </>
                                                                )}
                                                                <Row>
                                                                    <Col lg={5} className="pe-5">
                                                                        <hr className="mt-0 mb-0" />
                                                                    </Col>
                                                                </Row>

                                                            </div>
                                                        )
                                                    })}
                                                </Col>
                                            </Row>
                                        </Card.Body>
                                    </Card>
                                )
                            })}
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </>
    )
}

export default StroskovnaMesta;