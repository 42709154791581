import React, { useContext, useEffect, useState } from "react";
import SelectSearch from "react-select-search";

/*Import React-Bootstrap components */
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";

import { format } from "date-fns";

/*React-Icons*/
import { AiOutlineEdit } from "react-icons/ai";

import PropTypes from "prop-types";

/*Import API */
import { fetchGET, fetchPUT } from "../../apiHelper";
import {
  financiranjeKategorije,
  prviNivoProgramiAktivni,
  rootProgram,
  seznamPodprogramov,
  urejanjeRazpisa,
} from "../../listOfApi";

import { UserContext } from "../UserContext";

const OsnovniPodatki = ({
  id,
  tenderDataDetails,
  getRazpis,
  getRazpisData,
}) => {
  const role = useContext(UserContext);
  const {
    razpis_naziv,
    pomemben,
    odprt,
    odprt_tekst,
    prijava_datum,
    prijava_ura,
    sifrantARRS,
    sifrantCERIF,
    sifrantKLASIUS,
    program_naziv,
    povezava,
    obtezitev,
    opis,
    program,
    programID,
    financiranje_kategorija,
    id_financiranje_kategorija,
  } = tenderDataDetails;

  const [showEditOsnovniPodatki, setShowEditOsnovniPodatki] = useState(false);
  const [tenderDataEdit, setTenderDataEdit] = useState({
    razpisID: parseInt(id),
    razpis_naziv: "",
    obtezitev: 5,
    opis: "",
    povezava: "",
    pomemben: false,
    odprt: false,
    odprt_tekst: "",
    prijava_datum: "",
    prijava_ura: "",
    sifrantARRS: false,
    sifrantCERIF: false,
    sifrantKLASIUS: false,
    programID: "",
    id_financiranje_kategorija: "",
  });
  const [financiranjeKatego, setFinanciranjeKatego] = useState([]);
  const [prviNivoOptions, setPrviNivoOptions] = useState([]);
  const [podprogramValue, setPodprogramValue] = useState("");
  const [programD, setProgramD] = useState("");
  const [ostaliNivojiOptions, setOstaliNivojiOptions] = useState([]);
  const [showEditError, setShowEditError] = useState(false);

  const utezi = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"];

  /* Nastavi podatke razpisa za urejanje razpisa */
  useEffect(() => {
    setTenderDataEdit({
      ...tenderDataEdit,
      razpis_naziv: razpis_naziv,
      obtezitev: obtezitev,
      opis: opis,
      povezava: povezava,
      pomemben: pomemben,
      odprt: odprt,
      odprt_tekst: odprt_tekst,
      prijava_datum: prijava_datum,
      prijava_ura: prijava_ura,
      sifrantARRS: sifrantARRS,
      sifrantCERIF: sifrantCERIF,
      sifrantKLASIUS: sifrantKLASIUS,
      programID: programID,
      id_financiranje_kategorija: id_financiranje_kategorija,
    });
  }, [tenderDataDetails]);

  /* Se sproži ob vsaki spremembi glede na check box "razpis odprte narave" */
  useEffect(() => {
    if (!tenderDataEdit.odprt) {
      setTenderDataEdit({
        ...tenderDataEdit,
        odprt_tekst: " ",
        prijava_datum: "",
      });
    } else if (tenderDataEdit.odprt) {
      setTenderDataEdit({
        ...tenderDataEdit,
        prijava_datum: "2100-01-01",
        prijava_ura: "",
        odprt_tekst: "Razpis je odprt do porabe sredstev",
      });
    }
  }, [tenderDataEdit.odprt]);

  /* Pridobivanje kategorij financiranja */
  useEffect(() => {
    fetchGET(financiranjeKategorije).then((finanKategorije) => {
      const financiranjeKategorijeOptions = finanKategorije.map(
        (kategorija, index) => {
          return { name: kategorija.naziv, value: kategorija.id };
        }
      );
      setFinanciranjeKatego(financiranjeKategorijeOptions);
    });

    fetchGET(prviNivoProgramiAktivni).then((programi) => {
      /*Preverimo če programi obstajajo */
      let programiOptions;

      if (programi !== 0) {
        programiOptions = programi.map((program) => {
          return { name: program.naziv, value: program.objectID };
        });
        setPrviNivoOptions(programiOptions);
      }
    });
  }, []);

  useEffect(() => {
    if (programD !== "") {
      setPodprogramValue(tenderDataEdit.programID);

      fetchGET(`${seznamPodprogramov}/${programD}`).then(
        (seznamPodprogramov) => {
          let podprogramiSeznamOptions;

          if (seznamPodprogramov !== 0) {
            podprogramiSeznamOptions = seznamPodprogramov.map((podprogram) => {
              return { name: podprogram.naziv, value: podprogram.id };
            });

            if (podprogramiSeznamOptions.length !== 0) {
              setOstaliNivojiOptions(podprogramiSeznamOptions);
            }
          }
        }
      );
    }
  }, [programD]);

  useEffect(() => {
    if (tenderDataEdit.programID !== "") {
      fetchGET(`${rootProgram}/${tenderDataEdit.programID}/root`).then(
        (rootProgram) => {
          setProgramD(rootProgram);
        }
      );
    }
  }, [tenderDataEdit]);

  /*preveri parametre ob vnosu in jih zabeleži v objekt tenderData */
  const handleFormData = (e) => {
    let value = checkFormDataType(e);
    setTenderDataEdit({
      ...tenderDataEdit,
      [e.target.name]: value,
    });
  };

  /* Funkcija: preveri tip vnesenega parametra */
  const checkFormDataType = (e) => {
    let value;

    if (e.target.name === "pomemben") {
      value = !tenderDataEdit.pomemben;
    } else if (e.target.name === "odprt") {
      value = !tenderDataEdit.odprt;
    } else if (
      e.target.name === "sifrantARRS" ||
      e.target.name === "sifrantCERIF" ||
      e.target.name === "sifrantKLASIUS"
    ) {
      value = !tenderDataEdit[e.target.name];
    } else if (e.target.name === "obtezitev") {
      value = parseInt(e.target.value);
    } else {
      value = e.target.value;
    }
    return value;
  };

  /* Funkcija za upravljanje z SELECT elementom (programi) */
  const handleChangeSelectProgrami = (selectedOption) => {
    setPodprogramValue({ selection: 0 });
    setOstaliNivojiOptions([]);
    setTenderDataEdit({ ...tenderDataEdit, programID: selectedOption });

    fetchGET(`${seznamPodprogramov}/${selectedOption}`).then(
      (seznamPodprogramov) => {
        let podprogramiSeznamOptions;

        if (seznamPodprogramov !== 0) {
          podprogramiSeznamOptions = seznamPodprogramov.map((podprogram) => {
            return { name: podprogram.naziv, value: podprogram.id };
          });

          if (podprogramiSeznamOptions.length !== 0) {
            setOstaliNivojiOptions(podprogramiSeznamOptions);
          }
        }
      }
    );
  };

  /* Funkcija za upravljanje z SELECT elementom (podprogrami) */
  const handleChangeSelectPodprogrami = (selectedOption) => {
    setTenderDataEdit({ ...tenderDataEdit, programID: selectedOption });
    setPodprogramValue(selectedOption);
  };

  /* Funkcija za upravljanje z SELECT elementom za finančne kategorije */
  const handleChangeFinanceKategorije = (selectedOption) => {
    setTenderDataEdit({
      ...tenderDataEdit,
      id_financiranje_kategorija: selectedOption,
    });
  };

  /* Funkcija za shranjevanje podatkov na backend */
  const handleSubmitForm = (e) => {
    e.preventDefault();

    fetchPUT(urejanjeRazpisa, tenderDataEdit).then((razpis) => {
      if (razpis === 1) {
        getRazpis();
        getRazpisData();
        setShowEditOsnovniPodatki(false);
      } else {
        setShowEditError(true);
        setTimeout(() => {
          setShowEditError(false);
        }, 3000);
      }
    });
  };

  return (
    <>
      <Row>
        <Col className="header-divider">
          <hr />
        </Col>
      </Row>
      <Row>
        <Col>
          <Card className="razpis-card">
            <Card.Body>
              {showEditError && (
                <Row>
                  <Col>
                    <h2 className="text-danger mt-3 mb-3">
                      Prišlo je do napake pri urejanju razpisa!
                    </h2>
                  </Col>
                </Row>
              )}

              <Row>
                <Col className="text-end">
                  {role.razpisi === 2 && (
                    <Button
                      className="org-button-details-text"
                      id="1"
                      onClick={() => {
                        setShowEditOsnovniPodatki(!showEditOsnovniPodatki);
                      }}
                    >
                      Uredi <AiOutlineEdit />
                    </Button>
                  )}
                </Col>
              </Row>

              {!showEditOsnovniPodatki && (
                <>
                  <Row>
                    <Col>
                      <Row>
                        <Col className="ms-3 razpisDetails-header font-weight-bold">
                          Osnovni podatki
                        </Col>
                      </Row>
                      <div className="p-4">
                        <Row>
                          <Col className="mb-3">
                            <span>Uradni naslov razpisa: </span>
                            {razpis_naziv}
                          </Col>
                        </Row>

                        <Row>
                          <Col className="mb-3">
                            <span>Izpostavljen: </span>
                            {pomemben ? "Da" : "Ne"}
                          </Col>
                        </Row>
                        <Row className="mb-3">
                          <Col>
                            <span>Razpis odprte narave : </span>
                            {odprt ? "Da" : "Ne"}
                          </Col>
                        </Row>

                        {odprt && (
                          <Row className="mb-3">
                            <Col>
                              <span>Tekst razpisa odprte narave: </span>
                              {odprt_tekst}
                            </Col>
                          </Row>
                        )}
                        {!odprt && (
                          <>
                            <Row className="mb-3">
                              <Col>
                                <span>Datum prijave: </span>
                                {prijava_datum !== ""
                                  ? format(
                                      new Date(prijava_datum),
                                      "d. M. yyyy"
                                    )
                                  : ""}
                              </Col>
                            </Row>
                            <Row className="mb-3">
                              <Col>
                                <span>Ura prijave: </span>
                                {prijava_ura
                                  ? prijava_ura.toString().slice(0, -3)
                                  : " -"}
                              </Col>
                            </Row>
                          </>
                        )}
                        <Row>
                          <Col className="mb-3">
                            <span>Kategorija financiranja razpisa: </span>
                            {financiranje_kategorija}
                          </Col>
                        </Row>
                      </div>
                    </Col>
                    <Col>
                      <Row>
                        <Col className="ml-3 razpisDetails-header font-weight-bold">
                          Šifranti
                        </Col>
                      </Row>
                      <div className="p-4">
                        <Row>
                          <Col className="mb-3">
                            <span>ARIS: </span>
                            {sifrantARRS ? " Obvezen" : "Neobvezen"}
                          </Col>
                        </Row>
                        <Row>
                          <Col className="mb-3">
                            <span>CERIF: </span>
                            {sifrantCERIF ? " Obvezen" : "Neobvezen"}
                          </Col>
                        </Row>
                        <Row>
                          <Col className="mb-3">
                            <span>KLASIUS: </span>
                            {sifrantKLASIUS ? " Obvezen" : "Neobvezen"}
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Row>
                        <Col className="ms-3 razpisDetails-header font-weight-bold">
                          Pripadajoč program
                        </Col>
                      </Row>
                      <div className="p-4">
                        <Row className="mb-3">
                          <Col>
                            {" "}
                            <span>Ime: </span> {program_naziv}
                          </Col>
                        </Row>
                        <Row className="mb-3">
                          <Col>
                            <div className="mb-3">
                              <span> Program (stuktura) :</span>
                            </div>
                            {program?.map((programi, index) => {
                              return (
                                <p
                                  className={`mb-2 pl-1 ml-${2 + index}`}
                                  key={index}
                                >
                                  - {programi}
                                </p>
                              );
                            })}
                          </Col>
                        </Row>
                      </div>
                    </Col>
                    <Col>
                      <Row>
                        <Col className="ms-3 razpisDetails-header font-weight-bold">
                          Ostalo
                        </Col>
                      </Row>
                      <div className="p-4">
                        <Row className="mb-3">
                          <Col>
                            <span>Spletna stran:</span> {povezava}
                          </Col>
                        </Row>
                        <Row className="mb-3">
                          <Col>
                            <span>Utež:</span> {obtezitev}
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Row>
                        <Col className="ms-3 razpisDetails-header font-weight-bold">
                          Opis
                        </Col>
                      </Row>
                      <div className="p-4">
                        <Row className="mb-3">
                          <Col>{opis}</Col>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                </>
              )}
              {showEditOsnovniPodatki && (
                <Form onSubmit={handleSubmitForm}>
                  <Row>
                    <Col>
                      <Row>
                        <Col className="ms-3 razpisDetails-header font-weight-bold">
                          Osnovni podatki
                        </Col>
                      </Row>

                      <div className="ps-4 pe-4 pt-1">
                        <Row>
                          <Col className="mb-3">
                            <Form.Label>Uradni naslov razpisa</Form.Label>
                            <Form.Control
                              name="razpis_naziv"
                              type="text"
                              required
                              minLength="3"
                              value={tenderDataEdit.razpis_naziv}
                              onChange={handleFormData}
                            />
                          </Col>
                        </Row>

                        <Row>
                          <Col className="mb-3">
                            <Form.Label>Izpostavljen razpis</Form.Label>
                            <Form.Check
                              name="pomemben"
                              type="checkbox"
                              onChange={handleFormData}
                              label="Da"
                              checked={tenderDataEdit.pomemben}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col className="mb-3">
                            <Form.Label>Razpis odprte narave</Form.Label>
                            <Form.Check
                              name="odprt"
                              type="checkbox"
                              checked={tenderDataEdit.odprt}
                              onChange={handleFormData}
                              label="Da"
                              readOnly
                            />
                          </Col>
                        </Row>
                        <Row>
                          {tenderDataEdit.odprt && (
                            <Col>
                              <Form.Label>
                                Tekst za razpis odprte narave
                              </Form.Label>
                              <Form.Control
                                name="odprt_tekst"
                                type="text"
                                value={tenderDataEdit.odprt_tekst}
                                onChange={handleFormData}
                              />
                            </Col>
                          )}
                          {!tenderDataEdit.odprt && (
                            <>
                              <Col>
                                <Form.Label>Rok prijave</Form.Label>
                                <Form.Control
                                  name="prijava_datum"
                                  type="date"
                                  value={tenderDataEdit.prijava_datum}
                                  onChange={handleFormData}
                                />
                              </Col>
                              <Col>
                                <Form.Label>Ura prijave</Form.Label>
                                <Form.Control
                                  name="prijava_ura"
                                  type="time"
                                  value={tenderDataEdit.prijava_ura}
                                  onChange={handleFormData}
                                />
                              </Col>
                            </>
                          )}
                        </Row>
                        <Row>
                          <Col className="mb-3 mt-3">
                            <Form.Label>Kategorija financiranja</Form.Label>
                            <SelectSearch
                              options={financiranjeKatego}
                              value={tenderDataEdit.id_financiranje_kategorija}
                              onChange={handleChangeFinanceKategorije}
                              name="selectFinanceKategorije"
                              placeholder="Izberi kategorijo financiranja"
                            />
                          </Col>
                        </Row>
                      </div>
                    </Col>

                    <Col>
                      <Row>
                        <Col className="ms-3 razpisDetails-header font-weight-bold">
                          Šifranti
                        </Col>
                      </Row>

                      <div className="ps-4 pe-4 pt-1">
                        <Form.Label>
                          Obvezen vnos šifrantov na projektih
                        </Form.Label>
                        <Row>
                          <Col>
                            <Form.Check
                              name="sifrantARRS"
                              type="checkbox"
                              onChange={handleFormData}
                              label="ARIS"
                              checked={tenderDataEdit.sifrantARRS}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <Form.Check
                              name="sifrantCERIF"
                              type="checkbox"
                              onChange={handleFormData}
                              label="CERIF"
                              checked={tenderDataEdit.sifrantCERIF}
                            />
                          </Col>
                        </Row>

                        <Row>
                          <Col>
                            <Form.Check
                              name="sifrantKLASIUS"
                              type="checkbox"
                              onChange={handleFormData}
                              label="Klasius – P16"
                              checked={tenderDataEdit.sifrantKLASIUS}
                            />
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Row>
                        <Col className="ms-3 razpisDetails-header font-weight-bold">
                          Pripadajoč program
                        </Col>
                      </Row>

                      <div className="ps-4 pe-4 pt-1">
                        <Row>
                          <Col>
                            <Form.Label>Program</Form.Label>
                            <SelectSearch
                              defaultValue={programD}
                              options={prviNivoOptions}
                              onChange={handleChangeSelectProgrami}
                              search
                              name="selectProgram"
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col className="mt-3">
                            <Form.Label>Podprogram</Form.Label>
                            <SelectSearch
                              options={ostaliNivojiOptions}
                              onChange={handleChangeSelectPodprogrami}
                              defaultValue={podprogramValue}
                              search
                              name="selectPodprogram"
                              placeholder="Izberi podprogram"
                            />
                          </Col>
                        </Row>
                      </div>
                    </Col>

                    <Col>
                      <Row>
                        <Col className="ms-3 razpisDetails-header font-weight-bold">
                          Ostalo
                        </Col>
                      </Row>
                      <div className="ps-4 pe-4 pt-1">
                        <Row className="mb-3">
                          <Col>
                            <Form.Label>URL</Form.Label>
                            <Form.Control
                              name="povezava"
                              type="text"
                              minLength="3"
                              value={tenderDataEdit.povezava}
                              onChange={handleFormData}
                            />
                          </Col>
                        </Row>
                        <Row className="mb-3">
                          <Col>
                            <Form.Label>Utež</Form.Label>
                            <Form.Control
                              as="select"
                              name="obtezitev"
                              onChange={handleFormData}
                              value={tenderDataEdit.obtezitev}
                            >
                              {utezi.map((utez) => {
                                return (
                                  <option key={utez} value={utez}>
                                    {utez}
                                  </option>
                                );
                              })}
                            </Form.Control>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <Row>
                        <Col className="ms-3 razpisDetails-header font-weight-bold">
                          Opis
                        </Col>
                      </Row>
                      <div className="ps-4 pe-4 pt-1">
                        <Row className="mb-3">
                          <Col>
                            {" "}
                            <Form.Label>Kratek opis</Form.Label>
                            <Form.Control
                              name="opis"
                              as="textarea"
                              rows={4}
                              value={tenderDataEdit.opis}
                              onChange={handleFormData}
                            />
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <div className="ps-4 pe-4 pt-1">
                      <Col>
                        <Button
                          variant="primary"
                          type="submit"
                          className="mt-4"
                        >
                          Shrani
                        </Button>
                        <Button
                          className="mt-4"
                          variant="outline-primary ml-3"
                          onClick={() => setShowEditOsnovniPodatki(false)}
                        >
                          Prekliči
                        </Button>
                      </Col>
                    </div>
                  </Row>
                </Form>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

OsnovniPodatki.propTypes = {
  tenderDataDetails: PropTypes.shape({
    razpis_naziv: PropTypes.string,
    povezava: PropTypes.string,
    pomemben: PropTypes.bool,
    obtezitev: PropTypes.number,
    odprt: PropTypes.bool,
    odprt_tekst: PropTypes.string,
    opis: PropTypes.string,
    prijava_datum: PropTypes.string,
    prijava_ura: PropTypes.string,
    sifrantARRS: PropTypes.bool,
    sifrantCERIF: PropTypes.bool,
    sifrantKLASIUS: PropTypes.bool,
    program_naziv: PropTypes.string,
    program: PropTypes.array,
    programID: PropTypes.number,
    id_financiranje_kategorija: PropTypes.number,
    financiranje_kategorija: PropTypes.string,
  }),
  getRazpis: PropTypes.func,
  getRazpisData: PropTypes.func,
};

export default OsnovniPodatki;
