import React, { useState, useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { useMsal, useIsAuthenticated, AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";

import "./App.scss";

/* Import API */
import { userAvtorizacijeUpn } from "./listOfApi";
import { fetchGET } from "./apiHelper";

/*Component Import*/
import Header from "./components/Header";
import SidebarNavigation from "./components/SidebarNavigation";
import AuthorisationComponent from "./components/AuthorisationComponent";
import LoginComponent from "./components/LoginComponent";

/*React-bootstrap components */
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

const App = () => {
  const [userAuthorisation, setUserAuthorisation] = useState({});
  const { accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();


  useEffect(() => {
    if (isAuthenticated) {
      const username = accounts[0].username;
      fetchGET(`${userAvtorizacijeUpn}/${username}`).then((user) => {
        if (user !== 0) {
          setUserAuthorisation({
            admin: user.admin,
            skrbnik: user.skrbnik,
            clanicaID: user.clanicaID,
            programi: user.programi,
            razpisi: user.razpisi,
            aktualniRazpisi: user.aktualniRazpisi,
            projekti: user.projekti,
            partnerji: user.partnerji,
          });
        } else {
          //TODO:Dobimo v primeru ko uporabnika ni v tabeli!
        }
      });
    }
  }, [isAuthenticated]);

  return (
    <Router>
      <Container fluid>
        <Row>
          <Header isAuthenticated={isAuthenticated} />
        </Row>
        <Row className="mt-5 ml-5">
        </Row>
        <Row>
          <UnauthenticatedTemplate>
            <LoginComponent />
          </UnauthenticatedTemplate>
          <AuthenticatedTemplate>
            <SidebarNavigation role={userAuthorisation} />
            <AuthorisationComponent role={userAuthorisation} />
          </AuthenticatedTemplate>
        </Row>
      </Container>

    </Router>
  );
};

export default App;
