import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

/*Import API*/
import { vnosAvtorizacije } from "../listOfApi";
import { fetchPOST } from "../apiHelper";

/* React-Bootstrap component */
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";

/*React-switch plugin */
import Switch from "react-switch";

const AddUserRolesAuthorisation = (props) => {
  const history = useNavigate();

  const [checked, setChecked] = useState(false);
  const [mail, setMail] = useState("");
  const [kisID, setKisID] = useState("");

  const [programiRole, setProgramiRole] = useState({
    programi: "2",
  });
  const [razpisiRole, setRazpisiRole] = useState({
    razpisi: "2",
  });
  const [projektiRole, setProjektiRole] = useState({
    projekti: "2",
  });
  const [partnerjiRole, setPartnerjiRole] = useState({
    partnerji: "2",
  });
  const [aktualniRazpisiRole, setAktaulniRazpisiRole] = useState({
    aktualniRazpisi: "2",
  });

  const [error, setError] = useState(false);

  const handleForm = (e) => {
    e.preventDefault();

    const avtorizacije = {
      upn: mail,
      clanicaID: 20,
      programi: parseInt(programiRole.programi),
      projekti: parseInt(projektiRole.projekti),
      partnerji: parseInt(partnerjiRole.partnerji),
      razpisi: parseInt(razpisiRole.razpisi),
      aktualniRazpisi: parseInt(aktualniRazpisiRole.aktualniRazpisi),
      kisID: parseInt(kisID),
      skrbnik: false,
      admin: checked,
    };

    fetchPOST(vnosAvtorizacije, avtorizacije).then((odgovor) => {
      if (odgovor === 1) {
        history(-1);
      } else {
        setError(true);
        setTimeout(() => {
          setError(false);
        }, 5000);
      }
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case "programi":
        setProgramiRole({
          [name]: value,
        });
        break;
      case "razpisi":
        setRazpisiRole({
          [name]: value,
        });
        break;
      case "projekti":
        setProjektiRole({
          [name]: value,
        });
        break;
      case "partnerji":
        setPartnerjiRole({
          [name]: value,
        });
        break;
      case "aktualniRazpisi":
        setAktaulniRazpisiRole({
          [name]: value,
        });
        break;
      default:
        break;
    }
  };

  const checkSwitch = () => {
    setChecked(!checked);
    if (checked === false) {
      setProgramiRole({ programi: "2" });
      setRazpisiRole({ razpisi: "2" });
      setProjektiRole({ projekti: "2" });
      setPartnerjiRole({ partnerji: "2" });
      setAktaulniRazpisiRole({ aktualniRazpisi: "2" });
    }
  };

  return (
    <Container fluid>
      <Row>
        <Col className="main-header font-weight-bold">Nov uporabnik</Col>
      </Row>
      <Row>
        <Col className="header-divider">
          <hr />
        </Col>
      </Row>
      <Row className={"mt-3 mb-3"}>
        <Col>
          {error && (
            <h5 className={"text-danger"}>Napaka pri dodajanju uporbnika!</h5>
          )}
        </Col>
      </Row>
      <Form onSubmit={handleForm}>
        <Row>
          <Col>
            <Form.Label>Digitalna identiteta</Form.Label>
            <Form.Control
              required
              name="enaslov"
              type="email"
              minLength="3"
              maxLength="100"
              onChange={(e) => {
                setMail(e.target.value);
              }}
            />
          </Col>
          <Col>
            <Form.Label>KIS ID</Form.Label>
            <Form.Control
              name="kisId"
              type="number"
              onChange={(e) => {
                setKisID(e.target.value);
              }}
            />
          </Col>
        </Row>
        <Row className="mt-4">
          <Col>
            <Form.Label>Super admin</Form.Label>
          </Col>
        </Row>
        <Row>
          <Col>
            <Switch className="ms-1" onChange={checkSwitch} checked={checked} />
          </Col>
        </Row>
        {!checked && (
          <>
            <Card className="mt-4">
              <Card.Body>
                <Row className="mt-2">
                  <Col>
                    <Form.Label>Programi</Form.Label>
                    <Form.Group controlId="formProgramiRadio" className="ms-2">
                      <Form.Check
                        name="programi"
                        type="radio"
                        value={0}
                        label="Ni dostopa"
                        onChange={handleChange}
                      />
                      <Form.Check
                        name="programi"
                        value={1}
                        type="radio"
                        label="Obiskovalec"
                        onChange={handleChange}
                      />
                      <Form.Check
                        name="programi"
                        type="radio"
                        value={2}
                        label="Urednik"
                        onChange={handleChange}
                        defaultChecked
                      />
                    </Form.Group>
                  </Col>

                  <Col>
                    <Form.Label>Razpisi</Form.Label>
                    <Form.Group controlId="formRazpisiRadio" className="ms-2">
                      <Form.Check
                        type="radio"
                        label="Ni dostopa"
                        name="razpisi"
                        value={0}
                        onChange={handleChange}
                      />
                      <Form.Check
                        type="radio"
                        label="Obiskovalec"
                        name="razpisi"
                        value={1}
                        onChange={handleChange}
                      />
                      <Form.Check
                        type="radio"
                        label="Urednik"
                        name="razpisi"
                        value={2}
                        onChange={handleChange}
                        defaultChecked
                      />
                    </Form.Group>
                  </Col>

                  <Col>
                    <Form.Label>Aktualni razpisi</Form.Label>
                    <Form.Group controlId="formRazpisiRadio" className="ms-2">
                      <Form.Check
                        type="radio"
                        label="Ni dostopa"
                        name="aktualniRazpisi"
                        value={0}
                        onChange={handleChange}
                      />
                      <Form.Check
                        type="radio"
                        label="Obiskovalec"
                        name="aktualniRazpisi"
                        value={1}
                        onChange={handleChange}
                      />
                      <Form.Check
                        type="radio"
                        label="Urednik"
                        name="aktualniRazpisi"
                        value={2}
                        onChange={handleChange}
                        defaultChecked
                      />
                    </Form.Group>
                  </Col>

                  <Col>
                    <Form.Label>Projekti</Form.Label>
                    <Form.Group controlId="formProjektiRadio" className="ms-2">
                      <Form.Check
                        type="radio"
                        label="Ni dostopa"
                        name="projekti"
                        value={0}
                        onChange={handleChange}
                      />
                      <Form.Check
                        type="radio"
                        label="Obiskovalec"
                        name="projekti"
                        value={1}
                        onChange={handleChange}
                      />
                      <Form.Check
                        type="radio"
                        label="Urednik"
                        name="projekti"
                        value={2}
                        onChange={handleChange}
                        defaultChecked
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Label>Projektni partnerji</Form.Label>
                    <Form.Group controlId="formProjektiRadio" className="ms-2">
                      <Form.Check
                        type="radio"
                        label="Ni dostopa"
                        name="partnerji"
                        value={0}
                        onChange={handleChange}
                      />
                      <Form.Check
                        type="radio"
                        label="Obiskovalec"
                        name="partnerji"
                        value={1}
                        onChange={handleChange}
                      />
                      <Form.Check
                        type="radio"
                        label="Urednik"
                        name="partnerji"
                        value={2}
                        onChange={handleChange}
                        defaultChecked
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </>
        )}

        <Row className="mt-4">
          <Col>
            <Button type="submit" variant="primary">
              Dodaj pravice
            </Button>
            <Button
              variant="outline-primary ms-3"
              onClick={() => {
                history(-1);
              }}
            >
              Prekliči
            </Button>
          </Col>
        </Row>
      </Form>
    </Container>
  );
};

export default AddUserRolesAuthorisation;
