import React, { useContext} from 'react';
import { Link } from 'react-router-dom';

/* Import Context */
import { UserContext } from '../UserContext';

/* React-Bootstrap component */
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

/*React-icons */
import { BiLayerPlus } from 'react-icons/bi';
import { AiOutlineEdit } from 'react-icons/ai';

const ProgramStructureContainerTreeItem = ({
  id,
  program,
  nivoK4,
  programStructureHandle,
  changeProgramStatus
}) => {
  const role = useContext(UserContext);
  return (
    <>
      <Row>
        <Col lg={7} xl={9} className='d-flex align-items-center'>
          <div
            className='program-structure-naslov__hover'
            onClick={programStructureHandle}
            id={program.objectID}
          >
            {program.naziv}
          </div>
          <div className={`programs-structure-card-aktiven-text ms-3 ${program.aktiven? 'card-text--activated':'card-text--deactivated'}`} onClick={()=> changeProgramStatus(program.objectID)}>
            {program.aktiven && <span> Aktiven </span>}
            {!program.aktiven && <span> Neaktiven </span>}
          </div>
        </Col>
        <Col lg={5} xl={3}>
          {role.programi === 2 && (
            <div className='float-end'>
              <Link
                className='programs-button-add-text'
                to={`/Programi/UrediProgram/${program.objectID}`}
              >
                <Button variant='outline-primary'>
                  <AiOutlineEdit />
                </Button>
              </Link>
              {!nivoK4 && (
                <Link
                  className='programs-button-add-text ms-2'
                  to={`/Programi/DodajProgram/${program.objectID}`}
                >
                  <Button variant='outline-primary'>
                    <BiLayerPlus />
                  </Button>
                </Link>
              )}
            </div>
          )}
        </Col>
      </Row>
    </>
  );
};

export default ProgramStructureContainerTreeItem;
