import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

/*Import podkomponente */
import ProgramsList from './programs/ProgramsList';

/*Import API */
import { prviNivoProgramiAPI } from '../listOfApi';
import { fetchGET } from '../apiHelper';

/* React-Bootstrap component */
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

/*import React-icons */
import { AiOutlineExclamationCircle } from 'react-icons/ai';

const Programs = ({ role }) => {
  const [programs, setPrograms] = useState([]);
  const [filteredPrograms, setFilteredPrograms] = useState([]);
  const [programsLoaded, setProgramsLoaded] = useState(false);
  const [searchProgram, setSearchProgram] = useState('');
  const [showActive, setShowActive] = useState(false);

  useEffect(() => {
    fetchGET(prviNivoProgramiAPI).then((programi) => {
      if (programi !== 0) {
        setPrograms(programi);
        setFilteredPrograms(programi);
        setProgramsLoaded(true);
      }
    });
  }, []);

  /*Se sproži ob spremembi statusa checkboxa aktiven */
  useEffect(() => {
    let filteredPrograms = [];
    if (showActive === false) {
      filteredPrograms = programs.filter((program) =>
        program.naziv.toLowerCase().includes(searchProgram.toLocaleLowerCase())
      );

      setFilteredPrograms(filteredPrograms);
    } else if (showActive === true) {
      filteredPrograms = programs.filter(
        (program) =>
          program.aktiven === true &&
          program.naziv
            .toLowerCase()
            .includes(searchProgram.toLocaleLowerCase())
      );
      setFilteredPrograms(filteredPrograms);
    }
  }, [showActive, searchProgram]);

  return (
    <Container fluid>
      {(role.programi === 1 || role.programi === 2) && (
        <>
          <Row>
            <Col className='main-header font-weight-bold'>Programi</Col>
          </Row>
          <Row className='mb-2'>
            <Col className='header-divider'>
              <hr />
            </Col>
          </Row>
          <Row className='mb-2 align-items-center'>
            <Col lg={5} md={8}>
              <Form.Control
                name='iskalnik'
                type='text'
                value={searchProgram}
                onChange={(e) => setSearchProgram(e.target.value)}
                placeholder='Poišči program po nazivu...'
              />
            </Col>
            <Col>
              <Form.Check
                name='aktiven'
                type='checkbox'
                checked={showActive}
                onChange={() => setShowActive(!showActive)}
                label='Aktiven'
              />
            </Col>
            <Col>
              <div className='float-end'>
                {role.programi === 2 && (
                  <Link
                    className='programs-button-add-text'
                    to={`/Programi/DodajProgram/`}
                  >
                    <Button variant='outline-primary'>Dodaj program</Button>
                  </Link>
                )}
              </div>
            </Col>
          </Row>
          <div>
            {/* Komponenta ProgramsList za prikaz seznama programov */}
            {programsLoaded && <ProgramsList programs={filteredPrograms} />}
          </div>

          {!programsLoaded && (
            <Row className='mt-3'>
              <Col>
                <h5>
                  <span className='me-3'>
                    <AiOutlineExclamationCircle />
                  </span>
                  Nalaganje programov...
                </h5>
              </Col>
            </Row>
          )}
        </>
      )}
    </Container>
  );
};

export default Programs;
