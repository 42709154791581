import React, { useContext} from 'react';
import { Link } from 'react-router-dom';

/* Import Context */
import { UserContext } from '../UserContext';

/* React-component */
import ProgramStructureContainerTree from './ProgramStructureContainerTree';

/* React-Bootstrap component */
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';

/*React-icons */
import { BiLayerPlus } from 'react-icons/bi';
import { AiOutlineEdit } from 'react-icons/ai';

const ProgramStructureContainer = ({ program, id, programLoaded, changeProgramStatus }) => {
  const role = useContext(UserContext);

  return (
    <Col>
      <Card className='border-left-card-n1 main-container-card__shadows'>
        <Card.Body className='pb-1 pt-1'>
          <Row className='mb-1'>
            <Col lg={9} className='d-flex align-items-center'>
              <div className='program-structure-naslov__hover'>
                {program.naziv}
              </div>
              <div className={`programs-structure-card-aktiven-text ms-3 ${program.aktiven? 'card-text--activated':'card-text--deactivated'}`} onClick={()=>changeProgramStatus(program.objectID)}>
                {program.aktiven && <span> Aktiven </span>}
                {!program.aktiven && <span> Neaktiven </span>}
              </div>
            </Col>
            <Col lg={3}>
              <Row>
                <Col>
                  {role.programi === 2 && (
                    <div className='float-end'>
                      <Link
                        className='programs-button-add-text'
                        to={`/Programi/UrediProgram/${program.objectID}`}
                      >
                        <Button variant='outline-primary'>
                          <AiOutlineEdit />
                        </Button>
                      </Link>
                      <Link
                        className='programs-button-add-text ms-2'
                        to={`/Programi/DodajProgram/${program.objectID}`}
                      >
                        <Button variant='outline-primary'>
                          <BiLayerPlus />
                        </Button>
                      </Link>
                    </div>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
          {/* Prikaz podstrukture od K2-> do K4 */}

          <>
            {programLoaded && (
              <ProgramStructureContainerTree program={program} changeProgramStatus={changeProgramStatus} />
            )}
          </>
        </Card.Body>
      </Card>
    </Col>
  );
};

export default ProgramStructureContainer;
