import React from 'react-dom';

/*import React-boostrap components */
import Row from 'react-bootstrap/Row';

const ProgramTreeStructureItem = ({ programItem }) => {
  return (
    <div className='d-flex'>
      <div>{programItem.naziv}</div>
      <div className='programs-structure-card-aktiven-text ms-3'>
        {programItem.aktiven && <span> Aktiven </span>}
        {!programItem.aktiven && <span> Neaktiven </span>}
      </div>
    </div>
  );
};

export default ProgramTreeStructureItem;
