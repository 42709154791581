import React from 'react-dom';

/*Import React-component */
import ProgramTreeStructureItem from './ProgramTreeStructureItem';

/*import React-boostrap components */
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';

/*import React-icons */

const ProgramTreeStructure = ({ programStructure }) => {
  if (Object.keys(programStructure).length === 0) {
    return null;
  } else {
    return (
      <>
        {programStructure.children?.map((k1) => {
          return (
            <Row className='mt-3 ms-5 programs-preview-structure'>
              <Col>
                <Card className='programs-margin-left'>
                  <Card.Body className='pt-2 pb-2'>
                    {/*Komponenta za prikaz podstrukture posameznega elementa - item-a */}
                    <ProgramTreeStructureItem programItem={k1} />
                    {k1.children?.map((k2) => {
                      return (
                        <Row className='mt-3'>
                          <Col>
                            <Card className='programs-margin-left'>
                              <Card.Body className='pt-2 pb-2 '>
                                <ProgramTreeStructureItem programItem={k2} />
                                {k2.children?.map((k3) => {
                                  return (
                                    <Row className='mt-3'>
                                      <Col>
                                        <Card className='programs-margin-left'>
                                          <Card.Body className='pt-2 pb-2'>
                                            <ProgramTreeStructureItem programItem={k3} />
                                          </Card.Body>
                                        </Card>
                                      </Col>
                                    </Row>
                                  );
                                })}
                              </Card.Body>
                            </Card>
                          </Col>
                        </Row>
                      );
                    })}
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          );
        })}
      </>
    );
  }
};
export default ProgramTreeStructure;
