import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import fontCalibri from "../../assets/fonts/calibri.ttf";
import fontCalibrib from "../../assets/fonts/calibrib.ttf";
import fontCalibrii from "../../assets/fonts/calibrii.ttf";

import { format } from "date-fns";

import {
  PDFDownloadLink,
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Font,
  Link,
} from "@react-pdf/renderer";

Font.register({
  family: "Calibri",
  fonts: [
    {
      src: fontCalibri,
      fontStyle: "normal",
    },
    {
      src: fontCalibrib,
      fontWeight: "bold",
    },
    {
      src: fontCalibrii,
      fontStyle: "italic",
    },
  ],
});

const styles = StyleSheet.create({
  page: {
    paddingTop: 35,
    paddingBottom: 45,
    paddingHorizontal: 35,
    fontFamily: "Calibri",
    fontSize: 10,
  },
  aktualniRazpisiNaslov: {
    color: "#006a8e",
    fontSize: 15,
    fontWeight: "bold",
    marginBottom: 5,
    paddingLeft: 3,
  },
  oddelekView: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 5,
    backgroundColor: "#ededed",
    padding: 5,
    paddingTop: 9,
  },
  oddelekNaslovView: {
    flexBasis: "80%",
    fontSize: 13,
    fontWeight: "bold",
  },
  oddelekVerzijaRazpisiView: {
    flexBasis: "20%",
    textAlign: "right",
  },
  opombaText: {
    padding: 5,
    textAlign: "justify",
  },
  izpostavljeniRazpisiView: {
    flexDirection: "row",
    alignItems: "center",
    color: "white",
    backgroundColor: "#2CB0AE",
    padding: 5,
    paddingTop: 10,
  },
  izpostavljeniRazpisiTitle: {
    fontSize: 13,
    fontWeight: "bold",
    flexBasis: "80%",
  },
  izpostavljeniRazpisiText: {
    flexBasis: "20%",
    textAlign: "right",
  },
  razpisiIzpotavljeniDynamicView: {
    flexDirection: "row",
    alignItems: "center",
    padding: 5,
    borderBottom: "1px solid silver",
  },
  razpisiIzpotavljeniDynamicViewLast: {
    flexDirection: "row",
    alignItems: "center",
    padding: 5,
  },
  razpisiIzpostavljeniNaziv: {
    flexBasis: "40%",
    paddingRight: 10,
  },
  razpisiIzpostavljeniOpis: {
    flexBasis: "45%",
    textAlign: "justify",
  },
  razpisiIzpostavljeniDatum: {
    flexBasis: "15%",
    textAlign: "right",
  },
  razpisiIzpostavljenDatumNovo: {
    border: "1px solid green",
    width: 25,
    color: "white",
    backgroundColor: "green",
    marginLeft: 53,
    marginBottom: 2,
    paddingTop: 5,
    paddingLeft: 2,
    paddingRight: 1,
    borderTopLeftRadius: 2,
    borderTopRightRadius: 2,
    borderBottomRightRadius: 2,
    borderBottomLeftRadius: 2,
  },
  aktualniRazpisiView: {
    flexDirection: "row",
    alignItems: "center",
    color: "white",
    backgroundColor: "#006a8e",
    padding: 5,
    paddingTop: 10,
  },
  aktualniRazpisiTitle: {
    fontSize: 13,
    fontWeight: "bold",
    flexBasis: "80%",
  },
  aktualniRazpisiText: {
    flexBasis: "20%",
    textAlign: "right",
  },
  podkategorijaNaziv: {
    backgroundColor: "#ededed",
    padding: 5,
    paddingTop: 10,
    fontWeight: "bold",
  },
  podkategorijaView: {
    flexDirection: "row",
    alignItems: "center",
    padding: 5,
    paddingTop: 10,
    borderBottom: "1px solid silver",
  },
  podkategorijaViewLast: {
    flexDirection: "row",
    alignItems: "center",
    padding: 5,
    paddingTop: 10,
  },
  podkategorijaNazivText: {
    flexBasis: "40%",
    paddingRight: 10,
  },
  podkategorijaOpisText: {
    flexBasis: "45%",
    textAlign: "justify",
  },
  podkategorijaDatumView: {
    flexBasis: "15%",
    textAlign: "right",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 8,
    bottom: 20,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
  razpisiDatumNovo: {
    border: "1px solid green",
    width: 25,
    color: "white",
    backgroundColor: "green",
    marginLeft: 53,
    marginBottom: 2,
    paddingTop: 5,
    paddingLeft: 2,
    paddingRight: 1,
    borderTopLeftRadius: 2,
    borderTopRightRadius: 2,
    borderBottomRightRadius: 2,
    borderBottomLeftRadius: 2,
  },
});

export default function CurrentTenderPDF({
  aktualniRazpisi,
  izpostavljeniAktualniRazpis,
  aktualniRazpisiVerzija,
  role,
}) {
  //deep copy aktualnih razpisov
  const poljeAktualniRazpisi = JSON.parse(JSON.stringify(aktualniRazpisi));

  //Odstranimo tiste podkategorije kjer ni razpisov.
  for (let i = 0; i < poljeAktualniRazpisi.length; i++) {
    for (let j = 0; j < poljeAktualniRazpisi[i].podkategorije.length; j++) {
      if (poljeAktualniRazpisi[i].podkategorije[j].razpisi.length === 0) {
        poljeAktualniRazpisi[i].podkategorije.splice(j, 1);
        j = j - 1;
      }
    }
  }

  //Odstranimo kategorije brez podkategorij
  const aktualniRazpisiFiltered = poljeAktualniRazpisi.filter((p, i) => {
    return p.podkategorije.length > 0;
  });

  const documentPDF = (
    <Document>
      <Page size="A4" style={styles.page}>
        {/* GLAVA */}
        <Text style={styles.aktualniRazpisiNaslov}>AKTUALNI RAZPISI</Text>
        <View style={styles.oddelekView}>
          <View style={styles.oddelekNaslovView}>
            <Text>Oddelek za raziskovalno in umetniško dejavnost</Text>
          </View>
          <View style={styles.oddelekVerzijaRazpisiView}>
            <Text>Aktualni razpisi</Text>
            <Text>
              {`${aktualniRazpisiVerzija.stevilka}/${new Date(
                aktualniRazpisiVerzija.datum
              ).getFullYear()}`}{" "}
              |
            </Text>
            <Text>
              {aktualniRazpisiVerzija && aktualniRazpisiVerzija.datum
                ? format(new Date(aktualniRazpisiVerzija.datum), "d. M. yyyy")
                : ""}
            </Text>
          </View>
        </View>
        <Text style={styles.opombaText}>
          Opomba: Pri rokih prijave je lahko ob datumu določena tudi ura.
          Preverite v razpisni dokumentaciji. Ure so navedene samo pri razpisih,
          objavljenih s strani ARIS.
        </Text>
        {/* IZPOSTAVLJENI RAZPISI */}
        {izpostavljeniAktualniRazpis.length > 0 && (
          <View style={styles.izpostavljeniRazpisiView}>
            <Text style={styles.izpostavljeniRazpisiTitle}>
              IZPOSTAVLJENI RAZPISI
            </Text>
            <Text style={styles.izpostavljeniRazpisiText}>Rok prijave</Text>
          </View>
        )}
        {izpostavljeniAktualniRazpis.map((razpisIzpostavljen, index, row) => {
          return (
            <View
              style={
                index + 1 === row.length
                  ? styles.razpisiIzpotavljeniDynamicViewLast
                  : styles.razpisiIzpotavljeniDynamicView
              }
              wrap={false}
            >
              <View style={styles.razpisiIzpostavljeniNaziv}>
                <Text>{razpisIzpostavljen.razpis_naziv}</Text>
              </View>
              <View style={styles.razpisiIzpostavljeniOpis}>
                <Text>{razpisIzpostavljen.opis}</Text>
              </View>
              <View style={styles.razpisiIzpostavljeniDatum}>
                {razpisIzpostavljen.novo === true && (
                  <Text style={styles.razpisiIzpostavljenDatumNovo}>Novo</Text>
                )}
                <Text>
                  {razpisIzpostavljen.prijava_datum === "2100-01-01" &&
                    "Odprt razpis"}
                  {razpisIzpostavljen.prijava_datum !== null &&
                  razpisIzpostavljen.prijava_datum !== "2100-01-01"
                    ? format(
                        new Date(razpisIzpostavljen.prijava_datum),
                        "d. M. yyyy"
                      )
                    : ""}
                </Text>
                <Text>
                  {razpisIzpostavljen.prijava_ura !== null &&
                    `do: ${razpisIzpostavljen.prijava_ura.slice(0, -3)}`}
                </Text>
              </View>
            </View>
          );
        })}
        {aktualniRazpisiFiltered.map((razpis, index) => {
          return (
            <View>
              <View style={styles.aktualniRazpisiView} wrap={false}>
                <Text style={styles.aktualniRazpisiTitle}>
                  {razpis.kategorija}
                </Text>
                <Text style={styles.aktualniRazpisiText}>Rok prijave</Text>
              </View>
              {razpis.podkategorije.map((podkategorija, index) => {
                return (
                  <View>
                    {podkategorija.podkategorijaID !== -1 && (
                      <View wrap={false}>
                        <Text style={styles.podkategorijaNaziv}>
                          {podkategorija.podkategorija}
                        </Text>
                      </View>
                    )}
                    {podkategorija.razpisi.length > 0 &&
                      podkategorija.razpisi.map((razpis, index, row) => {
                        return (
                          <View
                            style={
                              index + 1 === row.length
                                ? styles.podkategorijaViewLast
                                : styles.podkategorijaView
                            }
                            wrap={false}
                          >
                            <Text style={styles.podkategorijaNazivText}>
                              {razpis.url !== "" ? (
                                <Link src={razpis.url} wrap={false}>
                                  {razpis.naziv}
                                </Link>
                              ) : (
                                razpis.naziv
                              )}
                            </Text>
                            <Text style={styles.podkategorijaOpisText}>
                              {razpis.opis !== "" ? razpis.opis : "Ni opisa"}
                            </Text>
                            <View style={styles.podkategorijaDatumView}>
                              {razpis.novo === true && (
                                <Text style={styles.razpisiDatumNovo}>
                                  Novo
                                </Text>
                              )}

                              <Text>
                                {razpis.datum === "2100-01-01" &&
                                  "Odprt razpis"}
                                {razpis.datum !== null &&
                                razpis.datum !== "2100-01-01" &&
                                razpis &&
                                razpis.datum
                                  ? format(new Date(razpis.datum), "d. M. yyyy")
                                  : ""}
                              </Text>
                              <Text>
                                {razpis.ura !== null &&
                                  `do: ${razpis.ura.slice(0, -3)}`}
                              </Text>
                            </View>
                          </View>
                        );
                      })}
                  </View>
                );
              })}
            </View>
          );
        })}
        {/* "NOGA */}
        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) => `${pageNumber}/${totalPages}`}
          fixed
        />
      </Page>
    </Document>
  );

  return (
    <div>
      <Row>
        <Col>
          {role.aktualniRazpisi === 2 && (
            <>
              <PDFDownloadLink
                document={documentPDF}
                fileName={`Aktualni_razpisi`}
                className="no-underline "
              >
                {({ blob, url, loading, error }) =>
                  loading ? (
                    <span className="ms-1 mb-5 mt-5">Nalaganje...</span>
                  ) : (
                    <Button variant="info" className="mb-2">
                      Izvozi PDF
                    </Button>
                  )
                }
              </PDFDownloadLink>
            </>
          )}
        </Col>
      </Row>
    </div>
  );
}
