import React, { useState } from 'react';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

/* React Bootstrap imports */
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card"

import { fetchGET } from "../apiHelper";
import { izvozProjektov, izvozPartnerjev } from "../listOfApi";

import SpinnerLoading from './SpinnerLoading';
import { CodeChallengeMethodValues } from '@azure/msal-common';

const Reports = () => {
    const [reportButtonDisabled, setReportButtonDisabled] = useState(false);
    const [showSpinnerLoading, setShowSpinnerLoading] = useState(false);
    const [showSpinnerLoadingPartnerji, setShowSpinnerLoadingPartnerji] = useState(false);


    const exportXlsxProjectsAll = () => {
        setShowSpinnerLoading(true);
        setReportButtonDisabled(true);

        fetchGET(
            `${izvozProjektov}`
        ).then((izvozProjektov) => {
            if (izvozProjektov !== 0) {
                //GET API KLIC PODATKE IN EXPORTAJ
                const fileType =
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                const fileExtension = '.xlsx';

                const ws = XLSX.utils.json_to_sheet(izvozProjektov);
                const wb = { Sheets: { projekti: ws }, SheetNames: ['projekti'] };
                const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
                const data = new Blob([excelBuffer], { type: fileType });
                FileSaver.saveAs(data, 'izvoz_projektov' + fileExtension);

                setShowSpinnerLoading(false);
                setReportButtonDisabled(false);

            } else {
            }
        });
    }

    const exportXlsxProjectsPartners = () => {
        setShowSpinnerLoadingPartnerji(true);
        setReportButtonDisabled(true);

        fetchGET(
            `${izvozPartnerjev}`
        ).then((partnerjiIzvoz) => {
            if (partnerjiIzvoz !== 0) {

                //GET API KLIC PODATKE IN EXPORTAJ
                const fileType =
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                const fileExtension = '.xlsx';

                const ws = XLSX.utils.json_to_sheet(partnerjiIzvoz);
                const wb = { Sheets: { partnerjiProjekti: ws }, SheetNames: ['partnerjiProjekti'] };
                const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
                const data = new Blob([excelBuffer], { type: fileType });
                FileSaver.saveAs(data, 'izvoz_partnerjevProjektov' + fileExtension);

                setShowSpinnerLoadingPartnerji(false);
                setReportButtonDisabled(false);

            } else {
            }
        });
    }





    return (
        <Container fluid>
            <Row>
                <Col className='main-header font-weight-bold'>Izpisi</Col>
            </Row>
            <Row>
                <Col className='header-divider'>
                    <hr />
                </Col>
            </Row>
            <Row className='mt-4'>
                <Col>
                    <Card>
                        <Card.Body>
                            <Row className="align-items-center">
                                <Col>
                                    Izpis projektov
                                </Col>
                                <Col>
                                    <Button disabled={reportButtonDisabled} className="float-end" onClick={exportXlsxProjectsAll}>Izvozi excel</Button>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    {showSpinnerLoading && (
                                        <>
                                            <SpinnerLoading />
                                            <p className='text-center'>Poteka pridobivanje izpisa projektov...</p>
                                        </>
                                    )}
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row className='mt-4'>
                <Col>
                    <Card>
                        <Card.Body>
                            <Row className="align-items-center">
                                <Col>
                                    Izpis projektov s partnerji
                                </Col>
                                <Col>
                                    <Button disabled={reportButtonDisabled} className="float-end" onClick={exportXlsxProjectsPartners}>Izvozi excel</Button>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    {showSpinnerLoadingPartnerji && (
                                        <>
                                            <SpinnerLoading />
                                            <p className='text-center'>Poteka pridobivanje izpisa projektov s partnerji...</p>
                                        </>
                                    )}
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}

export default Reports;