import { acquireAPItoken } from "./msalAuthConfig";

async function getTokenForAPI() {
  const bearerToken = await acquireAPItoken();
  return bearerToken;
}

export const fetchGET = async (url) => {
  const bearerToken = await getTokenForAPI();

  const settings = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${bearerToken}`,
    },
  };

  try {
    const response = await fetch(url, settings);
    if (response.status === 200) {
      const getData = await response.json();
      return getData;
    } else {
      return 0;
    }
  } catch (e) {}
};

export const fetchGETPwd = async (url) => {
  const settings = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      PWD: `${process.env.REACT_APP_APIDOMAIN_IMENIK_KEY}`,
    },
  };
  try {
    const response = await fetch(url, settings);
    if (response.status === 200) {
      const getData = await response.json();
      return getData;
    } else {
      return 0;
    }
  } catch (e) {}
};

export const fetchPOST = async (url, data) => {
  const bearerToken = await getTokenForAPI();
  const settings = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${bearerToken}`,
    },
    body: JSON.stringify(data),
  };

  try {
    const response = await fetch(url, settings);
    if (response.status === 200) {
      return 1;
    } else {
      return 0;
    }
  } catch (e) {
    return e;
  }
};

export const fetchPUT = async (url, data) => {
  const bearerToken = await getTokenForAPI();
  const settings = {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${bearerToken}`,
    },
    body: JSON.stringify(data),
  };
  try {
    const response = await fetch(url, settings);
    if (response.status === 200) {
      return 1;
    } else {
      return 0;
    }
  } catch (e) {
    return e;
  }
};

export const fetchDELETE = async (url) => {
  const bearerToken = await getTokenForAPI();
  const settings = {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${bearerToken}`,
    },
  };
  try {
    const response = await fetch(url, settings);
    if (response.status === 200) {
      return 1;
    } else {
      return 0;
    }
  } catch (e) {}
};
