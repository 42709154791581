import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {Link} from 'react-router-dom';

/*Import Context */
import {UserContext} from './UserContext';

/*Import API helpers */
import {fetchGET} from '../apiHelper';
import {podatkiZaRazpis, potDoPrograma, financniViriRazpisa} from '../listOfApi';

/* React-Bootstrap component */
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';


/*import React-icons */
import {AiOutlineInfoCircle} from 'react-icons/ai';
import {BsGrid3X3GapFill} from 'react-icons/bs';

/*Import Components */
import FinancniViri from "./tenderDetails/FinancniViri";
import OsnovniPodatki from "./tenderDetails/OsnovniPodatki";

const TenderDetails = ({role}) => {
    /*Pridobljen parameter iz ID */
    const {id} = useParams();

    const [tenderDataDetails, setTenderDataDetails] = useState({
        razpis_naziv: '',
        obtezitev: 0,
        opis: '',
        povezava: '',
        pomemben: false,
        odprt: false,
        odprt_tekst: '',
        prijava_datum: '',
        prijava_ura: '',
        sifrantARRS: false,
        sifrantCERIF: false,
        sifrantKLASIUS: false,
        program_naziv: '',
        id_financiranje_kategorija: 0,
        financiranje_kategorija: '',
        program: [],
    });
    const [financialResources, setFinancialResources] = useState([]);
    const [invalidRazpisId, setInvalidRazpisId] = useState(false);

    /* Pridobivanje podatkov za razpis in finančne vire razpisa */
    useEffect(() => {
        getRazpis();
        getRazpisData()
    }, []);

    /* Pridobi podatke za razpis */
    const getRazpis = () => {
        fetchGET(`${podatkiZaRazpis}/${id}`).then((razpis) => {
            if (razpis !== 0) {
                setTenderDataDetails({
                    razpis_naziv: razpis.razpis_naziv,
                    povezava: razpis.povezava,
                    pomemben: razpis.pomemben,
                    obtezitev: parseInt(razpis.obtezitev),
                    odprt: razpis.odprt,
                    odprt_tekst: razpis.odprt_tekst,
                    opis: razpis.opis,
                    prijava_datum: razpis.prijava_datum,
                    prijava_ura: razpis.prijava_ura,
                    sifrantARRS: razpis.sifrantARRS,
                    sifrantCERIF: razpis.sifrantCERIF,
                    sifrantKLASIUS: razpis.sifrantKLASIUS,
                    program_naziv: razpis.program_naziv,
                    programID: razpis.programID,
                    financiranje_kategorija: razpis.financiranje_kategorija,
                    id_financiranje_kategorija: razpis.id_financiranje_kategorija
                });

                fetchGET(`${potDoPrograma}/${razpis.programID}`).then((poti) => {
                    setTenderDataDetails((prevState) => {
                        return {...prevState, program: poti};
                    });
                });
            } else {
                setInvalidRazpisId(true);
            }
        });
    }

    /* Pridobi podatke za finančne vire razpisa */
    const getRazpisData = () => {
        fetchGET(`${financniViriRazpisa}/${id}/financiranje`).then((vir) => {
            if (vir !== 0) {
                setFinancialResources(vir);
            } else {
                setFinancialResources([]);
            }
        })
    }

    return (
        <Container fluid>
            {invalidRazpisId && <p>Razpis ne obstaja!</p>}
            {!invalidRazpisId && (
                <>
                    {/* PROJEKT TITLE */}
                    <Row>
                        <Col className='main-header font-weight-bold'>
                            <AiOutlineInfoCircle className='me-2'/>
                            {tenderDataDetails.razpis_naziv}
                        </Col>
                        <Col>
                            <div className='float-end'>
                                <Link className='programs-button-add-text ms-2' to={`/razpisi`}>
                                    <Button variant='primary' className='button-back-razpisi '>
                                        <BsGrid3X3GapFill/> <span>Razpisi</span>
                                    </Button>
                                </Link>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='header-divider'>
                            <hr/>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            {/* TABS */}
                            <Tabs defaultActiveKey='osnovni'>
                                {/* TAB - Osnovni podatki */}
                                <Tab eventKey='osnovni' title='Osnovni podatki'>
                                    <UserContext.Provider value={role}>
                                        <OsnovniPodatki id={id} tenderDataDetails={tenderDataDetails}
                                                        getRazpis={getRazpis} getRazpisData={getRazpisData}/>
                                    </UserContext.Provider>
                                </Tab>
                                {/* TAB - Finančni podatki */}
                                <Tab eventKey='financniVir' title='Finančni viri'>
                                    <UserContext.Provider value={role}>
                                        <FinancniViri id={id} financialResources={financialResources}
                                                      financiranjeKategorija={tenderDataDetails.id_financiranje_kategorija}
                                                      getRazpisData={getRazpisData}
                                        />
                                    </UserContext.Provider>
                                </Tab>

                            </Tabs>
                        </Col>
                    </Row>
                </>
            )}
        </Container>
    );
};

export default TenderDetails;

