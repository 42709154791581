import React, { useState, useEffect } from 'react';
import MaterialTable from 'material-table';

/*Import API helpers */
import { fetchGET } from '../apiHelper';
import { sifrantARRS, sifrantKlasius, sifrantCerif } from '../listOfApi';

/*React-Bootstrap components */
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

/*React icons */
import { BsListOl } from 'react-icons/bs';

const CodeList = () => {
  const [sifrantTitle, setSifrantTitle] = useState('ARIS');
  const [sifrantList, setSifrantList] = useState([]);

  const columns = [
    {
      title: 'Naziv',
      field: 'naziv',
    },
  ];

  useEffect(() => {
    fetchGET(sifrantARRS).then((sifrant) => {
      if (sifrant !== 0) {
        setSifrantList(sifrant);
      } else {
      }
    });
  }, []);

  const handleChangeARRS = () => {
    fetchGET(sifrantARRS).then((sifrant) => {
      if (sifrant !== 0) {
        setSifrantList(sifrant);
        setSifrantTitle('ARIS');
      }
    });
  };

  const handleChangeCerif = () => {
    fetchGET(sifrantCerif).then((sifrant) => {
      if (sifrant !== 0) {
        setSifrantList(sifrant);
        setSifrantTitle('CERIF');
      }
    });
  };

  const handleChangeKlasius = () => {
    fetchGET(sifrantKlasius).then((sifrant) => {
      if (sifrant !== 0) {
        setSifrantList(sifrant);
        setSifrantTitle('KLASIUS');
      }
    });
  };

  return (
    <Container fluid>
      <Row>
        <Col className='main-header font-weight-bold'>
          <BsListOl className='me-2' />
          Šifranti
        </Col>
      </Row>
      <Row>
        <Col className='header-divider'>
          <hr />
        </Col>
      </Row>
      <Row>
        <Col>
          <Button variant='outline-primary border-0' onClick={handleChangeARRS}>
            Šifranti ARIS
          </Button>{' '}
          {'|'}
          <Button
            variant='outline-primary border-0'
            onClick={handleChangeCerif}
          >
            Šifranti Cerif
          </Button>{' '}
          {'|'}
          <Button
            variant='outline-primary border-0'
            onClick={handleChangeKlasius}
          >
            Šifranti Klasius
          </Button>
        </Col>
      </Row>
      <Row className='mt-3'>
        <Col>
          <h4>{sifrantTitle}</h4>
        </Col>
      </Row>
      <Row className='mt-4'>
        <Col>
          <MaterialTable
            title={''}
            columns={columns}
            data={sifrantList}
            localization={{
              toolbar: { searchPlaceholder: 'Iskalnik', exportCSVName: "Izvozi podatke v CSV" },
              body: {
                emptyDataSourceMessage: 'Ni zapisov za prikaz',
              },
            }}
            options={{
              headerStyle: { fontWeight: 'bold' },
              paging: false,
              searchFieldAlignment: 'left',
              exportButton: { csv: true, pdf: false },
              exportFileName: sifrantTitle==="ARIS"? `sifrant_aris_${new Date().toLocaleDateString("sl-SI").replace(/\. /g,"-")}`: sifrantTitle==="CERIF"?`sifrant_cerif_${new Date().toLocaleDateString("sl-SI").replace(/\. /g,"-")}`: `sifrant_klasius_${new Date().toLocaleDateString("sl-SI").replace(/\. /g,"-")}`
            }}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default CodeList;
