import React, { useState } from 'react';

/*React-component */
import ProgramStructureContainerTreeItem from './ProgramStructureContainerTreeItem';

/* React-Bootstrap component */
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';

const ProgramStructureContainerTree = ({ program, changeProgramStatus }) => {
  const [showProgramStructure, setShowProgramStructure] = useState(false);

  const handleProgramStucture = (e) => {
    const id = e.currentTarget.id;
    const idNumber = Object.keys(showProgramStructure);
    const getID = idNumber.filter((idKey) => idKey === id);

    let value;
    for (let key in showProgramStructure) {
      if (showProgramStructure.hasOwnProperty(key)) {
        if (key === getID[0]) {
          value = showProgramStructure[key];
        }
      }
    }

    if (id === getID[0]) {
      if (value === false) {
        setShowProgramStructure((showProgramStructure) => ({
          ...showProgramStructure,
          [id]: true,
        }));
      } else {
        setShowProgramStructure((showProgramStructure) => ({
          ...showProgramStructure,
          [id]: false,
        }));
      }
    } else {
      setShowProgramStructure((showProgramStructure) => ({
        ...showProgramStructure,
        [id]: true,
      }));
    }
  };

  if (Object.keys(program).length === 0) {
    return null;
  } else {
    return (
      <>
        {program.children?.map((k2) => {
          return (
            <Row key={k2.objectID}>
              <Col>
                <Card className='mb-2 programs-margin-left'>
                  <Card.Body className='pb-0 pt-0'>
                    <Row>
                      <Col>
                        <ProgramStructureContainerTreeItem
                          program={k2}
                          programStructureHandle={handleProgramStucture}
                          changeProgramStatus={changeProgramStatus}
                        />
                      </Col>
                    </Row>
                    {showProgramStructure[k2.objectID] && (
                      <>
                        {k2.children?.map((k3) => {
                          return (
                            <Row key={k3.objectID}>
                              <Col>
                                <Card className='mb-2 border-left-card-n3 programs-margin-left'>
                                  <Card.Body className='pb-0 pt-0'>
                                    <ProgramStructureContainerTreeItem
                                      program={k3}
                                      changeProgramStatus={changeProgramStatus}
                                      programStructureHandle={
                                        handleProgramStucture
                                      }
                                    />
                                    {showProgramStructure[k3.objectID] && (
                                      <>
                                        {k3.children?.map((k4) => {
                                          return (
                                            <Row key={k4.objectID}>
                                              <Col>
                                                <Card className='mb-2 border-left-card-n4 programs-margin-left '>
                                                  <Card.Body className='pb-0 pt-0'>
                                                    <ProgramStructureContainerTreeItem
                                                      program={k4}
                                                      nivoK4={'true'}
                                                      changeProgramStatus={changeProgramStatus}
                                                      programStructureHandle={
                                                        handleProgramStucture
                                                      }
                                                    />
                                                  </Card.Body>
                                                </Card>
                                              </Col>
                                            </Row>
                                          );
                                        })}
                                      </>
                                    )}
                                  </Card.Body>
                                </Card>
                              </Col>
                            </Row>
                          );
                        })}
                      </>
                    )}
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          );
        })}
      </>
    );
  }
};

export default ProgramStructureContainerTree;
