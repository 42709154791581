import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import SpinnerLoading from "../SpinnerLoading";
import { format } from "date-fns";

function CurrentFocusedTenders({
  izpostavljeniAktualniRazpis,
  izpostavljeniError,
  izpostavljeniLoading,
}) {
  if (izpostavljeniLoading === true) {
    return <SpinnerLoading />;
  }

  return (
    <div>
      <Row>
        <Col>
          {izpostavljeniError && (
            <h2 className="text-danger">
              Izpostavljenih razpisov ni bilo možno prikazati!
            </h2>
          )}
        </Col>
      </Row>
      <Row>
        <Col>
          <Row className="row-header-izpostavljeni-razpisi d-flex align-items-center mb-3 ">
            <Col lg={10} className="row-header-izpostavljeni-razpisi-title">
              IZPOSTAVLJENI RAZPISI
            </Col>
            <Col lg={2} className="text-end">
              Rok prijave
            </Col>
          </Row>
          {izpostavljeniAktualniRazpis.map((razpis) => {
            return (
              <div key={razpis.razpisID}>
                <Row className="row-izpostavljeni-razpisi-text">
                  <Col lg={4}>
                    {razpis.povezava !== "" ? (
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={razpis.povezava}
                      >
                        {razpis.razpis_naziv}
                      </a>
                    ) : (
                      razpis.razpis_naziv
                    )}
                  </Col>

                  <Col lg={7}>{razpis.opis}</Col>
                  <Col lg={1} className="text-end">
                    <Row className="mb-1">
                      <Col>
                        {razpis.novo === true && (
                          <span className="ms-2 p-1 rounded border-success bg-success text-light ">
                            Novo
                          </span>
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        {razpis.prijava_datum === "2100-01-01" &&
                          "Odprt razpis"}
                        {razpis.prijava_datum !== null &&
                          razpis.prijava_datum !== "2100-01-01" &&
                          razpis.prijava_datum !== "" &&
                          format(new Date(razpis.prijava_datum), "d. M. yyyy")}
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        {razpis.prijava_ura !== null &&
                          `do: ${razpis.prijava_ura.slice(0, -3)}`}
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <hr />
                  </Col>
                </Row>
              </div>
            );
          })}
        </Col>
      </Row>
    </div>
  );
}

export default CurrentFocusedTenders;
