import React from "react";

import {
  Route as AuthorizedRoute,
  Routes,
  BrowserRouter,
} from "react-router-dom";

/*Component Import*/
import Programs from "./Programs";
import AddProgram from "./AddProgram";
import ProgramStructure from "./ProgramStructure";
import Tenders from "./Tenders";
import TenderDetails from "./TenderDetails";
import AddTender from "./AddTender";
import EditProgram from "./EditProgram";
import UserRolesAuthorisation from "./UserRolesAuthorisation";
import AddUserRolesAuthorisation from "./AddUserRolesAuthorisation";
import AddUserFakultetaRolesAuthorisation from "./AddUserFakultetaRolesAuthorisation";
import EditUserRolesAuthorisation from "./EditUserRolesAuthorisation";
import EditUserFakultetaRolesAuthorisation from "./EditUserFakultetaRolesAuthorisation";
import Revision from "./Revision";
import CodeList from "./CodeList";
import ProjectPartners from "./ProjectPartners";
import AddProjectPartner from "./AddProjectPartner";
import ProjectPartnerDetails from "./ProjectPartnerDetails";
import AddOrgUnit from "./AddOrgUnit";
import EditOrgUnit from "./EditOrgUnit";
import AddProject from "./AddProject";
import ProjectDetails from "./ProjectDetails";
import Projects from "./Projects";
import Reports from "./Reports";
import CurrentTenders from "./CurrentTenders";

/*React-bootstrap components */
import Col from "react-bootstrap/Col";

const AuthorisationComponent = ({ role }) => {
  let routes;
  let vloga = "uporabnik";

  if (role.admin === true) {
    vloga = "superAdmin";
  }

  switch (vloga) {
    case "uporabnik":
      routes = (
        <Routes>
          <AuthorizedRoute
            exact
            path="/Programi"
            element={<Programs role={role} />}
          />
          <AuthorizedRoute
            exact
            path="/Programi/DodajProgram/:id?/"
            element={<AddProgram role={role} />}
          />
          <AuthorizedRoute
            exact
            path="/Programi/UrediProgram/:id"
            element={<EditProgram role={role} />}
          />
          <AuthorizedRoute
            exact
            path="/Programi/Struktura/:id"
            element={<ProgramStructure role={role} />}
          />
          <AuthorizedRoute
            exact
            path="/Razpisi"
            element={<Tenders role={role} />}
          />
          <AuthorizedRoute
            exact
            path="/RazpisPodrobnosti/:id"
            element={<TenderDetails role={role} />}
          />
          <AuthorizedRoute
            exact
            path="/Razpisi/DodajRazpis/:id?"
            element={<AddTender role={role} />}
          />

          <AuthorizedRoute
            exact
            path="/ProjektniPartnerji"
            element={<ProjectPartners role={role} />}
          />
          <AuthorizedRoute
            exact
            path="/ProjektniPartnerji/Dodaj"
            element={<AddProjectPartner role={role} />}
          />
          <AuthorizedRoute
            exact
            path="/ProjektniPartnerji/Podrobnosti/:id"
            element={<ProjectPartnerDetails role={role} />}
          />
          <AuthorizedRoute
            exact
            path="/ProjektniPartnerji/Enota/Dodaj/:id"
            element={<AddOrgUnit role={role} />}
          />
          <AuthorizedRoute
            exact
            path="/ProjektniPartnerji/Enota/Uredi/:id"
            element={<EditOrgUnit role={role} />}
          />
          <AuthorizedRoute
            exact
            path="/Projekti/DodajProjekt"
            element={<AddProject role={role} />}
          />
          <AuthorizedRoute
            exact
            path="/Projekti/Podrobnosti/:id"
            element={<ProjectDetails role={role} />}
          />
          <AuthorizedRoute
            exact
            path="/Projekti"
            element={<Projects role={role} />}
          />
          <AuthorizedRoute
            exact
            path="/Sifranti"
            element={<CodeList role={role} />}
          />
          <AuthorizedRoute
            exact
            path="/AktualniRazpisi"
            element={<CurrentTenders role={role} />}
          />
        </Routes>
      );
      break;
    case "superAdmin":
      routes = (
        <Routes>
          <AuthorizedRoute
            exact
            path="/Avtorizacije"
            element={<UserRolesAuthorisation />}
          />
          <AuthorizedRoute
            exact
            path="/Avtorizacije/Dodaj"
            element={<AddUserRolesAuthorisation />}
          />
          <AuthorizedRoute
            exact
            path="/Avtorizacije/DodajFakultetnega"
            element={<AddUserFakultetaRolesAuthorisation />}
          />
          <AuthorizedRoute
            exact
            path="/Avtorizacije/uredi/:id"
            element={<EditUserRolesAuthorisation />}
          />
          <AuthorizedRoute
            exact
            path="/Avtorizacije/uredi/fakultetni/:id"
            element={<EditUserFakultetaRolesAuthorisation />}
          />
          <AuthorizedRoute
            exact
            path="/Zgodovina-sprememb"
            element={<Revision />}
          />
          <AuthorizedRoute exact path="/Sifranti" element={<CodeList />} />
          <AuthorizedRoute
            exact
            path="/Programi"
            element={<Programs role={role} />}
          />
          <AuthorizedRoute
            exact
            path="/Programi/DodajProgram/:id?/"
            element={<AddProgram role={role} />}
          />
          <AuthorizedRoute
            exact
            path="/Programi/UrediProgram/:id"
            element={<EditProgram role={role} />}
          />
          <AuthorizedRoute
            exact
            path="/Programi/Struktura/:id"
            element={<ProgramStructure role={role} />}
          />
          <AuthorizedRoute
            exact
            path="/Razpisi"
            element={<Tenders role={role} />}
          />
          <AuthorizedRoute
            exact
            path="/RazpisPodrobnosti/:id"
            element={<TenderDetails role={role} />}
          />
          <AuthorizedRoute
            exact
            path="/Razpisi/DodajRazpis/:id?"
            element={<AddTender role={role} />}
          />
          <AuthorizedRoute
            exact
            path="/ProjektniPartnerji"
            element={<ProjectPartners role={role} />}
          />
          <AuthorizedRoute
            exact
            path="/ProjektniPartnerji/Dodaj"
            element={<AddProjectPartner role={role} />}
          />
          <AuthorizedRoute
            exact
            path="/ProjektniPartnerji/Podrobnosti/:id"
            element={<ProjectPartnerDetails role={role} />}
          />
          <AuthorizedRoute
            exact
            path="/ProjektniPartnerji/Enota/Dodaj/:id"
            element={<AddOrgUnit role={role} />}
          />
          <AuthorizedRoute
            exact
            path="/ProjektniPartnerji/Enota/Uredi/:id"
            element={<EditOrgUnit role={role} />}
          />
          <AuthorizedRoute
            exact
            path="/Projekti/DodajProjekt"
            element={<AddProject role={role} />}
          />
          <AuthorizedRoute
            exact
            path="/Projekti/Podrobnosti/:id"
            element={<ProjectDetails role={role} />}
          />
          <AuthorizedRoute
            exact
            path="/Projekti"
            element={<Projects role={role} />}
          />
          <AuthorizedRoute exact path="/izpisi" element={<Reports />} />
          <AuthorizedRoute
            exact
            path="/AktualniRazpisi"
            element={<CurrentTenders role={role} />}
          />
        </Routes>
      );
      break;
    default:
      break;
  }
  return <Col>{routes}</Col>;
};

export default AuthorisationComponent;
