import React, { useContext, useEffect, useState } from "react";

/* Import Context */
import { UserContext } from "../UserContext";

/*Import React Bootstrap */
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Select from "react-select";
import SelectSearch from "react-select-search";

/*Import React-Icons */
import { AiOutlineEdit } from "react-icons/ai";
import { BiTrash } from "react-icons/bi";
import { fetchGET } from "../../apiHelper";
import { sifrantPartnerjev } from "../../listOfApi";
import Modal from "react-bootstrap/Modal";

const Partnerji = ({
  projectSkrbnikClanica,
  showAddPartner,
  dodajanjePartnerjaError,
  formSelectValidation,
  sifrantVloge,
  partnerDataPost,
  dodajanjeKontaktnihError,
  partnersData,
  showEditPartnerKontakt,
  setStatePartnerDataPost,
  setStateShowAddPartner,
  setStateEditKontaktData,
  setStateProjectPartnerId,
  setStateShowEditPartnerKontakt,
  handleSubmitFormPartnerKontakti,
  handleSubmitFormAddPartner,
  handleDelete,
}) => {
  const role = useContext(UserContext);

  const [partnersSifrant, setPartnersSifrant] = useState([]);
  const [showPartnerDetails, setShowPartnerDetails] = useState(false);
  const [projectPartnerName, setProjectPartnerName] = useState("");
  const [showModal, setShowModal] = useState(false);

  // PRIDOBIVANJE SEZNAMA PARTNERJEV
  useEffect(() => {
    fetchGET(sifrantPartnerjev).then((partners) => {
      if (partners !== 0) {
          let partnerji = partners.map((partner) => {
          return { label: partner.naziv, value: partner.id };
        });
        setPartnersSifrant(partnerji);
      } else {
      }
    });
  }, []);

  /* PARTNERJI - Handle Strukturo Project partners Details */
  const handleStructure = (e) => {
    const id = e.currentTarget.id;
    const idNumber = Object.keys(showPartnerDetails);
    const getID = idNumber.filter((idKey) => idKey === id);

    let value;
    for (let key in showPartnerDetails) {
      if (showPartnerDetails.hasOwnProperty(key)) {
        if (key === getID[0]) {
          value = showPartnerDetails[key];
        }
      }
    }

    if (id === getID[0]) {
      if (value === false) {
        setShowPartnerDetails((showPartnerDetails) => ({
          ...showPartnerDetails,
          [id]: true,
        }));
      } else {
        setShowPartnerDetails((showPartnerDetails) => ({
          ...showPartnerDetails,
          [id]: false,
        }));
      }
    } else {
      setShowPartnerDetails((showPartnerDetails) => ({
        ...showPartnerDetails,
        [id]: true,
      }));
    }
  };

  return (
    <>
      <Modal
        show={showModal}
        onHide={() => {
          setShowModal(false);
        }}
      >
        <Modal.Body>
          Želite izbrisati projekt: <strong>{projectPartnerName}</strong>?
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="btn btn-danger"
            variant="secondary"
            onClick={() => {
              handleDelete();
              setShowModal(false);
            }}
          >
            Izbriši
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              setShowModal(false);
            }}
          >
            Ne
          </Button>
        </Modal.Footer>
      </Modal>
      <Card className="mt-2">
        <Card.Body>
          <Row className="align-items-center">
            <Col>
              <div className="float-end">
                {((role.skrbnik === true &&
                  role.clanicaID === projectSkrbnikClanica) ||
                  (role.skrbnik === false && role.projekti === 2)) && (
                    <Button
                      className="org-button-details-text"
                      id="1"
                      onClick={() => {
                        setStateShowAddPartner();
                        setStatePartnerDataPost();
                      }}
                    >
                      <AiOutlineEdit />
                      <span>Dodaj partnerja na projekt</span>
                    </Button>
                  )}
              </div>
            </Col>
          </Row>
          <Row className="project-partners-details-card">
            <Col>
              <div className="p-4">
                {showAddPartner && (
                  <Row>
                    <Col className="mb-3">
                      <Form>
                        <Card className="shadow-sm rounded">
                          <Card.Body>
                            <Row>
                              {dodajanjePartnerjaError && (
                                <Col className="mt-3 mb-3">
                                  <h5 className="text-danger">
                                    Dodajanje projektnega partnerja na projekt
                                    ni uspelo!
                                  </h5>
                                </Col>
                              )}
                            </Row>
                            <Row>
                              {formSelectValidation && (
                                <Col className="mt-3 mb-3">
                                  <h4 className="text-danger">
                                    Niste izbrali projektnega partnerja ali
                                    vloge partnerja!
                                  </h4>
                                </Col>
                              )}
                            </Row>
                            <Row>
                              <Col lg={3}>
                                <Select
                                  options={partnersSifrant}
                                  placeholder="Projektni partner"
                                  isSearchable
                                  onChange={(value) => {
                                    setStatePartnerDataPost(
                                      value.value,
                                      null,
                                      null,
                                      "partnerId"
                                    );
                                  }}
                                  menuPortalTarget={document.body}
                                  styles={{
                                    menuPortal: (base) => ({
                                      ...base,
                                      zIndex: 9999,
                                    }),
                                  }}
                                />
                              </Col>
                              <Col lg={3}>
                                <SelectSearch
                                  options={sifrantVloge}
                                  onChange={(value) => {
                                    setStatePartnerDataPost(
                                      null,
                                      value,
                                      null,
                                      "vlogaId"
                                    );
                                  }}
                                  search
                                  name="selectVloga"
                                  placeholder="Vloga na projektu"
                                />
                              </Col>
                              <Col lg={3}>
                                <Form.Control
                                  name="kontakt"
                                  placeholder="Kontaktni podatki"
                                  as="textarea"
                                  rows={1}
                                  value={partnerDataPost.kontaktnaOseba}
                                  onChange={(e) => {
                                    setStatePartnerDataPost(
                                      null,
                                      null,
                                      e.target.value,
                                      "kontaktnaOseba"
                                    );
                                  }}
                                />
                              </Col>
                              <Col lg={3}>
                                {" "}
                                <div className=" ps-4 pe-4 float-end">
                                  <Button
                                    variant="primary"
                                    type="submit"
                                    className="me-3"
                                    onClick={handleSubmitFormAddPartner}
                                  >
                                    Dodaj
                                  </Button>
                                  <Button
                                    variant="danger"
                                    onClick={setStateShowAddPartner}
                                  >
                                    Prekliči
                                  </Button>
                                </div>
                              </Col>
                            </Row>
                          </Card.Body>
                        </Card>
                      </Form>
                    </Col>
                  </Row>
                )}

                <Row>
                  {dodajanjeKontaktnihError && (
                    <Col className="mt-3 mb-3">
                      <h5 className="text-danger">
                        Dodajanje/urejanje kontaktnih podatkov ni uspelo!
                      </h5>
                    </Col>
                  )}
                </Row>
                {partnersData?.map((partner, index) => {
                  return (
                    <Row key={index}>
                      <Col className="mb-3">
                        <Card>
                          <Card.Body className="p-3">
                            <Row className="align-items-center">
                              <Col
                                lg={3}
                                id={partner.id}
                                onClick={handleStructure}
                                className="project-partner-details-structure-naslov__hover"
                              >
                                {partner.nazivDolgi}
                              </Col>

                              <Col lg={3}>
                                {!showEditPartnerKontakt[partner.id] && (
                                  <>
                                    <span>Vloga partnerja: </span>{" "}
                                    {partner.vloga}
                                  </>
                                )}

                                {showEditPartnerKontakt[partner.id] && (
                                  <SelectSearch
                                    options={sifrantVloge}
                                    onChange={(value) => {
                                      setStateEditKontaktData(
                                        value,
                                        null,
                                        null,
                                        "vlogaId"
                                      );
                                    }}
                                    value={partner.vlogaID}
                                    search
                                    name="selectVloga"
                                    placeholder="Vloga na projektu"
                                  />
                                )}
                              </Col>


                              {!showEditPartnerKontakt[partner.id] && (
                                <Col lg={4}>
                                  <span>Kontaktni podatki: </span>
                                  {partner.kontaktnaOseba}
                                </Col>
                              )}


                              {showEditPartnerKontakt[partner.id] && (
                                <Col lg={3}>
                                  <Form.Control
                                    name="kontakt"
                                    placeholder="Kontaktni podatki"
                                    type="text"
                                    defaultValue={partner.kontaktnaOseba}
                                    onChange={(e) => {
                                      setStateEditKontaktData(
                                        null,
                                        e.target.value,
                                        null,
                                        "kontaktnaOseba"
                                      );
                                    }}
                                  />
                                </Col>
                              )}


                                {role.projekti === 2 && (
                                  <>
                                    {showEditPartnerKontakt[partner.id] && (
                                      <Col lg={3}>
                                        <Button
                                          variant="primary"
                                          className="mr-2"
                                          onClick={
                                            handleSubmitFormPartnerKontakti
                                          }
                                        >
                                          Potrdi
                                        </Button>
                                        <Button
                                          variant="outline-primary ms-3"
                                          onClick={() => {
                                            setStateShowEditPartnerKontakt(
                                              null,
                                              "preklici"
                                            );
                                          }}
                                        >
                                          Prekliči
                                        </Button>
                                      </Col>
                                    )}
                                    {!showEditPartnerKontakt[partner.id] && (
                                      <Col lg={2}>
                                        {((role.skrbnik === true &&
                                          role.clanicaID ===
                                          projectSkrbnikClanica) ||
                                          (role.skrbnik === false &&
                                            role.projekti === 2)) && (
                                            <>
                                              <Button
                                                className="avtorizacije-button-trash float-end"
                                                onClick={() => {
                                                  setShowModal(true);
                                                  setStateProjectPartnerId(
                                                    partner.id
                                                  );
                                                  setProjectPartnerName(
                                                    partner.nazivSlo
                                                  );
                                                }}
                                              >
                                                <BiTrash className="text-danger" />
                                              </Button>
                                              <Button
                                                className="org-button-details-text float-end"
                                                id={partner.id}
                                                onClick={(e) => {
                                                  setStateEditKontaktData(
                                                    partner.vlogaID,
                                                    partner.kontaktnaOseba,
                                                    e.currentTarget.id
                                                  );
                                                  setStateShowEditPartnerKontakt(
                                                    e.currentTarget.id
                                                  );
                                                  setStateProjectPartnerId(
                                                    partner.id
                                                  );
                                                }}
                                              >
                                                <AiOutlineEdit />
                                              </Button>
                                            </>
                                          )}
                                      </Col>
                                    )}
                                  </>
                                )}
                            </Row>
                            {showPartnerDetails[partner.id] && (
                              <Row className="project-partners-details-card mt-4">
                                <Col>
                                  <Card className="razpis-card">
                                    <Card.Body>
                                      <Row>
                                        <Col>
                                          <Row>
                                            <Col className="ms-3 razpisDetails-header font-weight-bold">
                                              Osnovni podatki
                                            </Col>
                                          </Row>
                                          <div className="p-4">
                                            <Row>
                                              <Col className="mb-3">
                                                <span>Uradni naziv: </span>
                                                {partner.nazivSlo}
                                              </Col>
                                            </Row>

                                            <Row>
                                              <Col className="mb-3">
                                                <span>
                                                  Uradni naziv - angleški:{" "}
                                                </span>
                                                {partner.nazivEng}
                                              </Col>
                                            </Row>
                                            <Row className="mb-3">
                                              <Col>
                                                <span>Davčna številka:</span>{" "}
                                                {partner.davcna}
                                              </Col>
                                            </Row>
                                          </div>
                                        </Col>
                                        <Col>
                                          <Row>
                                            <Col className="ms-3 razpisDetails-header font-weight-bold">
                                              Naslov
                                            </Col>
                                          </Row>
                                          <div className="p-4">
                                            <Row>
                                              <Col className="mb-3">
                                                {partner.naslov}
                                              </Col>
                                            </Row>
                                            <Row>
                                              <Col className="mb-3">
                                                {partner.postnaStevilka}{" "}
                                                {partner.posta}
                                              </Col>
                                            </Row>
                                            <Row>
                                              <Col className="mb-3">
                                                {partner.drzava}
                                              </Col>
                                            </Row>
                                          </div>
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col>
                                          <Row>
                                            <Col className="ms-3 razpisDetails-header font-weight-bold">
                                              Dodatno
                                            </Col>
                                          </Row>
                                          <div className="p-4">
                                            <Row>
                                              <Col className="mb-3">
                                                <span>Spletna stran:</span>{" "}
                                                {partner.spletnaStran}
                                              </Col>
                                            </Row>
                                            <Row>
                                              <Col className="mb-3">
                                                <span>Opomba:</span>{" "}
                                                {partner.opomba}
                                              </Col>
                                            </Row>
                                          </div>
                                        </Col>
                                      </Row>
                                    </Card.Body>
                                  </Card>
                                </Col>
                              </Row>
                            )}
                          </Card.Body>
                        </Card>
                      </Col>
                    </Row>
                  );
                })}
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

export default Partnerji;
