import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import MaterialTable from "material-table";
import { format } from "date-fns";

/*React-Bootstrap components */
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

/*Import Material-icons */
import Search from "@material-ui/icons/Search";

const GlobalUserTabProjects = ({
  role,
  showRejectedProjects,
  globalniProjekti,
  globalniProjektiVsi,
  setDataForDeleteModal,
}) => {
  const history = useNavigate();
  const [showFilters, setShowFilters] = useState(false);
  const [date, setDate] = useState(null);

  return (
    <Row className="mt-4">
      <Col>
        <MaterialTable
          icons={{
            Filter: () => <Search style={{ fontSize: "15px" }} />,
          }}
          title={""}
          columns={[
            {
              title: "Skrbnik",
              field: "clanicaSkrbnik",
              width: null,
              cellStyle: { width: 100 },
              lookup: {
                EPF: "EPF",
                FE: "FE",
                FERI: "FERI",
                FF: "FF",
                FGPA: "FGPA",
                FKBV: "FKBV",
                FKKT: "FKKT",
                FL: "FL",
                FNM: "FNM",
                FOV: "FOV",
                FS: "FS",
                FT: "FT",
                FVV: "FVV",
                FZV: "FZV",
                MF: "MF",
                PEF: "PEF",
                PF: "PF",
                UKM: "UKM",
                REKT: "REKT",
                TTO: "TTO",
              },
            },
            {
              title: "Program",
              field: "programParent",
            },
            {
              title: "Podprogram",
              field: "program",
            },
            {
              title: "Naslov projekta",
              field: "naziv",
            },
            {
              title: "Vloga UM v projektu",
              field: "uMvloga",
              width: null,
              cellStyle: { width: 100 },
              lookup: {
                Koordinator: "Koordinator",
                Partner: "Partner",
                "Pridružen partner": "Pridružen partner",
              },
            },
            {
              title: "Status",
              field: "status",
              width: null,
              cellStyle: { width: 100 },
              lookup: {
                Osnutek: "Osnutek",
                Prijavljen: "Prijavljen",
                "V izvajanju": "V izvajanju",
                Zaključen: "Zaključen",
                Zavrnjen: "Zavrnjen",
              },
            },
            {
              title: "Začetek projekta",
              field: "zacetek",
              type: "date",
              width: null,
              cellStyle: { width: 100 },
              render: (rowData) => {
                const date = new Date(rowData.zacetek);
                const result = format(date, "d. M. yyyy");
                return result;
              },
              customFilterAndSearch: (value, rowData) => {
                const datumVTabeli = new Date(rowData.zacetek).setHours(
                  0,
                  0,
                  0,
                  0
                );
                const izbranDatum = new Date(value).setHours(0, 0, 0, 0);
                return izbranDatum >= datumVTabeli;
              },
            },
            {
              title: "Konec projekta",
              field: "konec",
              type: "date",
              width: null,
              cellStyle: { width: 100 },
              render: (rowData) => {
                const date = new Date(rowData.konec);
                const result = format(date, "d. M. yyyy");
                return result;
              },
              customFilterAndSearch: (value, rowData) => {
                const datumVTabeli = new Date(rowData.konec).setHours(
                  0,
                  0,
                  0,
                  0
                );
                const izbranDatum = new Date(value).setHours(0, 0, 0, 0);
                return datumVTabeli <= izbranDatum;
              },
            },
            {
              title: "Datum vnosa",
              field: "timestamp",
              type: "datetime",
              render: (rowData) => {
                const date = new Date(rowData.timestamp);
                const result = format(date, "d. M. yyyy");
                return result;
              },
              export: true,
            },
          ]}
          data={!showRejectedProjects ? globalniProjekti : globalniProjektiVsi}
          localization={{
            toolbar: {
              searchPlaceholder: "Iskalnik",
              exportCSVName: "Izvozi podatke v CSV",
            },
            header: { actions: "Akcije" },
            body: {
              emptyDataSourceMessage: "Ni zapisov za prikaz",
            },
            pagination: {
              labelRowsSelect: "Vrstic",
              firstAriaLabel: "Prva stran",
              firstTooltip: "Prva stran",
              previousAriaLabel: "Prejšnja stran",
              previousTooltip: "Prejšnja stran",
              nextAriaLabel: "Naslednja stran",
              nextTooltip: "Naslednja stran",
              lastAriaLabel: "Zadnja stran",
              lastTooltip: "Zadnja stran",
              labelDisplayedRows: "{from}-{to} od {count}",
            },
          }}
          options={{
            paging: true,
            pageSize: 50,
            pageSizeOptions: [50, 100, 500],
            searchFieldAlignment: "left",
            filtering: showFilters,
            actionsColumnIndex: -1,
            headerStyle: { fontWeight: "bold" },
            exportButton: { csv: true, pdf: false },
            exportFileName: `projekti_${new Date()
              .toLocaleDateString("sl-SI")
              .replace(/\. /g, "-")}`,
          }}
          onRowClick={(event, rowData) => {
            history(`/Projekti/Podrobnosti/${rowData.id}`);
          }}
          actions={[
            {
              icon: "filter_alt",
              tooltip: "Omogoči filter",
              isFreeAction: true,
              onClick: (event) => {
                setShowFilters(!showFilters);
              },
            },
            (rowData) => ({
              icon: "article",
              tooltip: "Podrobnosti",
              onClick: (event, rowData) => {
                history(`/Projekti/Podrobnosti/${rowData.id}`);
              },
            }),

            (rowData) => ({
              hidden: role.projekti !== 2,
              icon: "delete",
              tooltip: "Odstrani",
              iconProps: { color: "error" },
              onClick: (event, rowData) => {
                setDataForDeleteModal(rowData.id, rowData.naziv);
              },
            }),
          ]}
        />
      </Col>
    </Row>
  );
};

export default GlobalUserTabProjects;
