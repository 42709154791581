/*Seznam api klicev*/

/*----------------------------- |   IMENIK  | ------------------------------------*/
export const imenikSifrant = `${process.env.REACT_APP_APIDOMAIN_IMENIK}imenik/seznam`;

export const izbranaOsebaPodrobnosti = `${process.env.REACT_APP_APIDOMAIN_IMENIK}imenik/oseba`;

/* --------------------------|       PROJEKTI      |-------------------------------*/
/*GET --> Članica je na projektu */
export const jeClanicaNaProjektu = `${process.env.REACT_APP_APIDOMAIN}projekti`

/* GET --> Vloga na projektu */
export const vlogaProjekta = `${process.env.REACT_APP_APIDOMAIN}sifrant/vloga`;

/* GET --> Seznam statusov na projektu */
export const statusProjekta = `${process.env.REACT_APP_APIDOMAIN}sifrant/status`;

/* GET --> Seznam projektov brez zavrnjenih  */
export const seznamProjektovGlobalni = `${process.env.REACT_APP_APIDOMAIN}projekti/seznamProjektovGlobalni`;

/* GET --> Seznam vse projektov  vsi projekti vključno z zavrnjenimi */
export const seznamProjektovGlobalniVsi = `${process.env.REACT_APP_APIDOMAIN}projekti/seznamProjektovGlobalniVsi`;

/* GET --> Seznam vseh projektov (pri pogledu fakultetnega skrbnika - vidi tiste kjer je njegova članica skrbnik in partnert */
export const seznamProjektovFakultetni = `${process.env.REACT_APP_APIDOMAIN}projekti/seznamProjektovFakultetni`;

/* GET --> Seznam vseh projektov (pri pogledu fakultetnega skrbnika, razen tistih kjer je članica vključno z zavrnjenimi svoje članice */
export const seznamProjektovFakultetniVsi = `${process.env.REACT_APP_APIDOMAIN}projekti/seznamProjektovFakultetniVsi`;

/* GET --> Seznam ostalih projektov ko članica ni skrbnik ali partner */
export const seznamOstalihProjektovFakultetni = `${process.env.REACT_APP_APIDOMAIN}projekti/seznamOstalihProjektovFakultetni`;

/* GET --> Seznam ostalih projektov ko članica ni skrbnik ali partner - tudi zavrnjeni */
export const seznamOstalihProjektovFakultetniVsi = `${process.env.REACT_APP_APIDOMAIN}projekti/seznamOstalihProjektovFakultetniVsi`;


/* GET --> Seznam vseh kontaktnih partnerjev na projektu */
export const seznamKontaktnihOsebNaProjektu = `${process.env.REACT_APP_APIDOMAIN}projekti`;

/* GET --> Podatki - podrobnosti za projekt */
export const podrobnostiPodatkiZaProjekt = `${process.env.REACT_APP_APIDOMAIN}projekti`;

/* GET --> Vsi osnovni podatki za projekt */
export const osnovniPodatkiZaProjekt = `${process.env.REACT_APP_APIDOMAIN}projekti`;

/*GET --> Seznam vseh sodelujočih članic */
export const seznamSodelujocihClanicUM = `${process.env.REACT_APP_APIDOMAIN}projekti`;

/*GET --> Podatki za nacrtovane vrednosti projekta */
export const financniPodatkiZaNacrtovano = `${process.env.REACT_APP_APIDOMAIN}projekti`;

/*GET --> Podatki za realizirane vrednosti projekta */
export const financniPodatkiZaRealizirano = `${process.env.REACT_APP_APIDOMAIN}projekti`;

/*GET --> Financni podatki za sodelujoče članice - NAČRTOVANE VREDNOSTI */
export const financniNacrtovaniClanice = `${process.env.REACT_APP_APIDOMAIN}projekti`;

/*GET --> Financni podatki za sodelujoče članice - REALIZIRANE VREDNOSTI */
export const financniRealiziraneClanice = `${process.env.REACT_APP_APIDOMAIN}projekti`;

/*GET --> Seštevek Financni podatkov sodelujoče članice - NAČRTOVANE VREDNOSTI */
export const financniNacrtovaniSestevekClanic = `${process.env.REACT_APP_APIDOMAIN}projekti`;

/*GET --> Seštevek Financni podatkov sodelujoče članice - REALIZIRANE VREDNOSTI */
export const financniRealiziraniSestevekClanic = `${process.env.REACT_APP_APIDOMAIN}projekti`;

/*GET --> pridobivanje podatkov za STROŠKOVNA MESTA FINANČNIH PODATKOV */
export const seznamClanicSStroskovnimiMesti = `${process.env.REACT_APP_APIDOMAIN}projekti`;

/*GET --> pridobivanje podatkov za STROŠKOVNA MESTA FINANČNIH PODATKOV */
export const seznamNeizpolnjenihPolijZaPosamezenStatuts = `${process.env.REACT_APP_APIDOMAIN}projekti`;

/*GET --> Izvoz projektov */
export const izvozProjektov = `${process.env.REACT_APP_APIDOMAIN}projekti/izvoz`;

/*GET --> Izvoz partnerjev s projekti */
export const izvozPartnerjev = `${process.env.REACT_APP_APIDOMAIN}projekti/izvozPartnerji`;

/* POST --> Vnos osnovnih podatkov za projekt oz. kreiranje projekta */
export const vnosNovegaProjekta = `${process.env.REACT_APP_APIDOMAIN}projekti`;

/* POST --> Vnos podatkov kontakne osebe UM na projekt */
export const vnosKontaktneOsebeNaProjekt = `${process.env.REACT_APP_APIDOMAIN}projekti`;

/*POST --> Vnos sodelujoče člancice na projekt */
export const vnosSodelujoceClanice = `${process.env.REACT_APP_APIDOMAIN}projekti`;

/*POST --> Vnos podatkov za STROŠKOVNO MESTO ČLANICE ZA FINANČNE PODATKE */
export const vnosStroskovnegaMestaClanice = `${process.env.REACT_APP_APIDOMAIN}projekti`;

/* PUT --> Urejanje osnovnih podatkov za projekt */
export const osnovniPodatkiZaProjektUrejanje = `${process.env.REACT_APP_APIDOMAIN}projekti`;

/* PUT --> Urejanje/dodajanje dodatnih podatkov za projekt */
export const dodatniPodatkiZaProjektUrejanje = `${process.env.REACT_APP_APIDOMAIN}projekti`;

/* PUT --> Urejanje/dodajanje datuma od do pri kontaktnih podatkih za kontaktno osebo */
export const urejanjeKontaktnihPodatkovzaKontaktnoOsebo = `${process.env.REACT_APP_APIDOMAIN}projekti`;

/* PUT --> Urejanje sodelujoce clanice UM*/
export const urejanjeSodelujoceClanice = `${process.env.REACT_APP_APIDOMAIN}projekti`;

/*PUT --> Urejanje/dodajanje financih podatkov nacrtovanih vrednosti */
export const urejanjeDodajanjePodatkiZaNacrtovano = `${process.env.REACT_APP_APIDOMAIN}projekti`;

/*PUT --> Urejanje/dodajanje financnih podatkov za realizirane vrednosti */
export const urejanjeDodajanjePodatkiZaRealizirano = `${process.env.REACT_APP_APIDOMAIN}projekti`;

/*PUT --> Urejanje/dodajanje načrtovanih financnih podatkov za sodelujoče članice */
export const urejanjefinancniNacrtovaniClanice = `${process.env.REACT_APP_APIDOMAIN}projekti`;

/*PUT --> Urejanje/dodajanje realiziranih financnih podatkov za sodelujoče članice */
export const urejanjefinancniRealiziraneClanice = `${process.env.REACT_APP_APIDOMAIN}projekti`;

/*PUT --> Urejanje stroškovnih mest za članice */
export const urejanjeStroskovnihMestClanice = `${process.env.REACT_APP_APIDOMAIN}projekti`;

/*PUT --> Urejanje statusa za projekt */
export const urejanjeStatusaProjekta = `${process.env.REACT_APP_APIDOMAIN}projekti`;

/* DELETE -->  Brisanje projekta */
export const brisanjeProjekta = `${process.env.REACT_APP_APIDOMAIN}projekti`;

/* DELETE -->  Odstranjevanje kontaktne osebe iz projekta */
export const odstranjevanjeKontaktneOsebeIzProjekta = `${process.env.REACT_APP_APIDOMAIN}projekti`;

/* DELETE -->  Odstranjevanje sodelujoče članice UM iz projekta */
export const odstranjevanjeSodelujoceClaniceIzProjekta = `${process.env.REACT_APP_APIDOMAIN}projekti`;

/*DELETE --> Brisanje stroškovnega mesta za članice */
export const odstranjevanjeStroskovnegaMestaClanice = `${process.env.REACT_APP_APIDOMAIN}projekti`;

/* --------------------------| PROJEKTNI PARTNERJI |-------------------------------*/

/* GET -->  Pridobi poslovnega partnerja za projekte glede na ID */
export const pridobivanjePartnerjev = `${process.env.REACT_APP_APIDOMAIN}projekti`;

/* GET --> Seznam vseh poslovnih partnerjev */
export const seznamPartnerjev = `${process.env.REACT_APP_APIDOMAIN}partnerji`;

/*GET --> Šifrant Partnerjev */
export const sifrantPartnerjev = `${process.env.REACT_APP_APIDOMAIN}partnerji/sifrant`;


/* GET --> Podatki za specifičnega partnerja */
export const podatkiZaPartnerja = `${process.env.REACT_APP_APIDOMAIN}partnerji`;

/* POST --> Vnos partnerja */
export const vnosNovegaPartnerja = `${process.env.REACT_APP_APIDOMAIN}partnerji`;

/* POST --> Dodajanje partnerja na projekt */
export const dodajanjePartnerjaNaProjekt = `${process.env.REACT_APP_APIDOMAIN}projekti`;

/* PUT --> Vnos partnerja */
export const urejanjePartnerja = `${process.env.REACT_APP_APIDOMAIN}partnerji`;

/* PUT --> Urejanje kontaktnih podatkov za partnerja */
export const urejanjeKontaktnihPartner = `${process.env.REACT_APP_APIDOMAIN}projekti`;

/* DELETE --> Vnos partnerja */
export const brisanjePartnerja = `${process.env.REACT_APP_APIDOMAIN}partnerji`;

/* DELETE --> Brisanje partnerja iz projekta*/
export const brisanjePartnerjaIzProjekta = `${process.env.REACT_APP_APIDOMAIN}projekti`;

/* --------------------------| ORGANIZACIJSKE ENOTE |-------------------------------*/
/* GET --> Podatki za specifičnega partnerja */
export const podatkiZaEnoto = `${process.env.REACT_APP_APIDOMAIN}partnerji`;

/* POST --> Vnos partnerja */
export const vnosNoveOrgEnote = `${process.env.REACT_APP_APIDOMAIN}partnerji`;

/* PUT --> Vnos partnerja */
export const urejanjeNoveOrgEnote = `${process.env.REACT_APP_APIDOMAIN}partnerji`;

/* DELETE --> Vnos partnerja */
export const brisanjeNoveOrgEnote = `${process.env.REACT_APP_APIDOMAIN}partnerji`;

/* ---------------------------------| PROGRAMI |-----------------------------------------*/

/* GET  --> Podatki za vse programe, ki so na prvem nivoju (velja za: K1) */
export const prviNivoProgramiAPI = `${process.env.REACT_APP_APIDOMAIN}programi/nivo1`;

/*GET --> Podatki za vse programe, ki so na prvem nivoju  aktivni */
export const prviNivoProgramiAktivni = `${process.env.REACT_APP_APIDOMAIN}programi/nivo1/aktivni`;

/* GET --> Struktura oz. seznam vse otrok  oz. podkategorij nekega programa (velja za: K1) glede na njegov ID */
export const programStrukturaAPI = `${process.env.REACT_APP_APIDOMAIN}programi/struktura`;

/* GET --> Podatki za specifičen program glede na ID (velja za: K1, K2, K3, K4) */
export const podatkiZaProgram = `${process.env.REACT_APP_APIDOMAIN}programi`;

/*GET --> pot od programa/podprogram pri dodajanju in spreminjanju apija */
export const potDoPrograma = `${process.env.REACT_APP_APIDOMAIN}programi/pot`;

/*GET --> seznam podprogramov glede na id programa (K1)*/
export const seznamPodprogramov = `${process.env.REACT_APP_APIDOMAIN}programi/struktura/flat`;

/*GET --> seznam vseh programov/podprogramov */
export const seznamVsehProgramov = `${process.env.REACT_APP_APIDOMAIN}programi/sifrant`;

/*GET --> pridobivanje root programa za specifični podprogram na podlagi id */
export const rootProgram = `${process.env.REACT_APP_APIDOMAIN}programi`;

/* POST --> Vnos novega programa (vezlja za: K1, K2, K3, K4) */
export const vnosNovegaPrograma = `${process.env.REACT_APP_APIDOMAIN}programi`;

/* PUT --> Urejanje programa (velja za; K1, K2, K3, K4) */
export const urejanjePrograma = `${process.env.REACT_APP_APIDOMAIN}programi`;

/* PUT --> Nastavi posamezen program ali  podprogram aktiven ali neaktiven) */
export const spremeniStatusProgram = `${process.env.REACT_APP_APIDOMAIN}programi/aktiven`;

/*-------------------------------------| RAZPISI |-------------------------------------*/

/* GET --> seznam razpisov s podatki */
export const seznamRazpisov = `${process.env.REACT_APP_APIDOMAIN}razpisi`;

/* GET --> Podatki za specifičen razpis glede na ID */
export const podatkiZaRazpis = `${process.env.REACT_APP_APIDOMAIN}razpisi`;

/* GET --> Podatki za finančne vire za posamezen razpis glede na ID */
export const financniViriRazpisa = `${process.env.REACT_APP_APIDOMAIN}razpisi`;

/*GET --> Seznam razpisov z pripadajočimi programi/podprogrami */
export const sifrantRazpisov = `${process.env.REACT_APP_APIDOMAIN}razpisi/sifrant`;

/* GET --> Seznam akutlanih izpostavljenih izpisov */
export const izpostavljeniAktualniRazpisi = `${process.env.REACT_APP_APIDOMAIN}razpisi/aktualni/izpostavljeni`;

/* GET --> Seznam aktualnih razpisov */
export const seznamAktualnihRazpisov = `${process.env.REACT_APP_APIDOMAIN}razpisi/aktualni`;

/* GET -->  aktualnih razpiso (zadnja verzija seznama) */
export const aktualniSeznamVerzija = `${process.env.REACT_APP_APIDOMAIN}razpisi/aktualni/verzija`;

/* GET -->  get Url link za posamezen razpis */
export const aktualniSeznamGetURL = `${process.env.REACT_APP_APIDOMAIN}razpisi`;

/* POST --> Vnos novega razpisa */
export const vnosNovegaRazpisa = `${process.env.REACT_APP_APIDOMAIN}razpisi`;

/* POST --> Vnos novega finančnega vira na razpis */
export const vnosNovegaFinancnegaVira = `${process.env.REACT_APP_APIDOMAIN}razpisi`;

/* POST --> Vnos aktualnih razpisov N1  */
export const vnosAktualnihRazpisov = `${process.env.REACT_APP_APIDOMAIN}razpisi/aktualni/vnos1`;

/* POST --> Vnos aktualnih razpisov podkategorije N2  */
export const vnosAktualnihRazpisovPodkategorije = `${process.env.REACT_APP_APIDOMAIN}razpisi/aktualni/vnos/podkategorija`;

/* POST --> Vnost aktualnih razpisov N3 */
export const vnosAktualnihRazpisovTretjaKategorija = `${process.env.REACT_APP_APIDOMAIN}razpisi/aktualni/vnos/razpis`;

/* POST --> Vnos nove verzije aktualne verzije razpisa (za html predlogo prikaz) */
export const vnosNoveVerzijeAktualnegaRazpisa = `${process.env.REACT_APP_APIDOMAIN}razpisi/aktualni/verzija`;

/* PUT --> Urejanje obstoječega razpisa */
export const urejanjeRazpisa = `${process.env.REACT_APP_APIDOMAIN}razpisi`;

/* PUT --> Urejanje obstoječega finančnega vira */
export const urejanjeFinancnegaVira = `${process.env.REACT_APP_APIDOMAIN}razpisi`;

/* PUT --> Urejanje aktualenga razpisa (kategorija) */
export const urejanjeAktualnegaRazpisaKategorija = `${process.env.REACT_APP_APIDOMAIN}razpisi/aktualni/kategorije`;

/* PUT --> Urejanje aktualenga razpisa (podkategorija) */
export const urejanjeAktualnegaRazpisaPodkategorija = `${process.env.REACT_APP_APIDOMAIN}razpisi/aktualni/podkategorije`;

/* PUT --> Urejanje aktualenga razpisa (razpis) */
export const urejanjeAktualnegaRazpisaRazpisa = `${process.env.REACT_APP_APIDOMAIN}razpisi/aktualni/razpisi`;

/* PUT --> Urejanje aktualenga razpisa spreminjanje vrstnega reda kategorije */
export const vrstniRedKategorije = `${process.env.REACT_APP_APIDOMAIN}razpisi/aktualni/kategorije/vrstni_red`;

/* PUT --> Urejanje aktualenga razpisa spreminjanje vrstnega reda podkategorij */
export const vrstniRedPodkategorije = `${process.env.REACT_APP_APIDOMAIN}razpisi/aktualni/podkategorije/vrstni_red`;

/*DELETE --> Brisanje razpisaGlede na njegov ID */
export const brisanjeRazpisa = `${process.env.REACT_APP_APIDOMAIN}razpisi`;

/*DELETE --> Brisanje razpisaGlede na njegov ID */
export const izbrisFinancnegaVira = `${process.env.REACT_APP_APIDOMAIN}razpisi`;

/* DELETE --> Brisanje aktualnih razpisov kategorije */
export const brisanjeAktualnihRazpisov = `${process.env.REACT_APP_APIDOMAIN}razpisi/aktualni/kategorije`;

/* DELETE --> Brisanje aktualnih razpisov podkategorije */
export const brisanjeAktualnihRazpisovPodkategorije = `${process.env.REACT_APP_APIDOMAIN}razpisi/aktualni/podkategorije`;

/* DELETE --> Brisanje aktualni razpisov (razpisi na 1 ali 3 nivoju) */
export const brisanjeAktualnihRazpisovPrviTretjiNivo = `${process.env.REACT_APP_APIDOMAIN}razpisi/aktualni`;

/*-------------------------------------| AVTORIZACIJE |-------------------------------------*/

/* GET --> seznam userjev z avtorizacijami */
export const seznamUserjevAvtorizacije = `${process.env.REACT_APP_APIDOMAIN}avtorizacije`;

/* GET --> seznam fakultetnih userjev z avtorizacijami */
export const seznamFakultetnihUserjevAvtorizacije = `${process.env.REACT_APP_APIDOMAIN}avtorizacije/clanice`;

/* GET --> pridobi podatke avtorizacij uporabnika na podlagi id */
export const userAvtorizacije = `${process.env.REACT_APP_APIDOMAIN}avtorizacije/id`;

/*GET --> pridobi podatke avtorizacij uporabnika na podlagi upn */
export const userAvtorizacijeUpn = `${process.env.REACT_APP_APIDOMAIN}avtorizacije/upn`;

/* POST --> Vnos nove avtorizacije*/
export const vnosAvtorizacije = `${process.env.REACT_APP_APIDOMAIN}avtorizacije`;

/*PUT --> Urejanje avtorizacij uporabnik */
export const urejanjeAvtorizacije = `${process.env.REACT_APP_APIDOMAIN}avtorizacije`;

/*DELETE --> Brisanje uporabnika glede na njegov Id*/
export const brisanjeUporabnikovihAvtorizacij = `${process.env.REACT_APP_APIDOMAIN}avtorizacije`;

/*-------------------------------------| ZGODOVINA SPREMEB |-------------------------------------*/

/* GET  --> Podatki za revizijskeSledi  */
export const seznamRevizija = `${process.env.REACT_APP_APIDOMAIN}revizija`;

/*-------------------------------------| ŠIFRANT |-------------------------------------*/

/*GET --> Šifranti ARRS */
export const sifrantARRS = `${process.env.REACT_APP_APIDOMAIN}sifrant/arrs`;

/*GET --> Šifranti Cerif */
export const sifrantCerif = `${process.env.REACT_APP_APIDOMAIN}sifrant/cerif`;

/*GET --> Šifranti Klasius */
export const sifrantKlasius = `${process.env.REACT_APP_APIDOMAIN}sifrant/klasius`;

/* GET --> Seznam vseh članic */
export const claniceUM = `${process.env.REACT_APP_APIDOMAIN}sifrant/clanice`;

/* GET --> Seznam držav */
export const drzave = `${process.env.REACT_APP_APIDOMAIN}sifrant/drzave`;

/* GET -->  Financiranje kategorije */
export const financiranjeKategorije = `${process.env.REACT_APP_APIDOMAIN}sifrant/financiranje_kategorije`;

/*GET --> Viri financiranja */
export const financiranjeViri = `${process.env.REACT_APP_APIDOMAIN}sifrant/viri_financiranja`;


/*-------------------------------------| VSTOPNA STRAN |-------------------------------------*/
export const obvestilaAdmin = `${process.env.REACT_APP_APIDOMAIN}admin/obvestila`;