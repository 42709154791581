import React, { useContext, useEffect, useState } from "react";
import Select from "react-select";

import { format } from "date-fns";

/*Import React Bootstrap */
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

/*import React-Icons */
import { AiOutlineEdit } from "react-icons/ai";

/* Import Context */
import { UserContext } from "../UserContext";

/* React api helper */
import { fetchGET } from "../../apiHelper";
import { sifrantRazpisov } from "../../listOfApi";

const OsnovniPodatki = ({
  projectOsnovni,
  projectOsnovniProgramiStruktura,
  projectSkrbnikClanica,
  projectOsnovniEdit,
  vnosError,
  handleSubmitForm,
  handleFormData,
  setStateOsnovniPodatkiDropDown,
}) => {
  const role = useContext(UserContext);

  const [showEditOsnovni, setShowEditOsnovni] = useState(false);
  const [sifrantRazpisi, setSifrantRazpisi] = useState([]);

  /* GET Razpisi za šifrant */
  useEffect(() => {
    fetchGET(sifrantRazpisov).then((razpisi) => {
      if (razpisi !== 0) {
        let razpisiSeznam = razpisi.map((razpis) => {
          return { label: razpis.naziv, value: razpis.id };
        });
        setSifrantRazpisi(razpisiSeznam);
      }
    });
  }, []);

  return (
    <Card className="mt-3">
      <Card.Header>
        <Row className="align-items-center">
          <Col>
            <h5 className="mb-0">Osnovni podatki</h5>
          </Col>
          <Col>
            <div className="float-end">
              {((role.skrbnik === true &&
                role.clanicaID === projectSkrbnikClanica) ||
                (role.skrbnik === false && role.projekti === 2)) && (
                <Button
                  className="org-button-details-text"
                  id="1"
                  onClick={() => {
                    setShowEditOsnovni(!showEditOsnovni);
                  }}
                >
                  <AiOutlineEdit />
                </Button>
              )}
            </div>
          </Col>
        </Row>
      </Card.Header>
      {!showEditOsnovni && (
        <Card.Body>
          <Row className="project-partners-details-card">
            <Col>
              <div className="p-4">
                <Row>
                  <Col className="mb-3">
                    <span>Naslov projekta: </span>
                    {projectOsnovni.naziv}
                  </Col>
                </Row>

                <Row>
                  <Col className="mb-3">
                    <span>Akronim projekta: </span>
                    {projectOsnovni.akronim}
                  </Col>
                </Row>

                <Row>
                  <Col className="mb-3">
                    <span>Šifra projekta: </span>
                    {projectOsnovni.sifra}
                  </Col>
                </Row>
              </div>
            </Col>
            <Col>
              <div className="p-4">
                <Row>
                  <Col className="mb-3">
                    <span>Začetek projekta: </span>
                    {projectOsnovni.zacetek === null ||
                    projectOsnovni.zacetek === ""
                      ? ""
                      : format(new Date(projectOsnovni.zacetek), "d. M. yyyy")}
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col>
                    <span>Konec projekta: </span>

                    {projectOsnovni.konec === null ||
                    projectOsnovni.konec === ""
                      ? ""
                      : format(new Date(projectOsnovni.konec), "d. M. yyyy")}
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
          <Card.Footer className="ps-4 border-top-0 project-partners-details-card">
            <Row>
              <Col>
                <span>Razpis: </span>
                {projectOsnovni.razpis}
              </Col>
            </Row>
            <Row className="mt-3">
              <Col>
                <div className="mb-3">
                  <span> Program (stuktura):</span>
                </div>
                {projectOsnovniProgramiStruktura?.map((programi, index) => {
                  return (
                    <p className={`mb-2 ps-1 ms-${2 + index}`} key={index}>
                      - {programi}
                    </p>
                  );
                })}
              </Col>
            </Row>
          </Card.Footer>
          <Row className="mt-4">
            <Col className="text-secondary">
              <span>Datum vnosa projekta: </span>
              {projectOsnovni.timestamp !== "" &&
                format(new Date(projectOsnovni.timestamp), "d. M. yyyy")}
            </Col>
          </Row>
        </Card.Body>
      )}

      {showEditOsnovni && (
        <>
          <Row>
            {vnosError && (
              <Col className="mt-3">
                <h4 className="text-danger ms-4">
                  Urejanje osnovnih podatkov projekta ni uspelo!
                </h4>
              </Col>
            )}
          </Row>
          <Form onSubmit={handleSubmitForm}>
            <Card.Body>
              <Row className="project-partners-details-card">
                <Col>
                  <div className="p-4">
                    <Row>
                      <Col className="mb-3">
                        <Form.Label>Naslov projekta:</Form.Label>
                        <Form.Control
                          name="naziv"
                          type="text"
                          required
                          minLength="3"
                          value={projectOsnovniEdit.naziv}
                          onChange={handleFormData}
                        />
                      </Col>
                    </Row>

                    <Row>
                      <Col className="mb-3">
                        <Form.Label>Akronim projekta:</Form.Label>
                        <Form.Control
                          name="akronim"
                          type="text"
                          value={projectOsnovniEdit.akronim}
                          onChange={handleFormData}
                        />
                      </Col>
                    </Row>

                    <Row>
                      <Col className="mb-3">
                        <Form.Label>Šifra projekta:</Form.Label>
                        <Form.Control
                          name="sifra"
                          type="text"
                          maxLength="25"
                          value={projectOsnovniEdit.sifra}
                          onChange={handleFormData}
                        />
                      </Col>
                    </Row>
                  </div>
                </Col>
                <Col>
                  <div className="p-4">
                    <Row>
                      <Col className="mb-3">
                        <Form.Label>Začetek projekta </Form.Label>
                        <Form.Control
                          name="zacetek"
                          type="date"
                          required
                          value={projectOsnovniEdit.zacetek}
                          onChange={handleFormData}
                        />
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col>
                        <Form.Label>Konec projekta </Form.Label>
                        <Form.Control
                          name="konec"
                          type="date"
                          required
                          value={projectOsnovniEdit.konec}
                          onChange={handleFormData}
                        />
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
              <Card.Footer className="ps-4 border-top-0 project-partners-details-card">
                <Form.Label>Razpis</Form.Label>

                <Select
                  defaultValue={
                    sifrantRazpisi[
                      sifrantRazpisi.findIndex(
                        (x) => x.value === projectOsnovniEdit.razpisID
                      )
                    ]
                  }
                  onChange={(value) => setStateOsnovniPodatkiDropDown(value)}
                  menuPortalTarget={document.body}
                  styles={{
                    menuPortal: (base) => ({
                      ...base,
                      zIndex: 9999,
                    }),
                  }}
                  options={sifrantRazpisi}
                  placeholder="Razpisi"
                  isSearchable
                />
              </Card.Footer>
              <Row className="mt-4">
                <Col>
                  <div className="ps-4 pe-4">
                    <Button variant="primary" type="submit" className="me-3">
                      Potrdi
                    </Button>
                    <Button
                      variant="outline-primary"
                      onClick={() => setShowEditOsnovni(false)}
                    >
                      Prekliči
                    </Button>
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Form>
        </>
      )}
    </Card>
  );
};

export default OsnovniPodatki;
