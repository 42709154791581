import React, { useState, useEffect } from 'react';
import { useNavigate , useParams } from 'react-router-dom';
import Select from 'react-select';

/*Import Api */
import { fetchPOST, fetchGET } from '../apiHelper';
import { vnosNoveOrgEnote, drzave } from '../listOfApi';

/*Import React Bootstrap */
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

/*Import React Icons */
import { BiLayerPlus } from 'react-icons/bi';

const AddOrgUnit = ({ role }) => {
  const { id } = useParams();
  const history = useNavigate();

  const [vnosError, setVnosError] = useState(false);
  const [seznamDrzav, setSeznamDrzav] = useState([]);
  const [orgData, setorgData] = useState({
    davcna: '',
    nazivSlo: '',
    nazivEng: '',
    naslov: '',
    postnaStevilka: '',
    posta: '',
    drzavaID: '',
    spletnaStran: '',
    opomba: '',
    parentID: parseInt(id),
  });

  /********** PRIDOBIVANJE ŠIFRANTOV *************/
  useEffect(() => {
    fetchGET(drzave).then((drzaveSeznam) => {
      if (drzaveSeznam !== 0) {
        let drzave = drzaveSeznam.map((drzave) => {
          return {
            label: drzave.naziv,
            value: drzave.id,
          };
        });
        setSeznamDrzav(drzave);
      }
    });
  }, []);

  /* Funkcija za shranjevanje podatkov na backend */
  const handleSubmitForm = (e) => {
    e.preventDefault();
    fetchPOST(vnosNoveOrgEnote, orgData).then((org) => {
      if (org === 1) {
        history(-1);
      } else {
        setVnosError(true);
        setTimeout(() => {
          setVnosError(false);
        }, 5000);
      }
    });
  };

  const handleFormData = (e) => {
    setorgData({
      ...orgData,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <Container fluid>
      {role.partnerji === 2 && (
        <>
          <Row>
            <Col className='main-header font-weight-bold'>
              <BiLayerPlus className='me-2' />
              Dodaj: <span>{orgData.nazivSlo}</span>
            </Col>
          </Row>
          <Row>
            <Col className='header-divider'>
              <hr />
            </Col>
          </Row>
          <Row>
            {vnosError && (
              <Col>
                <h2 className='text-danger'>
                  Vnos organizacijske enote ni uspel
                </h2>
              </Col>
            )}
          </Row>
          <Row>
            <Col className='mt-3'>
              <Form onSubmit={handleSubmitForm}>
                <Row>
                  <Col>
                    <Form.Label>
                      Naziv organizacijske enote - uradni{' '}
                    </Form.Label>
                    <Form.Control
                      name='nazivSlo'
                      type='text'
                      required
                      minLength='3'
                      maxLength='100'
                      value={orgData.nazivSlo}
                      onChange={handleFormData}
                      placeholder='Naziv organizacijske enote - uradni'
                    />
                  </Col>
                  <Col>
                    <Form.Label>Naziv organizacije enote - angleški</Form.Label>
                    <Form.Control
                      name='nazivEng'
                      type='text'
                      maxLength='100'
                      value={orgData.nazivEng}
                      onChange={handleFormData}
                      placeholder='Naziv organizacijske enote - angleški'
                    />
                  </Col>
                </Row>
                <Row className='mt-3'>
                  <Col>
                    <Form.Label>Davčna številka</Form.Label>
                    <Form.Control
                      name='davcna'
                      type='text'
                      required
                      minLength='3'
                      maxLength='100'
                      value={orgData.davcna}
                      onChange={handleFormData}
                      placeholder='Davčna številka'
                    />
                  </Col>
                  <Col>
                    <Form.Label>Naslov </Form.Label>
                    <Form.Control
                      name='naslov'
                      type='text'
                      required
                      minLength='3'
                      maxLength='100'
                      value={orgData.naslov}
                      onChange={handleFormData}
                      placeholder='Naslov'
                    />
                  </Col>
                </Row>

                <Row className='mt-3'>
                  <Col>
                    <Form.Label>Poštna številka </Form.Label>
                    <Form.Control
                      name='postnaStevilka'
                      type='text'
                      required
                      minLength='3'
                      maxLength='100'
                      value={orgData.postnaStevilka}
                      onChange={handleFormData}
                      placeholder='Poštna številka'
                    />
                  </Col>
                  <Col>
                    <Form.Label>Kraj </Form.Label>
                    <Form.Control
                      name='posta'
                      type='text'
                      required
                      minLength='3'
                      maxLength='100'
                      value={orgData.posta}
                      onChange={handleFormData}
                      placeholder='Kraj'
                    />
                  </Col>
                  <Col>
                    <Form.Label>Država </Form.Label>
                    <Select
                      onChange={(value) =>
                        setorgData({
                          ...orgData,
                          drzavaID: value.value,
                        })
                      }
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (base) => ({
                          ...base,
                          zIndex: 9999,
                        }),
                      }}
                      options={seznamDrzav}
                      placeholder='Države'
                      isSearchable
                    />
                  </Col>
                </Row>

                <Row className='mt-3'>
                  <Col>
                    <Form.Label>Spletna stran </Form.Label>
                    <Form.Control
                      name='spletnaStran'
                      type='text'
                      minLength='3'
                      maxLength='100'
                      value={orgData.spletnaStran}
                      onChange={handleFormData}
                      placeholder='Spletna stran'
                    />
                  </Col>
                </Row>
                <Row className='mt-3'>
                  <Col>
                    <Form.Label>Opomba </Form.Label>
                    <Form.Control
                      name='opomba'
                      type='text'
                      as='textarea'
                      maxLength='500'
                      value={orgData.opomba}
                      onChange={handleFormData}
                      placeholder='Opomba'
                    />
                  </Col>
                </Row>
                <Row className='mt-3'>
                  <Col>
                    <Button variant='primary' type='submit'>
                      Dodaj
                    </Button>
                    <Button
                      variant='outline-primary ms-3'
                      onClick={() => {
                        history(-1);
                      }}
                    >
                      Prekliči
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </>
      )}
    </Container>
  );
};

export default AddOrgUnit;
