import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";

/* Import API */
import { userAvtorizacije, urejanjeAvtorizacije } from "../listOfApi";
import { fetchGET, fetchPUT } from "../apiHelper";

/* React-Bootstrap component */
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";

/*React-switch plugin */
import Switch from "react-switch";

/* React-Icons */
import { AiOutlineEdit } from "react-icons/ai";

const EditUserRolesAuthorisation = () => {
  /*Pridobljen parameter iz ID */
  const { id } = useParams();
  const history = useNavigate();

  const [checked, setChecked] = useState(false);
  const [userDI, setUserDI] = useState("");
  const [programiRole, setProgramiRole] = useState({
    programi: "",
  });
  const [razpisiRole, setRazpisiRole] = useState({
    razpisi: "",
  });
  const [projektiRole, setProjektiRole] = useState({
    projekti: "",
  });
  const [partnerjiRole, setPartnerjiRole] = useState({
    partnerji: "",
  });
  const [aktualniRazpisiRole, setAktualniRazpisiRole] = useState({
    aktualniRazpisi: "",
  });

  useEffect(() => {
    fetchGET(`${userAvtorizacije}/${id}`).then((user) => {
      if (user !== 0) {
        setUserDI(user.upn);
        setProgramiRole({ programi: user.programi.toString() });
        setRazpisiRole({ razpisi: user.razpisi.toString() });
        setProjektiRole({ projekti: user.projekti.toString() });
        setPartnerjiRole({ partnerji: user.partnerji.toString() });
        setAktualniRazpisiRole({
          aktualniRazpisi: user.aktualniRazpisi.toString(),
        });
        setChecked(user.admin);
      } else {
      }
    });
  }, [id]);
  const handleForm = (e) => {
    e.preventDefault();

    const avtorizacije = {
      id: parseInt(id),
      upn: userDI,
      clanicaID: 20,
      programi: parseInt(programiRole.programi),
      projekti: parseInt(projektiRole.projekti),
      partnerji: parseInt(partnerjiRole.partnerji),
      razpisi: parseInt(razpisiRole.razpisi),
      aktualniRazpisi: parseInt(aktualniRazpisiRole.aktualniRazpisi),
      skrbnik: false,
      admin: checked,
    };


    fetchPUT(urejanjeAvtorizacije, avtorizacije).then((odgovor) => {
      if (odgovor === 1) {
        history(-1);
      } else {
      }
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case "programi":
        setProgramiRole({
          [name]: value,
        });
        break;
      case "razpisi":
        setRazpisiRole({
          [name]: value,
        });
        break;
      case "projekti":
        setProjektiRole({
          [name]: value,
        });
        break;
      case "partnerji":
        setPartnerjiRole({
          [name]: value,
        });
        break;
      case "aktualniRazpisi":
        setAktualniRazpisiRole({
          [name]: value,
        });
        break;
      default:
        break;
    }
  };

  const checkSwitch = () => {
    setChecked(!checked);
    if (checked === false) {
      setProgramiRole({ programi: "2" });
      setRazpisiRole({ razpisi: "2" });
      setProjektiRole({ projekti: "2" });
      setPartnerjiRole({ partnerji: "2" });
      setAktualniRazpisiRole({ aktualniRazpisi: "2" });
    }
  };

  return (
    <Container fluid>
      <>
        <Row>
          <Col className="main-header font-weight-bold">
            <AiOutlineEdit className="me-2" />
            {userDI}
          </Col>
        </Row>
        <Row>
          <Col className="header-divider">
            <hr />
          </Col>
        </Row>
        <Form onSubmit={handleForm}>
          <Row>
            <Col>
              <Form.Label>Digitalna identiteta</Form.Label>
              <Form.Control
                readOnly
                required
                name="enaslov"
                type="email"
                minLength="3"
                maxLength="100"
                value={userDI}
              />
            </Col>
          </Row>
          <Row className="mt-4">
            <Col>
              <Form.Label>Globalni skrbnik</Form.Label>
            </Col>
          </Row>
          <Row>
            <Col>
              <Switch
                className="ms-1"
                onChange={checkSwitch}
                checked={checked}
              />
            </Col>
          </Row>
          {!checked && (
            <>
              <Card className="mt-4">
                <Card.Body>
                  <Row className="mt-2">
                    <Col>
                      <Form.Label>Programi</Form.Label>
                      <Form.Group
                        controlId="formProgramiRadio"
                        className="ms-2"
                      >
                        <Form.Check
                          name="programi"
                          type="radio"
                          value={0}
                          label="Ni dostopa"
                          onChange={handleChange}
                          checked={programiRole.programi === "0"}
                        />
                        <Form.Check
                          name="programi"
                          value={1}
                          type="radio"
                          label="Obiskovalec"
                          onChange={handleChange}
                          checked={programiRole.programi === "1"}
                        />
                        <Form.Check
                          name="programi"
                          type="radio"
                          value={2}
                          label="Urednik"
                          onChange={handleChange}
                          checked={programiRole.programi === "2"}
                        />
                      </Form.Group>
                    </Col>

                    <Col>
                      <Form.Label>Razpisi</Form.Label>
                      <Form.Group controlId="formRazpisiRadio" className="ml-2">
                        <Form.Check
                          type="radio"
                          label="Ni dostopa"
                          name="razpisi"
                          value={0}
                          onChange={handleChange}
                          checked={razpisiRole.razpisi === "0"}
                        />
                        <Form.Check
                          type="radio"
                          label="Obiskovalec"
                          name="razpisi"
                          value={1}
                          onChange={handleChange}
                          checked={razpisiRole.razpisi === "1"}
                        />
                        <Form.Check
                          type="radio"
                          label="Urednik"
                          name="razpisi"
                          value={2}
                          onChange={handleChange}
                          checked={razpisiRole.razpisi === "2"}
                        />
                      </Form.Group>
                    </Col>

                    <Col>
                      <Form.Label>Aktualni razpisi</Form.Label>
                      <Form.Group controlId="formRazpisiRadio" className="ml-2">
                        <Form.Check
                          type="radio"
                          label="Ni dostopa"
                          name="aktualniRazpisi"
                          value={0}
                          onChange={handleChange}
                          checked={aktualniRazpisiRole.aktualniRazpisi === "0"}
                        />
                        <Form.Check
                          type="radio"
                          label="Obiskovalec"
                          name="aktualniRazpisi"
                          value={1}
                          onChange={handleChange}
                          checked={aktualniRazpisiRole.aktualniRazpisi === "1"}
                        />
                        <Form.Check
                          type="radio"
                          label="Urednik"
                          name="aktualniRazpisi"
                          value={2}
                          onChange={handleChange}
                          checked={aktualniRazpisiRole.aktualniRazpisi === "2"}
                        />
                      </Form.Group>
                    </Col>

                    <Col>
                      <Form.Label>Projekti</Form.Label>
                      <Form.Group
                        controlId="formProjektiRadio"
                        className="ms-2"
                      >
                        <Form.Check
                          type="radio"
                          label="Ni dostopa"
                          name="projekti"
                          value={0}
                          onChange={handleChange}
                          checked={projektiRole.projekti === "0"}
                        />
                        <Form.Check
                          type="radio"
                          label="Obiskovalec"
                          name="projekti"
                          value={1}
                          onChange={handleChange}
                          checked={projektiRole.projekti === "1"}
                        />
                        <Form.Check
                          type="radio"
                          label="Urednik"
                          name="projekti"
                          value={2}
                          onChange={handleChange}
                          checked={projektiRole.projekti === "2"}
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Label>Partnerji</Form.Label>
                      <Form.Group
                        controlId="formProjektiRadio"
                        className="ms-2"
                      >
                        <Form.Check
                          type="radio"
                          label="Ni dostopa"
                          name="partnerji"
                          value={0}
                          onChange={handleChange}
                          checked={partnerjiRole.partnerji === "0"}
                        />
                        <Form.Check
                          type="radio"
                          label="Obiskovalec"
                          name="partnerji"
                          value={1}
                          onChange={handleChange}
                          checked={partnerjiRole.partnerji === "1"}
                        />
                        <Form.Check
                          type="radio"
                          label="Urednik"
                          name="partnerji"
                          value={2}
                          onChange={handleChange}
                          checked={partnerjiRole.partnerji === "2"}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </>
          )}

          <Row className="mt-4">
            <Col>
              <Button type="submit" variant="primary">
                Uredi avtorizacije
              </Button>
              <Button
                variant="outline-primary ms-3"
                onClick={() => {
                  history(-1);
                }}
              >
                Prekliči
              </Button>
            </Col>
          </Row>
        </Form>
      </>
    </Container>
  );
};

export default EditUserRolesAuthorisation;
