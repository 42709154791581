import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { components } from "react-select";

import { Tooltip as ReactTooltip } from "react-tooltip";

/*Podkomponente */
import OsnovniPodatki from "./projectDetails/OsnovniPodatki";
import DodatniPodatki from "./projectDetails/DodatniPodatki";
import SodelujoceOsebe from "./projectDetails/SodelujoceOsebe";
import Partnerji from "./projectDetails/Partnerji";
import SodelujoceClanice from "./projectDetails/SodelujoceClanice";
import NacrtovanaVrednostProjekta from "./projectDetails/NacrtovanaVrednostProjekta";
import RealiziraneVrednostiProjekta from "./projectDetails/RealiziraneVrednostiProjekta";
import StroskovnaMesta from "./projectDetails/StroskovnaMesta";
import StatusProjekta from "./projectDetails/StatusProjekta";

/*Import Context */
import { UserContext } from "./UserContext";

/* React Bootstrap */
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";

/*React Icons */
import { AiOutlineInfoCircle } from "react-icons/ai";
import { AiOutlineEdit } from "react-icons/ai";

import { BiSearch } from "react-icons/bi";
import { BsFillKanbanFill } from "react-icons/bs";

/* React api helper */
import {
  fetchGET,
  fetchPUT,
  fetchDELETE,
  fetchPOST,
  fetchGETPwd,
} from "../apiHelper";
import {
  jeClanicaNaProjektu,
  osnovniPodatkiZaProjekt,
  osnovniPodatkiZaProjektUrejanje,
  dodatniPodatkiZaProjektUrejanje,
  potDoPrograma,
  vlogaProjekta,
  pridobivanjePartnerjev,
  brisanjePartnerjaIzProjekta,
  dodajanjePartnerjaNaProjekt,
  urejanjeKontaktnihPartner,
  imenikSifrant,
  izbranaOsebaPodrobnosti,
  vnosKontaktneOsebeNaProjekt,
  seznamKontaktnihOsebNaProjektu,
  odstranjevanjeKontaktneOsebeIzProjekta,
  urejanjeKontaktnihPodatkovzaKontaktnoOsebo,
  vnosSodelujoceClanice,
  seznamSodelujocihClanicUM,
  odstranjevanjeSodelujoceClaniceIzProjekta,
  urejanjeSodelujoceClanice,
  financniPodatkiZaNacrtovano,
  urejanjeDodajanjePodatkiZaNacrtovano,
  financniPodatkiZaRealizirano,
  urejanjeDodajanjePodatkiZaRealizirano,
  financniNacrtovaniClanice,
  urejanjefinancniNacrtovaniClanice,
  financniRealiziraneClanice,
  urejanjefinancniRealiziraneClanice,
  financniNacrtovaniSestevekClanic,
  financniRealiziraniSestevekClanic,
  seznamClanicSStroskovnimiMesti,
  vnosStroskovnegaMestaClanice,
  urejanjeStroskovnihMestClanice,
  odstranjevanjeStroskovnegaMestaClanice,
  urejanjeStatusaProjekta,
} from "../listOfApi";

const ProjectDetails = ({ role }) => {
  /*Pridobljen parameter iz ID */
  const { id } = useParams();
  const [projectSkrbnikClanica, setProjectSkrbnikClanica] = useState("");
  const [key, setKey] = useState("osnovni");
  const [jeClanicaProjekta, setJeClanicaProjekta] = useState(false);
  const [vnosError, setVnosError] = useState(false);
  const [tooltipSifranti, setTooltipSifranti] = useState({});
  const [showEditDodatni, setShowEditDodatni] = useState(false);
  const [sifrantVloge, setSifrantVloge] = useState([]);
  const [projectOsnovniProgramiStruktura, setProjectOsnovniProgramiStruktura] =
    useState([]);
  const [projectOsnovni, setProjectOsnovni] = useState({
    naziv: "",
    akronim: "",
    status: "",
    statusID: "",
    zacetek: "",
    konec: "",
    razpis: "",
    razpisID: "",
    sifra: "",
    timestamp: "",
  });
  const [projectOsnovniEdit, setProjectOsnovniEdit] = useState({
    id: "",
    naziv: "",
    akronim: "",
    statusID: "",
    zacetek: "",
    konec: "",
    razpisID: "",
  });
  const [projectDodatni, setProjectDodatni] = useState({
    id: "",
    pogodba: "",
    referencnaSt: "",
    spletnaStran: "",
    sodGopodarstvom: "",
    sodStudenti: "",
    stStudenti: "",
    povzetek: "",
    cerif: "",
    cerifID: -1,
    arrs: "",
    arrsID: -1,
    klasius: "",
    klasiusID: -1,
  });
  const [projectDodatniEdit, setProjectDodatniEdit] = useState({
    id: "",
    pogodba: "",
    referencnaSt: "",
    spletnaStran: "",
    sodGopodarstvom: "",
    sodStudenti: "",
    stStudenti: "",
    povzetek: "",
    cerifID: "",
    arrsID: "",
    klasiusID: "",
  });
  const [showAddPartner, setShowAddPartner] = useState(false);
  const [partnersData, setPartnersData] = useState([]);
  const [projectPartnerId, setProjectPartnerId] = useState("");
  const [partnerDataPost, setPartnerDataPost] = useState({
    partnerID: "",
    kontaktnaOseba: "",
    vlogaID: "",
  });
  const [dodajanjePartnerjaError, setDodajanjePartnerjaError] = useState(false);
  const [formSelectValidation, setFormSelectValidation] = useState(false);
  const [showEditPartnerKontakt, setShowEditPartnerKontakt] = useState(false);
  const [editKontaktData, setEditKontaktData] = useState({
    partnerID: "",
    vlogaID: "",
    kontaktnaOseba: "",
  });
  const [dodajanjeKontaktnihError, setDodajanjeKontaktnihError] =
    useState(false);
  const [kontaktneOsebeSeznam, setKontaktneOsebeSeznam] = useState([]);
  const [kontaktnaOsebaPodrobnosti, setKontaktnaOsebaPodrobnosti] = useState({
    naziv: "",
    kisid: "",
    clanica: "",
    od: null,
    do: null,
  });
  const [seznamKontaktnihOseb, setSeznamKontaktnihOseb] = useState([]);
  const [showPodatkiZaNajdenoOsebo, setShowPodatkiZaNajdenoOsebo] =
    useState(false);
  const [kontaktnaOsebaId, setKontaktnaOsebaId] = useState("");
  const [
    showErrorDodajanjeKontaktneOsebe,
    setShowErrorDodajanjeKontaktneOsebe,
  ] = useState(false);
  const [showEditKontaktnaOsebaDatum, setShowEditKontaktnaOsebaDatum] =
    useState(false);
  const [editKontaktnaOsebaDatum, setEditKontaktnaOsebaDatum] = useState({
    kisID: "",
    od: null,
    do: null,
  });
  const [showAddSodelujocaClanica, setShowAddSodelujocaClanica] =
    useState(false);
  const [projectSodelujocaClanicaPost, setProjectSodelujocaClanicaPost] =
    useState({
      clanicaID: "",
      vlogaID: "",
      kisID: "",
      oseba: "",
    });
  const [projectSodelujocaClanicaEdit, setProjectSodelujocaClanicaEdit] =
    useState({
      clanicaID: "",
      vlogaID: "",
      skrbnik: "",
      kisID: "",
      oseba: "",
    });
  const [
    showKontaktniPodatkiOsebaSodelujoceClanice,
    setShowKontaktniPodatkiOsebaSodelujoceClanice,
  ] = useState(false);
  const [seznamSodelujocihClanic, setSeznamSodelujocihClanic] = useState([]);
  const [
    showErrorDodajanjeSodelujoceClanice,
    setShowErrorDodajanjeSodelujoceClanice,
  ] = useState(false);
  const [
    formSelectValidationSodelujocaClanica,
    setFormSelectValidationSodelujocaClanica,
  ] = useState(false);
  const [sodelujocaClanicaId, setSodelujocaClanicaId] = useState("");
  const [showUrejanjeSodelujocaClanica, setShowUrejanjeSodelujocaClanica] =
    useState(false);
  const [nacrtovaneVrednostiProjekta, setNacrtovaneVrednostiProjekta] =
    useState({
      nacrtovanaVrednost: "",
      nacrtovaniProjektniViri: "",
    });
  const [
    nacrtovanaVrednostProjektaUrejanje,
    setNacrtovanaVrednostProjektaUrejanje,
  ] = useState({
    nacrtovanaVrednost: "",
    nacrtovaniProjektniViri: "",
  });
  const [
    nacrtovaneVrednostiProjektaPrikaz,
    setNacrtovaneVrednostiProjektaPrikaz,
  ] = useState({
    nacrtovanaVrednost: "",
    nacrtovaniProjektniViri: "",
  });
  const [showNacrtovaneVrednostiProjekta, setShowNacrtovaneVrednostiProjekta] =
    useState(false);
  const [
    showErrorDodajanjeUrejanjeNacrtovanihFinanc,
    setShowErrorDodajanjeUrejanjeNacrtovanihFinanc,
  ] = useState(false);
  const [realiziraneVrednostiProjekta, setRealiziraneVrednostiProjekta] =
    useState({
      realiziranaVrednost: "",
      realiziraniProjektniViri: "",
    });
  const [
    realiziraneVrednoProjektaUrejanje,
    setRealiziraneVrednostiProjektaUrejanje,
  ] = useState({
    realiziranaVrednost: "",
    realiziraniProjektniViri: "",
  });
  const [
    realiziraneVrednoProjektaPrikaz,
    setRealiziraneVrednostiProjektaPrikaz,
  ] = useState({
    realiziranaVrednost: "",
    realiziraniProjektniViri: "",
  });
  const [
    showRealiziraneVrednostiProjekta,
    setShowRealiziraneVrednostiProjekta,
  ] = useState(false);
  const [
    showErrorDodajanjeUrejanjeRealiziranihFinanc,
    setShowErrorDodajanjeUrejanjeRealiziranihFinanc,
  ] = useState(false);
  const [
    showUrejanjeDodajanjeFinancniClaniceNacrtovani,
    setShowUrejanjeDodajanjeFinancniClaniceNacrtovani,
  ] = useState(false);
  const [nacrtovaneVrednostiClanice, setNacrtovaneVrednostiClanice] = useState(
    []
  );
  const [
    nacrtovaneVrednostiClaniceUrejanje,
    setNacrtovaneVrednostiClaniceUrejanje,
  ] = useState({
    clanicaID: "",
    nacrtovanaVrednost: "",
    nacrtovanaDelez: "",
    nacrtovanaLastnaVrednost: "",
    nacrtovanaVrednostViri: "",
    nacrtovaniObseg: "",
  });
  const [
    nacrtovaneVrednostiClanicePrikaz,
    setNacrtovaneVrednostiClanicePrikaz,
  ] = useState({
    clanicaID: "",
    nacrtovanaVrednost: "",
    nacrtovanaLastnaVrednost: "",
    nacrtovanaVrednostViri: "",
    nacrtovaniObseg: "",
  });
  const [
    showUrejanjeDodajanjeFinancniClaniceRealizirano,
    setShowUrejanjeDodajanjeFinancniClaniceRealizirano,
  ] = useState(false);
  const [realiziraneVrednostiClanice, setRealiziraneVrednostiClanice] =
    useState([]);
  const [
    realiziraneVrednostiClaniceUrejanje,
    setRealiziraneVrednostiClaniceUrejanje,
  ] = useState({
    clanicaID: "",
    realiziranaVrednost: "",
    realiziranaLastnaVrednost: "",
    realiziranaVrednostViri: "",
    realiziranaObseg: "",
  });
  const [
    realiziraneVrednostiClanicePrikaz,
    setRealiziraneVrednostiClanicePrikaz,
  ] = useState({
    clanicaID: "",
    realiziranaVrednost: "",
    realiziranaLastnaVrednost: "",
    realiziranaVrednostViri: "",
    realiziranaObseg: "",
  });
  const [
    sestevekNacrtovanihVrednostiClanic,
    setSestevekNacrtovanihVrednostiClanic,
  ] = useState({});
  const [
    sestevekRealiziranihVrednostiClanice,
    setSestevekRealiziranihVrednostiClanic,
  ] = useState({});
  const [stroskovnaMestaClanic, setStroskovnaMestaClanic] = useState([]);
  const [showAddStroskovnoMesto, setShowAddStroskovnoMesto] = useState(false);
  const [addStroskovnoMesto, setAddstroskovnoMesto] = useState({
    clanicaID: "",
    stroskovnoMesto: "",
    stroskovniNosilec: "",
  });
  const [showAddStroskovnoMestoError, setShowAddStroskovnoMestoError] =
    useState(false);
  const [showEditStroskovnoMesto, setShowEditStroskovnoMesto] = useState(false);
  const [editStroskovnoMesto, setEditStroskovnoMesto] = useState({
    id: "",
    clanicaID: "",
    stroskovnoMesto: "",
    stroskovniNosilec: "",
  });
  const [clanicaStroskovnoMestoNosilecId, setClanicaStroskovnoMestoNosilecId] =
    useState("");

  /*-------------------------- TOOLTIP REBUILD ---------------------------------*/
  useEffect(() => {
    //ReactTooltip.rebuild();
  });
  /*----------------------------------------------------------------------------*/

  /*--------------------------- STATE UPDATE------------------------------------*/
  useEffect(() => {
    if (projectDodatniEdit.sodStudenti === false) {
      setProjectDodatniEdit({ ...projectDodatniEdit, stStudenti: 0 });
    }
  }, [projectDodatniEdit.sodStudenti]);
  /*----------------------------------------------------------------------------*/

  /* ----------------------------- CLANICA NA PROJEKUT----------------------------*/
  /*GET - Preveri ali je članica na projektu */

  useEffect(() => {
    fetchGET(`${jeClanicaNaProjektu}/${id}/skrbnik`).then((jeNaProjektu) => {
      if (jeNaProjektu !== 0) {
        setJeClanicaProjekta(jeNaProjektu);
      }
    });
  }, []);

  /* ----------------------------ŠIFRANTI ------------------------------------- */
  /*TOOLTIPS JSON TOOLTIP SIFRANT */
  const getData = () => {
    fetch("../../../tooltip.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        setTooltipSifranti(myJson);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  /* GET - VLOGE NA PROJEKTU SIFRANT */
  useEffect(() => {
    fetchGET(vlogaProjekta).then((vloge) => {
      if (vloge !== 0) {
        let vlogeProjekta = vloge.map((vloga) => {
          return { name: vloga.naziv, value: vloga.id };
        });
        setSifrantVloge(vlogeProjekta);
      }
    });
  }, []);
  /*----------------------------------------------------------------------------*/
  /*------------------------- GET DATA FROM API -----------------------------------------*/
  // OSNOVNI PODATKI PROJEKTA
  useEffect(() => {
    fetchGET(`${osnovniPodatkiZaProjekt}/${id}`).then((projectOsnovni) => {
      if (projectOsnovni !== 0) {
        setProjectOsnovni({
          naziv: projectOsnovni.naziv,
          akronim: projectOsnovni.akronim,
          clanica: projectOsnovni.clanica,
          status: projectOsnovni.status,
          statusID: projectOsnovni.statusID,
          zacetek: projectOsnovni.zacetek,
          konec: projectOsnovni.konec,
          razpis: projectOsnovni.razpis,
          vloga: projectOsnovni.vloga,
          programID: projectOsnovni.programID,
          pogodba: projectOsnovni.pogodba,
          sifra: projectOsnovni.sifra,
          timestamp: projectOsnovni.timestamp,
        });
        setProjectOsnovniEdit({
          id: projectOsnovni.id,
          naziv: projectOsnovni.naziv,
          akronim: projectOsnovni.akronim,
          clanicaID: projectOsnovni.clanicaID,
          zacetek: projectOsnovni.zacetek,
          konec: projectOsnovni.konec,
          razpisID: projectOsnovni.razpisID,
          vlogaID: projectOsnovni.vlogaID,
          sifra: projectOsnovni.sifra,
        });
        setProjectDodatni({
          id: projectOsnovni.id,
          pogodba: projectOsnovni.pogodba,
          referencnaSt: projectOsnovni.referencnaSt,
          spletnaStran: projectOsnovni.spletnaStran,
          sodGopodarstvom: projectOsnovni.sodGopodarstvom,
          sodStudenti: projectOsnovni.sodStudenti,
          stStudenti: projectOsnovni.stStudenti,
          povzetek: projectOsnovni.povzetek,
          cerif: projectOsnovni.cerif,
          cerifID: projectOsnovni.cerifID,
          arrs: projectOsnovni.arrs,
          arrsID: projectOsnovni.arrsID,
          klasius: projectOsnovni.klasius,
          klasiusID: projectOsnovni.klasiusID,
        });
        setProjectDodatniEdit({
          id: projectOsnovni.id,
          pogodba: projectOsnovni.pogodba || "",
          referencnaSt: projectOsnovni.referencnaSt || "",
          spletnaStran: projectOsnovni.spletnaStran || "",
          sodGopodarstvom:
            projectOsnovni.sodGopodarstvom === null
              ? false
              : projectOsnovni.sodGopodarstvom,
          sodStudenti:
            projectOsnovni.sodStudenti === null
              ? false
              : projectOsnovni.sodStudenti,
          stStudenti: projectOsnovni.stStudenti,
          povzetek: projectOsnovni.povzetek || "",
          cerifID: projectOsnovni.cerifID === -1 ? 0 : projectOsnovni.cerifID,
          arrsID: projectOsnovni.arrsID === -1 ? 0 : projectOsnovni.arrsID,
          klasiusID:
            projectOsnovni.klasiusID === -1 ? 0 : projectOsnovni.klasiusID,
        });
      } else {
        //TODO:
        /*setInvalidPartnerId(true); */
      }
    });
  }, [id, showEditDodatni]);

  // PRIDOBIVANJE STRUKTURE PROGRAMOV ZA PROJEKT
  useEffect(() => {
    if (projectOsnovni.programID !== undefined) {
      fetchGET(`${potDoPrograma}/${projectOsnovni.programID}`).then((poti) => {
        setProjectOsnovniProgramiStruktura(poti);
      });
    }
  }, [projectOsnovni.programID]);

  // PRIDOBIVANJE OSEB ZA KONTAKTNE OSEBE
  useEffect(() => {
    fetchGETPwd(imenikSifrant).then((osebe) => {
      if (osebe !== 0) {
        let seznamOseb = osebe.map((oseba) => {
          return { label: oseba.oseba, value: oseba.kisid };
        });
        setKontaktneOsebeSeznam(seznamOseb);
      } else {
      }
    });
  }, []);

  // PRIDOBIVANJE PARTNERJEV ZA POSAMEZEN PROJEKT
  useEffect(() => {
    fetchGET(`${pridobivanjePartnerjev}/${id}/partnerji`).then(
      (partnerjiProjekta) => {
        if (partnerjiProjekta !== 0) {
          setPartnersData(partnerjiProjekta);
        } else {
        }
      }
    );
  }, []);

  //PRIDOBIVANJE KONTAKTNIH OSEB - PODROBNOSTI ZA PROJEKT
  useEffect(() => {
    fetchGET(`${seznamKontaktnihOsebNaProjektu}/${id}/kontaktne-osebe`).then(
      (osebeNaProjektu) => {
        if (osebeNaProjektu !== 0) {
          setSeznamKontaktnihOseb(osebeNaProjektu);
        } else {
        }
      }
    );
  }, []);

  //PRIDOBIVANJA SEZNAMA SODELUJOČIH ČLANIC
  useEffect(() => {
    fetchGET(`${seznamSodelujocihClanicUM}/${id}/sodelujoce-clanice`).then(
      (sodelujoceClanice) => {
        if (sodelujoceClanice !== 0) {
          setSeznamSodelujocihClanic(sodelujoceClanice);
          setProjectSkrbnikClanica(sodelujoceClanice[0].clanicaID);
        } else {
        }
      }
    );
  }, []);

  //PRIDOBIVANJE NACRTOVANIH FINANCNIH PODATKOV ZA PROJEKT
  useEffect(() => {
    fetchGET(`${financniPodatkiZaNacrtovano}/${id}/finance/nacrtovano`).then(
      (finance) => {
        if (finance !== 0) {
          setNacrtovaneVrednostiProjekta({
            ...nacrtovaneVrednostiProjekta,
            nacrtovanaVrednost: finance.nacrtovanaVrednost,
            nacrtovaniProjektniViri: finance.nacrtovaniProjektniViri,
          });

          setNacrtovanaVrednostProjektaUrejanje({
            ...nacrtovanaVrednostProjektaUrejanje,
            nacrtovanaVrednost: finance.nacrtovanaVrednost,
            nacrtovaniProjektniViri: finance.nacrtovaniProjektniViri,
          });

          setNacrtovaneVrednostiProjektaPrikaz({
            ...nacrtovaneVrednostiProjektaPrikaz,
            nacrtovanaVrednost:
              finance.nacrtovanaVrednost !== null
                ? finance.nacrtovanaVrednost
                : undefined,
            nacrtovaniProjektniViri:
              finance.nacrtovaniProjektniViri !== null
                ? finance.nacrtovaniProjektniViri
                : undefined,
          });
        } else {
        }
      }
    );
  }, []);

  //PRIDOBIVANJE REALIZIRANIH FINANCNIH PODATKOV ZA PROJEKT
  useEffect(() => {
    fetchGET(`${financniPodatkiZaRealizirano}/${id}/finance/realizirano`).then(
      (finance) => {
        if (finance !== 0) {
          setRealiziraneVrednostiProjekta({
            ...realiziraneVrednostiProjekta,
            realiziranaVrednost: finance.realiziranaVrednost,
            realiziraniProjektniViri: finance.realiziraniProjektniViri,
          });
          setRealiziraneVrednostiProjektaUrejanje({
            ...realiziraneVrednoProjektaUrejanje,
            realiziranaVrednost: finance.realiziranaVrednost,
            realiziraniProjektniViri: finance.realiziraniProjektniViri,
          });
          setRealiziraneVrednostiProjektaPrikaz({
            ...realiziraneVrednoProjektaUrejanje,
            realiziranaVrednost:
              finance.realiziranaVrednost !== null
                ? finance.realiziranaVrednost
                : undefined,
            realiziraniProjektniViri:
              finance.realiziraniProjektniViri !== null
                ? finance.realiziraniProjektniViri
                : undefined,
          });
        } else {
        }
      }
    );
  }, []);

  //PRIDOBIVANJE NACRTOVANIH FINANCNIH PODATKOV ZA SODELUJOČE ČLANICE NA PROJEKTU
  useEffect(() => {
    fetchGET(
      `${financniNacrtovaniClanice}/${id}/finance/nacrtovano/clanice`
    ).then((financeClanice) => {
      if (financeClanice !== 0) {
        setNacrtovaneVrednostiClanice(financeClanice);
      } else {
      }
    });
  }, [seznamSodelujocihClanic]);

  //PRIDOBIVANJE REALIZIRANIH FINANČNIH PODATKOV ZA SODELUJOČE ČLANICE NA PROJEKTU
  useEffect(() => {
    fetchGET(
      `${financniRealiziraneClanice}/${id}/finance/realizirano/clanice`
    ).then((financeClanice) => {
      if (financeClanice !== 0) {
        setRealiziraneVrednostiClanice(financeClanice);
      } else {
      }
    });
  }, [seznamSodelujocihClanic]);

  //PRIDOBIVANJE SEŠTEVKA NAČRTOVANIH FINANČNIH PODATKOV OD SODELUJOČIH ČLANIC NA PROJEKTU
  useEffect(() => {
    fetchGET(
      `${financniNacrtovaniSestevekClanic}/${id}/finance/nacrtovano/suma`
    ).then((financeClaniceSestevek) => {
      if (financeClaniceSestevek !== 0) {
        setSestevekNacrtovanihVrednostiClanic(financeClaniceSestevek);
      } else {
      }
    });
  }, [seznamSodelujocihClanic]);

  //PRIDOBIVANJE SEŠTEVKA REALIZIRANIH FINANČNIH PODATKOV OD SODELUJOČIH ČLANIC NA PROJEKTU
  useEffect(() => {
    fetchGET(
      `${financniRealiziraniSestevekClanic}/${id}/finance/realizirano/suma`
    ).then((financeClaniceSestevek) => {
      if (financeClaniceSestevek !== 0) {
        setSestevekRealiziranihVrednostiClanic(financeClaniceSestevek);
      } else {
      }
    });
  }, [seznamSodelujocihClanic]);

  //PRIDOBIVANJE STROŠKOVNIH MEST ZA PROJEKT
  useEffect(() => {
    fetchGET(`${seznamClanicSStroskovnimiMesti}/${id}/stroskovna-mesta`).then(
      (stroskovnaMesta) => {
        if (stroskovnaMesta !== 0) {
          setStroskovnaMestaClanic(stroskovnaMesta);
        } else {
        }
      }
    );
  }, [seznamSodelujocihClanic]);

  useEffect(() => {
    if (projectSodelujocaClanicaEdit.kisID !== "") {
      fetchGETPwd(
        `${izbranaOsebaPodrobnosti}/${projectSodelujocaClanicaEdit.kisID}`
      ).then((oseba) => {
        if (oseba !== 0) {
          setKontaktnaOsebaPodrobnosti({
            ...kontaktnaOsebaPodrobnosti,
            naziv: oseba.skupni_naziv,
            kisid: parseInt(oseba.osebna_stevilka_zaposlenca),
            clanica: oseba.naziv_clanice,
          });

          setProjectSodelujocaClanicaEdit({
            ...projectSodelujocaClanicaEdit,
            kisID: parseInt(projectSodelujocaClanicaEdit.kisID),
            oseba: oseba.skupni_naziv,
          });

          setShowKontaktniPodatkiOsebaSodelujoceClanice(true);
        } else {
        }
      });
    }
  }, [projectSodelujocaClanicaEdit.kisID]);
  /*----------------------------------------------------------------------------*/

  /* ------------------- TAB OSNOVNI PODATKI -------------------------*/
  /* OSNOVI PODATKI - preveri parametre ob vnosu in jih zabeleži */
  const handleFormData = (e) => {
    setProjectOsnovniEdit({
      ...projectOsnovniEdit,
      [e.target.name]: e.target.value,
    });
  };

  /* OSNOVNI PDOATKI - shranjevanje podatkov na backend */
  const handleSubmitForm = (e) => {
    e.preventDefault();
    fetchPUT(osnovniPodatkiZaProjektUrejanje, projectOsnovniEdit).then(
      (projekti) => {
        if (projekti === 1) {
          window.location.reload();
        } else {
          setVnosError(true);
          setTimeout(() => {
            setVnosError(false);
          }, 3000);
        }
      }
    );
  };

  /* OSNOVNI PODATKI - nastavljanje  vrednosti za dropDowne */
  const setStateOsnovniPodatkiDropDown = (value, dropDown) => {
    if (dropDown === "sifrantiStatus") {
      setProjectOsnovniEdit({
        ...projectOsnovniEdit,
        statusID: value,
      });
    } else {
      setProjectOsnovniEdit({
        ...projectOsnovniEdit,
        razpisID: value.value,
      });
    }
  };
  /* --------------------------------------------------------------- */

  /* ---------------- TAB DODATNI PODATKI -------------------------- */
  /*DODATNI PODATKI - preveri parametre ob vnosu in jih zabeleži */
  const handleFormDataDodatni = (e) => {
    let value;

    if (e.target.name === "sodGopodarstvom") {
      value = !projectDodatniEdit.sodGopodarstvom;
    } else if (e.target.name === "sodStudenti") {
      value = !projectDodatniEdit.sodStudenti;
    } else if (e.target.name === "stStudenti") {
      value = parseInt(e.target.value);
    } else {
      value = e.target.value;
    }
    setProjectDodatniEdit({
      ...projectDodatniEdit,
      [e.target.name]: value,
    });
  };

  /* DODATNI PODATKI - shranjevanje podatkov na backend */
  const handleSubmitFormDodatni = (e) => {
    e.preventDefault();

    fetchPUT(
      `${dodatniPodatkiZaProjektUrejanje}/${id}/dodatno`,
      projectDodatniEdit
    ).then((projekti) => {
      if (projekti === 1) {
        setShowEditDodatni(false);
        setProjectDodatni({
          id: projectDodatni.id,
          pogodba: projectDodatni.pogodba,
          referencnaSt: projectDodatni.referencnaSt,
          spletnaStran: projectDodatni.spletnaStran,
          sodGopodarstvom: projectDodatni.sodGopodarstvom,
          sodStudenti: projectDodatni.sodStudenti,
          stStudenti: projectDodatni.stStudenti,
          povzetek: projectDodatni.povzetek,
          cerif: projectDodatni.cerif,
          cerifID: projectDodatni.cerifID,
          arrs: projectDodatni.arrs,
          arrsID: projectDodatni.arrsID,
          klasius: projectDodatni.klasius,
          klasiusID: projectDodatni.klasiusID,
        });
        setProjectDodatniEdit({
          id: projectDodatni.id,
          pogodba: projectDodatni.pogodba || "",
          referencnaSt: projectDodatni.referencnaSt || "",
          spletnaStran: projectDodatni.spletnaStran || "",
          sodGopodarstvom:
            projectDodatni.sodGopodarstvom === null
              ? false
              : projectDodatni.sodGopodarstvom,
          sodStudenti:
            projectDodatni.sodStudenti === null
              ? false
              : projectDodatni.sodStudenti,
          stStudenti: projectDodatni.stStudenti,
          povzetek: projectDodatni.povzetek || "",
          cerifID: projectDodatni.cerifID === -1 ? 0 : projectDodatni.cerifID,
          arrsID: projectDodatni.arrsID === -1 ? 0 : projectDodatni.arrsID,
          klasiusID:
            projectDodatni.klasiusID === -1 ? 0 : projectDodatni.klasiusID,
        });
      } else {
        setVnosError(true);
        setTimeout(() => {
          setVnosError(false);
        }, 3000);
      }
    });
  };

  /* DODATNI PODATKI - nastavljanje  vrednosti za dropDowne */
  const setStateDodatniPodatkiDropDown = (value, dropDown) => {
    if (dropDown === "arrs") {
      setProjectDodatniEdit({
        ...projectDodatniEdit,
        arrsID: value.value,
      });
    } else if (dropDown === "cerif") {
      setProjectDodatniEdit({
        ...projectDodatniEdit,
        cerifID: value.value,
      });
    } else {
      setProjectDodatniEdit({
        ...projectDodatniEdit,
        klasiusID: value.value,
      });
    }
  };

  /* DODATNI PODATKI - nastavljanje vrednosti za prikaz showEditDodatni*/
  const setStateShowEdit = () => {
    setShowEditDodatni(!showEditDodatni);
  };
  /* ------------------------------------------------------------------ */

  /* --------------------- TAB SODELUJOČE OSEBE -------------------------- */
  /*SODELUJOČE OSEBE - DODAJANJE KONTAKTNE OSEB UM */
  const handleAddKontaktnaOseba = (e) => {
    e.preventDefault();
    fetchPOST(
      `${vnosKontaktneOsebeNaProjekt}/${id}/kontaktne-osebe`,
      kontaktnaOsebaPodrobnosti
    ).then((kontaktnaOseba) => {
      if (kontaktnaOseba === 1) {
        fetchGET(
          `${seznamKontaktnihOsebNaProjektu}/${id}/kontaktne-osebe`
        ).then((osebeNaProjektu) => {
          if (osebeNaProjektu !== 0) {
            setSeznamKontaktnihOseb(osebeNaProjektu);
          } else {
          }
        });
        setKontaktnaOsebaPodrobnosti({
          naziv: "",
          kisid: "",
          clanica: "",
          od: null,
          do: null,
        });
        setShowPodatkiZaNajdenoOsebo(false);
      } else {
        setShowErrorDodajanjeKontaktneOsebe(true);
        setTimeout(() => setShowErrorDodajanjeKontaktneOsebe(false), 3000);
      }
    });
  };

  /*SODELUJOČE OSEBE Pridobi podrobnosti za posamezno KONTAKTNO OSEBO, KI JE NA ŠIFRANTU */
  const selectKontaktnaOseba = (value) => {
    if (value !== null) {
      fetchGETPwd(`${izbranaOsebaPodrobnosti}/${value.value}`).then((oseba) => {
        if (oseba !== 0) {
          setKontaktnaOsebaPodrobnosti({
            ...kontaktnaOsebaPodrobnosti,
            naziv: oseba.skupni_naziv,
            kisid: parseInt(oseba.osebna_stevilka_zaposlenca),
            clanica: oseba.naziv_clanice,
            od: projectOsnovni.zacetek,
            do: projectOsnovni.konec,
          });
          setShowPodatkiZaNajdenoOsebo(true);
        } else {
        }
      });
    }
  };

  /* SODELUJOČE OSEBE Odstranitve kontaktne osebe iz projekta */
  const handleDeleteKontaktnaOseba = () => {
    fetchDELETE(
      `${odstranjevanjeKontaktneOsebeIzProjekta}/${id}/kontaktne-osebe/${kontaktnaOsebaId}`
    ).then((deleted) => {
      if (deleted === 1) {
        fetchGET(
          `${seznamKontaktnihOsebNaProjektu}/${id}/kontaktne-osebe`
        ).then((osebeNaProjektu) => {
          if (osebeNaProjektu !== 0) {
            setSeznamKontaktnihOseb(osebeNaProjektu);
          } else {
          }
        });
      } else {
        //TODO: prišlo je do napake pri brisanju podatkov.
      }
    });
  };

  /* SODELUJOČE OSEBE Urejanje datuma od do */
  const handleSubmitFormUrediKontaktDatum = (e) => {
    fetchPUT(
      `${urejanjeKontaktnihPodatkovzaKontaktnoOsebo}/${id}/kontaktne-osebe`,
      editKontaktnaOsebaDatum
    ).then((kontaktni) => {
      if (kontaktni === 1) {
        fetchGET(
          `${seznamKontaktnihOsebNaProjektu}/${id}/kontaktne-osebe`
        ).then((osebeNaProjektu) => {
          if (osebeNaProjektu !== 0) {
            setSeznamKontaktnihOseb(osebeNaProjektu);
          } else {
          }
        });
        setShowEditKontaktnaOsebaDatum(false);
      } else {
      }
    });
  };

  /* SODELUJOČE OSEBE - nastavljanje  vrednosti za dropDowne */
  const setStateSodelujoceOsebeDropDown = (value, dropDown) => {
    if (dropDown === "zacetek") {
      setKontaktnaOsebaPodrobnosti({
        ...kontaktnaOsebaPodrobnosti,
        od: value,
      });
    } else {
      setKontaktnaOsebaPodrobnosti({
        ...kontaktnaOsebaPodrobnosti,
        od: value,
      });
    }
  };

  /* SODELUJOČE OSEBE - nastavljanje stanja za prikaz okenca s podatki za najdeno osebo */
  const setStateShowPodatkiZaNajdenoOsebo = () => {
    setShowPodatkiZaNajdenoOsebo(false);
  };

  /* SODELUJOČE OSEBE - nastavljanje stanja za prikaz okenca za urejanje datuma */
  const setStateShowEditKontaktnaOsebaDatum = (id, button) => {
    if (button === "preklici") {
      setShowEditKontaktnaOsebaDatum(false);
    } else {
      setShowEditKontaktnaOsebaDatum({
        [id]: true,
      });
    }
  };

  /* SODELUJOČE OSEBE - nastavljanje stanja za datum */
  const setStateEditKontaktnaOsebaDatum = (getKisID, getOd, getDo, date) => {
    if (date === "od") {
      setEditKontaktnaOsebaDatum({
        ...editKontaktnaOsebaDatum,
        kisID: getKisID,
        od: getOd,
      });
    } else if (date === "do") {
      setEditKontaktnaOsebaDatum({
        ...editKontaktnaOsebaDatum,
        kisID: getKisID,
        do: getDo,
      });
    } else {
      setEditKontaktnaOsebaDatum({
        ...editKontaktnaOsebaDatum,
        kisID: getKisID,
        od: getOd,
        do: getDo,
      });
    }
  };

  /* SODELUJOČE OSEBE - nastavljanje stanja za ibris izbrane sodelujoče osebe */
  const setStateKontaktnaOsebaId = (id) => {
    setKontaktnaOsebaId(id);
  };
  /* ------------------------------------------------------------------------ */

  /* ----------- TAB  PARTNERSKE ORGANIZACIJE -> PODTAB PARTNERJI ------------*/
  /* PARTNERJI - shranjevanje podatkov na backend */
  const handleSubmitFormAddPartner = (e) => {
    e.preventDefault();

    if (partnerDataPost.partnerID !== "" && partnerDataPost.vlogaID !== "") {
      fetchPOST(
        `${dodajanjePartnerjaNaProjekt}/${id}/partnerji`,
        partnerDataPost
      ).then((partner) => {
        if (partner === 1) {
          setPartnerDataPost({
            partnerID: "",
            kontaktnaOseba: "",
            vlogaID: "",
          });
          fetchGET(`${pridobivanjePartnerjev}/${id}/partnerji`).then(
            (partnerjiProjekta) => {
              if (partnerjiProjekta !== 0) {
                setPartnersData(partnerjiProjekta);
              } else {
              }
            }
          );
          setShowAddPartner(false);
        } else {
          setDodajanjePartnerjaError(true);
          setTimeout(() => {
            setDodajanjePartnerjaError(false);
          }, 3000);
        }
      });
    } else {
      setFormSelectValidation(true);
      setTimeout(() => {
        setFormSelectValidation(false);
      }, 3000);
    }
  };

  /* PARTNERJI - Urejanje kontaktnih podatkov PARTNERJEV */
  const handleSubmitFormPartnerKontakti = (e) => {
    fetchPUT(
      `${urejanjeKontaktnihPartner}/${id}/partnerji/${projectPartnerId}`,
      editKontaktData
    ).then((kontaktni) => {
      if (kontaktni === 1) {
        fetchGET(`${pridobivanjePartnerjev}/${id}/partnerji`).then(
          (partnerjiProjekta) => {
            if (partnerjiProjekta !== 0) {
              setPartnersData(partnerjiProjekta);
            } else {
            }
          }
        );
        setShowEditPartnerKontakt(false);
        setEditKontaktData({
          kontaktnaOseba: "",
          partnerID: "",
          vlogaID: "",
        });
      } else {
        setDodajanjeKontaktnihError(true);
        setTimeout(() => {
          setDodajanjeKontaktnihError(false);
        }, 3000);
      }
    });
  };

  /* PARTNERJI - BRISANJE PARTNERJA IZ PROJEKTA */
  const handleDelete = () => {
    fetchDELETE(
      `${brisanjePartnerjaIzProjekta}/${id}/partnerji/${projectPartnerId}`
    ).then((deleted) => {
      if (deleted === 1) {
        fetchGET(`${pridobivanjePartnerjev}/${id}/partnerji`).then(
          (partnerjiProjekta) => {
            if (partnerjiProjekta !== 0) {
              setPartnersData(partnerjiProjekta);
            } else {
            }
          }
        );
      } else {
        //TODO: prišlo je do napake pri brisanju podatkov.
      }
    });
  };

  /* PARTNERJI - nastavljanje stanja podatkov za dodajanje novega partnerja */
  const setStatePartnerDataPost = (
    partnerID,
    vlogaID,
    kontaktnaOseba,
    polje
  ) => {
    if (polje === "partnerId") {
      setPartnerDataPost({
        ...partnerDataPost,
        partnerID: partnerID,
      });
    } else if (polje === "vlogaId") {
      setPartnerDataPost({
        ...partnerDataPost,
        vlogaID: vlogaID,
      });
    } else if (polje === "kontaktnaOseba") {
      setPartnerDataPost({
        ...partnerDataPost,
        kontaktnaOseba: kontaktnaOseba,
      });
    } else {
      setPartnerDataPost({
        partnerID: "",
        kontaktnaOseba: "",
        vlogaID: "",
      });
    }
  };

  /* PARTNERJI - nastavljanje stanja za prikaz okenca za dodajanje partnerja */
  const setStateShowAddPartner = () => {
    setShowAddPartner(!showAddPartner);
  };

  /* PARTNERJI - nastavljanje stanja podatkov za urejanje partnerja  */
  const setStateEditKontaktData = (
    vlogaId,
    kontaktnaOseba,
    parnterID,
    polje
  ) => {
    if (polje === "vlogaId") {
      setEditKontaktData({
        ...editKontaktData,
        vlogaID: vlogaId,
      });
    } else if (polje === "kontaktnaOseba") {
      setEditKontaktData({
        ...editKontaktData,
        kontaktnaOseba: kontaktnaOseba,
      });
    } else {
      setEditKontaktData({
        ...editKontaktData,
        kontaktnaOseba: kontaktnaOseba,
        vlogaID: vlogaId,
        partnerID: parseInt(parnterID),
      });
    }
  };

  /* PARTNERJI - nastavljanje stanja za odstranitev partnerja iz projekta */
  const setStateProjectPartnerId = (id) => {
    setProjectPartnerId(id);
  };

  /* PARTNERJI - nastavljanje stanja za prikaz okenca za urejanje partnerja */
  const setStateShowEditPartnerKontakt = (id, button) => {
    //TU si
    if (button === "preklici") {
      setShowEditPartnerKontakt(false);
    } else {
      setShowEditPartnerKontakt({
        [id]: true,
      });
    }
  };
  /* ---------------------------------------------------------------------*/

  /* ----------- TAB  PARTNERSKE ORGANIZACIJE -> SODELUJOČE ČLANICE ------------*/
  /* SODELUJOČE ČLANICE - Izbiranje kontaktne osebe za sodelujočo članico */
  const selectKontaktnaOsebaSodelujoceClanice = (value) => {
    if (value !== null) {
      fetchGETPwd(`${izbranaOsebaPodrobnosti}/${value.value}`).then((oseba) => {
        if (oseba !== 0) {
          setKontaktnaOsebaPodrobnosti({
            ...kontaktnaOsebaPodrobnosti,
            naziv: oseba.skupni_naziv,
            kisid: parseInt(oseba.osebna_stevilka_zaposlenca),
            clanica: oseba.naziv_clanice,
          });

          setProjectSodelujocaClanicaPost({
            ...projectSodelujocaClanicaPost,
            kisID: parseInt(oseba.osebna_stevilka_zaposlenca),
            oseba: oseba.skupni_naziv,
          });

          setShowKontaktniPodatkiOsebaSodelujoceClanice(true);
        } else {
        }
      });
    }
  };

  /* SODELUJOČE ČLANICE - Izbira kontaktne osebe za sodelujočo članico - urejanje */
  const selectKontaktnaOsebaSodelujoceClaniceUrejanje = (value) => {
    if (value !== null) {
      fetchGETPwd(`${izbranaOsebaPodrobnosti}/${value.value}`).then((oseba) => {
        if (oseba !== 0) {
          setKontaktnaOsebaPodrobnosti({
            ...kontaktnaOsebaPodrobnosti,
            naziv: oseba.skupni_naziv,
            kisid: parseInt(oseba.osebna_stevilka_zaposlenca),
            clanica: oseba.naziv_clanice,
          });

          setProjectSodelujocaClanicaEdit({
            ...projectSodelujocaClanicaEdit,
            kisID: parseInt(oseba.osebna_stevilka_zaposlenca),
            oseba: oseba.skupni_naziv,
          });

          setShowKontaktniPodatkiOsebaSodelujoceClanice(true);
        }
      });
    } else if (value === null) {
      setProjectSodelujocaClanicaEdit({
        ...projectSodelujocaClanicaEdit,
        kisID: 0,
        oseba: "",
      });

      setKontaktnaOsebaPodrobnosti({
        ...kontaktnaOsebaPodrobnosti,
        naziv: "",
        kisid: 0,
        clanica: "",
      });

      setShowKontaktniPodatkiOsebaSodelujoceClanice(false);
    }
  };

  const removeKontaktnaOsebaFromSodelujocaClanica = () => {
    setProjectSodelujocaClanicaEdit({
      ...projectSodelujocaClanicaEdit,
      kisID: 0,
      oseba: "",
    });

    setKontaktnaOsebaPodrobnosti({
      ...kontaktnaOsebaPodrobnosti,
      naziv: "",
      kisid: 0,
      clanica: "",
    });

    setShowKontaktniPodatkiOsebaSodelujoceClanice(false);
  };

  /* SODELUJOČE ČLANICE - Dodajanje sodelujoče članice na projekt */
  const handleSubmitFormAddSodelujocaClanica = (e) => {
    e.preventDefault();

    if (
      projectSodelujocaClanicaPost.clanicaID !== "" &&
      projectSodelujocaClanicaPost.vlogaID !== "" &&
      projectSodelujocaClanicaPost.kisID !== ""
    ) {
      fetchPOST(
        `${vnosSodelujoceClanice}/${id}/sodelujoce-clanice`,
        projectSodelujocaClanicaPost
      ).then((sodelujocaClanica) => {
        if (sodelujocaClanica === 1) {
          fetchGET(
            `${seznamSodelujocihClanicUM}/${id}/sodelujoce-clanice`
          ).then((sodelujoceClanice) => {
            if (sodelujoceClanice !== 0) {
              setSeznamSodelujocihClanic(sodelujoceClanice);
            } else {
            }
          });
          setKontaktnaOsebaPodrobnosti({
            naziv: "",
            kisid: "",
            clanica: "",
            od: null,
            do: null,
          });
          setProjectSodelujocaClanicaPost({
            clanicaID: "",
            vlogaID: "",
            kisID: "",
            oseba: "",
          });

          setShowKontaktniPodatkiOsebaSodelujoceClanice(false);
          setShowAddSodelujocaClanica(false);
        } else {
          setShowErrorDodajanjeSodelujoceClanice(true);
          setTimeout(() => setShowErrorDodajanjeSodelujoceClanice(false), 3000);
        }
      });
    } else {
      setFormSelectValidationSodelujocaClanica(true);
      setTimeout(() => setFormSelectValidationSodelujocaClanica(false), 3000);
    }
  };

  /* SODELUJOČE ČLANICE - Odstranitve sodelujoče članice iz projekta */
  const handleDeleteSodelujocaClanica = () => {
    fetchDELETE(
      `${odstranjevanjeSodelujoceClaniceIzProjekta}/${id}/sodelujoce-clanice/${sodelujocaClanicaId}`
    ).then((deleted) => {
      if (deleted === 1) {
        fetchGET(`${seznamSodelujocihClanicUM}/${id}/sodelujoce-clanice`).then(
          (sodelujoceClanice) => {
            if (sodelujoceClanice !== 0) {
              setSeznamSodelujocihClanic(sodelujoceClanice);
            } else {
            }
          }
        );
      } else {
        //TODO: prišlo je do napake pri brisanju podatkov.
      }
    });
  };

  /* SODELUJOČE ČLANICE - Urejanje sodelujoče članice */
  const handleSubmitFormUrediSodelujocaClanica = (e) => {
    e.preventDefault();

    if (
      projectSodelujocaClanicaEdit.clanicaID !== "" &&
      projectSodelujocaClanicaEdit.vlogaID !== "" &&
      projectSodelujocaClanicaEdit.kisID !== ""
    ) {
      fetchPUT(
        `${urejanjeSodelujoceClanice}/${id}/sodelujoce-clanice`,
        projectSodelujocaClanicaEdit
      ).then((sodelujocaClanica) => {
        if (sodelujocaClanica === 1) {
          fetchGET(
            `${seznamSodelujocihClanicUM}/${id}/sodelujoce-clanice`
          ).then((sodelujoceClanice) => {
            if (sodelujoceClanice !== 0) {
              setSeznamSodelujocihClanic(sodelujoceClanice);
            } else {
            }
          });
          setKontaktnaOsebaPodrobnosti({
            naziv: "",
            kisid: "",
            clanica: "",
            od: null,
            do: null,
          });
          setProjectSodelujocaClanicaEdit({
            clanicaID: "",
            vlogaID: "",
            kisID: "",
            skrbnik: "",
            oseba: "",
          });
          setShowUrejanjeSodelujocaClanica(false);
          setShowKontaktniPodatkiOsebaSodelujoceClanice(false);
        } else {
          setShowErrorDodajanjeSodelujoceClanice(true);
          setTimeout(() => setShowErrorDodajanjeSodelujoceClanice(false), 3000);
        }
      });
    } else {
      setFormSelectValidationSodelujocaClanica(true);
      setTimeout(() => setFormSelectValidationSodelujocaClanica(false), 3000);
    }
  };

  /* SODELUJOČE ČLANICE - Nastavljanje stanja za prikaz podatkov osebe */
  const setStateShowKontaktniPodatkiOsebaSodelujoceClanice = () => {
    setShowKontaktniPodatkiOsebaSodelujoceClanice(false);
  };

  /* SODELUJOČE ČLANICE - Nastavljanje stanja za podatke za dodajanje nove sodelujoče članice */
  const setStateProjectSodelujocaClanicaPost = (clanicaID, vlogaID, polje) => {
    if (polje === "clanicaId") {
      setProjectSodelujocaClanicaPost({
        ...projectSodelujocaClanicaPost,
        clanicaID: clanicaID,
      });
    } else {
      setProjectSodelujocaClanicaPost({
        ...projectSodelujocaClanicaPost,
        vlogaID: vlogaID,
      });
    }
  };

  /* SODELUJOČE ČLANICE - Nastavljanje stanja za prikaz okenca za vnos sodeljujoče članice */
  const seStateShowAddSodelujocaClanica = (button) => {
    setShowKontaktniPodatkiOsebaSodelujoceClanice(false);
    setKontaktnaOsebaPodrobnosti({
      ...kontaktnaOsebaPodrobnosti,
      naziv: "",
      kisid: "",
      clanica: "",
    });

    if (button === "preklici") {
      setShowAddSodelujocaClanica(false);
    } else {
      setShowAddSodelujocaClanica(!showAddSodelujocaClanica);
    }
  };

  /* SODELUJOČE ČLANICE - Nastavljanje id za sodelujočo članico, ki jo želimo izbrisati */
  const setStateSodelujocaClanicaId = (id) => {
    setSodelujocaClanicaId(id);
  };

  /* SODELUJOČE ČLANICE - Nastavljanje prikaza okenca za urejanje sodelujoče članice */
  const setStateShowUrejanjeSodelujocaClanica = (id, button) => {
    if (button === "preklici") {
      setShowUrejanjeSodelujocaClanica(false);
    } else {
      setShowUrejanjeSodelujocaClanica({
        [id]: true,
      });
    }
  };

  /* SODELUJOČE ČLANICE - Nastavljanje stanja podatkov za urejanje sodelujoče članice */
  const setStateProjectSodelujocaClanicaEdit = (
    oseba,
    clanicaId,
    vlogaId,
    kisId,
    skrbnik,
    polje
  ) => {
    if (polje === "vlogaId") {
      setProjectSodelujocaClanicaEdit({
        ...projectSodelujocaClanicaEdit,
        vlogaID: vlogaId,
      });
    } else if (polje === "skrbnik") {
      setProjectSodelujocaClanicaEdit({
        ...projectSodelujocaClanicaEdit,
        skrbnik: skrbnik,
      });
    } else {
      setProjectSodelujocaClanicaEdit({
        oseba: oseba,
        clanicaID: clanicaId,
        vlogaID: vlogaId,
        kisID: kisId,
        skrbnik: skrbnik,
      });
    }
  };
  /* ------------------------------------------------------------------------------*/

  /* ----------- TAB FINANČNI PODATKI -> NAČRTOVANE VREDNOSTI PROJEKTA ------------*/
  /* NAČRTOVANE VREDNOSTI PROJEKTA - Dodajanje - urejanje podatkov načrtovanih vrednosti projekta */
  const handleAddNacrtovaneVrednostiProjekta = () => {
    fetchPUT(
      `${urejanjeDodajanjePodatkiZaNacrtovano}/${id}/finance/nacrtovano`,
      nacrtovanaVrednostProjektaUrejanje
    ).then((dodaneFinance) => {
      if (dodaneFinance === 1) {
        fetchGET(
          `${financniPodatkiZaNacrtovano}/${id}/finance/nacrtovano`
        ).then((finance) => {
          if (finance !== 0) {
            setNacrtovaneVrednostiProjekta({
              ...nacrtovaneVrednostiProjekta,
              nacrtovanaVrednost: finance.nacrtovanaVrednost,
              nacrtovaniProjektniViri: finance.nacrtovaniProjektniViri,
            });
            setNacrtovanaVrednostProjektaUrejanje({
              ...nacrtovanaVrednostProjektaUrejanje,
              nacrtovanaVrednost: finance.nacrtovanaVrednost,
              nacrtovaniProjektniViri: finance.nacrtovaniProjektniViri,
            });
          } else {
          }
        });
        setShowNacrtovaneVrednostiProjekta(false);
      } else {
        setShowErrorDodajanjeUrejanjeNacrtovanihFinanc(true);
        setTimeout(() => {
          setShowErrorDodajanjeUrejanjeNacrtovanihFinanc(false);
        }, 3000);
      }
    });
  };

  /* NAČRTOVANE VREDNOSTI PROJEJKTA - Dodajanje- urejanje načrtovanih finančnih podatkov za posamezne članice */
  const handleAddNacrtovaneVrednostiClanice = (e) => {
    e.preventDefault();

    fetchPUT(
      `${urejanjefinancniNacrtovaniClanice}/${id}/finance/nacrtovano/clanice`,
      nacrtovaneVrednostiClaniceUrejanje
    ).then((dodaneFinance) => {
      if (dodaneFinance === 1) {
        fetchGET(
          `${financniNacrtovaniClanice}/${id}/finance/nacrtovano/clanice`
        ).then((financeClanice) => {
          if (financeClanice !== 0) {
            setNacrtovaneVrednostiClanice(financeClanice);
          } else {
          }
        });

        fetchGET(
          `${financniNacrtovaniSestevekClanic}/${id}/finance/nacrtovano/suma`
        ).then((financeClaniceSestevek) => {
          if (financeClaniceSestevek !== 0) {
            setSestevekNacrtovanihVrednostiClanic(financeClaniceSestevek);
          } else {
          }
        });

        setShowUrejanjeDodajanjeFinancniClaniceNacrtovani(false);
      } else {
        setShowErrorDodajanjeUrejanjeNacrtovanihFinanc(true);
        setTimeout(() => {
          setShowErrorDodajanjeUrejanjeNacrtovanihFinanc(false);
        }, 3000);
      }
    });
  };

  /* NAČRTOVANE VREDNOSTI PROJEKTA - Nastavljanje prikaza okenca za urejanje nacrtovanih vrednosti projekta  */
  const seStateShowNacrtovaneVrednostiProjekta = () => {
    setShowNacrtovaneVrednostiProjekta(!showNacrtovaneVrednostiProjekta);
  };

  /* NAČRTOVANE VREDNOSTI PROJEKTA - Nastavljanje podatkov za urejanje nacrtovanih vrednosti projekta  */
  const setStateNacrtovanaVrednostProjektaUrejanje = (
    nacrtovanaVrednost,
    nacrtovaniProjektniViri,
    polje
  ) => {
    if (polje === "nacrtovanaVrednost") {
      setNacrtovanaVrednostProjektaUrejanje({
        ...nacrtovanaVrednostProjektaUrejanje,
        nacrtovanaVrednost:
          nacrtovanaVrednost !== undefined
            ? parseFloat(nacrtovanaVrednost.replace(",", "."))
            : null,
      });

      setNacrtovaneVrednostiProjektaPrikaz({
        ...nacrtovaneVrednostiProjektaPrikaz,
        nacrtovanaVrednost: nacrtovanaVrednost,
      });
    } else {
      setNacrtovanaVrednostProjektaUrejanje({
        ...nacrtovanaVrednostProjektaUrejanje,
        nacrtovaniProjektniViri:
          nacrtovaniProjektniViri !== undefined
            ? parseFloat(nacrtovaniProjektniViri.replace(",", "."))
            : null,
      });

      setNacrtovaneVrednostiProjektaPrikaz({
        ...nacrtovaneVrednostiProjektaPrikaz,
        nacrtovaniProjektniViri: nacrtovaniProjektniViri,
      });
    }
  };

  /* NAČRTOVANE VREDNOSTI PROJEKTA - Nastavljanje prikaza okenca za urejanje nacrtovanih vrednosti ČLANICE na projektu  */
  const setStateShowUrejanjeDodajanjeFinancniClaniceNacrtovani = (
    id,
    button
  ) => {
    if (button === "preklici") {
      setShowUrejanjeDodajanjeFinancniClaniceNacrtovani(false);
    } else {
      setShowUrejanjeDodajanjeFinancniClaniceNacrtovani({
        [id]: true,
      });
    }
  };

  /* NAČRTOVANJE VREDNOSTI PROJEKTA - Nastavljanje stanja podatkov -  za urejanje nacrtovanih vrednosti članic projekta*/
  const setStateNacrtovaneVrednostiClaniceUrejanje = (
    clanicaID,
    nacrtovanaVrednost,
    nacrtovanaLastnaVrednost,
    nacrtovanaVrednostViri,
    nacrtovaniObseg,
    polje
  ) => {
    if (polje === "nacrtovanaVrednost") {
      setNacrtovaneVrednostiClaniceUrejanje({
        ...nacrtovaneVrednostiClaniceUrejanje,
        nacrtovanaVrednost: nacrtovanaVrednost,
      });
    } else if (polje === "nacrtovanaLastnaVrednost") {
      setNacrtovaneVrednostiClaniceUrejanje({
        ...nacrtovaneVrednostiClaniceUrejanje,
        nacrtovanaLastnaVrednost: nacrtovanaLastnaVrednost,
      });
    } else if (polje === "nacrtovanaVrednostViri") {
      setNacrtovaneVrednostiClaniceUrejanje({
        ...nacrtovaneVrednostiClaniceUrejanje,
        nacrtovanaVrednostViri: nacrtovanaVrednostViri,
      });
    } else if (polje === "nacrtovaniObseg") {
      setNacrtovaneVrednostiClaniceUrejanje({
        ...nacrtovaneVrednostiClaniceUrejanje,
        nacrtovaniObseg: nacrtovaniObseg,
      });
    } else {
      setNacrtovaneVrednostiClaniceUrejanje({
        clanicaID: clanicaID,
        nacrtovanaVrednost: nacrtovanaVrednost,
        nacrtovanaLastnaVrednost: nacrtovanaLastnaVrednost,
        nacrtovanaVrednostViri: nacrtovanaVrednostViri,
        nacrtovaniObseg: nacrtovaniObseg,
      });
    }
  };

  /* NAČRTOVANJE VREDNOSTI PROJEKTA - Nastavljanje stanja podatkov -  za prikaz nacrtovanih vrednosti članic projekta*/
  const setStateNacrtovaneVrednostiClanicePrikaz = (
    clanicaID,
    nacrtovanaVrednost,
    nacrtovanaLastnaVrednost,
    nacrtovanaVrednostViri,
    nacrtovaniObseg,
    polje
  ) => {
    if (polje === "nacrtovanaVrednost") {
      setNacrtovaneVrednostiClanicePrikaz({
        ...nacrtovaneVrednostiClanicePrikaz,
        nacrtovanaVrednost: nacrtovanaVrednost,
      });
    } else if (polje === "nacrtovanaLastnaVrednost") {
      setNacrtovaneVrednostiClanicePrikaz({
        ...nacrtovaneVrednostiClanicePrikaz,
        nacrtovanaLastnaVrednost: nacrtovanaLastnaVrednost,
      });
    } else if (polje === "nacrtovanaVrednostViri") {
      setNacrtovaneVrednostiClanicePrikaz({
        ...nacrtovaneVrednostiClanicePrikaz,
        nacrtovanaVrednostViri: nacrtovanaVrednostViri,
      });
    } else {
      setNacrtovaneVrednostiClanicePrikaz({
        clanicaID: clanicaID,
        nacrtovanaVrednost: nacrtovanaVrednost,
        nacrtovanaLastnaVrednost: nacrtovanaLastnaVrednost,
        nacrtovanaVrednostViri: nacrtovanaVrednostViri,
        nacrtovaniObseg: nacrtovaniObseg,
      });
    }
  };
  /* ------------------------------------------------------------------------------*/

  /* ----------- TAB FINANČNI PODATKI -> REALIZIRANE VREDNOSTI PROJEKTA ------------*/
  /* REALIZIRANE VREDNOSTI PROJEKTA - urejanje podatkov realiziranih vrednosti projekta*/
  const handleAddRealiziraneVrednostiProjekta = () => {
    fetchPUT(
      `${urejanjeDodajanjePodatkiZaRealizirano}/${id}/finance/realizirano`,
      realiziraneVrednoProjektaUrejanje
    ).then((dodaneFinance) => {
      if (dodaneFinance === 1) {
        fetchGET(
          `${financniPodatkiZaRealizirano}/${id}/finance/realizirano`
        ).then((finance) => {
          if (finance !== 0) {
            setRealiziraneVrednostiProjekta({
              ...realiziraneVrednostiProjekta,
              realiziranaVrednost: finance.realiziranaVrednost,
              realiziraniProjektniViri: finance.realiziraniProjektniViri,
            });
            setRealiziraneVrednostiProjektaUrejanje({
              ...realiziraneVrednoProjektaUrejanje,
              realiziranaVrednost: finance.realiziranaVrednost,
              realiziraniProjektniViri: finance.realiziraniProjektniViri,
            });
          } else {
          }
        });
        setShowRealiziraneVrednostiProjekta(false);
      } else {
        setShowErrorDodajanjeUrejanjeRealiziranihFinanc(true);
        setTimeout(() => {
          setShowErrorDodajanjeUrejanjeRealiziranihFinanc(false);
        }, 3000);
      }
    });
  };

  /* REALIZIRANE VREDNOSTI PROJEKTA - urejanje realiziranih finančnih podatkov za posamezne članice */
  const handleAddRealiziraneVrednostiClanic = (e) => {
    e.preventDefault();

    fetchPUT(
      `${urejanjefinancniRealiziraneClanice}/${id}/finance/realizirano/clanice`,
      realiziraneVrednostiClaniceUrejanje
    ).then((dodaneFinance) => {
      if (dodaneFinance === 1) {
        fetchGET(
          `${financniRealiziraneClanice}/${id}/finance/realizirano/clanice`
        ).then((financeClanice) => {
          if (financeClanice !== 0) {
            setRealiziraneVrednostiClanice(financeClanice);
          } else {
          }
        });

        fetchGET(
          `${financniRealiziraniSestevekClanic}/${id}/finance/realizirano/suma`
        ).then((financeClaniceSestevek) => {
          if (financeClaniceSestevek !== 0) {
            setSestevekRealiziranihVrednostiClanic(financeClaniceSestevek);
          } else {
          }
        });

        setShowUrejanjeDodajanjeFinancniClaniceRealizirano(false);
      } else {
        setShowErrorDodajanjeUrejanjeRealiziranihFinanc(true);
        setTimeout(() => {
          setShowErrorDodajanjeUrejanjeRealiziranihFinanc(false);
        }, 3000);
      }
    });
  };

  /* REALIZIRANE VREDNOSTI PROJEKTA - nastavljanje okenca za prikaz urejanje realiziranih finančnih podatkov */
  const setStateShowRealiziraneVrednostiProjekta = () => {
    setShowRealiziraneVrednostiProjekta(!showRealiziraneVrednostiProjekta);
  };

  /* REALIZIRANE VREDNOSTI PROJEKTA - Nastavljanje podatkov za urejanje realiziranih vrednosti projekta  */
  const setStateRealiziranaVrednostProjektaUrejanje = (
    realiziranaVrednost,
    realiziraniProjektniViri,
    polje
  ) => {
    if (polje === "realiziranaVrednost") {
      setRealiziraneVrednostiProjektaUrejanje({
        ...realiziraneVrednoProjektaUrejanje,
        realiziranaVrednost:
          realiziranaVrednost !== undefined
            ? parseFloat(realiziranaVrednost.replace(",", "."))
            : null,
      });

      setRealiziraneVrednostiProjektaPrikaz({
        ...realiziraneVrednoProjektaPrikaz,
        realiziranaVrednost: realiziranaVrednost,
      });
    } else {
      setRealiziraneVrednostiProjektaUrejanje({
        ...realiziraneVrednoProjektaUrejanje,
        realiziraniProjektniViri:
          realiziraniProjektniViri !== undefined
            ? parseFloat(realiziraniProjektniViri.replace(",", "."))
            : null,
      });

      setRealiziraneVrednostiProjektaPrikaz({
        ...realiziraneVrednoProjektaPrikaz,
        realiziraniProjektniViri: realiziraniProjektniViri,
      });
    }
  };

  /* REALIZIRANE VREDNOSTI PROJEKTA - nastavljanje okenca za urejanje realiziranih finančnih vrednosti projekta */
  const setStateShowUrejanjeDodajanjeFinancniClaniceRealizirano = (
    id,
    button
  ) => {
    if (button === "preklici") {
      setShowUrejanjeDodajanjeFinancniClaniceRealizirano(false);
    } else {
      setShowUrejanjeDodajanjeFinancniClaniceRealizirano({
        [id]: true,
      });
    }
  };

  /* REALIZIRANE VREDNOSTI PROJEKTA - Nastavljanje stanja podatkov za urejanje realiziranih vrednosti članic projekta*/
  const setStateRealiziraneVrednostiClaniceUrejanje = (
    clanicaID,
    realiziranaVrednost,
    realiziranaLastnaVrednost,
    realiziranaVrednostViri,
    realiziranaObseg,
    polje
  ) => {
    if (polje === "realiziranaVrednost") {
      setRealiziraneVrednostiClaniceUrejanje({
        ...realiziraneVrednostiClaniceUrejanje,
        realiziranaVrednost: realiziranaVrednost,
      });
    } else if (polje === "realiziranaLastnaVrednost") {
      setRealiziraneVrednostiClaniceUrejanje({
        ...realiziraneVrednostiClaniceUrejanje,
        realiziranaLastnaVrednost: realiziranaLastnaVrednost,
      });
    } else if (polje === "realiziranaVrednostViri") {
      setRealiziraneVrednostiClaniceUrejanje({
        ...realiziraneVrednostiClaniceUrejanje,
        realiziranaVrednostViri: realiziranaVrednostViri,
      });
    } else if (polje === "realiziranaObseg") {
      setRealiziraneVrednostiClaniceUrejanje({
        ...realiziraneVrednostiClaniceUrejanje,
        realiziranaObseg: realiziranaObseg,
      });
    } else {
      setRealiziraneVrednostiClaniceUrejanje({
        clanicaID: clanicaID,
        realiziranaVrednost: realiziranaVrednost,
        realiziranaLastnaVrednost: realiziranaLastnaVrednost,
        realiziranaVrednostViri: realiziranaVrednostViri,
        realiziranaObseg: realiziranaObseg,
      });
    }
  };

  /* REALIZIRANE VREDNOSTI PROJEKTA - Nastavljanje stanja podatkov za urejanje prikaza realiziranih vrednosti članic projekta*/
  const setStateRealiziraneVrednostiClanicePrikaz = (
    clanicaID,
    realiziranaVrednost,
    realiziranaLastnaVrednost,
    realiziranaVrednostViri,
    realiziranaObseg,
    polje
  ) => {
    if (polje === "realiziranaVrednost") {
      setRealiziraneVrednostiClanicePrikaz({
        ...realiziraneVrednostiClanicePrikaz,
        realiziranaVrednost: realiziranaVrednost,
      });
    } else if (polje === "realiziranaLastnaVrednost") {
      setRealiziraneVrednostiClanicePrikaz({
        ...realiziraneVrednostiClanicePrikaz,
        realiziranaLastnaVrednost: realiziranaLastnaVrednost,
      });
    } else if (polje === "realiziranaVrednostViri") {
      setRealiziraneVrednostiClanicePrikaz({
        ...realiziraneVrednostiClanicePrikaz,
        realiziranaVrednostViri: realiziranaVrednostViri,
      });
    } else {
      setRealiziraneVrednostiClanicePrikaz({
        clanicaID: clanicaID,
        realiziranaVrednost: realiziranaVrednost,
        realiziranaLastnaVrednost: realiziranaLastnaVrednost,
        realiziranaVrednostViri: realiziranaVrednostViri,
        realiziranaObseg: realiziranaObseg,
      });
    }
  };
  /* ------------------------------------------------------------------------------*/

  /* --------------- TAB FINANČNI PODATKI -> STROŠKOVNA MESTA ---------------------*/
  /* STROŠKOVNA MESTA -  dodajanje stroškovnih mest in stroškovnih nosilcev */
  const handleAddStroskovnoMestoAliNosilec = (e) => {
    e.preventDefault();

    fetchPOST(
      `${vnosStroskovnegaMestaClanice}/${id}/stroskovna-mesta`,
      addStroskovnoMesto
    ).then((addStroskovno) => {
      if (addStroskovno === 1) {
        fetchGET(
          `${seznamClanicSStroskovnimiMesti}/${id}/stroskovna-mesta`
        ).then((stroskovnaMesta) => {
          if (stroskovnaMesta !== 0) {
            setStroskovnaMestaClanic(stroskovnaMesta);
          } else {
          }
        });
        setShowAddStroskovnoMesto(false);
      } else {
        setShowAddStroskovnoMestoError(true);
        setTimeout(() => {
          setShowAddStroskovnoMestoError(false);
        }, 3000);
      }
    });
  };

  /* STROŠKOVNA MESTA - urejanje stroškovnih mest in stroškovnih nosilcev */
  const handleEditStroskovnoMestoAliNosilec = (e) => {
    e.preventDefault();

    fetchPUT(
      `${urejanjeStroskovnihMestClanice}/${id}/stroskovna-mesta`,
      editStroskovnoMesto
    ).then((stroskovnoMesto) => {
      if (stroskovnoMesto === 1) {
        fetchGET(
          `${seznamClanicSStroskovnimiMesti}/${id}/stroskovna-mesta`
        ).then((stroskovnaMesta) => {
          if (stroskovnaMesta !== 0) {
            setStroskovnaMestaClanic(stroskovnaMesta);
          } else {
          }
        });
        setShowEditStroskovnoMesto(false);
      } else {
        setShowAddStroskovnoMestoError(true);
        setTimeout(() => {
          setShowAddStroskovnoMestoError(false);
        }, 3000);
      }
    });
  };

  /* STROŠKOVNA MESTA - odstranjevanje stroškovnega mesta članici */
  const handleDeleteStroskovno = () => {
    fetchDELETE(
      `${odstranjevanjeStroskovnegaMestaClanice}/${id}/stroskovna-mesta/${clanicaStroskovnoMestoNosilecId}`
    ).then((deleted) => {
      if (deleted === 1) {
        fetchGET(
          `${seznamClanicSStroskovnimiMesti}/${id}/stroskovna-mesta`
        ).then((stroskovnaMesta) => {
          if (stroskovnaMesta !== 0) {
            setStroskovnaMestaClanic(stroskovnaMesta);
          } else {
          }
        });
      } else {
        //TODO: prišlo je do napake pri brisanju podatkov.
      }
    });
  };

  /* STROŠKOVNA MESTA - nastavljanje prikaza okenca za dodajanje stroškovnegaMesta */
  const setStateShowAddStroskovnoMesto = (id, button) => {
    if (button === "preklici") {
      setShowAddStroskovnoMesto(false);
    } else {
      setShowAddStroskovnoMesto({
        [id]: true,
      });
    }
  };

  /* STROŠKOVNA MESTA - nastavljanje stanja podatkov za dodajanje novega stroškovnega mesta */
  const setStateAddStroskovnoMesto = (
    clanicaID,
    stroskovnoMesto,
    stroskovniNosilec,
    polje
  ) => {
    if (polje === "clanicaID") {
      setAddstroskovnoMesto({
        clanicaID: clanicaID,
        stroskovnoMesto: "",
        stroskovniNosilec: "",
      });
    } else if (polje === "stroskovnoMesto") {
      setAddstroskovnoMesto({
        ...addStroskovnoMesto,
        stroskovnoMesto: stroskovnoMesto,
      });
    } else if (polje === "stroskovniNosilec") {
      setAddstroskovnoMesto({
        ...addStroskovnoMesto,
        stroskovniNosilec: stroskovniNosilec,
      });
    } else {
      setAddstroskovnoMesto({
        clanicaID: "",
        stroskovnoMesto: "",
        stroskovniNosilec: "",
      });
    }
  };

  /* STROŠKOVNA MESTA - nastavlajnje prikaza okenca za urejanje stroškovnega mesta */
  const setStateShowEditStroskovnoMesto = (id, button) => {
    if (button === "preklici") {
      setShowEditStroskovnoMesto(false);
    } else {
      setShowEditStroskovnoMesto({
        [id]: true,
      });
    }
  };

  /* STROŠKOVNA MESTA - nastavljanje id stroškovnega mesta za izbris */
  const setStateClanicaStroskovnoMestoNosilecId = (id) => {
    setClanicaStroskovnoMestoNosilecId(id);
  };

  /* STROŠKOVNA MESTA - nastavljanje stanja podatkov za urejanje stroškovnega mesta */
  const setStateEditStroskovnoMesto = (
    id,
    clanicaID,
    stroskovnoMesto,
    stroskovniNosilec,
    polje
  ) => {
    if (polje === "stroskovnoMesto") {
      setEditStroskovnoMesto({
        ...editStroskovnoMesto,
        stroskovnoMesto: stroskovnoMesto,
      });
    } else if (polje === "stroskovniNosilec") {
      setEditStroskovnoMesto({
        ...editStroskovnoMesto,
        stroskovniNosilec: stroskovniNosilec,
      });
    } else if (polje === "vsaPolja") {
      setEditStroskovnoMesto({
        id: id,
        clanicaID: clanicaID,
        stroskovnoMesto: stroskovnoMesto,
        stroskovniNosilec: stroskovniNosilec,
      });
    } else {
      setEditStroskovnoMesto({
        id: "",
        clanicaID: "",
        stroskovnoMesto: "",
        stroskovniNosilec: "",
      });
    }
  };

  /* STATUS - nastavljanje stanja podatkov za projekt*/
  const changeProjectStatus = (projektID, statusID) => {
    fetchPUT(
      `${urejanjeStatusaProjekta}/${projektID}/status/${statusID}`,
      editStroskovnoMesto
    ).then((status) => {
      if (status === 1) {
        fetchGET(`${osnovniPodatkiZaProjekt}/${id}`).then((projectOsnovni) => {
          if (projectOsnovni !== 0) {
            setProjectOsnovni((prevState) => ({
              ...prevState,
              status: projectOsnovni.status,
              statusID: projectOsnovni.statusID,
            }));
          } else {
            //TODO:
            /*setInvalidPartnerId(true); */
          }
        });
      }
    });
  };
  /* ------------------------------------------------------------------------------*/

  /* Funkcija za prikaz valute € */
  const numberFormatValuta = (value) => {
    return (+value).toLocaleString("sl-SI", {
      style: "currency",
      currency: "EUR",
      minimumFractionDigits: 2,
    });
  };

  /* CUSTOM ICONICA ZA ISKALNIK pri kontaktnih osebah */
  const DropdownIndicator = (props) => {
    return (
      components.DropdownIndicator && (
        <components.DropdownIndicator {...props}>
          <BiSearch />
        </components.DropdownIndicator>
      )
    );
  };

  return (
    <Container fluid>
      {((role.skrbnik === true && role.clanicaID === projectSkrbnikClanica) ||
        (role.skrbnik === true &&
          jeClanicaProjekta === true &&
          role.clanicaID !== projectSkrbnikClanica) ||
        (role.skrbnik === true &&
          role.clanicaID !== projectSkrbnikClanica &&
          (projectOsnovni.statusID === 3 || projectOsnovni.statusID === 4)) ||
        (role.skrbnik === false && role.projekti === 2) ||
        (role.skrbnik === false && role.projekti === 1)) && (
        <>
          {/* TOOLTIPI */}
          <ReactTooltip id="tab-tooltip" style={{ zIndex: 1000 }} />

          {/* PROJEKT TITLE */}
          <Row>
            <Col className="main-header font-weight-bold">
              <AiOutlineInfoCircle className="me-2" />
              {projectOsnovni.naziv}
            </Col>
            <Col>
              <div className="float-end">
                <Link
                  className="programs-button-add-text ms-2"
                  to={`/Projekti`}
                >
                  <Button variant="primary" className="button-back-razpisi ">
                    <BsFillKanbanFill /> <span>Projekti</span>
                  </Button>
                </Link>
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="header-divider">
              <hr />
            </Col>
          </Row>

          {/*VRSTICA S STATUSOM PROJEKTA */}
          <UserContext.Provider value={role}>
            <StatusProjekta
              projectSkrbnikClanica={projectSkrbnikClanica}
              status={projectOsnovni.status}
              statusID={projectOsnovni.statusID}
              projektID={id}
              changeProjectStatus={changeProjectStatus}
            />
          </UserContext.Provider>

          {/*TABS */}
          <Row className="mt-3">
            <Col>
              <Tabs
                id="controlled-tab-example"
                activeKey={key}
                onSelect={(k) => setKey(k)}
              >
                {/* TAB Z OSNOVNIMI PODATKI (PODMODUL OsnovniPodatki.js */}
                <Tab eventKey="osnovni" title="Osnovni podatki">
                  <UserContext.Provider value={role}>
                    <OsnovniPodatki
                      projectOsnovni={projectOsnovni}
                      projectOsnovniProgramiStruktura={
                        projectOsnovniProgramiStruktura
                      }
                      projectOsnovniEdit={projectOsnovniEdit}
                      projectSkrbnikClanica={projectSkrbnikClanica}
                      vnosError={vnosError}
                      setStateOsnovniPodatkiDropDown={
                        setStateOsnovniPodatkiDropDown
                      }
                      handleSubmitForm={handleSubmitForm}
                      handleFormData={handleFormData}
                    />
                  </UserContext.Provider>
                </Tab>
                {/* TAB Z DODATNIMI PODATKI (PODMODUL DodatniPodatki.js */}
                <Tab eventKey="dodatni" title="Dodatni podatki">
                  <UserContext.Provider value={role}>
                    <DodatniPodatki
                      projectDodatni={projectDodatni}
                      projectDodatniEdit={projectDodatniEdit}
                      projectSkrbnikClanica={projectSkrbnikClanica}
                      jeClanicaProjekta={jeClanicaProjekta}
                      tooltipSifranti={tooltipSifranti}
                      showEditDodatni={showEditDodatni}
                      vnosError={vnosError}
                      setStateShowEdit={setStateShowEdit}
                      setStateDodatniPodatkiDropDown={
                        setStateDodatniPodatkiDropDown
                      }
                      handleFormDataDodatni={handleFormDataDodatni}
                      handleSubmitFormDodatni={handleSubmitFormDodatni}
                    />
                  </UserContext.Provider>
                </Tab>
                <Tab eventKey="projektni" title="Partnerske organizacije">
                  <Card className="mt-3">
                    <Card.Header>
                      <Row className="align-items-center">
                        <Col>
                          <h5 className="mb-0">Partnerske organizacije</h5>
                        </Col>
                      </Row>
                    </Card.Header>
                    <Card.Body>
                      <Tabs
                        defaultActiveKey="partnerji"
                        className="mt-1 pe-3 ps-3"
                      >
                        {/* TAB/PODTAB S PARTNERJI (PODMODUL Partnerji.js */}
                        <Tab eventKey="partnerji" title="Partnerji">
                          <UserContext.Provider value={role}>
                            <Partnerji
                              projectSkrbnikClanica={projectSkrbnikClanica}
                              dodajanjePartnerjaError={dodajanjePartnerjaError}
                              formSelectValidation={formSelectValidation}
                              sifrantVloge={sifrantVloge}
                              partnerDataPost={partnerDataPost}
                              showAddPartner={showAddPartner}
                              dodajanjeKontaktnihError={
                                dodajanjeKontaktnihError
                              }
                              partnersData={partnersData}
                              showEditPartnerKontakt={showEditPartnerKontakt}
                              editKontaktData={editKontaktData}
                              setStatePartnerDataPost={setStatePartnerDataPost}
                              setStateShowAddPartner={setStateShowAddPartner}
                              setStateEditKontaktData={setStateEditKontaktData}
                              setStateProjectPartnerId={
                                setStateProjectPartnerId
                              }
                              setStateShowEditPartnerKontakt={
                                setStateShowEditPartnerKontakt
                              }
                              handleDelete={handleDelete}
                              handleSubmitFormAddPartner={
                                handleSubmitFormAddPartner
                              }
                              handleSubmitFormPartnerKontakti={
                                handleSubmitFormPartnerKontakti
                              }
                            />
                          </UserContext.Provider>
                        </Tab>
                        {/* TAB/PODTAB S SODELUJOČIMI ČLANICAMI (PODMODUL SodelujoceClanice.js */}
                        <Tab
                          eventKey="sodelujoceClanice"
                          title="Sodelujoče članice UM"
                        >
                          <UserContext.Provider value={role}>
                            <SodelujoceClanice
                              projectSkrbnikClanica={projectSkrbnikClanica}
                              projectSodelujocaClanicaPost={
                                projectSodelujocaClanicaPost
                              }
                              projectSodelujocaClanicaEdit={
                                projectSodelujocaClanicaEdit
                              }
                              sifrantVloge={sifrantVloge}
                              kontaktneOsebeSeznam={kontaktneOsebeSeznam}
                              seznamSodelujocihClanic={seznamSodelujocihClanic}
                              kontaktnaOsebaPodrobnosti={
                                kontaktnaOsebaPodrobnosti
                              }
                              showErrorDodajanjeSodelujoceClanice={
                                showErrorDodajanjeSodelujoceClanice
                              }
                              showKontaktniPodatkiOsebaSodelujoceClanice={
                                showKontaktniPodatkiOsebaSodelujoceClanice
                              }
                              showAddSodelujocaClanica={
                                showAddSodelujocaClanica
                              }
                              showUrejanjeSodelujocaClanica={
                                showUrejanjeSodelujocaClanica
                              }
                              formSelectValidationSodelujocaClanica={
                                formSelectValidationSodelujocaClanica
                              }
                              dodajanjeKontaktnihError={
                                dodajanjeKontaktnihError
                              }
                              selectKontaktnaOsebaSodelujoceClanice={
                                selectKontaktnaOsebaSodelujoceClanice
                              }
                              handleSubmitFormAddSodelujocaClanica={
                                handleSubmitFormAddSodelujocaClanica
                              }
                              handleDeleteSodelujocaClanica={
                                handleDeleteSodelujocaClanica
                              }
                              handleSubmitFormUrediSodelujocaClanica={
                                handleSubmitFormUrediSodelujocaClanica
                              }
                              setStateShowKontaktniPodatkiOsebaSodelujoceClanice={
                                setStateShowKontaktniPodatkiOsebaSodelujoceClanice
                              }
                              setStateProjectSodelujocaClanicaPost={
                                setStateProjectSodelujocaClanicaPost
                              }
                              seStateShowAddSodelujocaClanica={
                                seStateShowAddSodelujocaClanica
                              }
                              setStateSodelujocaClanicaId={
                                setStateSodelujocaClanicaId
                              }
                              setStateShowUrejanjeSodelujocaClanica={
                                setStateShowUrejanjeSodelujocaClanica
                              }
                              setStateProjectSodelujocaClanicaEdit={
                                setStateProjectSodelujocaClanicaEdit
                              }
                              selectKontaktnaOsebaSodelujoceClaniceUrejanje={
                                selectKontaktnaOsebaSodelujoceClaniceUrejanje
                              }
                              removeKontaktnaOsebaFromSodelujocaClanica={
                                removeKontaktnaOsebaFromSodelujocaClanica
                              }
                              DropdownIndicator={DropdownIndicator}
                            />
                          </UserContext.Provider>
                        </Tab>
                      </Tabs>
                    </Card.Body>
                  </Card>
                </Tab>
                <Tab eventKey="financi" title="Finančni podatki">
                  <Card className="mt-3">
                    <Card.Header>
                      <Row className="align-items-center">
                        <Col>
                          <h5 className="mb-0">Finančni podatki</h5>
                        </Col>
                      </Row>
                    </Card.Header>
                    <Card.Body>
                      <Tabs
                        defaultActiveKey="nacrtovana"
                        className="mt-1 pe-3 ps-3"
                      >
                        {/* TAB/PODTAB z NAČRTOVANIMI VREDNOSTMI PROJEKTA (PODMODUL NacrtovanaVrednostProjekta.js */}
                        <Tab
                          eventKey="nacrtovana"
                          title="Načrtovana vrednost projekta"
                        >
                          <UserContext.Provider value={role}>
                            <NacrtovanaVrednostProjekta
                              projectSkrbnikClanica={projectSkrbnikClanica}
                              nacrtovaneVrednostiProjekta={
                                nacrtovaneVrednostiProjekta
                              }
                              showErrorDodajanjeUrejanjeNacrtovanihFinanc={
                                showErrorDodajanjeUrejanjeNacrtovanihFinanc
                              }
                              showNacrtovaneVrednostiProjekta={
                                showNacrtovaneVrednostiProjekta
                              }
                              tooltipSifranti={tooltipSifranti}
                              sestevekNacrtovanihVrednostiClanic={
                                sestevekNacrtovanihVrednostiClanic
                              }
                              nacrtovaneVrednostiProjektaPrikaz={
                                nacrtovaneVrednostiProjektaPrikaz
                              }
                              showUrejanjeDodajanjeFinancniClaniceNacrtovani={
                                showUrejanjeDodajanjeFinancniClaniceNacrtovani
                              }
                              nacrtovaneVrednostiClanice={
                                nacrtovaneVrednostiClanice
                              }
                              nacrtovaneVrednostiClaniceUrejanje={
                                nacrtovaneVrednostiClaniceUrejanje
                              }
                              nacrtovaneVrednostiClanicePrikaz={
                                nacrtovaneVrednostiClanicePrikaz
                              }
                              seStateShowNacrtovaneVrednostiProjekta={
                                seStateShowNacrtovaneVrednostiProjekta
                              }
                              setStateNacrtovanaVrednostProjektaUrejanje={
                                setStateNacrtovanaVrednostProjektaUrejanje
                              }
                              setStateShowUrejanjeDodajanjeFinancniClaniceNacrtovani={
                                setStateShowUrejanjeDodajanjeFinancniClaniceNacrtovani
                              }
                              setStateNacrtovaneVrednostiClaniceUrejanje={
                                setStateNacrtovaneVrednostiClaniceUrejanje
                              }
                              setStateNacrtovaneVrednostiClanicePrikaz={
                                setStateNacrtovaneVrednostiClanicePrikaz
                              }
                              numberFormatValuta={numberFormatValuta}
                              handleAddNacrtovaneVrednostiProjekta={
                                handleAddNacrtovaneVrednostiProjekta
                              }
                              handleAddNacrtovaneVrednostiClanice={
                                handleAddNacrtovaneVrednostiClanice
                              }
                              jeClanicaProjekta={jeClanicaProjekta}
                            />
                          </UserContext.Provider>
                        </Tab>
                        {/* TAB/PODTAB z REALIZIRANIMI VREDNOSTMI PROJEKTA (PODMODUL RealiziraneVrednostiProjekta.js */}
                        <Tab
                          eventKey="realizacija"
                          title="Realizacija ob zaključku projekta"
                        >
                          <UserContext.Provider value={role}>
                            <RealiziraneVrednostiProjekta
                              projectSkrbnikClanica={projectSkrbnikClanica}
                              tooltipSifranti={tooltipSifranti}
                              showErrorDodajanjeUrejanjeRealiziranihFinanc={
                                showErrorDodajanjeUrejanjeRealiziranihFinanc
                              }
                              showRealiziraneVrednostiProjekta={
                                showRealiziraneVrednostiProjekta
                              }
                              sestevekRealiziranihVrednostiClanice={
                                sestevekRealiziranihVrednostiClanice
                              }
                              realiziraneVrednostiProjekta={
                                realiziraneVrednostiProjekta
                              }
                              realiziraneVrednoProjektaUrejanje={
                                realiziraneVrednoProjektaUrejanje
                              }
                              realiziraneVrednoProjektaPrikaz={
                                realiziraneVrednoProjektaPrikaz
                              }
                              realiziraneVrednostiClanice={
                                realiziraneVrednostiClanice
                              }
                              realiziraneVrednostiClanicePrikaz={
                                realiziraneVrednostiClanicePrikaz
                              }
                              realiziraneVrednostiClaniceUrejanje={
                                realiziraneVrednostiClaniceUrejanje
                              }
                              showUrejanjeDodajanjeFinancniClaniceRealizirano={
                                showUrejanjeDodajanjeFinancniClaniceRealizirano
                              }
                              numberFormatValuta={numberFormatValuta}
                              setStateShowRealiziraneVrednostiProjekta={
                                setStateShowRealiziraneVrednostiProjekta
                              }
                              setStateRealiziranaVrednostProjektaUrejanje={
                                setStateRealiziranaVrednostProjektaUrejanje
                              }
                              setStateShowUrejanjeDodajanjeFinancniClaniceRealizirano={
                                setStateShowUrejanjeDodajanjeFinancniClaniceRealizirano
                              }
                              setStateRealiziraneVrednostiClaniceUrejanje={
                                setStateRealiziraneVrednostiClaniceUrejanje
                              }
                              setStateRealiziraneVrednostiClanicePrikaz={
                                setStateRealiziraneVrednostiClanicePrikaz
                              }
                              handleAddRealiziraneVrednostiProjekta={
                                handleAddRealiziraneVrednostiProjekta
                              }
                              handleAddRealiziraneVrednostiClanic={
                                handleAddRealiziraneVrednostiClanic
                              }
                              jeClanicaProjekta={jeClanicaProjekta}
                            />
                          </UserContext.Provider>
                        </Tab>
                        {/* TAB/PODTAB s STROŠKOVNIMI MESTI PROJEKTA (PODMODUL StroskovnaMesta.js */}
                        {((role.skrbnik === true &&
                          role.clanicaID === projectSkrbnikClanica) ||
                          (role.skrbnik === true &&
                            jeClanicaProjekta === true &&
                            role.clanicaID !== projectSkrbnikClanica) ||
                          (role.skrbnik === false && role.projekti === 2)) && (
                          <Tab eventKey="strokovna" title="Stroškovna mesta">
                            <UserContext.Provider value={role}>
                              <StroskovnaMesta
                                showAddStroskovnoMestoError={
                                  showAddStroskovnoMestoError
                                }
                                stroskovnaMestaClanic={stroskovnaMestaClanic}
                                showAddStroskovnoMesto={showAddStroskovnoMesto}
                                showEditStroskovnoMesto={
                                  showEditStroskovnoMesto
                                }
                                editStroskovnoMesto={editStroskovnoMesto}
                                handleAddStroskovnoMestoAliNosilec={
                                  handleAddStroskovnoMestoAliNosilec
                                }
                                handleEditStroskovnoMestoAliNosilec={
                                  handleEditStroskovnoMestoAliNosilec
                                }
                                handleDeleteStroskovno={handleDeleteStroskovno}
                                setStateShowAddStroskovnoMesto={
                                  setStateShowAddStroskovnoMesto
                                }
                                setStateShowEditStroskovnoMesto={
                                  setStateShowEditStroskovnoMesto
                                }
                                setStateAddStroskovnoMesto={
                                  setStateAddStroskovnoMesto
                                }
                                setStateClanicaStroskovnoMestoNosilecId={
                                  setStateClanicaStroskovnoMestoNosilecId
                                }
                                setStateEditStroskovnoMesto={
                                  setStateEditStroskovnoMesto
                                }
                                projectSkrbnikClanica={projectSkrbnikClanica}
                              />
                            </UserContext.Provider>
                          </Tab>
                        )}
                      </Tabs>
                    </Card.Body>
                  </Card>
                </Tab>
                {/* TAB s Sodelujocimi osebami projekta (PODMODUL SodelujoceOsebe.js */}
                {((role.skrbnik === true &&
                  role.clanicaID === projectSkrbnikClanica) ||
                  (role.skrbnik === true &&
                    jeClanicaProjekta === true &&
                    role.clanicaID !== projectSkrbnikClanica) ||
                  (role.skrbnik === false && role.projekti === 2) ||
                  (role.skrbnik === false && role.projekti === 1)) && (
                  <Tab eventKey="kontaktne" title="Sodelujoče osebe">
                    <UserContext.Provider value={role}>
                      <SodelujoceOsebe
                        projectSkrbnikClanica={projectSkrbnikClanica}
                        projectOsnovni={projectOsnovni}
                        kontaktneOsebeSeznam={kontaktneOsebeSeznam}
                        kontaktnaOsebaPodrobnosti={kontaktnaOsebaPodrobnosti}
                        showErrorDodajanjeKontaktneOsebe={
                          showErrorDodajanjeKontaktneOsebe
                        }
                        showPodatkiZaNajdenoOsebo={showPodatkiZaNajdenoOsebo}
                        showEditKontaktnaOsebaDatum={
                          showEditKontaktnaOsebaDatum
                        }
                        seznamKontaktnihOseb={seznamKontaktnihOseb}
                        dodajanjeKontaktnihError={dodajanjeKontaktnihError}
                        setStateShowPodatkiZaNajdenoOsebo={
                          setStateShowPodatkiZaNajdenoOsebo
                        }
                        selectKontaktnaOseba={selectKontaktnaOseba}
                        setStateSodelujoceOsebeDropDown={
                          setStateSodelujoceOsebeDropDown
                        }
                        setStateShowEditKontaktnaOsebaDatum={
                          setStateShowEditKontaktnaOsebaDatum
                        }
                        setStateEditKontaktnaOsebaDatum={
                          setStateEditKontaktnaOsebaDatum
                        }
                        setStateKontaktnaOsebaId={setStateKontaktnaOsebaId}
                        handleAddKontaktnaOseba={handleAddKontaktnaOseba}
                        handleSubmitFormUrediKontaktDatum={
                          handleSubmitFormUrediKontaktDatum
                        }
                        handleDeleteKontaktnaOseba={handleDeleteKontaktnaOseba}
                        DropdownIndicator={DropdownIndicator}
                      />
                    </UserContext.Provider>
                  </Tab>
                )}
              </Tabs>
            </Col>
          </Row>
        </>
      )}
    </Container>
  );
};

export default ProjectDetails;
