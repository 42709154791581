import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Select from "react-select";

/* React Bootstrap */
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

/*React Icons */
import {
  AiOutlineEdit,
  AiOutlineInfoCircle,
  AiOutlineLink,
} from "react-icons/ai";
import { BsGrid3X3GapFill } from "react-icons/bs";
import { BiDetail, BiTrash } from "react-icons/bi";
import Modal from "react-bootstrap/Modal";

/* Import API */
import { fetchDELETE, fetchGET, fetchPUT } from "../apiHelper";
import {
  brisanjeNoveOrgEnote,
  podatkiZaPartnerja,
  urejanjePartnerja,
  drzave,
} from "../listOfApi";

const ProjectPartnerDetails = ({ role }) => {
  /*Pridobljen parameter iz ID */
  const { id } = useParams();

  const [showOrgUnit, setShowOrgUnit] = useState(false);
  const [partnerDetails, setPartnerDetails] = useState({});
  const [partnerDetailsEdit, setPartnerDetailsEdit] = useState({
    davcna: "",
    nazivSlo: "",
    nazivEng: "",
    naslov: "",
    postnaStevilka: "",
    posta: "",
    drzavaID: "",
    spletnaStran: "",
    opomba: "",
  });
  const [invalidPartnerId, setInvalidPartnerId] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showEditDetails, setShowEditDetails] = useState(false);
  const [enotaName, setEnotaName] = useState("");
  const [enotaId, setEnotaId] = useState("");
  const [seznamDrzav, setSeznamDrzav] = useState([]);
  const [vnosError, setVnosError] = useState(false);

  useEffect(() => {
    fetchPartnersData();
  }, [id]);
  /********** PRIDOBIVANJE ŠIFRANTOV *************/
  useEffect(() => {
    fetchGET(drzave).then((drzaveSeznam) => {
      if (drzaveSeznam !== 0) {
        let drzave = drzaveSeznam.map((drzave) => {
          return {
            label: drzave.naziv,
            value: drzave.id,
          };
        });
        setSeznamDrzav(drzave);
      }
    });
  }, []);

  const fetchPartnersData = () => {
    fetchGET(`${podatkiZaPartnerja}/${id}`).then((partner) => {
      if (partner !== 0) {
        setPartnerDetails(partner);

        setPartnerDetailsEdit({
          id: parseInt(id),
          davcna: partner.davcna,
          nazivSlo: partner.nazivSlo,
          nazivEng: partner.nazivEng,
          naslov: partner.naslov,
          postnaStevilka: partner.postnaStevilka,
          posta: partner.posta,
          drzavaID: partner.drzavaID,
          spletnaStran: partner.spletnaStran,
          opomba: partner.opomba,
        });
      } else {
        setInvalidPartnerId(true);
      }
    });
  };

  const handleDelete = () => {
    fetchDELETE(`${brisanjeNoveOrgEnote}/${enotaId}`).then((deleted) => {
      if (deleted === 1) {
        setShowModal(false);
        window.location.reload();
      } else {
        //TODO: prišlo je do napake pri brisanju podatkov.
      }
    });
  };

  const handleStructure = (e) => {
    const id = e.currentTarget.id;
    const idNumber = Object.keys(showOrgUnit);
    const getID = idNumber.filter((idKey) => idKey === id);

    let value;
    for (let key in showOrgUnit) {
      if (showOrgUnit.hasOwnProperty(key)) {
        if (key === getID[0]) {
          value = showOrgUnit[key];
        }
      }
    }

    if (id === getID[0]) {
      if (value === false) {
        setShowOrgUnit((showOrgUnit) => ({
          ...showOrgUnit,
          [id]: true,
        }));
      } else {
        setShowOrgUnit((showOrgUnit) => ({
          ...showOrgUnit,
          [id]: false,
        }));
      }
    } else {
      setShowOrgUnit((showOrgUnit) => ({
        ...showOrgUnit,
        [id]: true,
      }));
    }
  };

  const handleFormData = (e) => {
    setPartnerDetailsEdit({
      ...partnerDetailsEdit,
      [e.target.name]: e.target.value,
    });
  };

  /* Funkcija za shranjevanje podatkov na backend */
  const handleSubmitForm = (e) => {
    e.preventDefault();
    fetchPUT(urejanjePartnerja, partnerDetailsEdit).then((partner) => {
      if (partner === 1) {
        fetchPartnersData();
        setShowEditDetails(false);
      } else {
        setVnosError(true);
        setTimeout(() => {
          setVnosError(false);
        }, 5000);
      }
    });
  };

  return (
    <Container fluid>
      {invalidPartnerId && <h4 className="mt-3">Partner ne obstaja!</h4>}
      {!invalidPartnerId && (
        <>
          <Modal
            show={showModal}
            onHide={() => {
              setShowModal(false);
            }}
          >
            <Modal.Body>
              Želite izbrisati projektnega partnerja:{" "}
              <strong>{enotaName}</strong> ?
            </Modal.Body>
            <Modal.Footer>
              <Button
                className="btn btn-danger"
                variant="secondary"
                onClick={handleDelete}
              >
                Izbriši
              </Button>
              <Button
                variant="primary"
                onClick={() => {
                  setShowModal(false);
                }}
              >
                Ne
              </Button>
            </Modal.Footer>
          </Modal>
          <Row>
            <Col className="main-header font-weight-bold">
              <AiOutlineInfoCircle className="me-2" />
              {partnerDetails.nazivSlo}
            </Col>
            <Col>
              <div className="float-end">
                <Link
                  className="programs-button-add-text ml-2"
                  to={`/ProjektniPartnerji`}
                >
                  <Button variant="primary" className="button-back-razpisi ">
                    <BsGrid3X3GapFill /> <span>Projektni partnerji</span>
                  </Button>
                </Link>
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="header-divider">
              <hr />
            </Col>
          </Row>

          <Row className="project-partners-details-card">
            <Col>
              <Card className="razpis-card">
                <Row>
                  <Col className="text-end">
                    {role.razpisi === 2 && (
                      <div className="p-2">
                        <Button
                          className="org-button-details-text"
                          id="1"
                          onClick={() => {
                            setShowEditDetails(!showEditDetails);
                          }}
                        >
                          Uredi <AiOutlineEdit />
                        </Button>
                      </div>
                    )}
                  </Col>
                </Row>
                {!showEditDetails && (
                  <Card.Body>
                    <Row>
                      <Col>
                        <Row>
                          <Col className="ms-3 razpisDetails-header font-weight-bold">
                            Osnovni podatki
                          </Col>
                        </Row>
                        <div className="p-4">
                          <Row>
                            <Col className="mb-3">
                              <span>Uradni naziv: </span>
                              {partnerDetails.nazivSlo}
                            </Col>
                          </Row>

                          <Row>
                            <Col className="mb-3">
                              <span>Uradni naziv - angleški: </span>
                              {partnerDetails.nazivEng}
                            </Col>
                          </Row>
                          <Row className="mb-3">
                            <Col>
                              <span>Davčna številka:</span>{" "}
                              {partnerDetails.davcna}
                            </Col>
                          </Row>
                        </div>
                      </Col>
                      <Col>
                        <Row>
                          <Col className="ms-3 razpisDetails-header font-weight-bold">
                            Naslov
                          </Col>
                        </Row>
                        <div className="p-4">
                          <Row>
                            <Col className="mb-3">{partnerDetails.naslov}</Col>
                          </Row>
                          <Row>
                            <Col className="mb-3">
                              {partnerDetails.postnaStevilka}{" "}
                              {partnerDetails.posta}
                            </Col>
                          </Row>
                          <Row>
                            <Col className="mb-3">{partnerDetails.drzava}</Col>
                          </Row>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Row>
                          <Col className="ms-3 razpisDetails-header font-weight-bold">
                            Dodatno
                          </Col>
                        </Row>
                        <div className="p-4">
                          <Row>
                            <Col className="mb-3">
                              <span>Spletna stran:</span>{" "}
                              {partnerDetails.spletnaStran}
                            </Col>
                          </Row>
                          <Row>
                            <Col className="mb-3">
                              <span>Opomba:</span> {partnerDetails.opomba}
                            </Col>
                          </Row>
                          {partnerDetails.sifra !== null && (
                            <Row>
                              <Col className="mb-3">
                                <span>Šifra:</span> {partnerDetails.sifra}
                              </Col>
                            </Row>
                          )}
                        </div>
                      </Col>
                    </Row>
                  </Card.Body>
                )}
                {showEditDetails && (
                  <Form onSubmit={handleSubmitForm}>
                    <Card.Body>
                      <Row>
                        <Col>
                          <Row>
                            <Col className="ms-3 razpisDetails-header font-weight-bold">
                              Osnovni podatki
                            </Col>
                          </Row>
                          <div className="p-4">
                            <Row>
                              <Col className="mb-3">
                                <Form.Label>
                                  Naziv partnerja - uradni{" "}
                                </Form.Label>
                                <Form.Control
                                  name="nazivSlo"
                                  type="text"
                                  required
                                  minLength="3"
                                  maxLength="100"
                                  placeholder="Naziv partnerja - uradni"
                                  value={partnerDetailsEdit.nazivSlo}
                                  onChange={handleFormData}
                                />
                              </Col>
                            </Row>

                            <Row>
                              <Col className="mb-3">
                                <Form.Label>
                                  Naziv partnerja - angleški
                                </Form.Label>
                                <Form.Control
                                  name="nazivEng"
                                  type="text"
                                  maxLength="100"
                                  placeholder="Naziv partnerja - angleški"
                                  value={partnerDetailsEdit.nazivEng}
                                  onChange={handleFormData}
                                />
                              </Col>
                            </Row>
                            <Row className="mb-3">
                              <Col>
                                <Form.Label>Davčna številka</Form.Label>
                                <Form.Control
                                  name="davcna"
                                  type="text"
                                  maxLength="100"
                                  placeholder="Davčna številka"
                                  value={partnerDetailsEdit.davcna}
                                  onChange={handleFormData}
                                />
                              </Col>
                            </Row>
                          </div>
                        </Col>
                        <Col>
                          <Row>
                            <Col className="ms-3 razpisDetails-header font-weight-bold">
                              Naslov
                            </Col>
                          </Row>
                          <div className="p-4">
                            <Row>
                              <Col className="mb-3">
                                <Form.Label>Naslov </Form.Label>
                                <Form.Control
                                  name="naslov"
                                  type="text"
                                  required
                                  minLength="3"
                                  maxLength="100"
                                  placeholder="Naslov"
                                  value={partnerDetailsEdit.naslov}
                                  onChange={handleFormData}
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col className="mb-3">
                                <Form.Label>Poštna številka </Form.Label>
                                <Form.Control
                                  name="postnaStevilka"
                                  type="text"
                                  required
                                  minLength="3"
                                  maxLength="100"
                                  placeholder="Poštna številka"
                                  value={partnerDetailsEdit.postnaStevilka}
                                  onChange={handleFormData}
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col className="mb-3">
                                <Form.Label>Kraj </Form.Label>
                                <Form.Control
                                  name="posta"
                                  type="text"
                                  required
                                  minLength="3"
                                  maxLength="100"
                                  placeholder="Kraj"
                                  value={partnerDetailsEdit.posta}
                                  onChange={handleFormData}
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <Form.Label>Država </Form.Label>
                                <Select
                                  onChange={(value) =>
                                    setPartnerDetailsEdit({
                                      ...partnerDetailsEdit,
                                      drzavaID: value.value,
                                    })
                                  }
                                  value={
                                    seznamDrzav[
                                      seznamDrzav.findIndex(
                                        (x) =>
                                          x.value ===
                                          partnerDetailsEdit.drzavaID
                                      )
                                    ]
                                  }
                                  menuPortalTarget={document.body}
                                  styles={{
                                    menuPortal: (base) => ({
                                      ...base,
                                      zIndex: 9999,
                                    }),
                                  }}
                                  options={seznamDrzav}
                                  placeholder="Države"
                                  isSearchable
                                />
                              </Col>
                            </Row>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Row>
                            <Col className="ms-3 razpisDetails-header font-weight-bold">
                              Dodatno
                            </Col>
                          </Row>
                          <div className="p-4">
                            <Row>
                              <Col>
                                <Form.Label>Spletna stran </Form.Label>
                                <Form.Control
                                  name="spletnaStran"
                                  type="text"
                                  minLength="3"
                                  maxLength="100"
                                  placeholder="Spletna stran"
                                  value={partnerDetailsEdit.spletnaStran}
                                  onChange={handleFormData}
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col className="mb-3">
                                <Form.Label>Opomba </Form.Label>
                                <Form.Control
                                  name="opomba"
                                  type="text"
                                  as="textarea"
                                  maxLength="500"
                                  placeholder="Opomba"
                                  value={partnerDetailsEdit.opomba}
                                  onChange={handleFormData}
                                />
                              </Col>
                            </Row>
                            {partnerDetails.sifra !== null && (
                              <Row>
                                <Col className="mb-3">
                                  <span>Šifra:</span> {partnerDetails.sifra}
                                </Col>
                              </Row>
                            )}
                            <Row>
                              {vnosError && (
                                <Col>
                                  <h2 className="text-danger">
                                    Vnos projektnega partnerja ni uspel
                                  </h2>
                                </Col>
                              )}
                            </Row>
                            <Row>
                              <div className="ps-2 pe-4 pt-1">
                                <Col>
                                  <Button
                                    variant="primary"
                                    type="submit"
                                    className="mt-4 me-2"
                                  >
                                    Shrani
                                  </Button>
                                  <Button
                                    className="mt-4"
                                    variant="outline-primary"
                                    onClick={() => setShowEditDetails(false)}
                                  >
                                    Prekliči
                                  </Button>
                                </Col>
                              </div>
                            </Row>
                          </div>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Form>
                )}
              </Card>
            </Col>
          </Row>

          <Row className={"mt-3 project-partners-details-card"}>
            <Col>
              <Card className="razpis-card">
                <Card.Body>
                  <Row className="align-items-center">
                    <Col className="ms-3 razpisDetails-header font-weight-bold mt-0">
                      Organizacijske enote
                    </Col>
                    <Col>
                      <div className="float-end">
                        {role.partnerji === 2 && (
                          <Link
                            className="programs-button-add-text "
                            to={`/ProjektniPartnerji/Enota/Dodaj/${partnerDetails.id}`}
                          >
                            <Button variant="outline-primary border-0">
                              Dodaj organizacijsko enoto
                            </Button>
                          </Link>
                        )}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="ps-4 pe-4 mt-4">
                        {partnerDetails.enote?.map((enota, index) => {
                          return (
                            <Card className="mt-3" key={index}>
                              <Card.Body>
                                <Row className="align-items-center">
                                  <Col lg={8}>
                                    <h5
                                      className="mb-0"
                                      id={enota.id}
                                      role="button"
                                      onClick={handleStructure}
                                    >
                                      {enota.nazivSlo}
                                    </h5>
                                  </Col>
                                  <Col>
                                    <div className="float-end">
                                      <Button
                                        className="org-button-details-text me-2"
                                        id={enota.id}
                                        onClick={handleStructure}
                                      >
                                        <BiDetail />
                                      </Button>
                                      <Link
                                        className="razpisi-button-details-text me-3"
                                        to={{ pathname: enota.spletnaStran }}
                                        target="_blank"
                                      >
                                        <AiOutlineLink />
                                      </Link>

                                      {role.partnerji === 2 && (
                                        <>
                                          <Link
                                            className="razpisi-button-details-text"
                                            to={`/ProjektniPartnerji/Enota/Uredi/${enota.id}`}
                                          >
                                            <AiOutlineEdit className="me-1" />
                                          </Link>
                                          <Button
                                            className="avtorizacije-button-trash"
                                            onClick={() => {
                                              setShowModal(true);
                                              setEnotaId(enota.id);
                                              setEnotaName(enota.nazivSlo);
                                            }}
                                          >
                                            <BiTrash className="text-danger" />
                                          </Button>
                                        </>
                                      )}
                                    </div>
                                  </Col>
                                </Row>
                                {showOrgUnit[enota.id] && (
                                  <>
                                    <Row className="mt-4 ps-4">
                                      <Col>
                                        <Row>
                                          <Col className="ms-3 razpisDetails-header font-weight-bold">
                                            Osnovni podatki
                                          </Col>
                                        </Row>
                                        <div className="p-4">
                                          <Row>
                                            <Col className="mb-3">
                                              <span>Uradni naziv: </span>
                                              {enota.nazivSlo}
                                            </Col>
                                          </Row>

                                          <Row>
                                            <Col className="mb-3">
                                              <span>
                                                Uradni naziv - angleški:{" "}
                                              </span>{" "}
                                              {enota.nazivEng}
                                            </Col>
                                          </Row>
                                          <Row className="mb-3">
                                            <Col>
                                              <span>Davčna številka:</span>{" "}
                                              {enota.davcna}
                                            </Col>
                                          </Row>
                                        </div>
                                      </Col>
                                      <Col>
                                        <Row>
                                          <Col className="ms-3 razpisDetails-header font-weight-bold">
                                            Naslov
                                          </Col>
                                        </Row>
                                        <div className="p-4">
                                          <Row>
                                            <Col className="mb-3">
                                              {enota.naslov}
                                            </Col>
                                          </Row>
                                          <Row>
                                            <Col className="mb-3">
                                              {enota.postnaStevilka}{" "}
                                              {enota.posta}
                                            </Col>
                                          </Row>
                                          <Row>
                                            <Col className="mb-3">
                                              {enota.drzava}
                                            </Col>
                                          </Row>
                                        </div>
                                      </Col>
                                    </Row>
                                    <Row className="ps-4">
                                      <Col>
                                        <Row>
                                          <Col className="ms-3 razpisDetails-header font-weight-bold">
                                            Dodatno
                                          </Col>
                                        </Row>
                                        <div className="p-4">
                                          <Row>
                                            <Col className="mb-3">
                                              <span>Spletna stran:</span>{" "}
                                              {enota.spletnaStran}
                                            </Col>
                                          </Row>
                                          <Row>
                                            <Col className="mb-3">
                                              <span>Opomba:</span>{" "}
                                              {enota.opomba}
                                            </Col>
                                          </Row>
                                          {enota.sifra !== null && (
                                            <Row>
                                              <Col className="mb-3">
                                                <span>Šifra:</span>{" "}
                                                {enota.sifra}
                                              </Col>
                                            </Row>
                                          )}
                                        </div>
                                      </Col>
                                    </Row>{" "}
                                  </>
                                )}
                              </Card.Body>
                            </Card>
                          );
                        })}
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </>
      )}
    </Container>
  );
};

export default ProjectPartnerDetails;
