import React, {useState, useEffect, useContext} from 'react';
import SelectSearch from "react-select-search";

/*Import React Bootstrap */
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";

import PropTypes from 'prop-types';

/* Import React Icons */
import {AiOutlineEdit} from "react-icons/ai";
import {BiTrash} from "react-icons/bi";


/* Import API */
import {
    financiranjeKategorije,
    financiranjeViri,
    vnosNovegaFinancnegaVira,
    izbrisFinancnegaVira,
    urejanjeFinancnegaVira
} from "../../listOfApi";
import {fetchDELETE, fetchGET, fetchPOST, fetchPUT} from "../../apiHelper";


import {UserContext} from "../UserContext";

const FinancniViri = ({financialResources, financiranjeKategorija, id, getRazpisData}) => {
    const [showAddFinancniVir, setShowAddFinancniVir] = useState(false);
    const [financiranjeViriOptions, setFinanciranjeViriOptions] = useState([]);
    const [financiranjeKategorijeOptions, setFinanciranjeKategorijeOptions] = useState([]);
    const [validationError, setValidationError] = useState(false);
    const [postError, setPostError] = useState(false);
    const [deleteError, setDeleteError] = useState(false);
    const [putError, setPutError] = useState(false);
    const [delezError, setDelezError] = useState(false)
    const [financeViriPostData, setFinanceViriPostData] = useState({
        id_vir_financiranja: 0,
        delez: 0
    })
    const [financiranjeViriPutData, setFinanciranjeViriPutData] = useState({
        delez: 0
    })
    const [modalDeleteFinancniVir, setModalDeleteFinancniVir] = useState({
        id: null,
        naziv: ''
    })
    const [showModalDelete, setShowModalDelete] = useState(false);
    const [showEditFinancniVir, setShowEditFinancniVir] = useState(false)
    const [delezSUM, setDelezSUM] = useState(0);

    const role = useContext(UserContext);


    /*Pridobivanje finančnih virov */
    useEffect(() => {
        if (showAddFinancniVir || showEditFinancniVir) {
            fetchGET(`${financiranjeViri}/${financiranjeKategorija}`).then((finanViri) => {
                const financiranjeViriOptions = finanViri.map((viri, index) => {
                    return {name: viri.naziv, value: viri.id}
                })
                setFinanciranjeViriOptions(financiranjeViriOptions)
            })

            fetchGET(financiranjeKategorije).then((finanKategorije) => {
                const financiranjeKategorijeOptions = finanKategorije.map((kategorija, index) => {
                    return {name: kategorija.naziv, value: kategorija.id}
                })
                setFinanciranjeKategorijeOptions(financiranjeKategorijeOptions)
            })
        }
    }, [showAddFinancniVir, showEditFinancniVir])


    useEffect(()=> {
        if(financialResources.length > 0){
            const sestevekDelezi = financialResources.reduce((skupnaVrednost, vrednost) => skupnaVrednost + vrednost.delez, 0)
            setDelezSUM(sestevekDelezi);

            if(sestevekDelezi !== 100){
                setDelezError(true);
            }else{
                setDelezError(false);
            }
        }else{
            setDelezError(false);
        }
    },[financialResources])

    /* Shranjevanje finančnega vira */
    const handleFormAddFinancniVir = (e) => {
        e.preventDefault();

        if (financeViriPostData.id_vir_financiranja !== 0) {
            fetchPOST(`${vnosNovegaFinancnegaVira}/${id}/financiranje`, financeViriPostData).then((finance) => {
                if (finance === 1) {
                    setShowAddFinancniVir(false)
                    setFinanceViriPostData({...financeViriPostData, id_vir_financiranja: 0, delez: 0})
                    /*Callback funkcija s parent komponente - TenderDetails */
                    getRazpisData()
                } else {
                    setPostError(true);
                    setTimeout(() => setPostError(false), 3000)
                }
            });
        } else {
            setValidationError(true);
            setTimeout(() => {
                setValidationError(false);
            }, 3000)
        }
    }

    /* Odstranitev finančnega vira */
    const handleDeleteFinancniVir = () => {
        fetchDELETE(
            `${izbrisFinancnegaVira}/${id}/financiranje/${modalDeleteFinancniVir.id}`
        ).then((deleted) => {
            if (deleted === 1) {
                getRazpisData();
            } else {
                setDeleteError(true);
                setTimeout(() => setDeleteError(false), 3000)
            }
        });
        setShowModalDelete(false);
    }

    /* Nastavitev podatkov za odstranitev finančnega vira */
    const handleSetModalDataFinancniViri = (e) => {
        setModalDeleteFinancniVir({...modalDeleteFinancniVir, id: e.currentTarget.id, naziv: e.currentTarget.value})
        setShowModalDelete(true);
    }

    /* Handle prikaz urejanja finančnega vira */
    const handleShowEditFinancniVir = (id, delez) => {
        setShowEditFinancniVir({
            [id]: true
        })

        setFinanciranjeViriPutData({
            delez: delez
        })
    }

    const handleFormPutFinancniVir = (e) => {
        e.preventDefault();

        fetchPUT(`${urejanjeFinancnegaVira}/${id}/financiranje/${e.currentTarget.id}`, financiranjeViriPutData).then((vir) => {
            if (vir === 1) {
                getRazpisData();
                setShowEditFinancniVir(false);
            } else {
                setPutError(true);
                setTimeout(() => setPutError(false), 3000)
            }
        })

    }

    return (
        <>
            {/* Modal */}
            <Modal
                show={showModalDelete}
                onHide={() => {
                    setShowModalDelete(false);
                }}
            >
                <Modal.Body>
                    Želite izbrisati: <strong>{modalDeleteFinancniVir.naziv}</strong>?
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        className='btn btn-danger'
                        variant='secondary'
                        onClick={handleDeleteFinancniVir}
                    >
                        Izbriši
                    </Button>
                    <Button
                        variant='primary'
                        onClick={() => {
                            setShowModalDelete(false);
                        }}
                    >
                        Ne
                    </Button>
                </Modal.Footer>
            </Modal>

            <Row>
                <Col className='header-divider'>
                    <hr/>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Card className='razpis-card'>
                        <Card.Body>
                            <Row className='project-partners-details-card'>
                                <Col>
                                    <Card className="mt-3">
                                        <Card.Body>
                                            {/* Validacija podatkov in prikaz napak */}
                                            {deleteError && (
                                                <Row>
                                                    <Col>
                                                        <h2 className='text-danger mt-3 mb-3'>Prišlo je do napake pri
                                                            brisanju finančnega vira!</h2>
                                                    </Col>
                                                </Row>
                                            )}
                                            {postError && (
                                                <Row>
                                                    <Col>
                                                        <h2 className='text-danger mt-3 mb-3'>Prišlo je do napake pri
                                                            dodajanju finančnega vira!</h2>
                                                    </Col>
                                                </Row>
                                            )}
                                            {putError && (
                                                <Row>
                                                    <Col>
                                                        <h2 className='text-danger mt-3 mb-3'>Prišlo je do napake pri
                                                            urejanju finančnega vira!</h2>
                                                    </Col>
                                                </Row>
                                            )}
                                            {delezError && (
                                                <Row>
                                                    <Col>
                                                        <h6 className='text-danger mt-3 mb-3'>Delež finančnih virov ni 100% ampak: {delezSUM} %</h6>
                                                    </Col>
                                                </Row>
                                            )}
                                            {validationError && (
                                                <Row>
                                                    <Col>
                                                        <h2 className='text-danger mt-3 mb-3'>Niste izbrali vira
                                                            financiranja!</h2>
                                                    </Col>
                                                </Row>
                                            )}

                                            <Row className=" ps-2 mb-2">
                                                <Col lg={3}>
                                                    <span>Vir financiranja</span>
                                                </Col>
                                                <Col lg={3}>
                                                    <span>Kategorija financiranja</span>
                                                </Col>
                                                <Col lg={3}>
                                                    <span>Delež financiranja</span>
                                                </Col>
                                                <Col className="text-end">
                                                    {(role.razpisi === 2 ) &&(
                                                    <Button className='org-button-details-text'
                                                            onClick={() => {
                                                                setShowAddFinancniVir(!showAddFinancniVir)
                                                                setFinanceViriPostData({
                                                                    ...financeViriPostData,
                                                                    id_vir_financiranja: 0,
                                                                    delez: 0
                                                                })
                                                            }}>
                                                        <span>Dodaj finančni vir</span>
                                                        <AiOutlineEdit/>
                                                    </Button>
                                                    )}
                                                </Col>
                                            </Row>

                                            {/* Forma za dodajanjee finančnega vira */}
                                            {showAddFinancniVir && (
                                                <Form onSubmit={handleFormAddFinancniVir}>
                                                    <Row className="mt-3 ps-2 mb-2">
                                                        <Col lg={3}>
                                                            <SelectSearch
                                                                name='selectFinanceKategorije'
                                                                placeholder='Izberi vir financiranja'
                                                                options={financiranjeViriOptions}
                                                                onChange={(selectedValue) => setFinanceViriPostData({
                                                                    ...financeViriPostData,
                                                                    id_vir_financiranja: selectedValue
                                                                })}
                                                            />
                                                        </Col>
                                                        <Col lg={3}>
                                                            <SelectSearch
                                                                name='selectFinanceKategorije'
                                                                options={financiranjeKategorijeOptions}
                                                                value={financiranjeKategorija}
                                                                disabled
                                                            />
                                                        </Col>
                                                        <Col lg={3}>
                                                            <Form.Control
                                                                required
                                                                type="number"
                                                                min="0"
                                                                step="0.01"
                                                                placeholder="Delež"
                                                                onChange={(e) => setFinanceViriPostData({
                                                                    ...financeViriPostData,
                                                                    delez: parseFloat(e.currentTarget.value)
                                                                })}
                                                            />
                                                        </Col>
                                                        <Col lg={3}>
                                                            <Button
                                                                variant='primary'
                                                                className='me-2'
                                                                type="submit"
                                                            >
                                                                Dodaj
                                                            </Button>
                                                            <Button
                                                                variant='outline-primary'
                                                                onClick={() => {
                                                                    setShowAddFinancniVir(false)
                                                                    setFinanceViriPostData({
                                                                        ...financeViriPostData,
                                                                        id_vir_financiranja: 0,
                                                                        delez: 0
                                                                    })
                                                                }}
                                                            >
                                                                Prekliči
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                </Form>
                                            )}

                                            {/* Seznam finančnih virov */}
                                            <Row>
                                                <Col>
                                                    {financialResources?.map((vir, index) => {
                                                        return (
                                                            <>
                                                                {showEditFinancniVir[vir.id] && (
                                                                    <Form onSubmit={handleFormPutFinancniVir}
                                                                          id={vir.id}>
                                                                        <Row className="mt-3 ps-2 mb-2">
                                                                            <Col lg={3}>
                                                                                <SelectSearch
                                                                                    name='selectFinanceKategorije'
                                                                                    placeholder='Izberi vir financiranja'
                                                                                    value={vir.id_vir_financiranja}
                                                                                    options={financiranjeViriOptions}
                                                                                    disabled
                                                                                />
                                                                            </Col>
                                                                            <Col lg={3}>
                                                                                <SelectSearch
                                                                                    name='selectFinanceKategorije'
                                                                                    options={financiranjeKategorijeOptions}
                                                                                    value={financiranjeKategorija}
                                                                                    disabled
                                                                                />
                                                                            </Col>
                                                                            <Col lg={3}>
                                                                                <Form.Control
                                                                                    required
                                                                                    type="number"
                                                                                    min="0"
                                                                                    step="0.01"
                                                                                    placeholder="Delež"
                                                                                    defaultValue={vir.delez}
                                                                                    onChange={(e) => setFinanciranjeViriPutData({
                                                                                        ...financiranjeViriPutData,
                                                                                        delez: parseFloat(e.target.value)
                                                                                    })}
                                                                                />
                                                                            </Col>
                                                                            <Col lg={3}>
                                                                                <Button
                                                                                    variant='primary'
                                                                                    className='me-2'
                                                                                    type="submit"
                                                                                >
                                                                                    Uredi
                                                                                </Button>
                                                                                <Button
                                                                                    variant='outline-primary'
                                                                                    onClick={() => {
                                                                                        setShowEditFinancniVir(false)
                                                                                    }}
                                                                                >
                                                                                    Prekliči
                                                                                </Button>
                                                                            </Col>
                                                                        </Row>
                                                                    </Form>

                                                                )}
                                                                {!showEditFinancniVir[vir.id] && (
                                                                    <div key={vir.id_vir_financiranja}>
                                                                        <Row
                                                                            className="p-2 align-items-center">
                                                                            <Col lg={3}>
                                                                                {vir.vir_financiranja}
                                                                            </Col>
                                                                            <Col lg={3}>
                                                                                {vir.financiranje_kategorija}
                                                                            </Col>
                                                                            <Col lg={3}>
                                                                                {vir.delez}
                                                                            </Col>
                                                                            <Col lg={3}>
                                                                                {(role.razpisi === 2 ) && (
                                                                                    <>
                                                                                        <Button
                                                                                            className='org-button-details-text ps-1'
                                                                                            onClick={() => {
                                                                                                handleShowEditFinancniVir(vir.id, vir.delez)
                                                                                            }}
                                                                                        >
                                                                                            <AiOutlineEdit/>
                                                                                        </Button>
                                                                                        <Button
                                                                                            id={vir.id}
                                                                                            value={vir.vir_financiranja}
                                                                                            className='avtorizacije-button-trash '
                                                                                            onClick={handleSetModalDataFinancniViri}
                                                                                        >
                                                                                            <BiTrash
                                                                                                className='text-danger'/>
                                                                                        </Button>
                                                                                    </>
                                                                                ) }
                                                                            </Col>
                                                                        </Row>
                                                                        <Row>
                                                                            <Col className="pe-5">
                                                                                <hr className="mt-0 mb-0"/>
                                                                            </Col>
                                                                        </Row>
                                                                    </div>
                                                                )}
                                                            </>
                                                        )
                                                    })}
                                                </Col>
                                            </Row>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </>
    )
}

FinancniViri.propTypes = {
    financialResources: PropTypes.arrayOf(PropTypes.shape(
        {
            id: PropTypes.number,
            id_razpis: PropTypes.number,
            id_vir_financiranja: PropTypes.number,
            vir_financiranja: PropTypes.string,
            id_financiranje_kategorija: PropTypes.number,
            financiranje_kategorija: PropTypes.string,
            delez: PropTypes.number,
        }
    )),
    financiranjeKategorija: PropTypes.number,
    id: PropTypes.string,
    getRazpisData: PropTypes.func
}

export default FinancniViri;