import React, { useState } from 'react';
import { Link } from 'react-router-dom';

/*Import podkomponente */
import ProgramTreeStructure from './ProgramTreeStructure';

/*Import API */
import { programStrukturaAPI } from '../../listOfApi';
import { fetchGET } from '../../apiHelper';

/*import React-boostrap components */
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';

/*import React-icons */
import { FaStream } from 'react-icons/fa';

const ProgramsList = ({ programs }) => {
  const [showProgramStructure, setShowProgramStructure] = useState(false);
  const [programStructure, setProgramStructure] = useState({});

  const handleProgramStucture = (e) => {
    const id = e.currentTarget.id;
    const idNumber = Object.keys(showProgramStructure);

    if (idNumber[0] === id) {
      setShowProgramStructure(false);
    } else {
      fetchGET(`${programStrukturaAPI}/${id}`).then((programi) => {
        if (programi !== 0) {
          setShowProgramStructure({ [id]: true });
          setProgramStructure(programi);
        }
      });
    }
  };

  return programs.map((program) => {
    return (
      <Row key={program.objectID}>
        <Col className='mt-3'>
          <Card className='main-container-card__shadows'>
            <Card.Body className='p-2 ps-3'>
              <Row>
                <Col lg={10} className='d-flex align-items-center'>
                  <Card.Title className='m-0'>
                    <Link
                      className={'programs-card-title-text text-decoration-none'}
                      to={'/Programi/Struktura/' + program.objectID}
                    >
                      <h5 className='m-0'>{program.naziv}</h5>
                    </Link>
                  </Card.Title>
                  <div className='programs-structure-card-aktiven-text ms-3'>
                    {program.aktiven && <span> Aktiven </span>}
                    {!program.aktiven && <span> Neaktiven </span>}
                  </div>
                </Col>
                <Col lg={2}>
                  <div className='float-end'>
                    <Button
                      id={program.objectID}
                      variant='outline-primary programs-tree-button'
                      onClick={handleProgramStucture}
                    >
                      <FaStream />
                    </Button>
                  </div>
                </Col>
              </Row>
              {/* Komponenta za prikaz celotne podstrukture */}
              {showProgramStructure[program.objectID] && (
                <ProgramTreeStructure programStructure={programStructure} />
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    );
  });
};

export default ProgramsList;
