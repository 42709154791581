import React, { useState, useEffect } from "react";
import MaterialTable from "material-table";
import { format } from "date-fns";

/*Import API helpers */
import { fetchGET } from "../apiHelper";
import { seznamRevizija } from "../listOfApi";

/*import Material-icons*/
import Search from "@material-ui/icons/Search";

/*React-bootstrap components */
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

const Revision = () => {
  const [revision, setRevision] = useState([]);
  const [showFilters, setShowFilters] = useState(false);

  const columns = [
    {
      title: "Čas spremembe",
      field: "timestamp",
      render: (rowData) => {
        const date = new Date(rowData.timestamp);
        const result = format(date, "d. M. yyyy HH:mm");
        return result;
      },
    },
    { title: "Uporabnik", field: "upn" },
    {
      title: "Akcija",
      field: "akcija",
      lookup: {
        Vstavljanje: "Vstavljanje",
        Dodajanje: "Dodajanje",
        Spremembe: "Spremembe",
        Brisanje: "Brisanje",
      },
    },
    { title: "Sklop", field: "objekt" },
    { title: "Objekt ID", field: "objektID", filtering: false },
  ];

  useEffect(() => {
    fetchGET(`${seznamRevizija}/1000`).then((revision) => {
      if (revision !== 0) {
        setDateTime(revision);
      } else {
      }
    });
  }, []);

  const handleRevisionAllData = () => {
    fetchGET(`${seznamRevizija}/0`).then((revision) => {
      if (revision !== 0) {
        setDateTime(revision);
      } else {
      }
    });
  };

  const setDateTime = (revision) => {
    const revisionSorted = revision.map((item) => {
      return {
        timestamp: item.timestamp,
        upn: item.upn,
        akcija: item.akcija,
        objekt: item.objekt,
        objektID: item.objektID,
        vrednost: item.vrednost,
      };
    });
    setRevision(revisionSorted);
  };

  return (
    <Container fluid>
      <Row>
        <Col className="main-header font-weight-bold">Zgodovina sprememb</Col>
      </Row>
      <Row>
        <Col className="header-divider">
          <hr />
        </Col>
      </Row>
      <Row>
        <Col>
          <Button
            variant="outline-primary"
            className="programs-button-add-text"
            onClick={handleRevisionAllData}
          >
            Prikaži vso zgodovino sprememb
          </Button>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col>
          <MaterialTable
            icons={{
              Filter: () => <Search style={{ fontSize: "15px" }} />,
            }}
            title={""}
            columns={columns}
            data={revision}
            localization={{
              toolbar: {
                searchPlaceholder: "Iskalnik",
                exportCSVName: "Izvozi podatke v CSV",
              },
              body: {
                emptyDataSourceMessage: "Ni zapisov za prikaz",
              },
              pagination: {
                labelRowsSelect: "Vrstic",
                firstAriaLabel: "Prva stran",
                firstTooltip: "Prva stran",
                previousAriaLabel: "Prejšnja stran",
                previousTooltip: "Prejšnja stran",
                nextAriaLabel: "Naslednja stran",
                nextTooltip: "Naslednja stran",
                lastAriaLabel: "Zadnja stran",
                lastTooltip: "Zadnja stran",
                labelDisplayedRows: "{from}-{to} od {count}",
              },
            }}
            options={{
              headerStyle: { fontWeight: "bold" },
              paging: true,
              pageSize: 50,
              pageSizeOptions: [50, 100, 500, revision.length],
              searchFieldAlignment: "left",
              filtering: showFilters,
              exportButton: { csv: true, pdf: false },
              exportFileName: `zgodovina_sprememb_${new Date()
                .toLocaleDateString("sl-SI")
                .replace(/\. /g, "-")}`,
            }}
            onRowClick={(event, rowData, togglePanel) => togglePanel()}
            detailPanel={[
              {
                tooltip: "Prikaži spremembo",
                render: (rowData) => {
                  return (
                    <Row>
                      <Col>
                        <div className="p-4">
                          <span>
                            <strong>Sprememba: </strong>
                          </span>
                          {rowData.vrednost}
                        </div>
                      </Col>
                    </Row>
                  );
                },
              },
            ]}
            actions={[
              {
                icon: "filter_alt",
                tooltip: "Omogoči filter",
                isFreeAction: true,
                onClick: (event) => {
                  setShowFilters(!showFilters);
                },
              },
            ]}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default Revision;
