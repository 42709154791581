import React, { useState, useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import { userReadWithExtraQuerys } from "../msalAuthConfig";

/*React-bootstrap components */
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card"

/*Import API */
import { obvestilaAdmin } from "../listOfApi";
import { fetchGETPwd } from "../apiHelper";

const LoginComponent = () => {
    const { instance } = useMsal();
    const [bottomMessage, setBottomMessage] = useState({})


    useEffect(() => {
        //Pridobivanje vseh obvestil za prikaz
        fetchGETPwd(obvestilaAdmin).then((obvestila) => {
            if (obvestila !== 0) {
                const kategorijaBottom = obvestila.find(value => value.kategorija === "bottom");
                setBottomMessage(kategorijaBottom)
            } else {
            }
        });

    }, [])

    //funkcija za prijavo v aplikacijo z uporabo MSAl z dodatnimi parametri
    const handleLogin = (loginType) => {
        if (loginType === "redirect") {
            instance.loginRedirect(userReadWithExtraQuerys).catch(e => {
                console.log(e);
            });
        }
    }

    return (
        <Col lg={{ span: 4, offset: 4 }} className="mt-5">
            <Row className="mt-5">
                <Col className="loginTitle text-center" >
                    Prijava v Bazo projektov
                </Col>
            </Row>
            <hr className="loginLine" />
            <Row>
                <Col className="text-center digitalna-text mt-2">
                    Prijavite se s svojo digitalno identiteto. <span><a className="text-decoration-underline" target="_blank" rel="noopener noreferrer" href="https://it.um.si/zaposleni/Strani/default.aspx?podrocje=Dostopi%20in%20varnost#id31">Več...</a></span>
                </Col>
            </Row>
            <Row className="mt-4">
                <Col className="text-center">
                    <Button className="w-50" onClick={() => handleLogin("redirect")}>Vstop v aplikacijo</Button>
                </Col>
            </Row>
            <Row>
                <Col className="mt-4 text-center">
                    <a className="text-decoration-underline" target="_blank" rel="noopener noreferrer" href="https://adfs.um.si/adfs/portal/updatepassword">Želite spremeniti geslo?</a> | <a className="text-decoration-underline" target="_blank" rel="noopener noreferrer" href="https://ponastavigeslo.um.si/">Ste pozabili geslo?</a>
                </Col>
            </Row>
            {Object.keys(bottomMessage).length !== 0 && (
                <Row className="mt-5">
                    <Col className="mt-3">
                        <Card className="p-2">
                            {bottomMessage.obvestilo}
                        </Card>
                    </Col>
                </Row>
            )}
        </Col>
    );
};

export default LoginComponent;
