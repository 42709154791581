import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import MaterialTable from "material-table";

import { format } from "date-fns";

/*Import API helpers */
import { fetchGET, fetchDELETE } from "../apiHelper";
import {
  seznamRazpisov,
  brisanjeRazpisa,
  aktualniSeznamGetURL,
} from "../listOfApi";

/*React-Bootstrap component */
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";

/*Import React-icons */
import { AiOutlineLink } from "react-icons/ai";
import { BsTable, BsGrid3X3GapFill } from "react-icons/bs";
import { BiDetail, BiTrash } from "react-icons/bi";

/*Import Material-icons */
import Search from "@material-ui/icons/Search";

/* Seznam vseh razpisov */
const Tenders = ({ role }) => {
  const [tenders, setTenders] = useState([]);
  const [filteredTenders, setFilteredTenders] = useState([]);
  const [showTable, setShowTable] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [tenderId, setTenderId] = useState("");
  const [razpisName, setRazpisName] = useState("");
  const [searchTender, setSearchTender] = useState("");
  const [showPomemben, setShowPomemben] = useState(false);
  const [showOdprt, setShowOdprt] = useState(false);
  const [showFilters, setShowFilters] = useState(false);

  const history = useNavigate();

  const columns = [
    {
      title: "Naziv",
      field: "razpis_naziv",
    },
    {
      title: "Izpostavljen",
      field: "pomemben",
      lookup: { true: "Da", false: "Ne" },
    },
    {
      title: "Odprt razpis",
      field: "odprt",
      lookup: { true: "Da", false: "Ne" },
    },
    {
      title: "Program",
      field: "program_naziv",
    },
    {
      title: "Nadrejeni program",
      field: "programParent",
    },
    {
      title: "Datum prijave",
      field: "prijava_datum",
      filterComponent: (props) => (
        <Form.Check
          name="odprt"
          type="checkbox"
          checked={showOdprt}
          onChange={() => setShowOdprt(!showOdprt)}
          label="Aktiven"
          {...props}
        />
      ),
    },
  ];

  useEffect(() => {
    let letSet = null;
    if (localStorage.getItem("layout") === "true") {
      letSet = true;
    } else if (localStorage.getItem("layout") === "false") {
      letSet = false;
    }
    if (localStorage.getItem("layout") !== null) {
      setShowTable(letSet);
    }
  });

  useEffect(() => {
    fetchGET(seznamRazpisov).then((tenders) => {
      if (tenders !== 0) {
        const tendersData = tenders.map((item) => {
          return {
            razpisID: item.razpisID,
            razpis_naziv: item.razpis_naziv,
            pomemben: item.pomemben,
            odprt: item.odprt,
            program_naziv: item.program_naziv,
            programParent: item.programParent,
            prijava_datum_date: item.prijava_datum,
            prijava_datum:
              item.prijava_datum === "2100-01-01"
                ? ""
                : item.prijava_datum === null
                ? ""
                : format(new Date(item.prijava_datum), "d. M. yyyy"),
          };
        });

        setTenders(tendersData);
        setFilteredTenders(tendersData);
      } else {
      }
    });
  }, []);

  useEffect(() => {
    let filteredTenders = [];

    if (showPomemben === false && showOdprt === false) {
      filteredTenders = tenders.filter(
        (tender) =>
          tender.razpis_naziv
            .toLowerCase()
            .includes(searchTender.toLowerCase()) ||
          tender.program_naziv
            .toLowerCase()
            .includes(searchTender.toLowerCase())
      );
    } else if (showPomemben === true && showOdprt === true) {
      filteredTenders = tenders.filter((tender) => {
        return (
          tender.pomemben === true &&
          compareDate(tender.prijava_datum_date) &&
          (tender.razpis_naziv
            .toLowerCase()
            .includes(searchTender.toLowerCase()) ||
            tender.program_naziv
              .toLowerCase()
              .includes(searchTender.toLowerCase()))
        );
      });
    } else if (showPomemben === true && showOdprt === false) {
      filteredTenders = tenders.filter(
        (tender) =>
          tender.pomemben === true &&
          (tender.razpis_naziv
            .toLowerCase()
            .includes(searchTender.toLowerCase()) ||
            tender.program_naziv
              .toLowerCase()
              .includes(searchTender.toLowerCase()))
      );
    } else if (showPomemben === false && showOdprt === true) {
      filteredTenders = tenders.filter(
        (tender) =>
          compareDate(tender.prijava_datum_date) &&
          (tender.razpis_naziv
            .toLowerCase()
            .includes(searchTender.toLowerCase()) ||
            tender.program_naziv
              .toLowerCase()
              .includes(searchTender.toLowerCase()))
      );
    }

    setFilteredTenders(filteredTenders);
  }, [searchTender, showPomemben, showOdprt]);

  const compareDate = (datum) => {
    const tenderDate = new Date(datum).setHours(0, 0, 0, 0);
    const todayDate = new Date().setHours(0, 0, 0, 0);
    return tenderDate >= todayDate;
  };

  const getUrlFromTender = (id) => {
    fetchGET(`${aktualniSeznamGetURL}/${id}/link`).then((tenderURL) => {
      if (tenderURL !== 0) {
        if (tenderURL.povezava !== "")
          window.open(tenderURL.povezava, "_blank");
      } else {
      }
    });
  };

  const handleDelete = () => {
    fetchDELETE(`${brisanjeRazpisa}/${tenderId}`).then((deleted) => {
      if (deleted === 1) {
        setShowModal(false);
        window.location.reload();
      } else {
        //TODO: prišlo je do napake pri brisanju podatkov.
      }
    });
  };

  return (
    <Container fluid>
      {(role.razpisi === 1 || role.razpisi === 2) && (
        <>
          <Modal
            show={showModal}
            onHide={() => {
              setShowModal(false);
            }}
          >
            <Modal.Body>
              Želite izbrisati razpis: <strong>{razpisName}</strong> ?
            </Modal.Body>
            <Modal.Footer>
              <Button
                className="btn btn-danger"
                variant="secondary"
                onClick={handleDelete}
              >
                Izbriši
              </Button>
              <Button
                variant="primary"
                onClick={() => {
                  setShowModal(false);
                }}
              >
                Ne
              </Button>
            </Modal.Footer>
          </Modal>
          <Row>
            <Col className="main-header font-weight-bold">Razpisi</Col>
          </Row>
          <Row>
            <Col className="header-divider">
              <hr />
            </Col>
          </Row>
          <Row className="align-items-center">
            {!showTable && (
              <>
                <Col lg={5} md={8}>
                  <Form.Control
                    name="iskalnik"
                    type="text"
                    value={searchTender}
                    onChange={(e) => setSearchTender(e.target.value)}
                    placeholder="Poišči razpis po nazivu ali programu..."
                  />
                </Col>
                <Col lg={1} className="me-2">
                  <Form.Check
                    name="izpostavljen"
                    type="checkbox"
                    checked={showPomemben}
                    onChange={() => setShowPomemben(!showPomemben)}
                    label="Izpostavljen"
                  />
                </Col>

                <Col lg={1}>
                  <Form.Check
                    name="odprt"
                    type="checkbox"
                    checked={showOdprt}
                    onChange={() => setShowOdprt(!showOdprt)}
                    label="Odprt"
                  />
                </Col>
              </>
            )}

            <Col>
              <div className="float-end">
                {role.razpisi === 2 && (
                  <Link
                    className="programs-button-add-text "
                    to={`/Razpisi/DodajRazpis/`}
                  >
                    <Button variant="outline-primary border-0">
                      Dodaj razpis
                    </Button>
                  </Link>
                )}
              </div>
              <div className="float-end me-4   ms-1">
                {role.razpisi === 2 && (
                  <Button
                    variant="outline-primary border-0"
                    onClick={() => {
                      setShowTable(false);
                      localStorage.setItem("layout", false);
                    }}
                  >
                    <BsGrid3X3GapFill />
                  </Button>
                )}
              </div>
              <div className="float-end">
                {role.razpisi === 2 && (
                  <Button
                    variant="outline-primary border-0"
                    onClick={() => {
                      setShowTable(true);
                      localStorage.setItem("layout", true);
                    }}
                  >
                    <BsTable />
                  </Button>
                )}
              </div>
            </Col>
          </Row>

          {showTable && (
            <Row className="mt-4">
              <Col>
                <MaterialTable
                  icons={{
                    Filter: () => (
                      <Search style={{ fontSize: "15px" }}></Search>
                    ),
                  }}
                  title={""}
                  columns={columns}
                  data={filteredTenders}
                  localization={{
                    toolbar: {
                      searchPlaceholder: "Iskalnik",
                      exportCSVName: "Izvozi podatke v CSV",
                    },
                    header: { actions: "Akcije" },
                    body: {
                      emptyDataSourceMessage: "Ni zapisov za prikaz",
                    },
                    pagination: {
                      labelRowsSelect: "Vrstic",
                      firstAriaLabel: "Prva stran",
                      firstTooltip: "Prva stran",
                      previousAriaLabel: "Prejšnja stran",
                      previousTooltip: "Prejšnja stran",
                      nextAriaLabel: "Naslednja stran",
                      nextTooltip: "Naslednja stran",
                      lastAriaLabel: "Zadnja stran",
                      lastTooltip: "Zadnja stran",
                      labelDisplayedRows: "{from}-{to} od {count}",
                    },
                  }}
                  options={{
                    paging: true,
                    pageSize: 50,
                    pageSizeOptions: [50, 100, 500],
                    searchFieldAlignment: "left",
                    filtering: showFilters,
                    actionsColumnIndex: -1,
                    headerStyle: { fontWeight: "bold" },
                    exportButton: { csv: true, pdf: false },
                    exportFileName: `razpisi_${new Date()
                      .toLocaleDateString("sl-SI")
                      .replace(/\. /g, "-")}`,
                  }}
                  onRowClick={(event, rowData) => {
                    history(`/RazpisPodrobnosti/${rowData.razpisID}`);
                  }}
                  actions={[
                    {
                      icon: "article",
                      tooltip: "Podrobnosti",
                      onClick: (event, rowData) => {
                        history(`/RazpisPodrobnosti/${rowData.razpisID}`);
                      },
                    },
                    {
                      icon: "link",
                      tooltip: "Povezava",
                      onClick: (event, rowData) => {
                        getUrlFromTender(rowData.razpisID);
                      },
                    },
                    {
                      hidden: /*role.razpisi === 2 ? false : */ true,
                      icon: "edit",
                      tooltip: "Uredi",
                      onClick: (event, rowData) => {
                        history(`/Razpisi/UrediRazpis/${rowData.razpisID}`);
                      },
                    },
                    {
                      hidden: role.razpisi === 2 ? false : true,
                      icon: "delete",
                      tooltip: "Odstrani",
                      iconProps: { color: "error" },
                      onClick: (event, rowData) => {
                        setShowModal(true);
                        setTenderId(rowData.razpisID);
                        setRazpisName(rowData.razpis_naziv);
                      },
                    },
                    {
                      icon: "filter_alt",
                      tooltip: "Omogoči filter",
                      isFreeAction: true,
                      onClick: (event) => {
                        setShowFilters(!showFilters);
                      },
                    },
                  ]}
                />
              </Col>
            </Row>
          )}
          {!showTable && (
            <Row className="mt-4 ">
              {filteredTenders.map((tender, index) => {
                return (
                  <Col lg={6} xl={3} className="mt-4">
                    <Card key={index} className="card-height">
                      <Card.Body>
                        <Row>
                          <Col>
                            <Card.Title>{tender.razpis_naziv}</Card.Title>
                          </Col>
                          <Col>
                            <div className="float-end">
                              {tender.prijava_datum}
                            </div>
                          </Col>
                        </Row>
                        <p className="mt-2"> {tender.program_naziv}</p>
                        {tender.pomemben && <p>Izpostavljen</p>}

                        {tender.prijava_ura && (
                          <p>{tender.prijava_ura.toString().slice(0, -3)}</p>
                        )}
                      </Card.Body>
                      <Card.Header className="p-1">
                        <div className="float-right">
                          <Link
                            className="razpisi-button-details-text me-3"
                            to={`/RazpisPodrobnosti/${tender.razpisID}`}
                          >
                            <BiDetail />
                          </Link>
                          {tender.povezava && (
                            <Link
                              className="razpisi-button-details-text me-3"
                              to={{ pathname: tender.povezava }}
                              target="_blank"
                            >
                              <AiOutlineLink />
                            </Link>
                          )}
                          {role.razpisi === 2 && (
                            <>
                              {/*
                              <Link
                                className='razpisi-button-details-text'
                                to={`/Razpisi/UrediRazpis/${tender.razpisID}`}
                              >
                                <AiOutlineEdit className='mr-1' />
                              </Link>
                              */}
                              <Button
                                className="avtorizacije-button-trash"
                                onClick={() => {
                                  setShowModal(true);
                                  setTenderId(tender.razpisID);
                                  setRazpisName(tender.razpis_naziv);
                                }}
                              >
                                <BiTrash className="text-danger" />
                              </Button>
                            </>
                          )}
                        </div>
                      </Card.Header>
                    </Card>
                  </Col>
                );
              })}
            </Row>
          )}
        </>
      )}
    </Container>
  );
};

export default Tenders;
