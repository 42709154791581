import React, { useContext } from "react";
import CurrencyInput from "react-currency-input-field";

/* Import Context */
import { UserContext } from "../UserContext";

/* Import React-Bootstrap */
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

/* Import React-Icons */
import { AiFillInfoCircle, AiOutlineEdit } from "react-icons/ai";

const RealiziraneVrednostiProjekta = ({
  projectSkrbnikClanica,
  tooltipSifranti,
  showErrorDodajanjeUrejanjeRealiziranihFinanc,
  showRealiziraneVrednostiProjekta,
  showUrejanjeDodajanjeFinancniClaniceRealizirano,
  sestevekRealiziranihVrednostiClanice,
  realiziraneVrednostiProjekta,
  realiziraneVrednoProjektaPrikaz,
  realiziraneVrednostiClanice,
  realiziraneVrednostiClanicePrikaz,
  realiziraneVrednostiClaniceUrejanje,
  numberFormatValuta,
  setStateShowRealiziraneVrednostiProjekta,
  setStateRealiziranaVrednostProjektaUrejanje,
  setStateShowUrejanjeDodajanjeFinancniClaniceRealizirano,
  setStateRealiziraneVrednostiClaniceUrejanje,
  setStateRealiziraneVrednostiClanicePrikaz,
  handleAddRealiziraneVrednostiProjekta,
  handleAddRealiziraneVrednostiClanic,
  jeClanicaProjekta
}) => {
  const role = useContext(UserContext);

  return (
    <Card className="mt-2">
      <Card.Body>
        <Row className="align-items-center">
          <Col>
            <div className="float-end">
              {((role.skrbnik === true &&
                role.clanicaID === projectSkrbnikClanica) ||
                (role.skrbnik === false && role.projekti === 2)) && (
                <Button
                  className="org-button-details-text"
                  id="1"
                  onClick={setStateShowRealiziraneVrednostiProjekta}
                >
                  <AiOutlineEdit />
                  <span>Uredi realizirane finančne podatke</span>
                </Button>
              )}
            </div>
          </Col>
        </Row>
        <Row className="project-partners-details-card">
          <Col>
            <div className="p-4">
              {showErrorDodajanjeUrejanjeRealiziranihFinanc && (
                <h4 className="text-danger mb-4">
                  Prišlo je do napake pri urejanju realiziranih finančnih
                  podatkov!
                </h4>
              )}
              {!showRealiziraneVrednostiProjekta && (
                <Row>
                  <Col>
                    <span>
                      {" "}
                      Vrednost projekta{" "}
                      <sup>
                        <AiFillInfoCircle
                          className="tooltip-icon"
                          data-tooltip-id="tab-tooltip"
                          data-tooltip-content={tooltipSifranti.P42vrednostProjekta}
                        />
                      </sup>
                      :{" "}
                    </span>{" "}
                    {realiziraneVrednostiProjekta.realiziranaVrednost !== null
                      ? `${numberFormatValuta(
                          realiziraneVrednostiProjekta.realiziranaVrednost
                        )}`
                      : "Ni še podatka"}
                  </Col>
                  <Col>
                    <span>
                      {" "}
                      Vrednost sofinanciranja iz projektnih virov{" "}
                      <sup>
                        <AiFillInfoCircle
                          className="tooltip-icon"
                          data-tooltip-id="tab-tooltip"
                          data-tooltip-content={
                            tooltipSifranti.P42vrednostSofinanciranjaIzProjektnihVirov
                          }
                        />
                      </sup>
                      :{" "}
                    </span>{" "}
                    {realiziraneVrednostiProjekta.realiziraniProjektniViri !==
                    null
                      ? `${numberFormatValuta(
                          realiziraneVrednostiProjekta.realiziraniProjektniViri
                        )}`
                      : "Ni še podatka"}
                  </Col>
                </Row>
              )}
              {showRealiziraneVrednostiProjekta && (
                <>
                  <Row className="align-items-center">
                    <Col lg={4}>
                      <Form.Label> Vrednost Projekta (€) </Form.Label>
                      <CurrencyInput
                        className="form-control"
                        placeholder="Vnesi znesek"
                        decimalScale={2}
                        value={
                          realiziraneVrednoProjektaPrikaz.realiziranaVrednost
                        }
                        intlConfig={{ locale: "sl-SI", currency: "EUR" }}
                        onValueChange={(value) => {
                          setStateRealiziranaVrednostProjektaUrejanje(
                            value,
                            null,
                            "realiziranaVrednost"
                          );
                        }}
                      />
                    </Col>
                    <Col lg={{ span: 4, offset: 1 }}>
                      <Form.Label>
                        Vrednost sofinanciranja iz projektnih virov (€)
                      </Form.Label>
                      <CurrencyInput
                        className="form-control"
                        placeholder="Vnesi znesek"
                        decimalScale={2}
                        value={
                          realiziraneVrednoProjektaPrikaz.realiziraniProjektniViri
                        }
                        intlConfig={{ locale: "sl-SI", currency: "EUR" }}
                        onValueChange={(value) => {
                          setStateRealiziranaVrednostProjektaUrejanje(
                            null,
                            value
                          );
                        }}
                      />
                    </Col>
                    <Col lg={3}>
                      <Button
                        variant="primary"
                        className="me-3 custom-top-margin projectiDetails-buttonWidth"
                        onClick={handleAddRealiziraneVrednostiProjekta}
                      >
                        Potrdi
                      </Button>
                      <Button
                        variant="outline-primary"
                        className="outline-primary ms-3 custom-top-margin projectiDetails-buttonWidth"
                        onClick={() =>
                          setStateShowRealiziraneVrednostiProjekta()
                        }
                      >
                        Prekliči
                      </Button>
                    </Col>
                  </Row>
                </>
              )}
              <Row className="mt-5">
                <Col>
                  <span>
                    {" "}
                    Vrednost projekta za UM{" "}
                    <sup>
                      <AiFillInfoCircle
                        className="tooltip-icon"
                        data-tooltip-id="tab-tooltip"
                        data-tooltip-content={tooltipSifranti.P42vrednostProjektaZaUM}
                      />{" "}
                    </sup>
                    :{" "}
                  </span>{" "}
                  {sestevekRealiziranihVrednostiClanice.realiziranaVrednost !==
                  null
                    ? `${numberFormatValuta(
                        sestevekRealiziranihVrednostiClanice.realiziranaVrednost
                      )}`
                    : "Ni podatka"}
                </Col>
                <Col>
                  <span>
                    {" "}
                    Vrednost sofinanciranja iz projektnih virov za UM{" "}
                    <sup>
                      <AiFillInfoCircle
                        className="tooltip-icon"
                        data-tooltip-id="tab-tooltip"
                        data-tooltip-content={
                          tooltipSifranti.P42vrednostSofinanciranjaIzProjektnihVirovZaUM
                        }
                      />
                    </sup>
                    :{" "}
                  </span>{" "}
                  {sestevekRealiziranihVrednostiClanice.realiziranaVrednostViri !==
                  null
                    ? `${numberFormatValuta(
                        sestevekRealiziranihVrednostiClanice.realiziranaVrednostViri
                      )}`
                    : "Ni podatka"}
                </Col>
              </Row>
              <Row className="mt-3">
                <Col>
                  <span>
                    Vrednost / delež lastnega sofinanciranja UM{" "}
                    <sup>
                      <AiFillInfoCircle
                        className="tooltip-icon"
                        data-tooltip-id="tab-tooltip"
                        data-tooltip-content={
                          tooltipSifranti.P42VrednostDelezLastnegaSofinanciranjaUM
                        }
                      />
                    </sup>
                    :
                  </span>{" "}
                  {sestevekRealiziranihVrednostiClanice.realiziranaLastnaVrednost !==
                    null &&
                  sestevekRealiziranihVrednostiClanice.realiziranaDelez !== null
                    ? `${numberFormatValuta(
                        sestevekRealiziranihVrednostiClanice.realiziranaLastnaVrednost
                      )} / ${(
                        " " +
                        sestevekRealiziranihVrednostiClanice.realiziranaDelez
                      )
                        .toString()
                        .replace(".", ",")} %`
                    : "Ni podatka / Ni podatka"}
                </Col>
                <Col>
                  <span>
                    Obseg zaposlitev (izraženo v FTE){" "}
                    <sup>
                      <AiFillInfoCircle
                        className="tooltip-icon"
                        data-tooltip-id="tab-tooltip"
                        data-tooltip-content={tooltipSifranti.P42obsegZaposlitveFTE}
                      />
                    </sup>
                    :{" "}
                  </span>{" "}
                  {sestevekRealiziranihVrednostiClanice.realiziranaObseg !== null
                    ? (
                        " " +
                        sestevekRealiziranihVrednostiClanice.realiziranaObseg
                      )
                        .toString()
                        .replace(".", ",")
                    : "Ni podatka"}
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
        {((role.skrbnik === true && role.clanicaID === projectSkrbnikClanica) || (role.skrbnik === true && jeClanicaProjekta === true && role.clanicaID !== projectSkrbnikClanica) ||
          (role.skrbnik === false && role.projekti === 2)) && (
          <>
            {realiziraneVrednostiClanice.map((clanicaFinance, index) => {
              return (
                <Row className="project-partners-details-card" key={index}>
                  <Col>
                    <Card className="mt-3">
                      <Card.Body>
                        {!showUrejanjeDodajanjeFinancniClaniceRealizirano[
                          clanicaFinance.clanicaID
                        ] && (
                          <Row>
                            <Col lg={1}>{clanicaFinance.clanica}</Col>
                            <Col>
                              <Row>
                                <Col>
                                  <span>
                                    Vrednost projekta za članico UM{" "}
                                    <sup>
                                      <AiFillInfoCircle
                                        className="tooltip-icon"
                                        data-tooltip-id="tab-tooltip"
                                        data-tooltip-content={
                                          tooltipSifranti.P42vrednostProjetaZaClanicoUM
                                        }
                                      />
                                    </sup>
                                    :{" "}
                                  </span>{" "}
                                  {clanicaFinance.realiziranaVrednost !== null
                                    ? `${numberFormatValuta(
                                        clanicaFinance.realiziranaVrednost
                                      )}`
                                    : "Ni podatka"}
                                </Col>
                              </Row>
                              <Row className="mt-2">
                                <Col>
                                  <span>
                                    Vrednost / delež lastnega sofinanciranja UM{" "}
                                    <sup>
                                      <AiFillInfoCircle
                                        className="tooltip-icon"
                                        data-tooltip-id="tab-tooltip"
                                        data-tooltip-content={
                                          tooltipSifranti.P42vrednostDelezLastnegaSofinanciranjaUM
                                        }
                                      />
                                    </sup>
                                    :{" "}
                                  </span>{" "}
                                  {clanicaFinance.realiziranaLastnaVrednost !==
                                  null
                                    ? numberFormatValuta(
                                        clanicaFinance.realiziranaLastnaVrednost
                                      )
                                    : "Ni podatka"}{" "}
                                  {"/"}
                                  {clanicaFinance.realiziranaDelez !== null
                                    ? `${(" " + clanicaFinance.realiziranaDelez)
                                        .toString()
                                        .replace(".", ",")} %`
                                    : "Ni podatka"}
                                </Col>
                              </Row>
                            </Col>
                            <Col>
                              <Row>
                                <Col>
                                  <span>
                                    Vrednost sofinanciranja iz projektnih virov
                                    za članico UM{" "}
                                    <sup>
                                      <AiFillInfoCircle
                                        className="tooltip-icon"
                                        data-tooltip-id="tab-tooltip"
                                        data-tooltip-content={
                                          tooltipSifranti.P42vrednostSofinanaciranjaIzProjektnihVirovZaClanicoUM
                                        }
                                      />
                                    </sup>
                                    :{" "}
                                  </span>{" "}
                                  {clanicaFinance.realiziranaVrednostViri !==
                                  null
                                    ? `${numberFormatValuta(
                                        clanicaFinance.realiziranaVrednostViri
                                      )}`
                                    : "Ni podatka"}
                                </Col>
                              </Row>
                              <Row className="mt-2">
                                <Col>
                                  <span>
                                    Obseg zaposlitev na članici UM{" "}
                                    <sup>
                                      <AiFillInfoCircle
                                        className="tooltip-icon"
                                        data-tooltip-id="tab-tooltip"
                                        data-tooltip-content={
                                          tooltipSifranti.P42obsegZaposlitveNaClaniciUM
                                        }
                                      />
                                    </sup>
                                    :
                                  </span>{" "}
                                  {clanicaFinance.realiziranaObseg !== null
                                    ? clanicaFinance.realiziranaObseg
                                        .toString()
                                        .replace(".", ",")
                                    : "Ni podatka"}
                                </Col>
                              </Row>
                            </Col>

                            <Col lg={1}>
                              <div className="float-end">
                                {((role.skrbnik === true && role.clanicaID === projectSkrbnikClanica) ||
                                      (role.skrbnik === false && role.projekti === 2)) && (
                                  <Button
                                    className="org-button-details-text"
                                    id={clanicaFinance.clanicaID}
                                    onClick={(e) => {
                                      setStateShowUrejanjeDodajanjeFinancniClaniceRealizirano(
                                        e.currentTarget.id
                                      );
                                      setStateRealiziraneVrednostiClaniceUrejanje(
                                        clanicaFinance.clanicaID,
                                        clanicaFinance.realiziranaVrednost,
                                        clanicaFinance.realiziranaLastnaVrednost,
                                        clanicaFinance.realiziranaVrednostViri,
                                        clanicaFinance.realiziranaObseg
                                      );

                                      setStateRealiziraneVrednostiClanicePrikaz(
                                        clanicaFinance.clanicaID,
                                        clanicaFinance.realiziranaVrednost !==
                                          null
                                          ? clanicaFinance.realiziranaVrednost
                                          : undefined,
                                        clanicaFinance.realiziranaLastnaVrednost !==
                                          null
                                          ? clanicaFinance.realiziranaLastnaVrednost
                                          : undefined,
                                        clanicaFinance.realiziranaVrednostViri !==
                                          null
                                          ? clanicaFinance.realiziranaVrednostViri
                                          : undefined,
                                        clanicaFinance.realiziranaObseg
                                      );
                                    }}
                                  >
                                    <AiOutlineEdit />
                                  </Button>
                                )}
                              </div>
                            </Col>
                          </Row>
                        )}

                        {showUrejanjeDodajanjeFinancniClaniceRealizirano[
                          clanicaFinance.clanicaID
                        ] && (
                          <Form onSubmit={handleAddRealiziraneVrednostiClanic}>
                            <Row>
                              <Col lg={1}>{clanicaFinance.clanica}</Col>
                              <Col>
                                <Row>
                                  <Col>
                                    <Form.Label>
                                      Vrednost projekta za članico UM (€)
                                    </Form.Label>
                                    <CurrencyInput
                                      className="form-control"
                                      placeholder="Vnesi znesek"
                                      decimalScale={2}
                                      value={
                                        realiziraneVrednostiClanicePrikaz.realiziranaVrednost
                                      }
                                      intlConfig={{
                                        locale: "sl-SI",
                                        currency: "EUR",
                                      }}
                                      onValueChange={(value) => {
                                        setStateRealiziraneVrednostiClaniceUrejanje(
                                          null,
                                          value !== undefined
                                            ? parseFloat(
                                                value.replace(",", ".")
                                              )
                                            : null,
                                          null,
                                          null,
                                          null,
                                          "realiziranaVrednost"
                                        );
                                        setStateRealiziraneVrednostiClanicePrikaz(
                                          null,
                                          value,
                                          null,
                                          null,
                                          null,
                                          "realiziranaVrednost"
                                        );
                                      }}
                                    />
                                  </Col>
                                </Row>
                                <Row className="mt-2">
                                  <Col>
                                    <Form.Label>
                                      Vrednost lastnega sofinanciranja UM (€){" "}
                                    </Form.Label>
                                    <Row>
                                      <Col>
                                        <CurrencyInput
                                          className="form-control"
                                          placeholder="Vnesi znesek"
                                          decimalScale={2}
                                          value={
                                            realiziraneVrednostiClanicePrikaz.realiziranaLastnaVrednost
                                          }
                                          intlConfig={{
                                            locale: "sl-SI",
                                            currency: "EUR",
                                          }}
                                          onValueChange={(value) => {
                                            setStateRealiziraneVrednostiClaniceUrejanje(
                                              null,
                                              null,
                                              value !== undefined
                                                ? parseFloat(
                                                    value.replace(",", ".")
                                                  )
                                                : null,
                                              null,
                                              null,
                                              "realiziranaLastnaVrednost"
                                            );
                                            setStateRealiziraneVrednostiClanicePrikaz(
                                              null,
                                              null,
                                              value,
                                              null,
                                              null,
                                              "realiziranaLastnaVrednost"
                                            );
                                          }}
                                        />
                                      </Col>
                                    </Row>
                                  </Col>
                                </Row>
                              </Col>
                              <Col>
                                <Row>
                                  <Col>
                                    <Form.Label>
                                      Vrednost sofinanciranja iz projektnih
                                      virov za članico UM (€)
                                    </Form.Label>
                                    <CurrencyInput
                                      className="form-control"
                                      placeholder="Vnesi znesek"
                                      decimalScale={2}
                                      value={
                                        realiziraneVrednostiClanicePrikaz.realiziranaVrednostViri
                                      }
                                      intlConfig={{
                                        locale: "sl-SI",
                                        currency: "EUR",
                                      }}
                                      onValueChange={(value) => {
                                        setStateRealiziraneVrednostiClaniceUrejanje(
                                          null,
                                          null,
                                          null,
                                          value !== undefined
                                            ? parseFloat(
                                                value.replace(",", ".")
                                              )
                                            : null,
                                          null,
                                          "realiziranaVrednostViri"
                                        );
                                        setStateRealiziraneVrednostiClanicePrikaz(
                                          null,
                                          null,
                                          null,
                                          value,
                                          null,
                                          "realiziranaVrednostViri"
                                        );
                                      }}
                                    />
                                  </Col>
                                </Row>
                                <Row className="mt-2">
                                  <Col>
                                    <Form.Label>
                                      Obseg zaposlitev na članici UM
                                    </Form.Label>
                                    <Form.Control
                                      type="number"
                                      placeholder={"Vnesi obseg zaposlitve"}
                                      min="0"
                                      step="0.01"
                                      value={
                                        realiziraneVrednostiClaniceUrejanje.realiziranaObseg
                                      }
                                      onChange={(e) => {
                                        setStateRealiziraneVrednostiClaniceUrejanje(
                                          null,
                                          null,
                                          null,
                                          null,
                                          isNaN(parseFloat(e.target.value))
                                            ? null
                                            : parseFloat(e.target.value),
                                          "realiziranaObseg"
                                        );
                                      }}
                                    />
                                  </Col>
                                </Row>
                              </Col>
                              <Col
                                lg={2}
                                className="d-flex align-items-center justify-content-center"
                              >
                                <Button
                                  variant="primary"
                                  className="me-2"
                                  type="submit"
                                >
                                  Potrdi
                                </Button>
                                <Button
                                  variant="outline-primary ml-3"
                                  onClick={() =>
                                    setStateShowUrejanjeDodajanjeFinancniClaniceRealizirano(
                                      null,
                                      "preklici"
                                    )
                                  }
                                >
                                  Prekliči
                                </Button>
                              </Col>
                            </Row>
                          </Form>
                        )}
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              );
            })}
          </>
        )}
      </Card.Body>
    </Card>
  );
};
export default RealiziraneVrednostiProjekta;
