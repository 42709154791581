import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import MaterialTable from "material-table";
import { format } from "date-fns";

/*React-Bootstrap components */
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

/*Import Material-icons */
import Search from "@material-ui/icons/Search";

const FacultetUserTabProjects = ({
  role,
  showRejectedProjects,
  fakultetniProjekti,
  fakultetniProjektiVsi,
  setDataForDeleteModal,
}) => {
  const history = useNavigate();
  const [showFilters, setShowFilters] = useState(false);

  //Kreiramo dinamični šifrant (za filter v MaterialTable) za polje clanicaSkrbnik.
  const getSifrantClaniceUnique = [
    ...new Set(fakultetniProjekti.map((value) => value.clanicaSkrbnik)),
  ];
  const sifrantClaniceForMaterialTable = getSifrantClaniceUnique.reduce(
    (obj, value) => {
      obj[value] = value;
      return obj;
    },
    {}
  );

  return (
    <Row className="mt-4">
      <Col>
        <MaterialTable
          icons={{
            Filter: () => <Search style={{ fontSize: "15px" }} />,
          }}
          title={""}
          columns={[
            {
              title: "Skrbnik",
              field: "clanicaSkrbnik",
              width: null,
              cellStyle: { width: 100 },
              lookup: sifrantClaniceForMaterialTable,
            },
            {
              title: "Program",
              field: "programParent",
            },
            {
              title: "Podprogram",
              field: "program",
            },
            {
              title: "Naslov projekta",
              field: "naziv",
            },
            {
              title: "Vloga UM v projektu",
              field: "uMvloga",
              width: null,
              cellStyle: { width: 100 },
              lookup: {
                Koordinator: "Koordinator",
                Partner: "Partner",
                "Pridružen partner": "Pridružen partner",
              },
            },
            {
              title: "Datum vnosa",
              field: "timestamp",
              hidden: true,
              export: true,
            },
            {
              title: "Status",
              field: "status",
              width: null,
              cellStyle: { width: 100 },
              lookup: {
                Osnutek: "Osnutek",
                Prijavljen: "Prijavljen",
                "V izvajanju": "V izvajanju",
                Zaključen: "Zaključen",
                Zavrnjen: "Zavrnjen",
              },
            },
            {
              title: "Začetek projekta",
              field: "zacetek",
              type: "date",
              width: null,
              cellStyle: { width: 100 },
              render: (rowData) => {
                const date = new Date(rowData.zacetek);
                const result = format(date, "d. M. yyyy");
                return result;
              },
              customFilterAndSearch: (value, rowData) => {
                const datumVTabeli = new Date(rowData.zacetek).setHours(
                  0,
                  0,
                  0,
                  0
                );
                const izbranDatum = new Date(value).setHours(0, 0, 0, 0);

                return izbranDatum <= datumVTabeli;
              },
            },
            {
              title: "Konec projekta",
              field: "konec",
              type: "date",
              width: null,
              cellStyle: { width: 100 },
              render: (rowData) => {
                const date = new Date(rowData.konec);
                const result = format(date, "d. M. yyyy");
                return result;
              },
              customFilterAndSearch: (value, rowData) => {
                const datumVTabeli = new Date(rowData.konec).setHours(
                  0,
                  0,
                  0,
                  0
                );
                const izbranDatum = new Date(value).setHours(0, 0, 0, 0);
                return datumVTabeli <= izbranDatum;
              },
            },
            {
              title: "Datum vnosa",
              field: "timestamp",
              type: "datetime",
              render: (rowData) => {
                const date = new Date(rowData.timestamp);
                const result = format(date, "dd.MM.yyyy");
                return result;
              },
              export: true,
            },
          ]}
          data={
            !showRejectedProjects ? fakultetniProjekti : fakultetniProjektiVsi
          }
          localization={{
            toolbar: {
              searchPlaceholder: "Iskalnik",
              exportCSVName: "Izvozi podatke v CSV",
            },
            header: { actions: "Akcije" },
            body: {
              emptyDataSourceMessage: "Ni zapisov za prikaz",
            },
            pagination: {
              labelRowsSelect: "Vrstic",
              firstAriaLabel: "Prva stran",
              firstTooltip: "Prva stran",
              previousAriaLabel: "Prejšnja stran",
              previousTooltip: "Prejšnja stran",
              nextAriaLabel: "Naslednja stran",
              nextTooltip: "Naslednja stran",
              lastAriaLabel: "Zadnja stran",
              lastTooltip: "Zadnja stran",
              labelDisplayedRows: "{from}-{to} od {count}",
            },
          }}
          options={{
            paging: true,
            pageSize: 50,
            pageSizeOptions: [50, 100, 500],
            searchFieldAlignment: "left",
            filtering: showFilters,
            actionsColumnIndex: -1,
            headerStyle: { fontWeight: "bold" },
            exportButton: { csv: true, pdf: false },
            exportFileName: `projekti_${new Date()
              .toLocaleDateString("sl-SI")
              .replace(/\. /g, "-")}`,
          }}
          onRowClick={(event, rowData) => {
            history(`/Projekti/Podrobnosti/${rowData.id}`);
          }}
          actions={[
            {
              icon: "filter_alt",
              tooltip: "Omogoči filter",
              isFreeAction: true,
              onClick: (event) => {
                setShowFilters(!showFilters);
              },
            },
            (rowData) => ({
              hidden: role.projekti !== 2,
              icon: "article",
              tooltip: "Podrobnosti",
              onClick: (event, rowData) => {
                history(`/Projekti/Podrobnosti/${rowData.id}`);
              },
            }),

            (rowData) => ({
              hidden: rowData.skrbnik === false,
              icon: "delete",
              tooltip: "Odstrani",
              iconProps: { color: "error" },
              onClick: (event, rowData) => {
                setDataForDeleteModal(rowData.id, rowData.naziv);
              },
            }),
          ]}
        />
      </Col>
    </Row>
  );
};

export default FacultetUserTabProjects;
