import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import MaterialTable from 'material-table';

/*Import API*/
import { fetchGET, fetchDELETE } from '../apiHelper';
import {
	seznamUserjevAvtorizacije,
	brisanjeUporabnikovihAvtorizacij,
	seznamFakultetnihUserjevAvtorizacije
} from '../listOfApi';

/*import Material-icons*/
import Search from '@material-ui/icons/Search';

/* React-Bootstrap component */
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';

const UserRolesAuthorisation = (props) => {
	const [ usersList, setUsersList ] = useState([]);
	const [ userListFakultetni, setUserListFakultetni ] = useState([]);
	const [ showModal, setShowModal ] = useState(false);
	const [ idForRemoveUser, setIdForRemoveUser ] = useState('');
	const [ userUpn, setUserUpn ] = useState('');
	const [ showFilters, setShowFilters ] = useState(false);

	const history = useNavigate();

	const lookupRoleValue = { 0: 'Ni dostopa', 1: 'Obiskovalec', 2: 'Urednik' };

	const columns = [
		{
			title: 'Uporabnik',
			field: 'upn'
		},
		{ title: 'Programi', field: 'programi', lookup: lookupRoleValue },
		{ title: 'Projekti', field: 'projekti', lookup: lookupRoleValue },
		{
			title: 'Projektni partnerji',
			field: 'partnerji',
			lookup: lookupRoleValue
		},
		{
			title: 'Razpisi',
			field: 'razpisi',
			lookup: lookupRoleValue
		},
		{
			title: 'Aktualni razpisi',
			field: 'aktualniRazpisi',
			lookup: lookupRoleValue
		},
		{
			title: 'Super admin',
			field: 'admin',
			lookup: { true: 'Da', false: 'Ne' }
		}
	];

	const columnsFakultetni = [
		{
			title: 'Uporabnik',
			field: 'upn'
		},
		{ title: 'Članica', field: 'clanica' }
	];

	useEffect(() => {
		fetchGET(seznamUserjevAvtorizacije).then((usersRoles) => {
			if (usersRoles !== 0) {
				const users = usersRoles.map((item) => {
					return {
						id: item.id,
						upn: item.upn,
						programi: item.programi,
						projekti: item.projekti,
						partnerji: item.partnerji,
						razpisi: item.razpisi,
						aktualniRazpisi: item.aktualniRazpisi,
						admin: item.admin
					};
				});
				setUsersList(users);
			} else {
			}
		});
	}, []);

	useEffect(() => {
		fetchGET(seznamFakultetnihUserjevAvtorizacije).then((usersRoles) => {
			if (usersRoles !== 0) {
				const users = usersRoles.map((item) => {
					return {
						id: item.id,
						upn: item.upn,
						clanica: item.clanica
					};
				});
				setUserListFakultetni(users);
			} else {
			}
		});
	}, []);

	const handleUserDelete = () => {
		fetchDELETE(`${brisanjeUporabnikovihAvtorizacij}/${idForRemoveUser}`).then((deleted) => {
			if (deleted === 1) {
				setShowModal(false);
				window.location.reload();
			} else {
				//TODO: prišlo je do napake pri brisanju podatkov.
			}
		});
	};

	return (
		<Container fluid>
			<Modal
				show={showModal}
				onHide={() => {
					setShowModal(false);
				}}
			>
				<Modal.Body>
					Želite izbrisati uporabnika: <strong>{userUpn}</strong> ?
				</Modal.Body>
				<Modal.Footer>
					<Button className="btn btn-danger" variant="secondary" onClick={handleUserDelete}>
						Izbriši
					</Button>
					<Button
						variant="primary"
						onClick={() => {
							setShowModal(false);
						}}
					>
						Ne
					</Button>
				</Modal.Footer>
			</Modal>
			<Row>
				<Col className="main-header font-weight-bold">Avtorizacije</Col>
			</Row>
			<Row>
				<Col className="header-divider">
					<hr />
				</Col>
			</Row>
			<Row>
				<Col>
					<Tabs defaultActiveKey="globalneAvtorizacije">
						<Tab eventKey="globalneAvtorizacije" title="Globalne avtorizacije">
							<Row>
								<Col>
									<div className="float-end mt-3">
										<Link className="programs-button-add-text" to={`/Avtorizacije/Dodaj`}>
											<Button variant="outline-primary">Nova avtorizacija</Button>
										</Link>
									</div>
								</Col>
							</Row>
							<Row className="mt-4">
								<Col>
									<MaterialTable
										icons={{
											Filter: () => <Search style={{ fontSize: '15px' }} />
										}}
										title=""
										columns={columns}
										data={usersList}
										localization={{
											toolbar: {
												searchPlaceholder: 'Iskalnik',
												exportCSVName: 'Izvozi podatke v CSV'
											},
											header: { actions: 'Akcije' },
											body: {
												emptyDataSourceMessage: 'Ni zapisov za prikaz'
											},
											pagination: {
												labelRowsSelect: 'Vrstic',
												firstAriaLabel: 'Prva stran',
												firstTooltip: 'Prva stran',
												previousAriaLabel: 'Prejšnja stran',
												previousTooltip: 'Prejšnja stran',
												nextAriaLabel: 'Naslednja stran',
												nextTooltip: 'Naslednja stran',
												lastAriaLabel: 'Zadnja stran',
												lastTooltip: 'Zadnja stran',
												labelDisplayedRows: '{from}-{to} od {count}'
											}
										}}
										options={{
											paging: true,
											pageSize: 50,
											pageSizeOptions: [ 50, 100, 500 ],
											searchFieldAlignment: 'left',
											filtering: showFilters,
											actionsColumnIndex: -1,
											headerStyle: { fontWeight: 'bold' },
											exportButton: { csv: true, pdf: false },
											exportFileName: `avtorizacije_${new Date()
												.toLocaleDateString('sl-SI')
												.replace(/\. /g, '-')}`
										}}
										actions={[
											{
												icon: 'edit',
												tooltip: 'Uredi',
												onClick: (event, rowData) => {
													history(`/avtorizacije/uredi/${rowData.id}`);
												}
											},
											{
												icon: 'delete',
												tooltip: 'Odstrani',
												iconProps: { color: 'error' },
												onClick: (event, rowData) => {
													setIdForRemoveUser(rowData.id);
													setUserUpn(rowData.upn);
													setShowModal(true);
												}
											},
											{
												icon: 'filter_alt',
												tooltip: 'Omogoči filter',
												isFreeAction: true,
												onClick: (event) => {
													setShowFilters(!showFilters);
												}
											}
										]}
									/>
								</Col>
							</Row>
						</Tab>
						<Tab eventKey="fakultetneAvtorizacije" title="Fakultetne avtorizacije">
							<Row>
								<Col>
									<div className="float-end mt-3">
										<Link
											className="programs-button-add-text"
											to={`/Avtorizacije/DodajFakultetnega`}
										>
											<Button variant="outline-primary">Nova fakultetna avtorizacija</Button>
										</Link>
									</div>
								</Col>
							</Row>
							<Row className="mt-4">
								<Col>
									<MaterialTable
										icons={{
											Filter: () => <Search style={{ fontSize: '15px' }} />
										}}
										title=""
										columns={columnsFakultetni}
										data={userListFakultetni}
										localization={{
											toolbar: {
												searchPlaceholder: 'Iskalnik',
												exportCSVName: 'Izvozi podatke v CSV'
											},
											header: { actions: 'Akcije' },
											body: {
												emptyDataSourceMessage: 'Ni zapisov za prikaz'
											},
											pagination: {
												labelRowsSelect: 'Vrstic',
												firstAriaLabel: 'Prva stran',
												firstTooltip: 'Prva stran',
												previousAriaLabel: 'Prejšnja stran',
												previousTooltip: 'Prejšnja stran',
												nextAriaLabel: 'Naslednja stran',
												nextTooltip: 'Naslednja stran',
												lastAriaLabel: 'Zadnja stran',
												lastTooltip: 'Zadnja stran',
												labelDisplayedRows: '{from}-{to} od {count}'
											}
										}}
										options={{
											paging: true,
											pageSize: 50,
											pageSizeOptions: [ 50, 100, 500 ],
											searchFieldAlignment: 'left',
											filtering: showFilters,
											actionsCellStyle: {
												display: 'flex',
												justifyContent: 'center',
												padding: '3px',
												width: '100%'
											},
											actionsColumnIndex: -1,
											headerStyle: { fontWeight: 'bold' },
											exportButton: { csv: true, pdf: false },
											exportFileName: `avtorizacije_${new Date()
												.toLocaleDateString('sl-SI')
												.replace(/\. /g, '-')}`
										}}
										actions={[
											{
												icon: 'edit',
												tooltip: 'Uredi',
												onClick: (event, rowData) => {
													history(`/avtorizacije/uredi/fakultetni/${rowData.id}`);
												}
											},
											{
												icon: 'delete',
												tooltip: 'Odstrani',
												iconProps: { color: 'error' },
												onClick: (event, rowData) => {
													setIdForRemoveUser(rowData.id);
													setUserUpn(rowData.upn);
													setShowModal(true);
												}
											},
											{
												icon: 'filter_alt',
												tooltip: 'Omogoči filter',
												isFreeAction: true,
												onClick: (event) => {
													setShowFilters(!showFilters);
												}
											}
										]}
									/>
								</Col>
							</Row>
						</Tab>
					</Tabs>
				</Col>
			</Row>
		</Container>
	);
};

export default UserRolesAuthorisation;
