import React, { useContext, useEffect, useState } from "react";

/* Import Context */
import { UserContext } from "../UserContext";

/* Import React-Bootstrap */
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import SelectSearch from "react-select-search";
import Select from "react-select";
import Modal from "react-bootstrap/Modal";

/* Import React-Icons */
import { AiFillStar, AiOutlineEdit } from "react-icons/ai";
import { BiTrash } from "react-icons/bi";

/*Import Api helper */
import { fetchGET } from "../../apiHelper";
import { claniceUM } from "../../listOfApi";

const SodelujoceClanice = ({
  projectSkrbnikClanica,
  projectSodelujocaClanicaPost,
  sifrantVloge,
  kontaktneOsebeSeznam,
  seznamSodelujocihClanic,
  kontaktnaOsebaPodrobnosti,
  selectKontaktnaOsebaSodelujoceClanice,
  projectSodelujocaClanicaEdit,
  DropdownIndicator,
  showErrorDodajanjeSodelujoceClanice,
  showKontaktniPodatkiOsebaSodelujoceClanice,
  showAddSodelujocaClanica,
  showUrejanjeSodelujocaClanica,
  dodajanjeKontaktnihError,
  formSelectValidationSodelujocaClanica,
  handleSubmitFormAddSodelujocaClanica,
  setStateShowKontaktniPodatkiOsebaSodelujoceClanice,
  setStateProjectSodelujocaClanicaPost,
  seStateShowAddSodelujocaClanica,
  setStateSodelujocaClanicaId,
  setStateShowUrejanjeSodelujocaClanica,
  setStateProjectSodelujocaClanicaEdit,
  selectKontaktnaOsebaSodelujoceClaniceUrejanje,
  handleDeleteSodelujocaClanica,
  handleSubmitFormUrediSodelujocaClanica,
  removeKontaktnaOsebaFromSodelujocaClanica,
}) => {
  const [sifrantClanice, setSifrantClanice] = useState([]);
  const [menuOpen, setMenuOpen] = useState(false);
  const [sodelujocaClanicaNaziv, setSodelujocaClanicaNaziv] = useState("");
  const [
    showModalDeleteSodelujocaClanica,
    setShowModalDeleteSodelujocaClanica,
  ] = useState(false);

  const role = useContext(UserContext);

  const [value, setValue] = useState({});

  /* GET - ČLANICE NA PROJEKTU SIFRANT */
  useEffect(() => {
    fetchGET(claniceUM).then((clanice) => {
      if (clanice !== 0) {
        let claniceProjekti = clanice.map((clanica) => {
          return { name: clanica.naziv, value: clanica.id };
        });
        setSifrantClanice(claniceProjekti);
      }
    });
  }, []);

  /* Preverimo če obstaja kisID kontakne osebe v šifrantu zaposlenih */
  useEffect(() => {
    if (projectSodelujocaClanicaEdit.kisId !== 0) {
      const kontaknaOseba =
        kontaktneOsebeSeznam[
          kontaktneOsebeSeznam.findIndex(
            (x) => x.value === projectSodelujocaClanicaEdit.kisID.toString()
          )
        ];

      /*Preverimo v primeru da se odstrani osebo iz projekta, da nastavimo default prazni label v Select */
      if (kontaknaOseba === undefined) {
        setValue(null);
      } else {
        setValue(kontaknaOseba);
      }
    }
  }, [projectSodelujocaClanicaEdit.kisID]);

  return (
    <>
      <Modal
        show={showModalDeleteSodelujocaClanica}
        onHide={() => {
          setShowModalDeleteSodelujocaClanica(false);
        }}
      >
        <Modal.Body>
          Želite odstraniti sodelujočo članico:{" "}
          <strong>{sodelujocaClanicaNaziv}</strong>?
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="btn btn-danger"
            variant="secondary"
            onClick={() => {
              handleDeleteSodelujocaClanica();
              setShowModalDeleteSodelujocaClanica(false);
            }}
          >
            Izbriši
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              setShowModalDeleteSodelujocaClanica(false);
            }}
          >
            Ne
          </Button>
        </Modal.Footer>
      </Modal>
      <Card className="mt-2">
        <Card.Body>
          <Row className="d-flex align-items-center">
            <Col>
              <div className="float-end">
                {((role.skrbnik === true &&
                  role.clanicaID === projectSkrbnikClanica) ||
                  (role.skrbnik === false && role.projekti === 2)) && (
                  <Button
                    className="org-button-details-text"
                    id="1"
                    onClick={seStateShowAddSodelujocaClanica}
                  >
                    <AiOutlineEdit />
                    <span>Dodaj sodelujočo članico na projekt</span>
                  </Button>
                )}
              </div>
            </Col>
          </Row>
          <Row className="project-partners-details-card">
            <Col>
              <div className="p-4">
                {showAddSodelujocaClanica && (
                  <Row>
                    <Col className="mb-3">
                      <Form>
                        <Card className="shadow-sm rounded">
                          <Card.Body>
                            <Row>
                              {showErrorDodajanjeSodelujoceClanice && (
                                <Col className="mt-3 mb-3">
                                  <h5 className="text-danger">
                                    Dodajanje sodelujoče članice UM ni uspelo/
                                    Članica UM je že dodana na ta projekt!
                                  </h5>
                                </Col>
                              )}
                            </Row>
                            <Row>
                              {formSelectValidationSodelujocaClanica && (
                                <Col className="mt-3 mb-3">
                                  <h4 className="text-danger">
                                    Niste izbrali vseh potrebnih podatkov za
                                    dodajanje sodelujoče članice UM!
                                  </h4>
                                </Col>
                              )}
                            </Row>
                            <Row>
                              <Col>
                                <Form.Label>Članica UM</Form.Label>
                                <SelectSearch
                                  options={sifrantClanice}
                                  onChange={(value) =>
                                    setStateProjectSodelujocaClanicaPost(
                                      value,
                                      null,
                                      "clanicaId"
                                    )
                                  }
                                  value={projectSodelujocaClanicaPost.clanicaID}
                                  search
                                  name="selectClanica"
                                  placeholder="Članica"
                                />
                              </Col>
                              <Col>
                                <Form.Label>Vloga članice UM</Form.Label>
                                <SelectSearch
                                  options={sifrantVloge}
                                  onChange={(value) =>
                                    setStateProjectSodelujocaClanicaPost(
                                      null,
                                      value
                                    )
                                  }
                                  value={projectSodelujocaClanicaPost.vlogaID}
                                  search
                                  name="selectVloga"
                                  placeholder="Vloga na projektu"
                                />
                              </Col>
                            </Row>
                            <Row className="mt-3">
                              <Col>
                                <Form.Label>
                                  Izberi kontaktno osebo članice UM
                                </Form.Label>
                                <Select
                                  onKeyDown={
                                    setStateShowKontaktniPodatkiOsebaSodelujoceClanice
                                  }
                                  isClearable="true"
                                  backspaceRemovesValue={true}
                                  onChange={
                                    selectKontaktnaOsebaSodelujoceClanice
                                  }
                                  onInputChange={(value) => {
                                    let menuIsOpen = false;
                                    if (value) {
                                      menuIsOpen = true;
                                    }
                                    setMenuOpen(menuIsOpen);
                                  }}
                                  menuIsOpen={menuOpen}
                                  menuPortalTarget={document.body}
                                  styles={{
                                    menuPortal: (base) => ({
                                      ...base,
                                      zIndex: 9999,
                                    }),
                                  }}
                                  options={kontaktneOsebeSeznam}
                                  placeholder="Vpišite ime in primek osebe"
                                  isSearchable
                                  components={{
                                    DropdownIndicator,
                                  }}
                                />
                                {showKontaktniPodatkiOsebaSodelujoceClanice && (
                                  <>
                                    <Row className="mt-3">
                                      <Col>
                                        <span>Podatki za najdeno osebo</span>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col>
                                        <Card className="ms-4 me-4">
                                          <Card.Body>
                                            <Row>
                                              <Col>
                                                <h5>
                                                  {
                                                    kontaktnaOsebaPodrobnosti.naziv
                                                  }
                                                </h5>
                                              </Col>
                                              <Col>
                                                <span>Članica:</span>{" "}
                                                {
                                                  kontaktnaOsebaPodrobnosti.clanica
                                                }
                                              </Col>
                                            </Row>
                                          </Card.Body>
                                        </Card>
                                      </Col>
                                    </Row>
                                  </>
                                )}
                              </Col>
                            </Row>
                            <div className="d-flex mt-4">
                              <Button
                                variant="primary"
                                className="me-2"
                                onClick={handleSubmitFormAddSodelujocaClanica}
                              >
                                Dodaj
                              </Button>
                              <Button
                                variant="outline-primary ms-3"
                                onClick={() =>
                                  seStateShowAddSodelujocaClanica("preklici")
                                }
                              >
                                Prekliči
                              </Button>
                            </div>
                          </Card.Body>
                        </Card>
                      </Form>
                    </Col>
                  </Row>
                )}
                <Row>
                  {dodajanjeKontaktnihError && (
                    <Col className="mt-3 mb-3">
                      <h5 className="text-danger">
                        Dodajanje/urejanje kontaktnih podatkov ni uspelo!
                      </h5>
                    </Col>
                  )}
                </Row>
                {seznamSodelujocihClanic?.map((clanica, index) => {
                  return (
                    <Row key={index}>
                      <Col className="mb-3">
                        <Card
                          key={index}
                          className={
                            clanica.skrbnik === true &&
                            "projekti-sodelujoce-clanice_background"
                          }
                        >
                          <Card.Body className="p-3">
                            {!showUrejanjeSodelujocaClanica[
                              clanica.clanicaID
                            ] && (
                              <Row className="align-items-center">
                                <Col lg={2} id={clanica.clanicaID}>
                                  {clanica.skrbnik === true && (
                                    <AiFillStar
                                      data-tooltip-id="tab-tooltip"
                                      data-tooltip-content={"Skrbnik projekta"}
                                      className="text-warning mb-1"
                                    />
                                  )}{" "}
                                  {clanica.clanica}
                                </Col>
                                <Col>
                                  <span>Vloga članice UM: </span>
                                  {clanica.vloga}
                                </Col>
                                <Col>
                                  <span>Kontaktna oseba članice UM: </span>
                                  {clanica.kisID !== 0
                                    ? clanica.oseba
                                    : "Ni podatka"}
                                </Col>
                                <Col lg={1}>
                                  <span>KIS: </span>
                                  {clanica.kisID !== 0
                                    ? clanica.kisID
                                    : "Ni podatka"}
                                </Col>
                                {((role.skrbnik === true &&
                                  role.clanicaID === projectSkrbnikClanica) ||
                                  (role.skrbnik === false &&
                                    role.projekti === 2)) && (
                                  <Col lg={1}>
                                    <>
                                      <Button
                                        className="avtorizacije-button-trash float-end"
                                        onClick={() => {
                                          setStateSodelujocaClanicaId(
                                            clanica.clanicaID
                                          );
                                          setShowModalDeleteSodelujocaClanica(
                                            true
                                          );
                                          setSodelujocaClanicaNaziv(
                                            clanica.clanica
                                          );
                                        }}
                                      >
                                        <BiTrash className="text-danger" />
                                      </Button>
                                      <Button
                                        className="org-button-details-text float-end"
                                        id={clanica.clanicaID}
                                        onClick={(e) => {
                                          setStateShowUrejanjeSodelujocaClanica(
                                            e.currentTarget.id
                                          );
                                          setStateProjectSodelujocaClanicaEdit(
                                            clanica.oseba,
                                            clanica.clanicaID,
                                            clanica.vlogaID,
                                            clanica.kisID,
                                            clanica.skrbnik
                                          );
                                        }}
                                      >
                                        <AiOutlineEdit />
                                      </Button>
                                    </>
                                  </Col>
                                )}
                              </Row>
                            )}

                            {showUrejanjeSodelujocaClanica[
                              clanica.clanicaID
                            ] && (
                              <Row>
                                <Col className="mb-3">
                                  <Form>
                                    <Card className="shadow-sm rounded">
                                      <Card.Body>
                                        <Row>
                                          {showErrorDodajanjeSodelujoceClanice && (
                                            <Col className="mt-3 mb-3">
                                              <h5 className="text-danger">
                                                Dodajanje sodelujoče članice UM
                                                ni uspelo/ Članica UM je že
                                                dodana na ta projekt!
                                              </h5>
                                            </Col>
                                          )}
                                        </Row>
                                        <Row>
                                          {formSelectValidationSodelujocaClanica && (
                                            <Col className="mt-3 mb-3">
                                              <h4 className="text-danger">
                                                Niste izbrali vseh potrebnih
                                                podatkov za dodajanje sodelujoče
                                                članice UM!
                                              </h4>
                                            </Col>
                                          )}
                                        </Row>
                                        <Row>
                                          <Col>
                                            <Form.Label>Članica UM</Form.Label>
                                            <SelectSearch
                                              options={sifrantClanice}
                                              disabled
                                              value={
                                                projectSodelujocaClanicaEdit.clanicaID
                                              }
                                              search
                                              name="selectClanica"
                                              placeholder="Članica"
                                            />
                                          </Col>
                                          <Col>
                                            <Form.Label>
                                              Vloga članice UM
                                            </Form.Label>
                                            <SelectSearch
                                              options={sifrantVloge}
                                              onChange={(value) =>
                                                setStateProjectSodelujocaClanicaEdit(
                                                  null,
                                                  null,
                                                  value,
                                                  null,
                                                  null,
                                                  "vlogaId"
                                                )
                                              }
                                              value={
                                                projectSodelujocaClanicaEdit.vlogaID
                                              }
                                              search
                                              name="selectVloga"
                                              placeholder="Vloga na projektu"
                                            />
                                          </Col>
                                        </Row>
                                        <Row className="mt-3">
                                          <Col>
                                            <Form.Label>
                                              Izberi kontaktno osebo članice
                                            </Form.Label>
                                            <Select
                                              value={value}
                                              onKeyDown={(e) => {
                                                setStateShowKontaktniPodatkiOsebaSodelujoceClanice();
                                              }}
                                              isClearable={true}
                                              backspaceRemovesValue={true}
                                              onChange={
                                                selectKontaktnaOsebaSodelujoceClaniceUrejanje
                                              }
                                              onInputChange={(value) => {
                                                let menuIsOpen = false;
                                                if (value) {
                                                  menuIsOpen = true;
                                                }
                                                setMenuOpen(menuIsOpen);
                                              }}
                                              menuIsOpen={menuOpen}
                                              menuPortalTarget={document.body}
                                              styles={{
                                                menuPortal: (base) => ({
                                                  ...base,
                                                  zIndex: 9999,
                                                }),
                                              }}
                                              options={kontaktneOsebeSeznam}
                                              placeholder="Vpišite ime in primek osebe"
                                              isSearchable
                                              components={{
                                                DropdownIndicator,
                                              }}
                                            />
                                            {showKontaktniPodatkiOsebaSodelujoceClanice && (
                                              <>
                                                <Row className="mt-3">
                                                  <Col>
                                                    <span>
                                                      Podatki za najdeno osebo
                                                    </span>
                                                  </Col>
                                                </Row>
                                                <Row>
                                                  <Col>
                                                    <Card className="ms-4 me-4">
                                                      <Card.Body>
                                                        <Row className="d-flex align-items-center">
                                                          <Col>
                                                            <h5 className="mb-0">
                                                              {
                                                                kontaktnaOsebaPodrobnosti.naziv
                                                              }
                                                            </h5>
                                                          </Col>
                                                          <Col className="mb-0">
                                                            <span>
                                                              Članica:
                                                            </span>{" "}
                                                            {
                                                              kontaktnaOsebaPodrobnosti.clanica
                                                            }
                                                          </Col>
                                                          <Col className="text-end">
                                                            <Button
                                                              variant="outline-primary ms-2"
                                                              onClick={() => {
                                                                /* Nastavimo value za Select */
                                                                setValue(null);
                                                                /* Odstranimo vrednosti prejšnje kontaktne osebe pri sodelujoči članici */
                                                                removeKontaktnaOsebaFromSodelujocaClanica();
                                                              }}
                                                            >
                                                              Odstrani osebo
                                                            </Button>
                                                          </Col>
                                                        </Row>
                                                      </Card.Body>
                                                    </Card>
                                                  </Col>
                                                </Row>
                                              </>
                                            )}
                                          </Col>
                                        </Row>
                                        <Row>
                                          <Col className={"mt-4 mb-2"}>
                                            <hr />
                                            {clanica.skrbnik === true && (
                                              <>
                                                <AiFillStar
                                                  data-tooltip-id="tab-tooltip"
                                                  data-tooltip-content={
                                                    "Skrbnik projekta"
                                                  }
                                                  className="text-warning mb-1"
                                                />
                                                <strong className="ms-2">
                                                  Ta članica je skrbnik{" "}
                                                </strong>
                                              </>
                                            )}
                                            {clanica.skrbnik === false && (
                                              <>
                                                <span>
                                                  <AiFillStar
                                                    data-tooltip-id="tab-tooltip"
                                                    data-tooltip-content={
                                                      "Skrbnik projekta"
                                                    }
                                                    className="text-warning mb-1"
                                                  />{" "}
                                                  Skrbnik projekta
                                                </span>
                                                <Form.Check
                                                  className={"mt-2 ms-3"}
                                                  onChange={() => {
                                                    setStateProjectSodelujocaClanicaEdit(
                                                      null,
                                                      null,
                                                      null,
                                                      null,
                                                      !projectSodelujocaClanicaEdit.skrbnik,
                                                      "skrbnik"
                                                    );
                                                  }}
                                                  type="checkbox"
                                                  label=" Izbrano članico določi za skrbnika projekta!"
                                                />
                                              </>
                                            )}
                                          </Col>
                                        </Row>
                                        <Row className="mt-4">
                                          <Col>
                                            <Button
                                              variant="primary"
                                              className="me-2"
                                              onClick={
                                                handleSubmitFormUrediSodelujocaClanica
                                              }
                                            >
                                              Potrdi
                                            </Button>
                                            <Button
                                              variant="outline-primary ms-2"
                                              onClick={() =>
                                                setStateShowUrejanjeSodelujocaClanica(
                                                  null,
                                                  "preklici"
                                                )
                                              }
                                            >
                                              Prekliči
                                            </Button>
                                          </Col>
                                        </Row>
                                      </Card.Body>
                                    </Card>
                                  </Form>
                                </Col>
                              </Row>
                            )}
                          </Card.Body>
                        </Card>
                      </Col>
                    </Row>
                  );
                })}
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

export default SodelujoceClanice;
