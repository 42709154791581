import React, {useContext, useState} from 'react';

/* Import Context */
import { UserContext } from '../UserContext';

/* Import React-Bootstrap */
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Select from "react-select";
import Modal from "react-bootstrap/Modal";

/*Import React-Icons */
import {BiTrash} from "react-icons/bi";
import {AiOutlineEdit} from "react-icons/ai";


const SodelujoceOsebe = ({
                             projectOsnovni,
                             projectSkrbnikClanica,
                             kontaktnaOsebaPodrobnosti,
                             kontaktneOsebeSeznam,
                             showErrorDodajanjeKontaktneOsebe,
                             showPodatkiZaNajdenoOsebo,
                             showEditKontaktnaOsebaDatum,
                             seznamKontaktnihOseb,
                             setStateShowPodatkiZaNajdenoOsebo,
                             setStateSodelujoceOsebeDropDown,
                             setStateShowEditKontaktnaOsebaDatum,
                             setStateEditKontaktnaOsebaDatum,
                             selectKontaktnaOseba,
                             setStateKontaktnaOsebaId,
                             handleAddKontaktnaOseba,
                             handleSubmitFormUrediKontaktDatum,
                             handleDeleteKontaktnaOseba,
                             dodajanjeKontaktnihError,
                             DropdownIndicator}) => {

    const [showAddPartner, setShowAddPartner] = useState(false);
    const [menuOpenSodelujocaOseba, setMenuSodelujocaOseba] = useState(false);
    const [showPodrobnostiZaKonataktnoOsebo, setShowPodrobnostiZaKontaktnoOsebo,] = useState(false);
    const [showModalDeleteKontaktnaOseba, setShowModalDeleteKontaktnaOseba] = useState(false);
    const [kontaktnaOsebaNaziv, setKontaktnaOsebaNaziv] = useState('');

    const role = useContext(UserContext);

    /* SODELUJOČE OSEBE - Handle struktura za prikaz podrobnosti kontakne osebe */
    const handleStructureKontaktnaOsebaProjekta = (e) => {
        const id = e.currentTarget.id;

        const idNumber = Object.keys(showPodrobnostiZaKonataktnoOsebo);
        const getID = idNumber.filter((idKey) => idKey === id);

        let value;
        for (let key in showPodrobnostiZaKonataktnoOsebo) {
            if (showPodrobnostiZaKonataktnoOsebo.hasOwnProperty(key)) {
                if (key === getID[0]) {
                    value = showPodrobnostiZaKonataktnoOsebo[key];
                }
            }
        }

        if (id === getID[0]) {
            if (value === false) {
                setShowPodrobnostiZaKontaktnoOsebo(
                    (showPodrobnostiZaKonataktnoOsebo) => ({
                        ...showPodrobnostiZaKonataktnoOsebo,
                        [id]: true,
                    })
                );
            } else {
                setShowPodrobnostiZaKontaktnoOsebo(
                    (showPodrobnostiZaKonataktnoOsebo) => ({
                        ...showPodrobnostiZaKonataktnoOsebo,
                        [id]: false,
                    })
                );
            }
        } else {
            setShowPodrobnostiZaKontaktnoOsebo(
                (showPodrobnostiZaKonataktnoOsebo) => ({
                    ...showPodrobnostiZaKonataktnoOsebo,
                    [id]: true,
                })
            );
        }
    };

    return (
        <>
            <Modal
                show={showModalDeleteKontaktnaOseba}
                onHide={() => {
                    setShowModalDeleteKontaktnaOseba(false);
                }}
            >
                <Modal.Body>
                    Želite odstraniti kontaktno osebo:{' '}
                    <strong>{kontaktnaOsebaNaziv}</strong>?
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        className='btn btn-danger'
                        variant='secondary'
                        onClick={()=> {
                            handleDeleteKontaktnaOseba()
                            setShowModalDeleteKontaktnaOseba(false);
                        }}
                    >
                        Izbriši
                    </Button>
                    <Button
                        variant='primary'
                        onClick={() => {
                            setShowModalDeleteKontaktnaOseba(false);
                        }}
                    >
                        Ne
                    </Button>
                </Modal.Footer>
            </Modal>
            <Card className='mt-3'>
            <Card.Header>
                <Row className='align-items-center'>
                    <Col>
                        <h5 className='mb-0'>Sodelujoče osebe UM</h5>
                    </Col>
                    <Col>
                        <div className='float-end'>
                            {((role.skrbnik === true && role.clanicaID === projectSkrbnikClanica) || (role.skrbnik === false && role.projekti === 2)) && (
                                <Button
                                    className='org-button-details-text'
                                    id='1'
                                    onClick={() => setShowAddPartner(!showAddPartner)}
                                >
                                    <AiOutlineEdit/>
                                    <span>Dodaj sodelujočo osebo UM</span>
                                </Button>
                            )}
                        </div>
                    </Col>
                </Row>
            </Card.Header>
            <Card.Body>
                <Row className='project-partners-details-card'>
                    <Col>
                        <div className='p-4'>
                            {showAddPartner && (
                                <Row>
                                    <Col className='mb-3'>
                                        <Form>
                                            <Card className='shadow-sm rounded'>
                                                <Card.Body>
                                                    <Row className='d-flex align-items-center ms-2 me-2'>
                                                        <Col lg={10}>
                                                            <Select
                                                                onKeyDown={setStateShowPodatkiZaNajdenoOsebo}
                                                                isClearable='true'
                                                                backspaceRemovesValue={true}
                                                                onChange={selectKontaktnaOseba}
                                                                onInputChange={(value) => {
                                                                    let menuIsOpen = false;
                                                                    if (value) {
                                                                        menuIsOpen = true;
                                                                    }
                                                                    setMenuSodelujocaOseba(menuIsOpen);
                                                                }}
                                                                menuIsOpen={menuOpenSodelujocaOseba}
                                                                menuPortalTarget={document.body}
                                                                styles={{
                                                                    menuPortal: (base) => ({
                                                                        ...base,
                                                                        zIndex: 9999,
                                                                    }),
                                                                }}
                                                                options={kontaktneOsebeSeznam}
                                                                placeholder='Vpišite ime in primek osebe'
                                                                isSearchable
                                                                components={{DropdownIndicator}}
                                                            />
                                                        </Col>
                                                        <Col lg={2}>
                                                            <Button
                                                                variant='outline-primary'
                                                                className="projectiDetails-buttonWidth w-100"
                                                                onClick={() => setShowAddPartner(!showAddPartner)}>
                                                                Prekliči
                                                            </Button>
                                                        </Col>
                                                    </Row>

                                                    {showErrorDodajanjeKontaktneOsebe && (
                                                        <Col className='mt-3 mb-3'>
                                                            <h4 className='text-danger'>
                                                                Prišlo je do napake pri
                                                                dodajanju
                                                                kontaktne oseba na projekt!
                                                            </h4>
                                                        </Col>
                                                    )}
                                                    {showPodatkiZaNajdenoOsebo && (
                                                        <>
                                                            <Row className='ms-2 mt-2 me-2'>
                                                                <Col>
                                                                  <span>
                                                                    Podatki za najdeno osebo
                                                                  </span>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col>
                                                                    <Card className='ms-4 me-4'>
                                                                        <Card.Body>
                                                                            <Row>
                                                                                <Col>
                                                                                    <h5>
                                                                                        {
                                                                                            kontaktnaOsebaPodrobnosti.naziv
                                                                                        }
                                                                                    </h5>
                                                                                </Col>
                                                                                <Col>
                                                                                    <span>Članica:</span>{' '}
                                                                                    {
                                                                                        kontaktnaOsebaPodrobnosti.clanica
                                                                                    }
                                                                                </Col>
                                                                            </Row>

                                                                            <Row
                                                                                className='mt-3'>
                                                                                <Col>
                                                                                    <div className='d-flex'>
                                                                                        <span className='me-2 pt-2'>
                                                                                          Od:
                                                                                        </span>
                                                                                        <Form.Control
                                                                                            name='zacetek'
                                                                                            value={projectOsnovni.zacetek}
                                                                                            type='date'
                                                                                            onChange={(e) => {setStateSodelujoceOsebeDropDown(e.target.value, "zacetek")}}
                                                                                        />
                                                                                    </div>
                                                                                </Col>
                                                                                <Col>
                                                                                    <div className='d-flex'>
                                                                                        <span className='me-2 pt-2'>
                                                                                          Do:
                                                                                        </span>
                                                                                        <Form.Control
                                                                                            name='zakljucek'
                                                                                            value={projectOsnovni.konec}
                                                                                            type='date'
                                                                                            onChange={(e) => {setStateSodelujoceOsebeDropDown(e.target.value)}}
                                                                                        />
                                                                                    </div>
                                                                                </Col>
                                                                            </Row>
                                                                            <Row
                                                                                className='mt-4'>
                                                                                <Col>
                                                                                    <Button
                                                                                        variant='primary'
                                                                                        type='submit'
                                                                                        className='me-3'
                                                                                        onClick={handleAddKontaktnaOseba}
                                                                                    >
                                                                                        Dodaj na
                                                                                        projekt
                                                                                    </Button>
                                                                                    <Button
                                                                                        variant='outline-primary'
                                                                                        onClick={() => setShowAddPartner(!showAddPartner)}>
                                                                                        Prekliči
                                                                                    </Button>
                                                                                </Col>
                                                                            </Row>
                                                                        </Card.Body>
                                                                    </Card>
                                                                </Col>
                                                            </Row>
                                                        </>
                                                    )}
                                                </Card.Body>
                                            </Card>
                                        </Form>
                                    </Col>
                                </Row>
                            )}
                            <Row>
                                {dodajanjeKontaktnihError && (
                                    <Col className='mt-3 mb-3'>
                                        <h5 className='text-danger'>
                                            Dodajanje/urejanje kontaktnih podatkov ni
                                            uspelo!
                                        </h5>
                                    </Col>
                                )}
                            </Row>
                            {seznamKontaktnihOseb?.map((oseba, index) => {
                                return (
                                    <Row className='mt-3' key={index}>
                                        <Col className='mb-3'>
                                            <Card>
                                                <Card.Body className='p-3'>
                                                    <Row className='align-items-center'>
                                                        <Col
                                                            id={oseba.kisID}
                                                            lg={6}
                                                            onClick={
                                                                handleStructureKontaktnaOsebaProjekta
                                                            }
                                                        >
                                                            <h5 className='mb-0 kontaktna-oseba-details-structure-naslov__hover'>
                                                                {oseba.naziv}
                                                            </h5>
                                                        </Col>
                                                        {showEditKontaktnaOsebaDatum[
                                                            oseba.kisID
                                                            ] && (
                                                            <>
                                                                <Col>
                                                                    <div className='d-flex'>
                                                                        <span className='me-2 pt-2'>
                                                                          Od:
                                                                        </span>
                                                                        <Form.Control
                                                                            name='zacetek'
                                                                            type='date'
                                                                            defaultValue={oseba.od}
                                                                            onChange={(e) => {setStateEditKontaktnaOsebaDatum(oseba.kisID, e.target.value, null, "od")}}
                                                                        />
                                                                    </div>
                                                                </Col>
                                                                <Col>
                                                                    <div className='d-flex'>
                                                                        <span className='me-2 pt-2'>
                                                                          Do:
                                                                        </span>
                                                                        <Form.Control
                                                                            name='zakljucek'
                                                                            type='date'
                                                                            defaultValue={oseba.do}
                                                                            onChange={(e) => {setStateEditKontaktnaOsebaDatum(oseba.kisID, null, e.target.value, "do")}}
                                                                        />
                                                                    </div>
                                                                </Col>
                                                                <Col>
                                                                    <Button
                                                                        variant='primary'
                                                                        className='me-2'
                                                                        id={oseba.kisID}
                                                                        onClick={
                                                                            handleSubmitFormUrediKontaktDatum
                                                                        }
                                                                    >
                                                                        Potrdi
                                                                    </Button>
                                                                    <Button
                                                                        variant='outline-primary ms-3'
                                                                        onClick={setStateShowEditKontaktnaOsebaDatum}
                                                                    >
                                                                        Prekliči
                                                                    </Button>
                                                                </Col>
                                                            </>
                                                        )}
                                                        {((role.skrbnik === true && role.clanicaID === projectSkrbnikClanica) || (role.skrbnik === false && role.projekti === 2))  && (
                                                            <>
                                                                {!showEditKontaktnaOsebaDatum[
                                                                    oseba.kisID
                                                                    ] && (
                                                                    <Col>
                                                                        <Button
                                                                            className='avtorizacije-button-trash float-end'
                                                                            onClick={() => {
                                                                                setShowModalDeleteKontaktnaOseba(true);
                                                                                setKontaktnaOsebaNaziv(oseba.naziv)
                                                                                setStateKontaktnaOsebaId(oseba.kisID)
                                                                            }}
                                                                        >
                                                                            <BiTrash
                                                                                className='text-danger'/>
                                                                        </Button>
                                                                        <Button
                                                                            className='org-button-details-text float-end'
                                                                            id={oseba.kisID}
                                                                            onClick={(e) => {
                                                                                setStateShowEditKontaktnaOsebaDatum(e.currentTarget.id)
                                                                                setStateEditKontaktnaOsebaDatum(oseba.kisID, oseba.od, oseba.do)
                                                                            }}
                                                                        >
                                                                          <span>
                                                                            Uredi časovno obdobje{' '}
                                                                          </span>
                                                                            <AiOutlineEdit/>
                                                                        </Button>
                                                                    </Col>
                                                                )}
                                                            </>
                                                        )}

                                                    </Row>
                                                    {showPodrobnostiZaKonataktnoOsebo[
                                                        oseba.kisID
                                                        ] && (
                                                        <>
                                                            <Row className='mt-2'>
                                                                <Col>{oseba.clanica}</Col>
                                                            </Row>
                                                            <Row className='mt-2'>
                                                                <Col>
                                                                    {oseba.od !== null && (
                                                                        <>
                                                                            <span>Od: </span>
                                                                            {new Date(
                                                                                oseba.od
                                                                            ).toLocaleDateString()}
                                                                        </>
                                                                    )}
                                                                    {oseba.do !== null && (
                                                                        <>
                                                                            <span> do: </span>
                                                                            {new Date(
                                                                                oseba.do
                                                                            ).toLocaleDateString()}
                                                                        </>
                                                                    )}
                                                                </Col>
                                                            </Row>
                                                        </>
                                                    )}
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    </Row>
                                );
                            })}
                        </div>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
        </>
    )
}

export default  SodelujoceOsebe