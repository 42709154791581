import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import SelectSearch from "react-select-search";

/*Import API*/
import {
  claniceUM,
  imenikSifrant,
  izbranaOsebaPodrobnosti,
  vnosAvtorizacije,
} from "../listOfApi";
import { fetchGET, fetchGETPwd, fetchPOST } from "../apiHelper";

/* React-Bootstrap component */
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Select, { components } from "react-select";
import { BiSearch } from "react-icons/bi";

const AddUserFakultetaRolesAuthorisation = (props) => {
  const history = useNavigate();

  const [sifrantClanice, setSifrantClanice] = useState([]);

  const [fakultetniUser, setFakultetniUser] = useState({
    upn: "",
    clanicaID: "",
    programi: 1,
    projekti: 2,
    partnerji: 1,
    razpisi: 1,
    aktualniRazpisi: 1,
    admin: false,
    skrbnik: true,
    kisID: "",
  });

  const [error, setError] = useState(false);
  const [errorValidation, setErrorValidation] = useState(false);
  const [kontaktneOsebeSeznam, setKontaktneOsebeSeznam] = useState([]);
  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    fetchGETPwd(imenikSifrant).then((osebe) => {
      if (osebe !== 0) {
        let seznamOseb = osebe.map((oseba) => {
          return { label: oseba.oseba, value: oseba.kisid };
        });
        setKontaktneOsebeSeznam(seznamOseb);
      } else {
      }
    });
  }, []);

  useEffect(() => {
    fetchGET(claniceUM).then((clanice) => {
      if (clanice !== 0) {
        let clanicePro = clanice
          .filter((clanica) => clanica.naziv !== "TTO")
          .map((clanica) => {
            return { name: clanica.naziv, value: clanica.id };
          });
        setSifrantClanice(clanicePro);
      }
    });
  }, []);

  const selectKontaktnaOseba = (value) => {
    if (value !== null) {
      fetchGETPwd(`${izbranaOsebaPodrobnosti}/${value.value}`).then(
        (oseba) => {
          if (oseba !== 0) {
            setFakultetniUser({
              ...fakultetniUser,
              upn: oseba.e_naslov,
              kisID: parseInt(oseba.osebna_stevilka_zaposlenca),
            });
          } else {
          }
        }
      );
    } else {
      setFakultetniUser({
        ...fakultetniUser,
        upn: "",
        kisID: "",
      });
    }
  };

  const handleForm = (e) => {
    e.preventDefault();

    if (
      fakultetniUser.clanicaID !== "" &&
      fakultetniUser.upn !== "" &&
      fakultetniUser.kisID !== ""
    ) {
      fetchPOST(vnosAvtorizacije, fakultetniUser).then((odgovor) => {
        if (odgovor === 1) {
          history(-1);
        } else {
          setError(true);
          setTimeout(() => {
            setError(false);
          }, 5000);
        }
      });
    } else {
      setErrorValidation(true);
      setTimeout(() => {
        setErrorValidation(false);
      }, 5000);
    }
  };

  /* CUSTOM ICONICA ZA ISKALNIK pri kontaktnih osebah */
  const DropdownIndicator = (props) => {
    return (
      components.DropdownIndicator && (
        <components.DropdownIndicator {...props}>
          <BiSearch />
        </components.DropdownIndicator>
      )
    );
  };

  return (
    <Container fluid>
      <Row>
        <Col className="main-header font-weight-bold">
          Nov fakultetni skrbnik
        </Col>
      </Row>
      <Row>
        <Col className="header-divider">
          <hr />
        </Col>
      </Row>
      <Row className={"mt-3 mb-3"}>
        <Col>
          {error && (
            <h5 className={"text-danger"}>Napaka pri dodajanju uporbnika!</h5>
          )}
        </Col>
      </Row>
      <Row className={"mt-3 mb-3"}>
        <Col>
          {errorValidation && (
            <h5 className={"text-danger"}>
              Niste izbrali članice ali digitalne identitete!
            </h5>
          )}
        </Col>
      </Row>
      <Form onSubmit={handleForm}>
        <Row>
          <Col>
            <Form.Label>Digitalna identiteta</Form.Label>
            <Select
              isClearable="true"
              backspaceRemovesValue={true}
              onChange={selectKontaktnaOseba}
              onInputChange={(value) => {
                let menuIsOpen = false;
                if (value) {
                  menuIsOpen = true;
                }
                setMenuOpen(menuIsOpen);
              }}
              menuIsOpen={menuOpen}
              menuPortalTarget={document.body}
              styles={{
                menuPortal: (base) => ({
                  ...base,
                  zIndex: 9999,
                }),
              }}
              options={kontaktneOsebeSeznam}
              placeholder="Vpišite ime in primek osebe"
              isSearchable
              components={{ DropdownIndicator }}
            />
          </Col>
          <Col>
            <Form.Label>Članica</Form.Label>
            <SelectSearch
              options={sifrantClanice}
              onChange={(value) => {
                setFakultetniUser({
                  ...fakultetniUser,
                  clanicaID: value,
                });
              }}
              search
              name="selectClanica"
              placeholder="Članica"
            />
          </Col>
        </Row>
        <Row className="mt-4">
          <Col>
            <Button type="submit" variant="primary">
              Dodaj pravice
            </Button>
            <Button
              variant="outline-primary ms-3"
              onClick={() => {
                history(-1);
              }}
            >
              Prekliči
            </Button>
          </Col>
        </Row>
      </Form>
    </Container>
  );
};

export default AddUserFakultetaRolesAuthorisation;
