import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {
  aktualniSeznamVerzija,
  izpostavljeniAktualniRazpisi,
  seznamAktualnihRazpisov,
  vnosNoveVerzijeAktualnegaRazpisa,
} from "../listOfApi";
import { format } from "date-fns";
import { fetchGET, fetchPOST } from "../apiHelper";
import CurrentFocusedTenders from "./currentTenders/CurrentFocusedTenders";

import SpinnerLoading from "./SpinnerLoading";
import CurrentTendersAutomatic from "./currentTenders/CurrentTendersAutomatic";
import CurrentTendersForm from "./currentTenders/CurrentTendersForm";
import CurrentTenderHtmlSnippet from "./currentTenders/CurrentTenderHTMLSnippet";
import CurrentTenderPDF from "./currentTenders/CurrentTenderPDF";

import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";

const aktualniRazpisiVerzijaData = {
  stevilka: 0,
  datum: "",
};

export default function CurrentTenders({ role }) {
  const [izpostavljeniAktualniRazpis, setIzpostavljeniAktualniRazpis] =
    useState([]);
  const [aktualniRazpisi, setAktualniRazpisi] = useState([]);
  const [izpostavljeniError, setIzpostavljeniError] = useState(false);
  const [izpostavljeniLoading, setIzpostavljeniLoading] = useState(true);
  const [aktualniRazpisiVerzija, setAktualniRazpisiVerzija] = useState({});
  const [aktualniRazpisiVerzijaSpremeni, setAktualniRazpisiVerzijaSpremeni] =
    useState(aktualniRazpisiVerzijaData);
  const [aktualniError, setAktualniError] = useState(false);
  const [aktualniLoading, setAktualniLoading] = useState(true);
  const [predogled, setPredogled] = useState(false);
  const [datumNovo, setDatumNovo] = useState(() => {
    let yourDate = new Date();
    /*Privzet datum je danes – 14 dni */
    yourDate.setDate(yourDate.getDate() - 14);
    return yourDate.toISOString().split("T")[0];
  });

  useEffect(() => {
    initIzpostavljeniAktualni();
  }, [datumNovo]);

  useEffect(() => {
    initAktualniRazpisi();
  }, [datumNovo]);

  useEffect(() => {
    initAktualniRazpisiVerzija();
  }, []);

  /* Pridobivanje seznama aktualnih razpisov prvega nivoja */
  function initAktualniRazpisi() {
    fetchGET(`${seznamAktualnihRazpisov}/${datumNovo}`).then((razpisi) => {
      if (razpisi !== 0) {
        setAktualniRazpisi(() => {
          setAktualniLoading(false);
          return [...razpisi];
        });
      } else {
        setAktualniLoading(false);
        setAktualniError(true);
        setTimeout(() => {
          setAktualniError(false);
        }, 3000);
      }
    });
  }

  /* Pridobivanje seznama aktualnih izpostavljenih razpisov */
  function initIzpostavljeniAktualni() {
    fetchGET(`${izpostavljeniAktualniRazpisi}/${datumNovo}`).then((razpisi) => {
      if (razpisi !== 0) {
        const setData = razpisi.map((razpis) => {
          return {
            ...razpis,
            razpisID: razpis.razpisID,
            povezava: razpis.povezava,
            razpis_naziv: razpis.razpis_naziv,
            opis: razpis.opis,
            prijava_datum: razpis.prijava_datum,
          };
        });
        setIzpostavljeniAktualniRazpis(() => {
          setIzpostavljeniLoading(false);
          return [...setData];
        });
      } else {
        setIzpostavljeniLoading(false);
        setIzpostavljeniError(true);
        setTimeout(() => setIzpostavljeniError(false), 3000);
      }
    });
  }

  /* Pridobivanje verzije seznama aktualnih razpisov*/
  function initAktualniRazpisiVerzija() {
    fetchGET(aktualniSeznamVerzija).then((razpisi) => {
      if (razpisi !== 0) {
        setAktualniRazpisiVerzija(razpisi);
        setAktualniRazpisiVerzijaSpremeni({
          ...aktualniRazpisiVerzijaSpremeni,
          stevilka: razpisi.stevilka,
          datum: razpisi.datum,
        });
      } else {
      }
    });
  }

  function aktualniRazpisiSPremeniPost(e) {
    e.preventDefault();

    fetchPOST(
      vnosNoveVerzijeAktualnegaRazpisa,
      aktualniRazpisiVerzijaSpremeni
    ).then((odgovor) => {
      if (odgovor === 1) {
        initAktualniRazpisiVerzija();
      } else {
      }
    });
  }

  if (role.aktualniRazpisi === 1 || role.aktualniRazpisi === 2) {
    return (
      <Container fluid>
        <Row>
          <Col className="main-header font-weight-bold">Aktualni razpisi</Col>
        </Row>
        <Row>
          <Col className="header-divider">
            <hr />
          </Col>
        </Row>
        <Row className="mb-3">
          <Col lg={8}>
            {role.aktualniRazpisi === 2 && (
              <Card className="mb-3">
                <Card.Body>
                  <Row>
                    <Col>
                      {role.aktualniRazpisi === 2 && (
                        <CurrentTendersForm
                          initAktualniRazpisi={initAktualniRazpisi}
                        />
                      )}
                      {role.aktualniRazpisi === 2 && (
                        <>
                          <Row className="mb-1 d-flex align-items-center">
                            <Col lg={3} className="ms-2">
                              Prikaži oznako novo od:
                            </Col>
                            <Col lg={4}>
                              <Form.Control
                                name="prijava_datum"
                                type="date"
                                value={datumNovo}
                                onChange={(e) => {
                                  if (e.target.value === "") {
                                    setDatumNovo(() => {
                                      let yourDate = new Date();
                                      /*Privzet datum je danes – 14 dni */
                                      yourDate.setDate(yourDate.getDate() - 14);
                                      return yourDate
                                        .toISOString()
                                        .split("T")[0];
                                    });
                                  } else {
                                    setDatumNovo(e.target.value);
                                  }
                                }}
                              />
                            </Col>
                          </Row>
                        </>
                      )}
                      {role.aktualniRazpisi === 2 && (
                        <Row className="mt-2 mb-2">
                          <Col>
                            <Button
                              onClick={() => setPredogled(!predogled)}
                              variant="warning"
                            >
                              Predogled aktualnih razpisov
                            </Button>
                          </Col>
                        </Row>
                      )}
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            )}
          </Col>
          <Col lg={4}>
            <Row>
              <Col>
                <Card>
                  <Card.Body>
                    <Row>
                      <Col>
                        <h5>
                          Aktualna verzija seznama razpisov:{" "}
                          <strong>{aktualniRazpisiVerzija.stevilka}</strong>
                        </h5>
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col>
                        Datum kreiranja:{" "}
                        <strong>
                          {aktualniRazpisiVerzija &&
                          aktualniRazpisiVerzija.datum
                            ? format(
                                new Date(aktualniRazpisiVerzija.datum),
                                "d. M. yyyy"
                              )
                            : ""}
                        </strong>
                      </Col>
                    </Row>

                    {role.aktualniRazpisi === 2 && (
                      <>
                        <Form onSubmit={aktualniRazpisiSPremeniPost}>
                          <Row className="mt-2">
                            <Col>
                              <Form.Label>Številka</Form.Label>
                              <Form.Control
                                type="number"
                                value={aktualniRazpisiVerzijaSpremeni.stevilka}
                                onChange={(e) =>
                                  setAktualniRazpisiVerzijaSpremeni({
                                    ...aktualniRazpisiVerzijaSpremeni,
                                    stevilka: parseInt(e.target.value),
                                  })
                                }
                                required
                              />
                            </Col>
                            <Col>
                              <Form.Label>Datum</Form.Label>
                              <Form.Control
                                name="datum"
                                type="date"
                                value={aktualniRazpisiVerzijaSpremeni.datum}
                                onChange={(e) => {
                                  setAktualniRazpisiVerzijaSpremeni({
                                    ...aktualniRazpisiVerzijaSpremeni,
                                    datum: e.target.value,
                                  });
                                }}
                                required
                              />
                            </Col>
                          </Row>
                          <Row className="mt-3">
                            <Col>
                              {role.aktualniRazpisi === 2 && (
                                <>
                                  <Button
                                    type="submit"
                                    variant="secondary"
                                    className="mb-2"
                                  >
                                    Posodobi aktualno verzijo dokumenta
                                  </Button>
                                </>
                              )}
                            </Col>
                          </Row>
                        </Form>
                        <Row>
                          <Col>
                            <CurrentTenderHtmlSnippet
                              izpostavljeniAktualniRazpis={
                                izpostavljeniAktualniRazpis
                              }
                              aktualniRazpisi={aktualniRazpisi}
                              aktualniRazpisiVerzija={aktualniRazpisiVerzija}
                              role={role}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <CurrentTenderPDF
                              izpostavljeniAktualniRazpis={
                                izpostavljeniAktualniRazpis
                              }
                              aktualniRazpisi={aktualniRazpisi}
                              aktualniRazpisiVerzija={aktualniRazpisiVerzija}
                              role={role}
                            />
                          </Col>
                        </Row>
                      </>
                    )}
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>

        {/* Izpostavljeni razpisi – prikaz */}
        <CurrentFocusedTenders
          izpostavljeniAktualniRazpis={izpostavljeniAktualniRazpis}
          izpostavljeniError={izpostavljeniError}
          izpostavljeniLoading={izpostavljeniLoading}
        />
        {/* Razpisi – prikaz */}
        <CurrentTendersAutomatic
          aktualniRazpisi={aktualniRazpisi}
          initAktualniRazpisi={initAktualniRazpisi}
          initIzpostavljeniAktualni={initIzpostavljeniAktualni}
          aktualniError={aktualniError}
          aktualniLoading={aktualniLoading}
          role={role}
          predogled={predogled}
        />
      </Container>
    );
  } else if (role.aktualniRazpisi === 0) {
    return <h4>Dostop do aktualnih razpisov ni mogoč!</h4>;
  } else {
    return <SpinnerLoading />;
  }
}
