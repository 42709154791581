import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Select from 'react-select';

/*Import API */
import {
  sifrantRazpisov,
  claniceUM,
  vnosNovegaProjekta,
} from '../listOfApi';
import { fetchGET, fetchPOST } from '../apiHelper';

/*import React-Bootstrap */
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';

/*Import React-Icons */
import { BiLayerPlus } from 'react-icons/bi';
import Button from 'react-bootstrap/Button';
import SelectSearch from 'react-select-search';

const AddProject = ({ role }) => {
  const [vnosError, setVnosError] = useState(false);
  const [sifrantRazpisi, setSifrantRazpisi] = useState([]);
  const [sifrantClanice, setSifrantClanice] = useState([]);
  const [razpisValidationEmpty, setRazapisValidationEmpty] = useState(false)
  const [clanicaValidationEmpty, setClanicaValidationEmpty] = useState(false)

  const [projectData, setProjectData] = useState({
    naziv: '',
    akronim: '',
    zacetek: '',
    konec: '',
    razpisID: '',
    clanicaID: '',
    sifra: ''
  });

  const history = useNavigate();

  /* Pridobivanje vseh šifrantov za drop downe */
  useEffect(() => {
    fetchGET(sifrantRazpisov).then((razpisi) => {
      if (razpisi !== 0) {
        let razpisiSeznam = razpisi.map((razpis) => {
          return { label: razpis.naziv, value: razpis.id };
        });
        setSifrantRazpisi(razpisiSeznam);
      }
    });
  }, []);

  useEffect(() => {
    fetchGET(claniceUM).then((clanice) => {
      if (clanice !== 0) {
        let clanicePro = clanice.filter(clanica =>clanica.naziv !== 'TTO').map(clanica => {return {name: clanica.naziv, value: clanica.id}});
        setSifrantClanice(clanicePro);
      }
    });
  }, []);


  useEffect(() => {
    if(role.skrbnik === true){
      setProjectData({...projectData, clanicaID: role.clanicaID})
    }
  }, [])

  /* Funkcija za shranjevanje podatkov na backend */
  const handleSubmitForm = (e) => {
    e.preventDefault();

    if(projectData.razpisID === ''){
      setRazapisValidationEmpty(true);
      setTimeout(() => {
        setRazapisValidationEmpty(false);
      }, 3000);
    }else if(projectData.clanicaID === ''){
      setClanicaValidationEmpty(true);
      setTimeout(() => {
        setClanicaValidationEmpty(false);
      }, 3000);
    } else {
      fetchPOST(vnosNovegaProjekta, projectData).then((project) => {
        if (project === 1) {
          history(-1);
        } else {
          setVnosError(true);
          setTimeout(() => {
            setVnosError(false);
          }, 3000);
        }
      });
    }
  };

  const handleFormData = (e) => {
    setProjectData({
      ...projectData,
      [e.target.name]: e.target.value,
    });
  };


  return (
    <Container fluid>
      {role.projekti === 2 && (
        <>
          <Row>
            <Col className='main-header font-weight-bold'>
              <BiLayerPlus className='me-2' />
              Dodaj: <span>{projectData.naziv}</span>
            </Col>
          </Row>
          <Row>
            <Col className='header-divider'>
              <hr />
            </Col>
          </Row>
          <Row>
            {vnosError && (
              <Col>
                <h2 className='text-danger'>Vnos projekta ni uspel</h2>
              </Col>
            )}
              {razpisValidationEmpty && (
              <Col>
                <h2 className='text-danger'>Manjkajoč podatek: RAZPIS</h2>
              </Col>
            )}
              {clanicaValidationEmpty && (
              <Col>
                <h2 className='text-danger'>Manjkajoč podatek: ČLANICA</h2>
              </Col>
            )}
          </Row>
          <Row>
            <Col className='mt-3'>
              <Form onSubmit={handleSubmitForm}>
                <Row>
                  <Col>
                    <Form.Label>Naslov projekta </Form.Label>
                    <Form.Control
                      name='naziv'
                      type='text'
                      required
                      minLength='3'
                      maxLength='500'
                      value={projectData.naziv}
                      onChange={handleFormData}
                      placeholder='Naslov projekta'
                    />
                  </Col>
                  <Col>
                    <Form.Label>Akronim Projekta</Form.Label>
                    <Form.Control
                      name='akronim'
                      type='text'
                      maxLength='500'
                      value={projectData.akronim}
                      onChange={handleFormData}
                      placeholder='Akronim projekta'
                    />
                  </Col>
                </Row>
                <Row className='mt-3'>
                  <Col>
                    <Form.Label>Razpis</Form.Label>
                    <Select
                      onChange={(value) =>
                        setProjectData({
                          ...projectData,
                          razpisID: value.value,
                        })
                      }
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (base) => ({
                          ...base,
                          zIndex: 9999,
                        }),
                      }}
                      options={sifrantRazpisi}
                      placeholder='Razpisi'
                      isSearchable
                    />
                  </Col>
                </Row>
                <Row className='mt-3'>
                  <Col>
                    <Form.Label>Začetek projekta </Form.Label>
                    <Form.Control
                      name='zacetek'
                      type='date'
                      required
                      value={projectData.zacetek}
                      onChange={handleFormData}
                    />
                  </Col>
                  <Col>
                    <Form.Label>Konec projekta </Form.Label>
                    <Form.Control
                      name='konec'
                      type='date'
                      required
                      value={projectData.konec}
                      onChange={handleFormData}
                    />
                  </Col>
                </Row>
                <Row className='mt-3'>
                  <Col>
                    <Form.Label>Šifra projekta</Form.Label>
                    <Form.Control
                        name='sifra'
                        type='text'
                        maxLength='25'
                        onChange={handleFormData}
                        placeholder='Šifra projekta'
                    />
                  </Col>
                </Row>

                {role.skrbnik === false &&
                (
                    <Row className='mt-5'>

                      <Col>
                        <Card className="projectAdd-clanica-background">
                          <Card.Body>
                            <Form.Label>Članica ki je skrbnik</Form.Label>
                            <SelectSearch
                                options={sifrantClanice}
                                onChange={(value) => {
                                  setProjectData(
                                      {
                                        ...projectData,
                                        clanicaID: value
                                      }
                                  )
                                }
                                }
                                search
                                name='selectClanica'
                                placeholder='Članica'
                            />
                          </Card.Body>
                        </Card>
                      </Col>
                    </Row>
                )}


                <Row className='mt-3'>
                  <Col>
                    <Button variant='primary' type='submit'>
                      Dodaj
                    </Button>
                    <Button
                      variant='outline-primary ms-3'
                      onClick={() => {
                        history(-1);
                      }}
                    >
                      Prekliči
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </>
      )}
    </Container>
  );
};

export default AddProject;
