import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import MaterialTable from "material-table";

/*Api */
import { fetchDELETE, fetchGET } from "../apiHelper";
import { brisanjePartnerja, seznamPartnerjev } from "../listOfApi";

/*React-Bootstrap components */
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

/*React-icons*/
import { BiDetail, BiTrash } from "react-icons/bi";
import { FaUserTie } from "react-icons/fa";
import { GiOrganigram } from "react-icons/gi";

/*Import Material-icons */
import Search from "@material-ui/icons/Search";
import { BsGrid3X3GapFill, BsTable } from "react-icons/bs";
import Card from "react-bootstrap/Card";
import { AiOutlineEdit, AiOutlineLink } from "react-icons/ai";
import Modal from "react-bootstrap/Modal";

const ProjectPartners = ({ role }) => {
  const [partners, setPartners] = useState([]);
  const [filteredPartners, setFilteredPartners] = useState([]);
  const [showTable, setShowTable] = useState(true);
  const [showFilters, setShowFilters] = useState(false);
  const [searchProjectPartner, setSearchProjectPartner] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [partnerId, setPartnerId] = useState("");
  const [partnerName, setPartnerName] = useState("");

  const history = useNavigate();

  const columns = [
    {
      title: "Naziv partnerja",
      field: "nazivSlo",
      render: (rowData) => {
        if (rowData.parentID === 0) {
          return (
            <Row>
              <Col lg={1}>
                <FaUserTie />
              </Col>
              <Col lg={11}>
                <span className="ms-2">{rowData.nazivSlo}</span>
              </Col>
            </Row>
          );
        } else {
          return (
            <Row>
              <Col lg={1}>
                <GiOrganigram className="ms-3" />
              </Col>
              <Col lg={11}>
                <span className="ms-2">{rowData.nazivSlo}</span>
              </Col>
            </Row>
          );
        }
      },
      cellStyle: {
        width: 400,
        minWidth: 400,
      },
      headerStyle: {
        width: 400,
        minWidth: 400,
      },
    },
    {
      title: "Naziv partnerja - angleški",
      field: "nazivEng",
    },
    {
      title: "Država",
      field: "drzava",
    },
    {
      title: "Kraj",
      field: "posta",
    },
  ];

  useEffect(() => {
    fetchGET(seznamPartnerjev).then((partners) => {
      if (partners !== 0) {
        setPartners(partners);
        setFilteredPartners(partners);
      } else {
      }
    });
  }, []);

  useEffect(() => {
    let letSet = null;
    if (localStorage.getItem("layout") === "true") {
      letSet = true;
    } else if (localStorage.getItem("layout") === "false") {
      letSet = false;
    }
    if (localStorage.getItem("layout") !== null) {
      setShowTable(letSet);
    }
  }, [showTable]);

  useEffect(() => {
    let filterPartners = partners.filter((partner) =>
      partner.nazivSlo
        .toLowerCase()
        .includes(searchProjectPartner.toLowerCase())
    );

    setFilteredPartners(filterPartners);
  }, [partners, searchProjectPartner]);

  const handleDelete = () => {
    fetchDELETE(`${brisanjePartnerja}/${partnerId}`).then((deleted) => {
      if (deleted === 1) {
        setShowModal(false);
        window.location.reload();
      } else {
        //TODO: prišlo je do napake pri brisanju podatkov.
      }
    });
  };

  return (
    <Container fluid>
      {(role.partnerji === 1 || role.partnerji === 2) && (
        <>
          <Modal
            show={showModal}
            onHide={() => {
              setShowModal(false);
            }}
          >
            <Modal.Body>
              Želite izbrisati projektnega partnerja:{" "}
              <strong>{partnerName}</strong> ?
            </Modal.Body>
            <Modal.Footer>
              <Button
                className="btn btn-danger"
                variant="secondary"
                onClick={handleDelete}
              >
                Izbriši
              </Button>
              <Button
                variant="primary"
                onClick={() => {
                  setShowModal(false);
                }}
              >
                Ne
              </Button>
            </Modal.Footer>
          </Modal>
          <Row>
            <Col className="main-header font-weight-bold">
              Projektni partnerji
            </Col>
          </Row>
          <Row>
            <Col className="header-divider">
              <hr />
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="float-end">
                {role.partnerji === 2 && (
                  <Link
                    className="programs-button-add-text "
                    to={`/ProjektniPartnerji/Dodaj/`}
                  >
                    <Button variant="outline-primary border-0">
                      Dodaj projektnega partnerja
                    </Button>
                  </Link>
                )}
              </div>
            </Col>
          </Row>

          {showTable && (
            <Row className="mt-4">
              <Col>
                <MaterialTable
                  icons={{
                    Filter: () => <Search style={{ fontSize: "15px" }} />,
                  }}
                  title={""}
                  columns={columns}
                  data={filteredPartners}
                  localization={{
                    toolbar: {
                      searchPlaceholder: "Iskalnik",
                      exportCSVName: "Izvozi podatke v CSV",
                    },
                    header: { actions: "Akcije" },
                    body: {
                      emptyDataSourceMessage: "Ni zapisov za prikaz",
                    },
                    pagination: {
                      labelRowsSelect: "Vrstic",
                      firstAriaLabel: "Prva stran",
                      firstTooltip: "Prva stran",
                      previousAriaLabel: "Prejšnja stran",
                      previousTooltip: "Prejšnja stran",
                      nextAriaLabel: "Naslednja stran",
                      nextTooltip: "Naslednja stran",
                      lastAriaLabel: "Zadnja stran",
                      lastTooltip: "Zadnja stran",
                      labelDisplayedRows: "{from}-{to} od {count}",
                    },
                  }}
                  options={{
                    paging: true,
                    pageSize: 50,
                    pageSizeOptions: [50, 100, 500],
                    searchFieldAlignment: "left",
                    filtering: showFilters,
                    actionsColumnIndex: -1,
                    headerStyle: { fontWeight: "bold" },
                    exportButton: { csv: true, pdf: false },
                    exportFileName: `projektni_partnerji_${new Date()
                      .toLocaleDateString("sl-SI")
                      .replace(/\. /g, "-")}`,
                    rowStyle: (rowData) => ({
                      backgroundColor:
                        rowData.parentID !== 0 ? "#fcfcfc" : "#FFF",
                    }),
                  }}
                  onRowClick={(event, rowData) => {
                    if (rowData.parentID === 0) {
                      history(`/ProjektniPartnerji/Podrobnosti/${rowData.id}`);
                    }
                  }}
                  parentChildData={(row, rows) =>
                    rows.find((a) => a.id === row.parentID)
                  }
                  actions={[
                    {
                      icon: "filter_alt",
                      tooltip: "Omogoči filter",
                      isFreeAction: true,
                      onClick: (event) => {
                        setShowFilters(!showFilters);
                      },
                    },

                    (rowData) => ({
                      hidden: role.partnerji !== 2 || rowData.parentID !== 0,
                      icon: "article",
                      tooltip: "Podrobnosti",
                      onClick: (event, rowData) => {
                        history(
                          `/ProjektniPartnerji/Podrobnosti/${rowData.id}`
                        );
                      },
                    }),

                    (rowData) => ({
                      hidden: role.partnerji !== 2 || rowData.parentID !== 0,
                      icon: "link",
                      tooltip: "Povezava",
                      onClick: (event, rowData) => {
                        window.open(rowData.spletnaStran, "_blank");
                      },
                    }),

                    (rowData) => ({
                      hidden: role.partnerji !== 2 || rowData.parentID !== 0,
                      icon: "delete",
                      tooltip: "Odstrani",
                      iconProps: { color: "error" },
                      onClick: (event, rowData) => {
                        setShowModal(true);
                        setPartnerId(rowData.id);
                        setPartnerName(rowData.nazivSlo);
                      },
                    }),
                  ]}
                />
              </Col>
            </Row>
          )}
        </>
      )}
    </Container>
  );
};

export default ProjectPartners;
