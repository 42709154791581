import React, { useEffect, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Select from "react-select";
import { fetchGET, fetchPOST } from "../../apiHelper";
import { seznamVsehProgramov, vnosAktualnihRazpisov } from "../../listOfApi";

const formFirstLevelData = {
  naziv: "",
  rocniVnos: false,
  programID: "",
};

function CurrentTendersForm({ initAktualniRazpisi }) {
  const [showAddFirstLevel, setShowAddFirstLevel] = useState(false);
  const [seznamProgramovSifrant, setSeznamProgramovSifrant] = useState([]);
  const [formFirstLevel, setFormtFirstLevel] = useState(formFirstLevelData);
  const [errorPostAktualniRazpisi, setErrorPostAktualniRazpisi] =
    useState(false);

  useEffect(() => {
    if (formFirstLevel.rocniVnos === true) {
      initDropDownProgrami();
    }
  }, [formFirstLevel.rocniVnos]);

  /* Pridobivanje vseh programo/podprogramov za šifrant pri dodajanju razpisa na seznam aktualnih razpisov */
  function initDropDownProgrami() {
    fetchGET(seznamVsehProgramov).then((seznamProgramov) => {
      if (seznamProgramov !== 0) {
        let seznam = seznamProgramov.map((program) => {
          return { label: program.naziv, value: program.objectID };
        });
        setSeznamProgramovSifrant(seznam);
      }
    });
  }
  /* Pridobivanje podatkov za obrazec na prvem nivoju */
  function handleFormData(event, dropdown) {
    let value;
    let name;

    if (event.target !== undefined) {
      if (event.target.name === "rocniVnos") {
        value = !formFirstLevel.rocniVnos;
      } else {
        value = event.target.value;
      }
      name = event.target.name;
    } else {
      name = dropdown.name;
      value = event.value;
    }

    setFormtFirstLevel((prevState) => {
      return { ...prevState, [name]: value };
    });
  }

  /* Pošiljanje podatkov za prvi nivo */
  function handleSubmitFormFirstLevel(e) {
    e.preventDefault();

    let value = formFirstLevel.programID;
    if (formFirstLevel.rocniVnos === false) {
      value = 0;
    }

    const data = {
      naziv: formFirstLevel.naziv,
    };
    fetchPOST(`${vnosAktualnihRazpisov}/${value}`, data).then((odgovor) => {
      if (odgovor === 1) {
        setFormtFirstLevel({
          naziv: "",
          rocniVnos: false,
          programID: "",
        });
        setShowAddFirstLevel(false);
        initAktualniRazpisi();
      } else {
        setErrorPostAktualniRazpisi(true);
        setTimeout(() => {
          setErrorPostAktualniRazpisi(false);
        }, 5000);
      }
    });
  }

  return (
    <>
      {errorPostAktualniRazpisi && (
        <Row className="mt-2 mb-2">
          <Col>
            <h4>Prišlo je do napake pri dodajanju Aktaulnih razpisov!</h4>
          </Col>
        </Row>
      )}
      <Row className="mt-2 mb-2">
        <Col>
          <Button
            className="org-button-details-text"
            id="1"
            onClick={() => {
              setShowAddFirstLevel(!showAddFirstLevel);
            }}
          >
            Dodaj kategorijo
          </Button>
        </Col>
      </Row>
      {showAddFirstLevel && (
        <Row className="mb-3">
          <Col>
            <Card className="main-container-card__shadows">
              <Card.Body>
                <Form onSubmit={handleSubmitFormFirstLevel}>
                  <Row className="d-flex align-items-center">
                    <Col>
                      <Form.Label>Uradni naslov</Form.Label>
                      <Form.Control
                        name="naziv"
                        type="text"
                        required
                        minLength="3"
                        onChange={(e) => handleFormData(e)}
                      />
                    </Col>
                    <Col>
                      <Form.Label>Struktura iz Baze projektov</Form.Label>
                      <Form.Check
                        name="rocniVnos"
                        type="checkbox"
                        onChange={(e) => handleFormData(e)}
                        label="Da"
                      />
                    </Col>
                    {formFirstLevel.rocniVnos && (
                      <Col>
                        <Form.Label>Programi</Form.Label>

                        <Select
                          menuPortalTarget={document.body}
                          options={seznamProgramovSifrant}
                          placeholder="Programi"
                          onChange={handleFormData}
                          isSearchable
                          name="programID"
                        />
                      </Col>
                    )}
                    <Col>
                      <Button
                        variant="primary"
                        type="submit"
                        className="mt-4 ml-2"
                      >
                        Dodaj
                      </Button>
                      <Button
                        variant="outline-primary ml-3"
                        onClick={() => {
                          setShowAddFirstLevel(!showAddFirstLevel);
                          setFormtFirstLevel({
                            naziv: "",
                            rocniVnos: false,
                            programID: "",
                          });
                        }}
                        className="mt-4 "
                      >
                        Prekliči
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      )}
    </>
  );
}

export default CurrentTendersForm;
