import React, { useState, useEffect, useContext } from 'react';
import SelectSearch from 'react-select-search';

/*Import Bootstrap components */
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

/*API helper import */
import { fetchGET } from '../../apiHelper';
import { statusProjekta, seznamNeizpolnjenihPolijZaPosamezenStatuts } from '../../listOfApi';

/* Import Context */
import { UserContext } from '../UserContext';

/*Import React-icons */
import { AiOutlineEdit } from "react-icons/ai";

const StatusProjekta = ({ projectSkrbnikClanica, status, statusID, projektID, changeProjectStatus }) => {
    const [showModal, setShowModal] = useState(false);
    const [sifrantStatusi, setSifrantStatusi] = useState([]);
    const [projectNewStatus, setProjectNewStatus] = useState("");
    const [praznaObveznaPolja, setPraznaObveznaPolja] = useState([])

    const role = useContext(UserContext);

    useEffect(() => {
        setProjectNewStatus(statusID);
    }, [statusID])

    /* pridobivanje šifranta statusov */
    useEffect(() => {
        fetchGET(statusProjekta).then((statusi) => {
            if (statusi !== 0) {
                let statusiProjekta = statusi.map((status) => {
                    return {
                        name: status.naziv,
                        value: status.id,
                    };
                });
                setSifrantStatusi(statusiProjekta);
            }
        });
    }, []);

    /* Se izvede ko izberemo novo vrednost v dropDownu */
    useEffect((() => {
        if (projectNewStatus !== "") {
            fetchStatusiHelper()
        }
    }), [projectNewStatus])

    /* Se izvede ko odpremo modalno okno da preverimo stanje po spremembi obstoječega statusa, če še zadostuje pravilom */
    useEffect((() => {
        if (projectNewStatus !== "") {
            if (showModal === true) {
                fetchStatusiHelper()
            }
        }

    }), [showModal])

    /* Projektu dodelimo nov status, kličemo funkcijo v ProjectDetails.js */
    const handleChangeProjectStatusButton = () => {
        changeProjectStatus(projektID, projectNewStatus)
        setShowModal(false)
    }

    /* HELPER funkcija za pridobivanje statusa */
    const fetchStatusiHelper = () => {
        fetchGET(`${seznamNeizpolnjenihPolijZaPosamezenStatuts}/${projektID}/status/${projectNewStatus}`).then((statusi) => {
            setPraznaObveznaPolja([]);
            if (statusi.length > 0) {
                const groupBy = key => array =>
                    array.reduce((objectsByKeyValue, obj) => {
                        const value = obj[key];
                        objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
                        return objectsByKeyValue;
                    }, {});

                const groupedData = groupBy("zavihek")(statusi);
                setPraznaObveznaPolja(groupedData)
            }
        });
    }

    return (
        <>
            <Modal
                show={showModal}
                onHide={() => {
                    setShowModal(false);
                    setProjectNewStatus(statusID);
                }}
                centered
                backdropClassName='static'
                size='lg'
            >
                <Modal.Header className='projekt-status-modal-header'>
                    <Row>
                        <Col>
                            <h5 className='mb-0'>Sprememba statusa projekta</h5>
                        </Col>
                    </Row>
                </Modal.Header>
                <Modal.Body>
                    <Row className='align-items-center'>
                        <Col>
                            Izberi novi status projekta:
                        </Col>
                        <Col>
                            <SelectSearch
                                options={sifrantStatusi}
                                onChange={(value) =>
                                    setProjectNewStatus(
                                        value,
                                        "sifrantiStatus"
                                    )
                                }
                                value={projectNewStatus}
                                search
                                id="statusID"
                                placeholder="Status Projekta"
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <hr />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            Prikaz manjkajočih vnosov za izbran status:
                        </Col>
                    </Row>
                    <Row className='mt-3'>
                        <div>
                            {
                                Object.keys(praznaObveznaPolja).map((key, index) => (
                                    <Row key={key} style={{animationDelay:`${index=== 0 ? index:index/4}s`}} className="ms-3 animationTransition-status">
                                        <Col>
                                            <strong>{key}</strong>
                                            <ul>
                                                {
                                                    praznaObveznaPolja[key].map(item => (
                                                        <li key={item.imePolja}>{item.imePolja}</li>
                                                    ))
                                                }
                                            </ul>
                                        </Col>
                                    </Row>
                                ))
                            }
                        </div>
                    </Row>

                    {praznaObveznaPolja.length === 0 && (
                        <Row style={{animationDelay:"0.2s"}} className='mt-2 mb-2 animationTransition-status'>
                            <Col className='text-success'>
                                Ni manjkajočih podatkov, izvedbo spremembe statusa potrdite s klikom na gumb "Potrdi spremembo statusa"
                            </Col>
                        </Row>
                    )}

                    {praznaObveznaPolja.length !== 0 && (
                        <div style={{animationDelay:"1.5s"}} className='mt-2 mb-2 animationTransition-status'>
                            <Row className='mt-3 text-danger'>
                                <Col>
                                    Preverjanje je zaključeno. Manjkajoči podatki so prikazani zgoraj.
                                </Col>
                            </Row>
                            <Row className='mt-1 text-danger'>
                                <Col>
                                    Sprememba statusa ni bila izvedena.
                                </Col>
                            </Row>
                        </div>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    {praznaObveznaPolja.length === 0 && (
                        <Button
                            variant='primary'
                            onClick={() => { handleChangeProjectStatusButton() }}
                        >
                            Potrdi spremembo statusa
                        </Button>

                    )}
                    <Button
                        className='btn'
                        variant='outline-primary'
                        onClick={() => { setShowModal(false); setProjectNewStatus(statusID) }}
                    >
                        Prekliči
                    </Button>

                </Modal.Footer>
            </Modal>
            <Row className="project-row-status">
                <Col className="d-inline-flex align align-items-center">
                    Status projekta: <span className="ms-1 text-success">{status}</span>
                    {((role.skrbnik === true && role.clanicaID === projectSkrbnikClanica) || (role.skrbnik === false && role.projekti === 2)) &&
                        <Button
                            className="org-button-details-text-status"
                            onClick={() => {
                                setShowModal(true);
                            }}
                            id="1"
                        >
                            <AiOutlineEdit />
                        </Button>
                    }

                </Col>
            </Row>

        </>
    );
}

export default StatusProjekta;