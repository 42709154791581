import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";

/* Import React Bootstrap */
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

/* Import React-Icons */
import { AiFillInfoCircle, AiOutlineEdit, AiOutlineLink } from "react-icons/ai";

/*Import Api helper */
import { fetchGET } from "../../apiHelper";
import { sifrantARRS, sifrantCerif, sifrantKlasius } from "../../listOfApi";

/* Import Context */
import { UserContext } from "../UserContext";

const DodatniPodatki = ({
  projectDodatni,
  projectDodatniEdit,
  projectSkrbnikClanica,
  jeClanicaProjekta,
  tooltipSifranti,
  vnosError,
  showEditDodatni,
  setStateShowEdit,
  handleFormDataDodatni,
  handleSubmitFormDodatni,
  setStateDodatniPodatkiDropDown,
}) => {
  const role = useContext(UserContext);

  const [sifrantListARRS, setSifrantListARRS] = useState([]);
  const [sifrantListKlasius, setSifrantListKlasius] = useState([]);
  const [sifrantListCerif, setSifrantListCerif] = useState([]);

  //Pridobivanje Sifrantov.
  useEffect(() => {
    if (projectDodatni.arrsID !== -1) {
      fetchGET(sifrantARRS).then((sifranti) => {
        if (sifranti !== 0) {
          let sifrantARRS = sifranti.map((sifrant) => {
            return { label: sifrant.naziv, value: sifrant.id };
          });
          setSifrantListARRS(sifrantARRS);
        } else {
        }
      });
    }

    if (projectDodatni.cerifID !== -1) {
      fetchGET(sifrantCerif).then((sifranti) => {
        if (sifranti !== 0) {
          let sifrantCerif = sifranti.map((sifrant) => {
            return { label: sifrant.naziv, value: sifrant.id };
          });
          setSifrantListCerif(sifrantCerif);
        } else {
        }
      });
    }

    if (projectDodatni.klasiusID !== -1) {
      fetchGET(sifrantKlasius).then((sifranti) => {
        if (sifranti !== 0) {
          let sifrantKlasius = sifranti.map((sifrant) => {
            return { label: sifrant.naziv, value: sifrant.id };
          });
          setSifrantListKlasius(sifrantKlasius);
        } else {
        }
      });
    }
  }, [projectDodatni.arrsID, projectDodatni.cerifID, projectDodatni.klasiusID]);

  return (
    <Card className="mt-3">
      <Card.Header>
        <Row className="align-items-center">
          <Col>
            <h5 className="mb-0">Dodatni podatki</h5>
          </Col>
          <Col>
            <div className="float-end">
              {((role.skrbnik === true &&
                role.clanicaID === projectSkrbnikClanica) ||
                (role.skrbnik === false && role.projekti === 2)) && (
                <Button
                  className="org-button-details-text"
                  id="1"
                  onClick={setStateShowEdit}
                >
                  <AiOutlineEdit />
                </Button>
              )}
            </div>
          </Col>
        </Row>
      </Card.Header>
      {!showEditDodatni && (
        <Card.Body>
          <Row className="project-partners-details-card">
            <Col>
              <div className="p-4">
                {((role.skrbnik === true &&
                  role.clanicaID === projectSkrbnikClanica) ||
                  (role.skrbnik === true &&
                    jeClanicaProjekta === true &&
                    role.clanicaID !== projectSkrbnikClanica) ||
                  (role.skrbnik === false && role.projekti === 2) ||
                  (role.skrbnik === false && role.projekti === 1)) && (
                  <>
                    <Row>
                      <Col className="mb-3">
                        <span>
                          Naslov in številka pogodbe{" "}
                          <sup>
                            <AiFillInfoCircle
                              className="tooltip-icon"
                              data-tooltip-id="tab-tooltip"
                              data-tooltip-content={
                                tooltipSifranti.P2naslovPogodbe
                              }
                            />
                          </sup>
                          :{" "}
                        </span>
                        {projectDodatni.pogodba}
                      </Col>
                    </Row>
                    <Row>
                      <Col className="mb-3">
                        <span> Referenčna številka: </span>
                        {projectDodatni.referencnaSt}
                      </Col>
                    </Row>
                  </>
                )}

                <Row>
                  <Col className="mb-3">
                    <span>Spletna stran projekta: </span>
                    <span className="text-break">
                      {projectDodatni.spletnaStran}
                    </span>
                    <span>
                      {projectDodatni.spletnaStran && (
                        <a
                          className="razpisi-button-details-text me-3 ms-2"
                          href={projectDodatni.spletnaStran}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <AiOutlineLink />
                        </a>
                      )}
                    </span>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col>
              {((role.skrbnik === true &&
                role.clanicaID === projectSkrbnikClanica) ||
                (role.skrbnik === true &&
                  jeClanicaProjekta === true &&
                  role.clanicaID !== projectSkrbnikClanica) ||
                (role.skrbnik === false && role.projekti === 2) ||
                (role.skrbnik === false && role.projekti === 1)) && (
                <div className="p-4">
                  <span>Področje:</span>{" "}
                  {projectDodatni.cerifID === -1 &&
                    projectDodatni.arrsID === -1 &&
                    projectDodatni.klasiusID === -1 &&
                    "Ni izbranega področja"}
                  <div className="ms-5 mt-2 me-3">
                    {projectDodatni.cerifID !== -1 && (
                      <Row>
                        <Col className="mb-3">CERIF/{projectDodatni.cerif}</Col>
                      </Row>
                    )}
                    {projectDodatni.arrsID !== -1 && (
                      <Row className="mb-3">
                        <Col>ARIS/{projectDodatni.arrs}</Col>
                      </Row>
                    )}
                    {projectDodatni.klasiusID !== -1 && (
                      <Row className="mb-3">
                        <Col>Klasius P-16/{projectDodatni.klasius}</Col>
                      </Row>
                    )}
                  </div>
                  <Row className="mb-3">
                    <Col>
                      <span>Sodelovanje z gopodarstvom: </span>
                      {projectDodatni.sodGopodarstvom === null
                        ? ""
                        : projectDodatni.sodGopodarstvom
                        ? "Da"
                        : "Ne"}
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col>
                      <span>Sodelovanje s študenti: </span>
                      {projectDodatni.sodStudenti === null
                        ? ""
                        : projectDodatni.sodStudenti
                        ? "Da"
                        : "Ne"}
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col>
                      {projectDodatni.sodStudenti && (
                        <span>
                          Število sodelujočih študentov:{" "}
                          {projectDodatni.stStudenti}{" "}
                        </span>
                      )}
                    </Col>
                  </Row>
                </div>
              )}
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="ps-4 pe-4">
                <h5>
                  <span>Povzetek:</span>
                </h5>
                <p>{projectDodatni.povzetek}</p>
              </div>
            </Col>
          </Row>
        </Card.Body>
      )}
      {showEditDodatni && (
        <Form onSubmit={handleSubmitFormDodatni}>
          <Card.Body>
            <Row>
              {vnosError && (
                <Col className="mt-3">
                  <h4 className="text-danger ms-4">
                    Urejanje dodatnih podatkov projekta ni uspelo!
                  </h4>
                </Col>
              )}
            </Row>
            <Row className="project-partners-details-card">
              <Col>
                <div className="p-4">
                  <Row>
                    <Col className="mb-3">
                      <Form.Label>Naslov in številka pogodbe:</Form.Label>
                      <Form.Control
                        name="pogodba"
                        type="text"
                        value={projectDodatniEdit.pogodba}
                        onChange={handleFormDataDodatni}
                        placeholder="Naziv pogodbe"
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col className="mb-3">
                      <Form.Label>Referenčna številka: </Form.Label>
                      <Form.Control
                        name="referencnaSt"
                        type="text"
                        value={projectDodatniEdit.referencnaSt}
                        onChange={handleFormDataDodatni}
                        placeholder="Referenčna številka"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col className="mb-3">
                      <Form.Label>Spletna stran projekta: </Form.Label>
                      <Form.Control
                        name="spletnaStran"
                        type="text"
                        value={projectDodatniEdit.spletnaStran}
                        onChange={handleFormDataDodatni}
                        placeholder="Spletna stran projekta"
                      />
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col>
                <div className="p-4">
                  {projectDodatni.cerifID !== -1 && (
                    <Row>
                      <Col className="mb-3">
                        <Form.Label>CERIF</Form.Label>
                        <Select
                          defaultValue={
                            sifrantListCerif[
                              sifrantListCerif.findIndex(
                                (x) => x.value === projectDodatniEdit.cerifID
                              )
                            ]
                          }
                          onChange={(value) =>
                            setStateDodatniPodatkiDropDown(value, "cerif")
                          }
                          menuPortalTarget={document.body}
                          styles={{
                            menuPortal: (base) => ({
                              ...base,
                              zIndex: 9999,
                            }),
                          }}
                          options={sifrantListCerif}
                          placeholder="CERIF"
                          isSearchable
                        />
                      </Col>
                    </Row>
                  )}
                  {projectDodatni.arrsID !== -1 && (
                    <Row>
                      <Col className="mb-3">
                        <Form.Label>ARIS</Form.Label>
                        <Select
                          defaultValue={
                            sifrantListARRS[
                              sifrantListARRS.findIndex(
                                (x) => x.value === projectDodatniEdit.arrsID
                              )
                            ]
                          }
                          onChange={(value) =>
                            setStateDodatniPodatkiDropDown(value, "arrs")
                          }
                          menuPortalTarget={document.body}
                          styles={{
                            menuPortal: (base) => ({
                              ...base,
                              zIndex: 9999,
                            }),
                          }}
                          options={sifrantListARRS}
                          placeholder="ARIS"
                          isSearchable
                        />
                      </Col>
                    </Row>
                  )}
                  {projectDodatni.klasiusID !== -1 && (
                    <Row>
                      <Col className="mb-4">
                        <Form.Label>Klasius</Form.Label>
                        <Select
                          defaultValue={
                            sifrantListKlasius[
                              sifrantListKlasius.findIndex(
                                (x) => x.value === projectDodatniEdit.klasiusID
                              )
                            ]
                          }
                          onChange={(value) =>
                            setStateDodatniPodatkiDropDown(value, "klasius")
                          }
                          menuPortalTarget={document.body}
                          styles={{
                            menuPortal: (base) => ({
                              ...base,
                              zIndex: 9999,
                            }),
                          }}
                          options={sifrantListKlasius}
                          placeholder="Klasius"
                          isSearchable
                        />
                      </Col>
                    </Row>
                  )}

                  <Row>
                    <Col className="mb-3">
                      <Form.Check
                        name="sodGopodarstvom"
                        type="checkbox"
                        onChange={handleFormDataDodatni}
                        checked={projectDodatniEdit.sodGopodarstvom}
                        label="Sodelovanje z gospodarstvom"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col className="mb-3">
                      <Form.Check
                        name="sodStudenti"
                        type="checkbox"
                        onChange={handleFormDataDodatni}
                        checked={projectDodatniEdit.sodStudenti}
                        label="Sodelovanje z študenti"
                      />
                    </Col>
                  </Row>
                  {projectDodatniEdit.sodStudenti && (
                    <Row>
                      <Col className="mb-3">
                        <Form.Label>Število sodelujočih študentov: </Form.Label>
                        <Form.Control
                          name="stStudenti"
                          min={0}
                          type="number"
                          value={projectDodatniEdit.stStudenti}
                          onChange={handleFormDataDodatni}
                          placeholder="Število sodelujočih študentov"
                        />
                      </Col>
                    </Row>
                  )}
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="ps-4 pe-4">
                  <h5>
                    <span>Povzetek:</span>
                  </h5>
                  <Form.Control
                    name="povzetek"
                    placeholder="Povzetek"
                    as="textarea"
                    minLength="3"
                    rows={5}
                    value={projectDodatniEdit.povzetek}
                    onChange={handleFormDataDodatni}
                  />
                </div>
              </Col>
            </Row>
            <Row className=" mt-3">
              <Col>
                <div className=" ps-4 pe-4">
                  <Button variant="primary" type="submit" className="me-3">
                    Potrdi
                  </Button>
                  <Button
                    variant="outline-primary"
                    onClick={() => setStateShowEdit(false)}
                  >
                    Prekliči
                  </Button>
                </div>
              </Col>
            </Row>
          </Card.Body>
        </Form>
      )}
    </Card>
  );
};

export default DodatniPodatki;
