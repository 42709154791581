import React, { useState } from "react";
import { Link } from "react-router-dom";

/*React-Bootstrap components*/
import Nav from "react-bootstrap/Nav";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

/* React-icons*/
import { CgChevronDoubleLeft, CgChevronDoubleRight } from "react-icons/cg";
import {
  BsGrid3X3GapFill,
  BsPeopleFill,
  BsListOl,
  BsFillKanbanFill,
  BsGrid1X2Fill,
} from "react-icons/bs";
import { AiFillProfile, AiOutlineMenu, AiOutlineHistory } from "react-icons/ai";
import { FaUserTie } from "react-icons/fa";
import { HiDocumentReport } from "react-icons/hi";

const SidebarNavigation = ({ role }) => {
  const [menuSizeBig, setMenuSizeBig] = useState(true);
  const [menuSizeClass, setMenuSizeClass] = useState("sidebar-big");
  const [showHamburgerMenu, setShowHamburgerMenu] = useState("show-menu-none");
  
  const sidebarSizeChange = () => {
    if (menuSizeBig) {
      setMenuSizeBig(false);
      setMenuSizeClass("sidebar-small");
    } else {
      setMenuSizeBig(true);
      setMenuSizeClass("sidebar-big");
    }
  };

  const handleSidebar = () => {
    if (showHamburgerMenu === "show-menu-none") {
      setShowHamburgerMenu("show-menu-block");
      setMenuSizeBig(true);
      setMenuSizeClass("sidebar-big");
    } else {
      setShowHamburgerMenu("show-menu-none");
    }
  };

  return (
    <Col className={`sidebar ${menuSizeClass}`}>
      <Nav defaultActiveKey="/home" className="flex-column sidebar-column">
        <div className="mt-4 sidebar-column-hamburger">
          <Button onClick={handleSidebar}>
            <AiOutlineMenu className="sidebar-icon-hamburger " />
          </Button>
        </div>

        <div onClick={() => setShowHamburgerMenu("show-menu-none")}>
          <div className={showHamburgerMenu}>
            <div className="mt-4">
              {(role.aktualniRazpisi === 1 || role.aktualniRazpisi === 2) && (
                <Nav.Link as={Link} to="/AktualniRazpisi" className="mb-3">
                  <BsGrid1X2Fill className="sidebar-icon" />
                  {menuSizeBig && <span>Aktualni razpisi</span>}
                </Nav.Link>
              )}
              {(role.programi === 1 || role.programi === 2) && (
                <Nav.Link as={Link} to="/Programi" className="mb-3">
                  <AiFillProfile className="sidebar-icon" />
                  {menuSizeBig && <span>Programi</span>}
                </Nav.Link>
              )}
              {(role.razpisi === 1 || role.razpisi === 2) && (
                <Nav.Link as={Link} to="/Razpisi" className="mb-3">
                  <BsGrid3X3GapFill className="sidebar-icon" />
                  {menuSizeBig && <span>Razpisi</span>}
                </Nav.Link>
              )}

              {(role.projekti === 1 || role.projekti === 2) && (
                <Nav.Link as={Link} to="/Projekti" className="mb-3">
                  <BsFillKanbanFill className="sidebar-icon" />
                  {menuSizeBig && <span>Projekti</span>}
                </Nav.Link>
              )}
              {(role.partnerji === 1 || role.partnerji === 2) && (
                <Nav.Link as={Link} to="/ProjektniPartnerji" className="mb-3">
                  <FaUserTie className="sidebar-icon" />
                  {menuSizeBig && <span>Projektni partnerji</span>}
                </Nav.Link>
              )}

              {role.admin === true && (
                <Nav.Link as={Link} to="/Avtorizacije" className="mb-3">
                  <BsPeopleFill className="sidebar-icon" />
                  {menuSizeBig && <span>Avtorizacije</span>}
                </Nav.Link>
              )}

              {role.admin === true && (
                <Nav.Link as={Link} to="/izpisi" className="mb-3">
                  <HiDocumentReport className="sidebar-icon" />
                  {menuSizeBig && <span>Izpisi</span>}
                </Nav.Link>
              )}

              <Nav.Link as={Link} to="/Sifranti" className="mb-3">
                <BsListOl className="sidebar-icon" />
                {menuSizeBig && <span>Šifranti</span>}
              </Nav.Link>

              {role.admin === true && (
                <Nav.Link as={Link} to="/Zgodovina-sprememb" className="mb-3">
                  <AiOutlineHistory className="sidebar-icon" />
                  {menuSizeBig && <span>Zgodovina sprememb</span>}
                </Nav.Link>
              )}
            </div>
          </div>
        </div>
        <div className="arrow-hamburger-menu__hidden">
          <div className={showHamburgerMenu}>
            <div className="text-center mb-3">
              {menuSizeBig && (
                <CgChevronDoubleLeft
                  className="sidebar-icon-bottom"
                  onClick={sidebarSizeChange}
                />
              )}

              {!menuSizeBig && (
                <CgChevronDoubleRight
                  className="sidebar-icon-bottom"
                  onClick={sidebarSizeChange}
                />
              )}
            </div>
          </div>
        </div>
      </Nav>
    </Col>
  );
};

export default SidebarNavigation;
