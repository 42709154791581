import React, { useState } from "react";
import * as ReactDOMServer from "react-dom/server";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

import { format } from "date-fns";

function CurrentTenderHtmlSnippet({
  aktualniRazpisi,
  izpostavljeniAktualniRazpis,
  aktualniRazpisiVerzija,
  role,
}) {
  const [datum, setDatum] = useState("");
  const [uspesnoKopirano, setUspesnoKopirano] = useState(false);

  //deep copy aktualnih razpisov
  const poljeAktualniRazpisi = JSON.parse(JSON.stringify(aktualniRazpisi));

  //Odstranimo tiste podkategorije kjer ni razpisov.
  for (let i = 0; i < poljeAktualniRazpisi.length; i++) {
    for (let j = 0; j < poljeAktualniRazpisi[i].podkategorije.length; j++) {
      if (poljeAktualniRazpisi[i].podkategorije[j].razpisi.length === 0) {
        poljeAktualniRazpisi[i].podkategorije.splice(j, 1);
        j = j - 1;
      }
    }
  }

  //Odstranimo kategorije brez podkategorij
  const aktualniRazpisiFiltered = poljeAktualniRazpisi.filter((p, i) => {
    return p.podkategorije.length > 0;
  });

  const html = ReactDOMServer.renderToStaticMarkup(
    <table
      style={{
        width: "100%",
        fontFamily: "Calibri",
        backgroundColor: "lightgrey",
      }}
    >
      <tr>
        <td>
          <table
            style={{
              width: "900px",
              borderCollapse: "collapse",
              backgroundColor: "white",
            }}
            align={"center"}
          >
            <tr
              style={{
                color: "#006a8e",
                fontWeight: "bold",
                fontSize: "30px",
                width: "100%",
              }}
            >
              <td
                style={{
                  paddingLeft: "10px",
                  paddingTop: "15px",
                  backgroundColor: "white",
                }}
                colSpan={2}
              >
                Aktualni razpisi
              </td>
            </tr>
            <tr
              style={{
                backgroundColor: "#EDEDED",
              }}
            >
              <td
                style={{
                  fontSize: "22px",
                  paddingTop: "15px",
                  paddingBottom: "15px",
                  paddingLeft: "10px",
                }}
              >
                Oddelek za raziskovalno in umetniško dejavnost
              </td>
              <td
                style={{
                  color: "grey",
                  textAlign: "right",
                  paddingRight: "10px",
                }}
              >
                Aktualni razpisi <br />
                {aktualniRazpisiVerzija.stevilka}/
                {new Date(aktualniRazpisiVerzija.datum).getFullYear()} | <br />
                {aktualniRazpisiVerzija.datum !== "" &&
                aktualniRazpisiVerzija &&
                aktualniRazpisiVerzija.datum
                  ? format(new Date(aktualniRazpisiVerzija.datum), "d. M. yyyy")
                  : ""}
              </td>
            </tr>
            <tr>
              <td
                colSpan={2}
                style={{
                  paddingLeft: "10px",
                  paddingTop: "15px",
                  paddingBottom: "15px",
                  backgroundColor: "white",
                }}
              >
                Opomba: Pri rokih prijave je lahko ob datumu določena tudi ura.
                Preverite v razpisni dokumentaciji. Ure so navedene samo pri
                razpisih, objavljenih s strani ARIS.
              </td>
            </tr>
          </table>

          <table
            style={{
              width: "900px",
              borderCollapse: "collapse",
              backgroundColor: "white",
            }}
            align={"center"}
          >
            {izpostavljeniAktualniRazpis.length > 0 && (
              <tr
                style={{
                  backgroundColor: "#2CB0AE",
                  color: "white",
                  fontWeight: "bold",
                }}
              >
                <td style={{ width: "250px", padding: "10px" }} colSpan={2}>
                  IZPOSTAVLJENI RAZPISI
                </td>

                <td
                  style={{
                    width: "150px",
                    textAlign: "right",
                    paddingRight: "10px",
                  }}
                >
                  Rok prijave
                </td>
              </tr>
            )}

            {izpostavljeniAktualniRazpis.map((razpis) => {
              return (
                <tr key={razpis.razpisID}>
                  <td
                    style={{
                      width: "250px",
                      padding: "10px",
                      borderBottom: "1px solid silver",
                    }}
                  >
                    {razpis.povezava !== "" ? (
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={razpis.povezava}
                      >
                        {razpis.razpis_naziv}
                      </a>
                    ) : (
                      razpis.razpis_naziv
                    )}
                  </td>
                  <td
                    style={{ width: "500px", borderBottom: "1px solid silver" }}
                  >
                    {razpis.opis}
                  </td>
                  <td
                    style={{
                      textAlign: "right",
                      width: "150px",
                      paddingRight: "10px",
                      borderBottom: "1px solid silver",
                    }}
                  >
                    <div>
                      {razpis.novo === true && (
                        <span
                          style={{
                            backgroundColor: "#218838",
                            borderBottom: "1px solid silver",
                            color: "#fff",
                            paddingTop: "2px",
                            borderRadius: "5px",
                            marginLeft: "6px",
                            fontSize: "13px",
                          }}
                        >
                          Novo
                        </span>
                      )}
                    </div>
                    <div>
                      {razpis.prijava_datum === "2100-01-01" && "Odprt razpis"}
                      {razpis.prijava_datum !== null &&
                        razpis.prijava_datum !== "2100-01-01" &&
                        format(new Date(razpis.prijava_datum), "d. M. yyyy")}
                    </div>
                    <div>
                      {razpis.prijava_ura !== null &&
                        `do: ${razpis.prijava_ura.slice(0, -3)}`}
                    </div>
                  </td>
                </tr>
              );
            })}
          </table>
          <table
            style={{
              width: "900px",
              marginBottom: "30px",
              borderCollapse: "collapse",
            }}
            align={"center"}
          >
            {aktualniRazpisiFiltered.map((kategorija, index) => {
              return (
                <>
                  <tr
                    style={{
                      backgroundColor: "#006a8e",
                      color: "white",
                      width: "100%",
                      fontWeight: "bold",
                    }}
                    key={kategorija.kategorijaID}
                  >
                    <td
                      style={{
                        width: "250px",
                        padding: "10px",
                        borderBottom: "1px solid silver",
                      }}
                      colSpan={2}
                    >
                      {kategorija.kategorija}
                    </td>
                    <td
                      style={{
                        textAlign: "right",
                        width: "150px",
                        paddingRight: "10px",
                        borderBottom: "1px solid silver",
                      }}
                    >
                      Rok prijave
                    </td>
                  </tr>

                  {kategorija.podkategorije.map((podkategorija) => {
                    return (
                      <>
                        {podkategorija.podkategorijaID !== -1 &&
                          podkategorija.razpisi.length > 0 && (
                            <tr
                              key={podkategorija.podkategorijaID}
                              style={{
                                backgroundColor: "#ededed",
                              }}
                            >
                              <td
                                style={{
                                  width: "250px",
                                  padding: "10px",
                                  fontWeight: "bold",
                                  backgroundColor: "#ededed",
                                  borderBottom: "1px solid silver",
                                }}
                                colSpan={3}
                              >
                                {podkategorija.podkategorija}
                              </td>
                            </tr>
                          )}
                        {podkategorija.razpisi.length > 0 &&
                          podkategorija.razpisi.map((razpis) => {
                            return (
                              <tr
                                key={razpis.razpisID}
                                style={{
                                  backgroundColor: "white",
                                }}
                              >
                                <td
                                  style={{
                                    width: "250px",
                                    padding: "10px",
                                    borderBottom: "1px solid silver",
                                  }}
                                >
                                  {razpis.url !== "" ? (
                                    <a
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      href={razpis.url}
                                    >
                                      {razpis.naziv}
                                    </a>
                                  ) : (
                                    <>{razpis.naziv}</>
                                  )}
                                </td>
                                <td
                                  style={{
                                    width: "500px",
                                    borderBottom: "1px solid silver",
                                  }}
                                >
                                  {razpis.opis !== ""
                                    ? razpis.opis
                                    : "Ni opisa"}
                                </td>
                                <td
                                  style={{
                                    textAlign: "right",
                                    width: "150px",
                                    paddingRight: "10px",
                                    borderBottom: "1px solid silver",
                                  }}
                                >
                                  <div>
                                    {razpis.novo === true && (
                                      <span
                                        style={{
                                          backgroundColor: "#218838",
                                          borderBottom: "1px solid silver",
                                          color: "#fff",
                                          padding: "2px",
                                          borderRadius: "5px",
                                          marginLeft: "6px",
                                          fontSize: "13px",
                                        }}
                                      >
                                        Novo
                                      </span>
                                    )}
                                  </div>
                                  <div>
                                    {razpis.datum === "2100-01-01" &&
                                      "Odprt razpis"}
                                    {razpis.datum !== null &&
                                      razpis.datum !== "2100-01-01" &&
                                      format(
                                        new Date(razpis.datum),
                                        "d. M. yyyy"
                                      )}
                                  </div>
                                  <div>
                                    {razpis.ura !== null &&
                                      `do: ${razpis.ura.slice(0, -3)}`}
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                      </>
                    );
                  })}
                </>
              );
            })}
          </table>
        </td>
      </tr>
    </table>
  );

  function clipboardWrite() {
    let type = "text/html";
    let blob = new Blob([html], { type: "text/html" });
    let data = [new window.ClipboardItem({ [type]: blob })];

    navigator.clipboard.write(data).then(
      function () {
        setUspesnoKopirano(true);
      },
      function () {
        /*
        setUspesnoKopirano(false);
        console.error("Unable to write to clipboard. :-(");

           */
      }
    );

    const datumKopiranja = format(new Date(), "d. M. yyyy");
    setDatum(datumKopiranja);

    setTimeout(() => {
      setUspesnoKopirano("");
    }, 3000);
  }

  return (
    <>
      <Row>
        <Col>
          {role.aktualniRazpisi === 2 && (
            <>
              <Button
                variant="success"
                className="ml-1 mb-2"
                onClick={clipboardWrite}
              >
                Izvozi predlogo
              </Button>
            </>
          )}
        </Col>
      </Row>
      <Row>
        <Col>
          {uspesnoKopirano && (
            <h5 className="text-success">Predloga je bila uspešno kopirana!</h5>
          )}
        </Col>
      </Row>
    </>
  );
}

export default CurrentTenderHtmlSnippet;
